import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";

export interface IRecoveryDto {
  username: string;
  verificationcode: string;

}

export class Recovery {
  public username: string;
  public verificationcode: string;

  private constructor() {
    this.username = null;
    this.verificationcode = null;
  }


  public static create(): Recovery {
    return new Recovery();
  }

  public toDto(): IRecoveryDto {
    return {
      username: this.username,
      verificationcode: this.verificationcode,
    }
  }

}  