import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";
import { Execution } from "@Core/Models/Execution";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import IsEqual from "lodash.isequal";

@Component
export default class DimensionSelector extends Vue {

  @Prop({ type: [String, Array], default: null })
  protected serviceId: ServiceID | ServiceID[];

  @Prop({ type: [Object, Array], default: null })
  protected value: Dimension | Dimension[];

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected noIcon: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;
  
  @Prop({ type: String, default: "No matching dimension" })
  protected noMatchText: string;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected filterable: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @Prop({ type: String, default: "Select a dimension" })
  protected placeholder: string;

  @Prop({ type: Boolean, default: false })
  protected refetch: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: Object, default: null })
  protected filteredDimensions:  ServiceMap<Dimension[]>;

  @Prop({ type: Function, default: () => () => true })
  protected filter: (dimension: Dimension) => boolean;

  @MapGetter(NavigationStore.Mapping)
  protected currentServiceId: ServiceID;

  @MapGetter(LicensingStore.Mapping)
  protected dimensions: ServiceMap<Dimension[]>;

  @MapAction(LicensingStore.Mapping)
  protected fetchAllDimensions: (serviceId: ServiceID) => Promise<void>;

  protected searchText: string = null;
  protected visibleDropdown: boolean = false;
  protected shownDimensions: ServiceMap<Dimension[]>
  protected disabledInput: boolean = false;

  protected getDimensions = Execution.create(
    (serviceIds: ServiceID[]) => {
      const fetchs = serviceIds
        .filter(this.shouldRefetch)
        .map(serviceId => this.fetchAllDimensions(serviceId));
      return Promise.all(fetchs);
    });

  protected shouldRefetch(serviceId: ServiceID): boolean {
    if (this.refetch) { return true; }
    const serviceDims = this.dimensions[serviceId];
    return !serviceDims || (Array.isArray(serviceDims) && serviceDims.length === 0);
  }

  protected get selectedServiceIds(): ServiceID[] {
    let services: ServiceID[] = [this.currentServiceId];
    if (!!this.serviceId || (Array.isArray(this.serviceId) && this.serviceId.length > 0)) {
      services = Array.isArray(this.serviceId) ? this.serviceId : [this.serviceId];
    }
    return services.filter(id => !!id);
  } 

  @Watch("selectedServiceIds", { immediate: true })
  protected onServiceIdChange(newServiceIds: ServiceID[], oldServiceIds: ServiceID[]) {
      if (!IsEqual(newServiceIds, oldServiceIds)) {
        this.getDimensions.run(this.selectedServiceIds);
        this.shownDimensions = this.dimensions
      }
  }

  protected get availableDimensionsMap(): ServiceMap<Dimension[]> {
    if (this.filteredDimensions !== null) {
      const keys = Object.keys(this.filteredDimensions)
      if (keys.length == 1 && this.filteredDimensions[keys[0]] && this.filteredDimensions[keys[0]].length == 1){
        this.onDimensionSelected(this.filteredDimensions[keys[0]][0])
        this.disabledInput = true
      }   
      return this.filteredDimensions
    } else{
      return Object.keys(this.dimensions)
      .sort((a: ServiceID, b: ServiceID) => this.getServiceIdName(a).localeCompare(this.getServiceIdName(b)))
      .filter((id: ServiceID) => this.selectedServiceIds.includes(id))
      .reduce((map, id) => {
        const dimensions: Dimension[] = this.dimensions[id]
          .filter((dim: Dimension) => {
            return this.filter(dim) && dim.meetsFilter(this.searchText)});
        if (dimensions.length > 0) {
          map[id] = dimensions;
        }
        return map;
      }, {} as ServiceMap<Dimension[]>);
    }
    
  }

  protected get hasAvailableDimensions(): boolean {
    const dimMap = this.availableDimensionsMap;
    const dimArr = Object.keys(dimMap).reduce((arr, serviceId) => {
      arr.push(dimMap[serviceId]);
      return arr;
    }, [] as Dimension[]);
    return dimArr.length > 0;
  }

  protected get serviceIdCount(): number {
    return Array.isArray(this.serviceId) 
      ? this.serviceId.length 
      : !!this.serviceId ? 1 : 0;
  }

  protected getServiceIdName(serviceId: ServiceID): string {
    return ServiceID.nameOf(serviceId);
  }
  
  protected onSelectFilterChange(text: string): void {
    this.searchText = text || null;
  }

  protected get hasNoValue(): boolean {
    if (!this.value) { 
      return true; 
    } else if (Array.isArray(this.value)) { 
      return this.value.length === 0;
    } else {
      return false;
    }
  }

  protected async onVisibleChange(visible: boolean) {
    this.visibleDropdown = visible;
    if (visible) { return; }
    await Utils.sleep(200);
    this.searchText = null;
  }

  protected onDimensionSelected(dimension: Dimension | Dimension[]): void {
    const selectedDimension = dimension || null;
    this.$emit("input", selectedDimension);
    this.$emit("update:value", selectedDimension);
    this.$emit("select", selectedDimension);
    this.$emit("change", selectedDimension);
    this.searchText = null;
  }
  
  protected get popperClass() {
    const classes: string[] = ["dimension-selector-dropdown"]; 
    if (!this.compact && this.multiple) {
      classes.push("is-no-checkmark");
    }
    if (this.serviceIdCount <= 1) {
      classes.push("is-no-group");
    }
    return classes.join(" ");
  }

}