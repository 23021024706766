import NotificationContentMixin, { INotificationContent, UiSuccessNotificationMap } from "@Module/Notifications/Mixins/NotificationContentMixin";
import NotificationsOutboxStore, { SendOrgInvitationPayload } from "@Module/Notifications/Store/NotificationsOutbox.store";
import { OrgInvitationNotification } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { NotificationFactory } from "@Module/Notifications/Models/Notification.factory";
import { Notification } from "@Module/Notifications/Models/Notification.model";
import { Component, MapAction, MapGetter, mixins, Prop } from "types-vue";
import AppRestartMixin from "@Core/Mixins/AppRestart.mixin";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { Utils } from "@Core/Utils/Utils";

@Component
export default class OrgInvitationNotifContent extends mixins(NotificationContentMixin, AppRestartMixin) implements INotificationContent {

  @Prop({ type: Object, required: true })
  protected notification: OrgInvitationNotification;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptOrgInvitationNotification: (notification: OrgInvitationNotification) => Promise<void>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected sendOrgInvitation: (payload: SendOrgInvitationPayload) => Promise<void>;

  protected resendInvitation = Execution.create(
    async () => this.sendOrgInvitation({ 
      email: this.notification.recipient, 
      role: this.notification.role 
    })
  );

  public getSuccessNotifConfig(): UiSuccessNotificationMap {
    return {
      Accept: {
        title: "Invitation accepted", 
        message: `Your invitation to access ${this.notification.organization.name} organization as ${this.notification.role.name} has been successfully accepted.`,
        duration: 6000
      },
      Decline: {
        title: "Invitation declined", 
        message: `Your invitation to access ${this.notification.organization.name} organization as ${this.notification.role.name} has been successfully declined.`,
        duration: 6000
      },
      Cancel: {
        title: "Invitation cancelled", 
        message: `The invitation has been successfully cancelled.`
      }
    }
  }

  public get notificationRecipient(): string {
    if (!this.isInbox) { return this.notification.recipient; }
    else if (this.$mq.sm) return Utils.smartTrim(this.notification.recipient, 13);
    else if (this.$mq.md) return Utils.smartTrim(this.notification.recipient, 20);
    else return Utils.smartTrim(this.notification.recipient, 26);
  }

  public async resend(): Promise<void> {
    try {
      await this.resendInvitation.run();
      Notify.Success({
        title: "Invitation resent", 
        message: `The invitation has been sent again.`
      });
    } catch (error) {
      Notify.Error({
        title: "Invitation resend error", 
        message: error.message
      });
    }
  }

  public discardChanges(notification: Notification) {
    Object.assign(notification, NotificationFactory.fromDto(notification.remote));
  }

  public async accept(): Promise<boolean> {
    await this.acceptOrgInvitationNotification(this.notification);
    if (this.noOrganization) {
      this.promptAppRestart();
    }
    return true;
  }

}