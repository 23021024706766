import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class LicensingStatusBreakdown extends Vue {

  @Prop({ type: Array, required: true })
  protected status: LicensingStatus[];

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: null })
  protected plain: Boolean;

  @Prop({ type: Boolean, default: null })
  protected bordered: Boolean;
  
  protected get firstLoad(): boolean {
    return this.status.length === 0 && this.loading;
  }

  protected get activeCount(): number {
    return this.status.filter(s => s.isActive).length;
  }

  protected get expiredCount(): number {
    return this.status.filter(s => s.isExpired).length;
  }

  protected get inGraceCount(): number {
    return this.status.filter(s => s.isInGrace).length;
  }

}