import { decode as JwtDecoder } from "jsonwebtoken";
import DayJS, { Dayjs } from "dayjs";

export interface TokenPayload {
  readonly sub: string;
  readonly email_verified: boolean;
  readonly iss: string;
  readonly "cognito:username": string;
  readonly "custom:tenant_id": string;
  readonly aud: string;
  readonly event_id: string;
  readonly "custom:Organization": string;
  readonly "custom:tenant_role": string;
  readonly "custom:surname": string;
  readonly "custom:allowed_org_ids": string;
  readonly "custom:preview_access": string;
  readonly token_use: string;
  readonly auth_time: number;
  readonly name: string;
  readonly exp: number;
  readonly iat: number;
  readonly email: string;
}

export class SessionToken {
  public readonly raw: string;  
  private readonly payload: TokenPayload;
  public readonly age: Dayjs;
  public readonly expiration: Dayjs;
  public readonly accessToken?: string;

  public constructor(token: string, accessToken?: string) {
    this.raw = token;
    this.accessToken = accessToken;
    this.payload = JwtDecoder(token) as TokenPayload;
    this.age = DayJS(this.payload.iat * 1000);
    this.expiration = DayJS(this.payload.exp * 1000);
  }

  public get username(): string {
    return this.payload["cognito:username"];
  }

  public get duration(): string {
    return this.age.to(this.expiration, true);
  }

  public get hasExpired(): boolean {
    return DayJS().isAfter(this.expiration);
  }

  public get relativeExpiration(): string {
    return DayJS().to(this.expiration);
  }
}