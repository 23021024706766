import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { Component, MapGetter } from "types-vue";
import Vue from "vue";

@Component
export default class PreviewAccessView extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  protected requestPreviewAccess() {
    window.open(`mailto:${process.env.VUE_APP_CONTACT_EMAIL}`);
  }

}