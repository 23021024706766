import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class SubMenuItem extends Vue {
  
  @Prop({ type: String, required: true })
  protected label: string;

  @Prop({ type: String, required: true })
  protected index: string;

  @Prop({ type: Boolean, required: false })
  protected disabled: boolean;

}