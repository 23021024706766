import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class TrialDimensionsSelector extends Vue {

  @Prop({ type: Number, required: true })
  protected slots: number;

  @Prop({ type: Array, required: true })
  protected dimensions: Dimension[];

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected selectionMap: Record<number, Dimension> = {};
  protected unitsMap: Record<string, number> = {};

  protected get isSingleOption(): boolean {
    return this.dimensions.length === 1;
  }

  protected get hasSelection(): boolean {
    return Object.keys(this.unitsMap).length > 0;
  }

  protected onDimensionSelected(index: number, dim: Dimension) {
    this.selectionMap[index] = dim;
    this.unitsMap = this.computeDimensionUnits();
    this.$emit("select", this.unitsMap);
  }

  protected computeDimensionUnits(): Record<string, number> {
    return Object.values(this.selectionMap).reduce((acc, curr) => {
      acc[curr.name] = (acc[curr.name] ?? 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  }

}