import { ForgotPwd } from "@Module/Auth/Models/ForgotPwd/ForgotPwd.model";
import NewPwdForm from "../NewPwdForm/NewPwdForm";
import AuthApi from "@Module/Auth/API/Auth.api";
import { ElForm } from "element-ui/types/form";
import { Utils } from "@Core/Utils/Utils";
import { Component, Prop } from "types-vue";
import Vue from "vue";
import { Notify } from "@Core/Utils/Notify";

const enum ForgotPwdStep {
  RecoveryEmail = 0,
  ChangePassword = 1,
  Finish = 2
}

@Component
export default class ForgotPwdPanel extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected currentStep: ForgotPwdStep = ForgotPwdStep.RecoveryEmail;
  protected forgotPwdModel: ForgotPwd = ForgotPwd.create();
  protected loading: boolean = false;
  protected error: Error = null;

  protected validationRules = {
    email: [
      { type: "email", message: "Please enter a valid email address.", trigger: "blur" },
      { required: true, message: "Please enter your recovery email address.", trigger: "change" },
      { required: true, message: "Please enter your recovery email address.", trigger: "blur" }
    ],
    code: [
      { required: true, message: "Please enter your verification code.", trigger: "change" },
      { required: true, message: "Please enter your verification code.", trigger: "blur" },
      { validator: this.onlyDigits, trigger: "blur" },
    ]
  }

  protected onlyDigits(rule: any, value: string, callback: (error?: Error) => void): void {
    const codePattern = /^[0-9]{6,6}$/;
    return codePattern.test(value) 
      ? callback() 
      : callback(new Error("Invalid verification code."));
  }

  protected onNewPasswordChange(value: string, valid: boolean) {
    this.forgotPwdModel.newPassword = valid ? value : null;
  }

  protected async requestForgotPwd() {
    try {
      await (this.$refs.emailForm as ElForm).validate();
      await this.sendToServer();
      (this.$refs.codeForm as ElForm).clearValidate();
    } catch {}
  }

  protected async confirmForgotPwd() {
    try {
      const codeValidation = (this.$refs.codeForm as ElForm).validate();
      const pwdValidation = (this.$refs.newPwdForm as NewPwdForm).validate();
      await Promise.all([codeValidation, pwdValidation]);
      await this.sendToServer();
    } catch {}
  }

  protected setLoading(loading: boolean) {
    this.loading = loading;
    this.$emit("update:disabled", loading);
  }

  protected async sendToServer() {
    try {
      this.error = null;
      this.setLoading(true);
      if (this.currentStep === ForgotPwdStep.RecoveryEmail) {
        await AuthApi.forgotPwd(this.forgotPwdModel);
        this.currentStep = ForgotPwdStep.ChangePassword;
      } else {
        await AuthApi.confirmForgotPwd(this.forgotPwdModel);
        this.currentStep = ForgotPwdStep.Finish;
        Notify.Success({
          title: "Password changed", 
          message: "The account password has been successfully changed."
        });
        await Utils.sleep(250);
        this.$emit("success");
      }
    } catch (error) {
      this.error = error;
    } finally {
      this.setLoading(false);
    }
  }

  public async closeGuard(close: VoidFunction) {
    if (this.currentStep !== ForgotPwdStep.ChangePassword) {
      return close();
    }
    
    try {
      const message = "<p>Do you really want to finish the current password recovery process?</p>"
        + "<p>You can start the password recovery process again at any time.</p>";
      await this.$confirm(message, "Finish password recovery", {
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Finish",
        dangerouslyUseHTMLString: true
      });
      return close();
    } catch {}
  }

}