import { BackupCloudProvider, CloudProviderType } from "./BackupCloudProvider.model";
import { BackupObject } from "../BackupObject.model";
import { IBackupDto } from "../Backup.model";

export class BackupGCloudCloudProvider implements BackupCloudProvider {
  public readonly location: string;
  public readonly path: string;
  public readonly encryption?: string;
  public readonly encryptionKeyName?: string;

  public readonly region: string;

  public static fromDto(dto: IBackupDto): BackupGCloudCloudProvider {
    return new BackupGCloudCloudProvider(dto);
  }

  protected constructor(dto: IBackupDto) {
    this.location = dto.location_name;
    this.path = dto.location_path;
    this.encryption = dto.encryption_type;
    this.encryptionKeyName = dto.encryption_key;
    this.region = dto.region;
  }

  public get providerName(): string {
    return CloudProviderType.nameOf(CloudProviderType.GCloud);
  }

  public buildLocationUrl(object?: BackupObject): string {
    return null;
  }
}