import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { NotifConfiguringResponseEvent } from "../NotificationListItem/NotificationListItem";
import { Notification } from "@Module/Notifications/Models/Notification.model";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import { Execution } from "@Core/Models/Execution";
import Vue from "vue";

@Component
export default class NotificationsInboxPopover extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @MapGetter(NotificationsInboxStore.Mapping)
  protected inbox: Notification[];

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<void>

  protected getNotifications = Execution.create(() => this.fetchInbox());
  protected visible: boolean = false;

  protected onPopoverVisible() {
    this.getNotifications.run();
  }

  protected get firstLoad(): boolean {
    return this.inbox.length === 0 && this.getNotifications.loading;
  }

  protected async onNotifConfigReponseChange(event: NotifConfiguringResponseEvent) {
    const popover: any = this.$refs.popover;
    if (!popover) { return; }
    if (event.configuring) {
      document.removeEventListener("click", popover.handleDocumentClick);
    } else {
      document.addEventListener("click", popover.handleDocumentClick);
    }
  }
  
}