import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class DateSpan extends Vue {

  @Prop({ type: String, default: null })
  protected fromLabel: string;

  @Prop({ type: String, default: null })
  protected toLabel: string;

  @Prop({ type: String, default: "solid" })
  protected lineStyle: "solid" | "dashed" | "dotted" | "none";

  protected get marginRightStyle() {
    return {
      "margin-right": this.lineStyle === "solid" ? "0" : this.lineStyle === "dashed" ? "4px" : "2px"
    }
  }

  protected get linesStyle() {
    return {
      "border-bottom-width": this.lineStyle === "solid" ? "1px" : "2px",
      "border-bottom-style": this.lineStyle
    }
  }

}