import DayJS, { Dayjs } from "dayjs";
import { Release } from "./Release.model";
import { ServiceID } from "@Service/ServiceID";

export interface IPlatformDto {
  id?: number;
  name: string;
  description: string;
  releases: Release[];
  product_version_id?: number
}

export interface ICreatePlatformDto {
  product: ServiceID,
  name: string,
  description: string,
  product_version_id?: number,
}

export class Platform {
  id: number;
  name: string;
  description: string;
  releases: Release[];
  productVersionId?: number;


  private constructor(dto: IPlatformDto) { 
    this.id = dto.id
    this.name = dto.name
    this.description = dto.description
    this.releases = dto?.releases
    this.productVersionId = dto?.product_version_id;
  }

  public static create(dto?: Partial<IPlatformDto>): Platform {
    return new Platform({
      name: dto?.name, 
      description: dto?.description,
      releases: dto?.releases,
      product_version_id: dto?.product_version_id
    });
  }

  public static fromDto(dto: IPlatformDto): Platform {
    return new Platform(dto);
  }

  public toDto(): IPlatformDto {
    return  {
      id: this.id,
      name: this.name,
      description: this.description,
      releases: this.releases
      
    }
  }
}