import { Backup, IBackupDto } from "./Backup.model";

export interface IBackupHistoryPageDto {
  page_number: number;
  page_size: number;
  page_items: number;
  total_pages: number;
  total_items: number;
  sort_by: string;
  sort_dir: "ASC" | "DESC";
  items: IBackupDto[];
}

export class BackupHistoryPage {
  public readonly page: number;
  public readonly total: number;
  public readonly pageSize: number;
  public readonly records: Backup[];

  public static fromDto(dto: IBackupHistoryPageDto): BackupHistoryPage {
    return new BackupHistoryPage(dto);
  }

  private constructor(dto: IBackupHistoryPageDto) {
    this.page = dto.page_number;
    this.pageSize = dto.page_size;
    this.total = dto.total_items;
    this.records = dto.items.map(Backup.fromDto);
  }
}