import { ElNotificationComponent, ElNotificationOptions } from "element-ui/types/notification";
import { Notification } from "element-ui";

export class Notify {

  private static readonly Offset = 50;

  public static Indeterminate(options: ElNotificationOptions, duration: number = 0): ElNotificationComponent {
    options.customClass ??= "";
    options.customClass += " is-indeterminate";
    return Notification({ showClose: false, ...Notify.MergeDefaults(options), iconClass: "el-icon-loading", duration });
  }

  public static Create(options: ElNotificationOptions): ElNotificationComponent {
    return Notification(Notify.MergeDefaults(options));
  }

  public static Info(options: ElNotificationOptions): ElNotificationComponent {
    options.customClass ??= "";
    options.customClass += " is-info";
    return Notification.info(Notify.MergeDefaults(options));
  }

  public static Success(options: ElNotificationOptions): ElNotificationComponent {
    options.customClass ??= "";
    options.customClass += " is-success";
    return Notification.success(Notify.MergeDefaults(options));
  }

  public static Warning(options: ElNotificationOptions): ElNotificationComponent {
    options.customClass ??= "";
    options.customClass += " is-warning";
    return Notification.warning(Notify.MergeDefaults(options));
  }

  public static Error(options: ElNotificationOptions): ElNotificationComponent {
    options.customClass ??= "";
    options.customClass += " is-error";
    return Notification.error(Notify.MergeDefaults(options));
  }

  public static CloseAll() {
    (Notification as any).closeAll();
  }

  private static MergeDefaults(options: ElNotificationOptions) {
    const isTop = !options.position || options.position.startsWith("top");
    return { ...options, offset: isTop ? Notify.Offset : 0 };
  }
}