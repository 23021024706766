import { BackupFilters, IBackupFiltersDto, SortOrder } from "@Service/Emory/Models/Backups/BackupFilters.model";
import { BackupHistoryPage } from "@Service/Emory/Models/Backups/BackupHistoryPage.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { FileSizeFilter } from "@Service/Emory/Models/FileSizeFilter.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import EmoryBackupsStore from "@Service/Emory/Store/EmoryBackups.store";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import { Backup } from "@Service/Emory/Models/Backups/Backup.model";
import { Component, MapAction, MapGetter, Watch } from "types-vue";
import { SortOrder as ElSortOrder } from "element-ui/types/table";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { Execution } from "@Core/Models/Execution";
import { ServiceID } from "@Service/ServiceID";
import Debounce from "lodash.debounce";
import IsEqual from "lodash.isequal";
import Vue from "vue";

@Component
export default class EmoryBackupHistory extends Vue {

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(EmoryBackupsStore.Mapping)
  protected currentBackupHistoryPage: BackupHistoryPage;

  @MapGetter(EmoryBackupsStore.Mapping)
  protected currentFilters: IBackupFiltersDto;

  @MapAction(EmoryBackupsStore.Mapping)
  private fetchBackups: (filters: BackupFilters) => Promise<BackupHistoryPage>;
  
  @MapAction(EmoryBackupsStore.Mapping)
  private clearLastBackupHistorySearch: () => Promise<void>;

  protected expandedRows: number[] = [];
  protected filtersDrawerVisible: boolean = false;
  protected pageSizes: number[] = [10, 20, 30, 40, 50];
  protected filters: BackupFilters = BackupFilters.create();
  protected getBackups = Execution.create((filters: BackupFilters) => this.fetchBackups(filters));

  protected async mounted() {
    if (!IsEqual(this.filters.toDto(), this.currentFilters)) {
      await this.clearLastBackupHistorySearch();
    }
    await this.fetchBackupHistory();
    this.$watch("filters.dto", this.onFiltersDtoChange, { deep: true });
  }

  protected async onFiltersDtoChange(newValue: IBackupFiltersDto, oldValue: IBackupFiltersDto) {
    if (IsEqual(newValue, this.currentFilters)) { 
      return; 
    } else if (!oldValue 
      || !this.filters.isFiltering
      || newValue.page_number !== oldValue.page_number 
      || newValue.page_size !== oldValue.page_size
      || newValue.sort_by !== oldValue.sort_by
      || newValue.sort_dir !== oldValue.sort_dir) {
        this.fetchBackupHistory(); // Immediate
      } else {
      this.debouncedFetchBackupHistory(); // Debounced
    }
  }

  protected debouncedFetchBackupHistory = Debounce(this.fetchBackupHistory, 500);
  protected async fetchBackupHistory() {
    this.getBackups.reset();
    await this.getBackups.run(this.filters);
  }

  protected get fileSizeSerializer(): Serializer<FileSizeFilter> {
    return {
      deserialize(param: string | string[]): FileSizeFilter {
        return FileSizeFilter.create(isNaN(Number(param)) ? 0 : Number(param));
      },
      serialize(value: FileSizeFilter): string {
        return !!value?.bytes ? value.bytes.toString() : undefined;
      }
    }
  }
  
  protected async clearFilters() {
    try {
      const msg = `Are you sure you want to clear all current filter criteria? This action cannot be undone.`;
      await this.$confirm(msg, "Clear confirmation", {
        type: "warning",
        confirmButtonText: "Clear filters",
        cancelButtonText: "Cancel" 
      });
      this.filters = BackupFilters.create();
    } catch {}
  }

  @Watch("$mq.lg")
  protected onLargeScreenSwitch(newVal: boolean, oldVal: boolean) {
    if (!newVal && oldVal) {
      this.filtersDrawerVisible = false;
    }
  }

  protected get firstLoad(): boolean {
    return this.backupRecords.length === 0 && this.getBackups.loading;
  }

  protected get backupRecords(): Backup[] {
    return this.currentBackupHistoryPage?.records || [];
  }

  protected get hasRecords(): boolean {
    return this.backupRecords.length > 0;
  }

  protected get totalItemCount(): number {
    return this.currentBackupHistoryPage?.total ?? Infinity;
  }

  protected get expandedRowIds(): number[] {
    return this.hasRecords ? this.expandedRows : [];
  }

  protected toggleExpansion(backup: Backup) {
    const index = this.expandedRows.indexOf(backup.id);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [backup.id];
    }
  }

  protected onSortChange(sorting: { prop: string, order: ElSortOrder }): void {
    if (!!sorting.order) {
      this.filters.sorting = {
        prop: sorting.prop,
        order: sorting.order as ElSortOrder === "ascending" 
          ? SortOrder.Ascending 
          : SortOrder.Descending
      }
    } else {
      this.filters.sorting = { 
        prop: null, 
        order: null 
      };
    }
  }

  protected viewDetails(backup: Backup) {
    this.$router.push({ 
      name: "backup-details", 
      params: { id: backup.id.toString() }
    });
  }

  protected get permissions() {
    return {
      CanExportBackups: this.accessRole.can(ServiceID.Emory, Permission.ExportBackups)
    }
  }

}