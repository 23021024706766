import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { EmoryHostFilters } from "./EmoryHostFilters.model";

export class EmoryLicenseFilters extends LicenseFilters {

  public hosts: EmoryHostFilters;

  protected initialize() {
    super.initialize();
    this.hosts = EmoryHostFilters.create();
  }

  public static create(): EmoryLicenseFilters {
    return new EmoryLicenseFilters();
  }

}