export interface IContractPurchaseDto {
  contractid: string;
  error: string;
  update: string;
}

export const enum PurchaseError {
  AlreadyAssigned = 10,
  AssignPending = 20
}

export class ContractPurchase {
  public readonly id: number;
  public readonly error: PurchaseError;
  public readonly isUpdate: boolean;

  private constructor(dto: IContractPurchaseDto) {
    this.id = parseInt(dto.contractid);
    this.error = !!dto.error ? parseInt(dto.error) as PurchaseError : undefined;
    this.isUpdate = !!dto.update ? true : false;
  }
  
  public static fromQuery(query: any): ContractPurchase | null {
    if (ContractPurchase.hasContractPurchase(query)) {
      return new ContractPurchase(query);
    } else {
      return null;
    }
  }

  public get isError(): boolean {
    return this.error !== undefined;
  }

  public static clearQuery(query: any): any {
    delete query.contractid;
    delete query.error;
    delete query.update;
    return query;
  }

  public static hasContractPurchase(query: any): query is IContractPurchaseDto {
    if (!query) return false;
    return !!(query as IContractPurchaseDto).contractid;
  }

  public get errorDescription(): string | null {
    if (!this.isError) { return null; }
    if (this.isUpdate) { return "An error occurred while updating contract."; }
    switch (this.error) {
      case PurchaseError.AlreadyAssigned: return "Contract is already purchased and assigned to an organization."
      case PurchaseError.AssignPending: return "Contract is already purchased and pending for organization assignment."
      default: return "An error occurred while purchasing the contract."
    }
  }
}