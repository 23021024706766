import LicensingStore, { FetchLicenseByIdPayload, UnregisterHostPayload } from "@Module/Licensing/Store/Licensing.store";
import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapAction, MapGetter, mixins, Prop, Watch } from "types-vue";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { HostFilters } from "@Module/Licensing/Models/HostFilters.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { License } from "@Module/Licensing/Models/License.model";
import FileDownloadMixin from "@Core/Mixins/FileDownload.mixin";
import LicensingApi from "@Module/Licensing/API/Licensing.api";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { Host } from "@Module/Licensing/Models/Host.model";
import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";

@Component
export default class LicenseDetailsView extends mixins(FileDownloadMixin) {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Object, default: () => HostFilters.create() })
  protected filters: HostFilters;
  
  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;
  
  @MapGetter(LicensingStore.Mapping)
  protected licenses: ServiceMap<License[]>;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseSharingOutbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseReturnOutbox: LicenseReturnNotification[];

  @MapAction(LicensingStore.Mapping)
  protected fetchLicenseById: (payload: FetchLicenseByIdPayload) => Promise<void>;
  
  @MapAction(LicensingStore.Mapping)
  protected unregisterHost: (payload: UnregisterHostPayload) => Promise<void>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected fetchPendingLicenseExchanges: () => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<void>;

  protected error: ConsoleError = null;
  protected expandedRows: number[] = [];
  
  
  protected recoverLicense = Execution.create(LicensingApi.recoverLicenseFile, LicensingApi);
  protected getLicenseById = Execution.create(
    (licenseId: number) => this.fetchLicenseById({
      service: this.serviceId,
      data: licenseId
    }));

  protected unregisterLicenseHost = Execution.create(
    (hostId: number) => this.unregisterHost({ 
      service: this.serviceId, 
      data: hostId
    }));

  protected getPendingExchanges = Execution.create(() => {
    return Promise.all([
      this.fetchInbox(),
      this.fetchPendingLicenseExchanges()
    ]);
  });

  protected created() {
    if (this.permissions.CanShareLicenses) {
      this.getPendingExchanges.run();
    }
  }

  @Watch("licenseHosts")
  protected onTableDataChange(data: Host[]) {
    this.expandedRows = this.expandedRows.filter(id => data.some(host => host.id === id));
    if (data.length === 1 && this.expandedRows.length === 0) {
      this.expandedRows = [data[0].id];
    }
  }

  protected get serviceLicenses(): License[] {
    return this.licenses[this.serviceId] || [];
  }

  protected get license(): License {
    const licenseId = Number(this.$route.params.id);
    return this.serviceLicenses.find(license => license.id === licenseId);
  }

  protected get licenseHosts(): Host[] {
    return this.license?.hosts || [];
  }

  protected get filteredLicenseHosts(): Host[] {
    return this.licenseHosts.filter(host => host.meetFilters(this.filters));
  }

  protected get hasHosts(): boolean {
    return (this.license?.hostCount ?? 0) > 0;
  }

  protected get hasMoreThanOneHost(): boolean {
    return (this.license?.hostCount ?? 0) > 1;
  }

  protected clearFilters() {
    this.filters.reset();
  }

  protected get canOperateWithLicense(): boolean {
    return !this.license.isShared || this.license.isSharedToOrg(this.currentOrg);
  } 

  protected get isOwnedByCurrentOrg(): boolean {
    return this.license?.isOwnedByOrg(this.currentOrg) ?? false;
  }

  protected get licenseExchangePending() {
    return this.licenseSharingPending || this.licenseReturnPending
  }

  protected get licenseSharingPending() {
    return !!this.license 
      ? this.orgLicenseSharingOutbox.find(noti => noti.isLicenseIncluded(this.license))
      : null;
  }

  protected get licenseReturnPending() {
    return !!this.license 
      ? this.orgLicenseReturnOutbox.find(noti => noti.isLicenseIncluded(this.license))
      : null;
  }

  protected get expandedRowIds(): number[] {
    return this.expandedRows
      .filter(id => this.filteredLicenseHosts.some(h => h.id === id));
  }
  
  protected toggleExpansion(host: Host) {
    const index = this.expandedRows.indexOf(host.id);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [host.id];
    }
  }

  protected async unregisterExistingHost(host: Host) {  
    try {
      await this.$confirm(`Are you sure you want to unregister the selected host (${host.hostname}) from this license?`, "Unregister confirmation", {
        confirmButtonText: "Unregister host",
        cancelButtonText: 'Cancel',
        type: 'warning'
      });
    } catch { return; }

    await this.unregisterLicenseHost.run(host.id);
    if (!!this.unregisterLicenseHost.error) {
      Notify.Error({ title: "Unregistration Error", message: this.unregisterLicenseHost.error.message });
    } else {
      Notify.Success({ title: "Unregistration Success", message: "Your host has been successfully unregistered." });
    }
  }

  protected viewContract(license: License) {
    this.$emit("select-contract", license);
  }

  protected async recoverLicenseFile(host: Host) {
    const licenseRecovery = await this.recoverLicense.run(host.id, this.serviceId);
    if (!!this.recoverLicense.error) {
      Notify.Error({
        title: "License recovering error",
        message: this.recoverLicense.error.message,
        duration: 3000
      });
    } else {
      this.downloadFile(host.externalId, licenseRecovery.hostContent);
    }
  }

  @Watch("$route.params.id", { immediate: true })
  protected async onParamsOrSessionChange() {
    this.error = null;
    this.getLicenseById.reset();
    await this.$nextTick();
    
    const licenseId = Number(this.$route.params.id);
    if (isNaN(licenseId)) {
      const errorMsg = `Invalid license identifier '${this.$route.params.id}'.`;
      this.error = new ConsoleError(errorMsg, { fatal: true, meta: { licenseId: this.$route.params.id } });
      return;
    }
    
    const license = this.serviceLicenses.some(license => license.id === licenseId);
    if (!license) {
      await this.getLicenseById.run(licenseId);
      if (!!this.getLicenseById.error) {
        const errorMsg = `License with identifier ${this.$route.params.id} was not found.`;
        this.error = new ConsoleError(errorMsg, { fatal: true, meta: { licenseId: this.$route.params.id } });
        return;
      }
    }
  }

  protected get permissions() {
    return {
      CanListContracts: this.accessRole.can(this.serviceId, Permission.ListContracts),
      CanListLicenses: this.accessRole.can(this.serviceId, Permission.ListLicenses),
      CanListHosts: this.accessRole.can(this.serviceId, Permission.ListHosts),
      CanRegisterHosts: this.accessRole.can(this.serviceId, Permission.RegisterHost),
      CanUnregisterHosts: this.accessRole.can(this.serviceId, Permission.UnregisterHost),
      CanRecoverLicenseFile: this.accessRole.can(this.serviceId, Permission.RecoverLicenseFile),
      CanShareLicenses: this.accessRole.can(this.serviceId, Permission.ShareLicenses)
    }
  }

  protected get slotScope() {
    return {
      license: this.license,
      error: this.getLicenseById.error,
      loading: this.getLicenseById.loading,
      hasRecords: this.hasHosts,
      exchangeStatus: {
        isPending: !!this.licenseExchangePending,
        sharing: this.licenseSharingPending,
        return: this.licenseReturnPending
      },
      data: { 
        original: this.licenseHosts, 
        filtered: this.filteredLicenseHosts
      }
    }
  }

}