import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class FeatureCard extends Vue {

  @Prop({ type: String, required: true })
  protected title: string;
  
  @Prop({ type: String, default: null })
  protected description: string;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  protected cardClass() {
    return {
      "is-plain": this.isPlainUi
    }
  }

}