import NotificationContentMixin, { INotificationContent, UiSuccessNotificationMap } from "@Module/Notifications/Mixins/NotificationContentMixin";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { Component, MapAction, mixins, Prop } from "types-vue";
import { ServiceID } from "@Service/ServiceID";

@Component
export default class LicenseReturnNotifContent extends mixins(NotificationContentMixin) implements INotificationContent {

  @Prop({ type: Object, required: true })
  protected notification: LicenseReturnNotification;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptExchangeNotification: (notification: LicenseReturnNotification) => Promise<void>;

  public get canResend(): boolean {
    return false;
  }

  public getSuccessNotifConfig(): UiSuccessNotificationMap {
    return {
      Accept: {
        title: "Invitation accepted", 
        message: `License return request successfully accepted.`,
        duration: 6000
      },
      Decline: {
        title: "Invitation declined", 
        message: `License return request successfully declined.`,
        duration: 6000
      },
      Cancel: {
        title: "License return cancelled", 
        message: `License return request has been successfully cancelled.`
      }
    }
  }

  public get licenseService(): string {
    return ServiceID.nameOf(this.notification.service);
  }
  
  public get shouldShowTenantDetails(): boolean {
    return this.isInbox && this.notification.voluntaryReturn
        || !this.isInbox && !this.notification.voluntaryReturn;
  }

  public get shouldShowOwnerDetails(): boolean {
    return this.isInbox && !this.notification.voluntaryReturn
        || !this.isInbox && this.notification.voluntaryReturn;
  }

  public async accept(): Promise<boolean> {
    await this.acceptExchangeNotification(this.notification);
    return true;
  }

}