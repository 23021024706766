import NavigationStore from '@Core/Store/Navigation/Navigation.store';
import { ConsoleRoute } from '@Core/Models/ConsoleRoute';
import { Component, MapGetter, Vue } from 'types-vue';
import { ElSelect } from 'element-ui/types/select';
import { Utils } from '@Core/Utils/Utils';
import { Console } from 'vue-router';

interface ConsoleMenuGroup {
  id: Console.RouteGroupId,
  label?: string;
  position: number;
}

interface MenuItemModel {
  group: ConsoleMenuGroup,
  items: ConsoleRoute[]
};

@Component
export default class ServiceSelector extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(NavigationStore.Mapping)
  protected availableServiceRoutes: ConsoleRoute[];

  protected isFocused: boolean = false;
  protected searchText: string = null;
  
  protected focusKey: string = "s";

  protected mounted() {
    this.overrideSelectDebounce();
  }

  private overrideSelectDebounce() {
    const select = this.$refs.input as any;
    select.debouncedOnInputChange = () => { select.onInputChange(); };
    select.debouncedQueryChange = (e: any) => { select.handleQueryChange(e.target.value); };
  }

  protected get environment(): string {
    return process.env.VUE_APP_ENV;
  }

  protected get groupedServiceRoutes(): MenuItemModel[] {
    const serviceRoutes = this.availableServiceRoutes || [];
    const text = this.searchText?.toLowerCase() || "";
    const filteredServiceRoutes = serviceRoutes.filter(item => {
      return item.meta.menu.label?.toLowerCase().includes(text) ?? false;
    }).sort((a, b) => a.meta.menu.label.localeCompare(b.meta.menu.label));
    const serviceGroups = Utils.groupBy(filteredServiceRoutes, route => route.meta.menu.groupId);
    return Object.keys(serviceGroups).map((groupId: Console.RouteGroupId) => {
      const group = this.getConsoleMenuGroup(groupId || "default");
      return { group, items: serviceGroups[group.id] }
    }).sort((a, b) => a.group.position - b.group.position);
  }

  protected normalizeServiceName(name: string): string {
    return name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  protected async onDropdownVisibleChange(visible: boolean) {
    if (visible) { return; }
    await Utils.sleep(200);
    this.searchText = null;
  }

  protected get currentServicePath(): string {
    return !this.currentRoute.isRoot 
      ? this.currentRoute.service.path 
      : null;
  }

  protected get hasAppInfo(): boolean {
    return !!this.currentRoute?.service.meta.appInfo;
  }

  protected get serviceSelectorClass(): Record<string, boolean> {
    return { 
      "has-selection": !!this.currentServicePath 
    };
  }

  private getConsoleMenuGroup(id: Console.RouteGroupId): ConsoleMenuGroup {
    switch (id) {
      case "service": 
        return { id: "service", label: "Services", position: 0 };
      case "internal": 
        return { id: "internal", position: 1 };
      case "admin": 
        return { id: "admin", label: "Administration", position: 9999 };
      default: 
        return { id: "default", position: -1 };
    }
  }

  protected async onServiceSelected(path: string) {
    this.$router.replace(path).catch(e => e);
    await this.$nextTick();
    const select = this.$refs.input as ElSelect;
    select.blur();
  }

  protected toggleFocus() {
    const select = this.$refs.input as ElSelect;
    if (this.isFocused) {
      select.blur();
    } else {
      select.focus();
    }
  }

  protected get keymap(): Record<string, Function> {
    const keyMap = {};
    keyMap[`ctrl+${this.focusKey}`] = this.toggleFocus;
    return keyMap;
  }

  protected handleKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === this.focusKey) {
      this.toggleFocus();
    }
  }
  
}