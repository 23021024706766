import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import OrgSelector from "@Module/Orgs/Components/OrgSelector/OrgSelector";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { License } from "@Module/Licensing/Models/License.model";
import { Component, MapGetter, Prop, Watch } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { ServiceID } from "@Service/ServiceID";
import IsEqual from "lodash.isequal";
import Vue from "vue";

type ExchangeNotification = LicenseSharingNotification | LicenseReturnNotification;

export const enum ExchangeMode {
  SharedWithMe = "with-me",
  SharedByMe = "by-me"
}

interface LicenseTransferOption {
  id: number;
  license: License;
  label: string;
  disabled: boolean;
  isExchangePending: boolean;
  isInboxPending: boolean;
  sharePending?: ExchangeNotification;
  returnPending?: ExchangeNotification;
}

export interface LicenseTransferChangeEvent {
  owner: Organization;
  tenant: Organization;
  toShare: License[];
  toReturn: License[];
  service: ServiceID;
  didChange: boolean;
  returnImmediately: boolean;
  voluntaryReturn: boolean;
}

interface TargetOrgSelectorConfig {
  options: Organization[];
  disabledItems: Organization[];
  disabled: boolean;
  hierarchical: boolean;
  noDataText: string;
}

interface TransferConfig {
  title: string;
}

interface InitialStatus {
  shared: Set<number>;
  notShared: Set<number>;
}

@Component
export default class LicenseTransfer extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Array, required: true })
  protected licenses: License[];

  @Prop({ type: String, required: true })
  protected exchangeMode: ExchangeMode;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected type: "plain" | "border";

  @Prop({ type: Boolean, default: false })
  protected singleCard: boolean;

  @Prop({ type: Boolean, default: false })
  protected noCheckbox: boolean;

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseSharingOutbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseReturnOutbox: LicenseReturnNotification[];

  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseSharingInbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseReturnInbox: LicenseReturnNotification[];

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgHasChildren: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgChildren: Organization[];

  protected returnImmediately: boolean = false;

  protected owner?: Organization = null;
  protected tenant?: Organization = null;
  
  protected leftChecked: number[] = [];
  protected rightChecked: number[] = [];

  protected sharedLicenseIds: number[] = [];

  protected initial: InitialStatus = { shared: new Set(), notShared: new Set() };

  protected created() {
    this.owner = this.ownerSelectorConfig.options.length === 1 ? this.ownerSelectorConfig.options[0] : null;
    this.tenant = this.tenantSelectorConfig.options.length === 1 ? this.tenantSelectorConfig.options[0] : null;
  }

  protected get tenantPendingNotifications() {
    const inbox = [...this.licenseSharingInbox, ...this.licenseReturnInbox].filter(noti => noti.isInvolved(this.owner, this.tenant));
    const outbox = [...this.orgLicenseSharingOutbox, ...this.orgLicenseReturnOutbox].filter(noti => noti.isInvolved(this.owner, this.tenant));
    return { inbox, outbox }
  }

  protected getPendingStatus(license: License) {
    const pendingNotis = this.tenantPendingNotifications;
    const inboxPendingNoti = pendingNotis.inbox.find(noti => noti.isLicenseIncluded(license));
    const outboxPendingNoti = pendingNotis.outbox.find(noti => noti.isLicenseIncluded(license));

    const isPending = !!inboxPendingNoti || !!outboxPendingNoti;
    const isInboxPending = !!inboxPendingNoti;
    const isOutboxPending = !!outboxPendingNoti;

    const isTenantPendingReturn = inboxPendingNoti?.isLicenseReturn && inboxPendingNoti?.isFrom(this.tenant)
                      || outboxPendingNoti?.isLicenseReturn && outboxPendingNoti?.isFrom(this.tenant);

    const tenantPendingReturnNoti = isTenantPendingReturn ? inboxPendingNoti || outboxPendingNoti : undefined;
    
    const isTenantPendingShare = inboxPendingNoti?.isLicenseSharing && inboxPendingNoti?.isDestinatedTo(this.tenant)
                      || outboxPendingNoti?.isLicenseSharing && outboxPendingNoti?.isDestinatedTo(this.tenant);
    
    const tenantPendingShareNoti = isTenantPendingShare ? inboxPendingNoti || outboxPendingNoti : undefined;

    const isTenantPendingExchange = isTenantPendingReturn || isTenantPendingShare;

    return { isPending, isTenantPendingExchange, tenantPendingReturnNoti, tenantPendingShareNoti, isTenantPendingReturn, isTenantPendingShare, isInboxPending, isOutboxPending, inboxPendingNoti, outboxPendingNoti };
  }

  protected getExchangeStatus(license: License) {
    const isShared = license.isShared;
    const isSharedToCurrentOrg = license.isSharedToOrg(this.currentOrg);
    const isSharedWithTenat = license.isSharedToOrg(this.tenant);
    const isSharedByOwner = isSharedToCurrentOrg && license.isOwnedByOrg(this.owner);
    const isEligibleToShare = license.isEligibleToShare(this.owner, this.tenant);
    const isEligibleToReturn = license.isEligibleToReturn(this.owner, this.tenant);
    return { isShared, isSharedToCurrentOrg, isSharedWithTenat, isSharedByOwner, isEligibleToShare, isEligibleToReturn };
  }

  protected get exchangeableLicenses(): License[] {
    return this.licenses.filter(license => {
      const pendingStatus = this.getPendingStatus(license);
      const exchangeStatus = this.getExchangeStatus(license);
      const eligibleForExchange = exchangeStatus.isEligibleToShare || exchangeStatus.isEligibleToReturn;
      const isPendingForOtherTenant = pendingStatus.isPending && !pendingStatus.isTenantPendingExchange;
      return eligibleForExchange && !isPendingForOtherTenant;
    });
  }

  protected get isSharedByMeMode(): boolean {
    return this.exchangeMode === ExchangeMode.SharedByMe;
  }

  public initialize() {
    this.leftChecked = [];
    this.rightChecked = [];

    const initiallyShared = this.exchangeableLicenses.reduce((ids, license) => {
      const pendingStatus = this.getPendingStatus(license);
      const exchangeStatus = this.getExchangeStatus(license);
      const isSharedOrPendingShare = exchangeStatus.isSharedWithTenat || pendingStatus.isTenantPendingShare;
      if (isSharedOrPendingShare && !pendingStatus.isTenantPendingReturn) {
        ids.add(license.id);
      }
      return ids;
    }, new Set<number>());

    const initiallyNotShared = this.exchangeableLicenses.reduce((ids, license) => {
      const pendingStatus = this.getPendingStatus(license);
      const exchangeStatus = this.getExchangeStatus(license);
      const isNotSharedOrPendingReturn = !exchangeStatus.isSharedWithTenat || pendingStatus.isTenantPendingReturn;
      if (isNotSharedOrPendingReturn && !pendingStatus.isTenantPendingShare) {
        ids.add(license.id);
      }
      return ids;
    }, new Set<number>());

    this.sharedLicenseIds = Array.from(initiallyShared);
    this.initial = { shared: initiallyShared, notShared: initiallyNotShared };
  }

  protected get transferOptions(): LicenseTransferOption[] {
    return this.exchangeableLicenses.map(license => { 
      const pendingStatus = this.getPendingStatus(license);
      const option: LicenseTransferOption = { 
        id: license.id,
        disabled: this.disabled || !this.owner || !this.tenant || pendingStatus.isTenantPendingExchange, 
        isExchangePending: pendingStatus.isTenantPendingExchange,
        sharePending: pendingStatus.tenantPendingShareNoti,
        returnPending: pendingStatus.tenantPendingReturnNoti,
        isInboxPending: pendingStatus.isInboxPending,
        label: license.externalId,
        license
      };
      return option;
    });
  }

  protected get status(): LicenseTransferChangeEvent {
    const currentSharedLicenseIds = this.sharedLicenseIds.sort((a, b) => a - b);
    const initialSharedLicenseIds = Array.from(this.initial.shared).sort((a, b) => a - b);
    return {
      owner: this.owner,
      tenant: this.tenant,
      service: this.serviceId,
      toShare: this.sharedLicenses.filter(license => this.initial.notShared.has(license.id)), 
      toReturn: this.notSharedLicenses.filter(license => this.initial.shared.has(license.id)),
      didChange: !IsEqual(currentSharedLicenseIds, initialSharedLicenseIds),
      returnImmediately: this.returnImmediately,
      voluntaryReturn: this.tenant?.id === this.currentOrg.id
    };
  }

  public reset() {
    this.sharedLicenseIds = Array.from(this.initial.shared);
  }

  @Watch("sharedLicenseIds")
  protected onSharedLicensesChange() {
    this.$emit("change", this.status);
  }

  @Watch("tenant")
  @Watch("tenantPendingNotifications", { deep: true })
  protected async onTenantChange(newVal: any, oldVal: any) {
    if (!this.tenant || IsEqual(newVal, oldVal)) { return; }
    this.initialize();
  }

  protected filter(query: string, option: LicenseTransferOption) {
    return option.license.meetsSearch(query);
  }

  protected get tenantSelectorConfig(): TargetOrgSelectorConfig {
    return {
      options: this.exchangeMode === ExchangeMode.SharedByMe ? this.currentOrgChildren : [this.currentOrg],
      disabledItems: !!this.owner ? [this.owner] : undefined,
      hierarchical: this.owner?.id === this.currentOrg.id,
      disabled: this.disabled || !this.owner || this.exchangeMode === ExchangeMode.SharedWithMe,
      noDataText: "No available organizations."
    }
  }

  protected get ownerSelectorConfig(): TargetOrgSelectorConfig {
    return {
      options: this.exchangeMode === ExchangeMode.SharedWithMe ?  this.licenseOwners : [this.currentOrg],
      disabledItems: undefined,
      hierarchical: false,
      disabled: this.disabled || this.exchangeMode === ExchangeMode.SharedByMe,
      noDataText: "No available organizations."
    }
  }

  protected get shareTransferConfig(): TransferConfig {
    return {
      title: !this.owner ? "Not shared licenses" : `${this.owner.name} not shared licenses`
    }
  }

  protected get returnTransferConfig(): TransferConfig {
    return {
      title: !this.owner || !this.tenant ? "Shared licenses" : `Shared with ${this.tenant.name} by ${this.owner.name}`
    }
  }

  protected numberOfLicensesShared(owner: Organization, tenant: Organization): number {
    return this.licenses.filter(license => license.isOwnedByOrg(owner) && license.isSharedToOrg(tenant)).length;
  }

  protected get licenseTenants(): Organization[] {
    if (!this.owner) { return []; }
    return this.owner.id === this.currentOrg.id 
      ? this.currentOrgChildren 
      : [this.currentOrg];
  }

  protected get licenseOwners(): Organization[] {
    return this.licenses.reduce((owners, license) => {
      const isOwnerAlreadyAdded = owners.some(owner => owner.id === license.owner.id);
      if (isOwnerAlreadyAdded) { return owners; }
      
      const isOwnerMyCurrentOrg = this.currentOrg.id === license.owner.id;
      if (!isOwnerMyCurrentOrg){
        owners.push(license.owner);
      }
      return owners;
    }, [] as Organization[]);
  }

  protected get notSharedLicenses(): License[] {
    return this.exchangeableLicenses.filter(license => !this.sharedLicenseIds.includes(license.id));
  }

  protected get sharedLicenses(): License[] {
    return this.exchangeableLicenses.filter(license => this.sharedLicenseIds.includes(license.id));
  }
  
  protected get transferClass() {
    return {
      "is-plain": this.type === "plain",
      "is-border": this.type === "border",
      "is-single-card": this.singleCard,
      "is-no-checkbox": this.noCheckbox
    }
  }

  protected isLicenseReturned(license: License): boolean {
    return this.sharedLicenseIds.includes(license.id)
        && !this.initial.shared.has(license.id);
  }

  protected isLicenseShared(license: License): boolean {
    return !this.sharedLicenseIds.includes(license.id)
        && this.initial.shared.has(license.id);
  }

  protected transferToRight() {
    (this.$refs.transfer as any)?.addToRight();
    this.leftChecked = [];
  }

  protected transferToLeft() {
    (this.$refs.transfer as any)?.addToLeft();
    this.rightChecked = [];
  }

  protected onLeftCheckStateChange(checked: number[]) {
    this.leftChecked = checked;
  }

  protected onRightCheckStateChange(checked: number[]) {
    this.rightChecked = checked;
  }

  protected isLicenseChecked(license: License): boolean {
    return [...this.leftChecked, ...this.rightChecked].includes(license.id);
  }

  protected get notSharedLicensesSummary(): Record<string, number> {
    return this.buildDimensionSummary(this.notSharedLicenses);
  }

  protected get sharedLicensesSummary(): Record<string, number> {
    return this.buildDimensionSummary(this.sharedLicenses);
  }

  protected buildDimensionSummary(licenses: License[]): Record<string, number> {
    return licenses.reduce((map, license) => {
      const currCount = map[license.dimension] ?? 0;
      map[license.dimension] = currCount + 1;
      return map;
    }, {} as Record<string, number>);
  }

  protected get toShareSummary(): string {
    const sharedDimSummary = this.buildDimensionSummary(this.status.toShare);
    const toShare = Object.entries(sharedDimSummary).map(entry => `${entry[1]} ${entry[0]}`)
    let summary = toShare.join(", ");
    const lastCommaIndex = summary.lastIndexOf(", ");
    if (lastCommaIndex !== -1) {
      summary = summary.substring(0, lastCommaIndex) + " and " + summary.substring(lastCommaIndex + ", ".length);
    }
    return summary;
  }

  protected get toReturnSummary(): string {
    const sharedDimSummary = this.buildDimensionSummary(this.status.toReturn);
    const toShare = Object.entries(sharedDimSummary).map(entry => `${entry[1]} ${entry[0]}`)
    let summary = toShare.join(", ");
    const lastCommaIndex = summary.lastIndexOf(", ");
    if (lastCommaIndex !== -1) {
      summary = summary.substring(0, lastCommaIndex) + " and " + summary.substring(lastCommaIndex + ", ".length);
    }
    return summary;
  }

  protected get serviceName(): string {
    return ServiceID.nameOf(this.serviceId);
  }

  protected get canReturnImmediately(): boolean {
    return this.initial.shared.size > 0;
  }

  protected get isCurrentOrgSelectedAsOwner(): boolean {
    return this.owner?.id === this.currentOrg.id;
  }

  protected get isCurrentOrgSelectedAsTenant(): boolean {
    return this.tenant?.id === this.currentOrg.id;
  }

  public async focusIfNoOwner() {
    if (!this.owner) {
      (this.$refs.ownerSelector as OrgSelector).focus();
    } else if (!this.tenant) {
      (this.$refs.tenantSelector as OrgSelector).focus();
    }
  }

}