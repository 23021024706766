import NotificationContentMixin, { INotificationContent, UiSuccessNotificationMap } from "@Module/Notifications/Mixins/NotificationContentMixin";
import OrgAccessRequestRoleSelectionDialog from "../../OrgAccessRequestRoleSelectionDialog/OrgAccessRequestRoleSelectionDialog";
import NotificationsInboxStore, { AcceptOrgAccessRequestPayload } from "@Module/Notifications/Store/NotificationsInbox.store";
import { OrgAccessRequestNotification } from "@Module/Notifications/Models/OrgAccessRequestNotification.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, MapAction, mixins, Prop } from "types-vue";

type ConfigDialog = OrgAccessRequestRoleSelectionDialog;

@Component
export default class OrgAccessRequestNotifContent extends mixins(NotificationContentMixin) implements INotificationContent {

  @Prop({ type: Object, required: true })
  protected notification: OrgAccessRequestNotification;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptOrgAccessRequestNotification: (payload: AcceptOrgAccessRequestPayload) => Promise<Notification>;

  protected newUserRole: Role = null;
  protected configuring: boolean = false;

  protected mounted() {
    this.$watch(
      () => (this.$refs.dialog as ConfigDialog)?.isOpened ?? false,
      isOpened => this.configuring = isOpened,
      { immediate: true }
    );
  }

  public getSuccessNotifConfig(): UiSuccessNotificationMap {
    return {
      Accept: {
        title: "Organization access accepted", 
        message: `User ${this.notification.sender} now has access to ${this.notification.organization.name} organization as ${this.newUserRole?.name}.`,
        duration: 6000
      },
      Decline: {
        title: "Organization access declined", 
        message: `Access request of ${this.notification.sender} to ${this.notification.organization.name} organization has been successfully declined.`,
        duration: 6000
      },
      Cancel: {
        title: "Access request cancelled", 
        message: `Your access request petition has been cancelled.`
      }
    }
  }

  public get isConfiguringResponse() {
    return this.configuring;
  }
  
  public async accept(): Promise<boolean> {
    const roleSelectionDialog = this.$refs.dialog as ConfigDialog;
    const shouldProceed = await roleSelectionDialog.open();
    if (!shouldProceed) { return false; }
    await this.acceptOrgAccessRequestNotification({
      notification: this.notification,
      role: this.newUserRole
    });
    return true;
  }

}