
import { ExportFormat } from "@Core/Models/ExportFormat.model";
import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ServiceID } from "@Service/ServiceID";

interface GetLicensingExportApiErrorMeta{
  service: ServiceID;
  format: ExportFormat;
}

export class GetLicensingExportApiError extends ConsoleError<GetLicensingExportApiErrorMeta> {}