import { render, staticRenderFns } from "./EmoryBackupHistory.vue?vue&type=template&id=79f12260&scoped=true&"
import script from "./EmoryBackupHistory.ts?vue&type=script&lang=ts&"
export * from "./EmoryBackupHistory.ts?vue&type=script&lang=ts&"
import style0 from "./EmoryBackupHistory.vue?vue&type=style&index=0&id=79f12260&lang=scss&scoped=true&"
import style1 from "./EmoryBackupHistory.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f12260",
  null
  
)

export default component.exports