import { Execution } from "@Core/Models/Execution";
import { IReportContractDto, IReportFiltersDto, ReportContract } from "@Module/Reports/Models/ReportContract.model";
import ReportsStore from "@Module/Reports/Store/Reports.store";
import ReportsApi from "@Module/Reports/API/Reports.api";
import { Component, MapAction, MapGetter, Watch, mixins } from "types-vue";
import { IReportContractFiltersDto, ReportContractsFilters } from "@Module/Reports/Models/ReportContractFilters";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import Debounce from "lodash.debounce";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import FileDownloadMixin from "@Core/Mixins/FileDownload.mixin";


interface FileRequestResult {
  name: string;
  content: string;
  base64: boolean;
  downloaded: boolean;
}

@Component
export default class ContractsReport extends mixins(FileDownloadMixin) {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapAction(ReportsStore.Mapping)
  protected openReportsDialog: () => Promise<boolean>;

  @MapAction(ReportsStore.Mapping)
  protected closeReportsDialog: () => Promise<void>;

  @MapGetter(ReportsStore.Mapping)
  protected reportContracts: ReportContract[];

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapAction(ReportsStore.Mapping)
  protected fetchReportContracts: (filters: ReportContractsFilters) => Promise<ReportContract[]>;

  protected disableFiltersWatcher: VoidFunction = null;
  protected filters: ReportContractsFilters = ReportContractsFilters.create();
  protected searchText: string = null;
  protected expandedRows: number[] = [];
  protected serviceId: ServiceID = ServiceID.Console;
  protected exportResult: FileRequestResult = null;
  protected contract: ReportContract = null;
  protected mrrChecked: boolean = false;
  protected tcvChecked: boolean = false;
  protected leadGenChecked: boolean = false;
  protected datasourceChecked: boolean = false;

  protected filtersBackup = {
    expand: [],
    searchText: null,
    filters: ReportContractsFilters.create()
  }

  protected fetchExcel = Execution.create(
    (filters: IReportContractFiltersDto) => ReportsApi.getExcel(filters),
    ReportsApi
  );

  protected fetchMRR = Execution.create(
    () => ReportsApi.getMRR(),
    ReportsApi
  );

  protected fetchLeadGenReport = Execution.create(
    () => ReportsApi.getLeadGenReport(),
    ReportsApi
  );

  protected fetchUploadReports = Execution.create(
    () => ReportsApi.uploadReports(),
    ReportsApi
  );

  protected sendCorrectContract(contractID) {
    return this.reportContracts.find(contract => contract.id == contractID)
  }

  protected async startExport() {
    if (this.datasourceChecked) {
      const content = await this.fetchExcel.run(this.filters.toDto());
      const name = `Export-${new Date().getTime()}.zip`;
      if (!this.fetchExcel.error) {
        this.exportResult = {
          name,
          content,
          base64: true,
          downloaded: false
        };

        const fileName = this.exportResult.name;
        const base64 = this.exportResult.base64;
        this.downloadFile(fileName, content, { base64 });
        this.exportResult.downloaded = true;
      }
    }

    if (this.mrrChecked) {
      const content = await this.fetchMRR.run();
      const name = `Export-MRR.zip`;
      if (!this.fetchExcel.error) {
        this.exportResult = {
          name,
          content,
          base64: true,
          downloaded: false
        };

        const fileName = this.exportResult.name;
        const base64 = this.exportResult.base64;
        this.downloadFile(fileName, content, { base64 });
        this.exportResult.downloaded = true;
      }
    }

    if (this.tcvChecked) {
      const content = await this.fetchTCV.run();
      const name = `Export-TCV.zip`;
      if (!this.fetchExcel.error) {
        this.exportResult = {
          name,
          content,
          base64: true,
          downloaded: false
        };

        const fileName = this.exportResult.name;
        const base64 = this.exportResult.base64;
        this.downloadFile(fileName, content, { base64 });
        this.exportResult.downloaded = true;
      }
    }

    if (this.leadGenChecked) {
      const content = await this.fetchLeadGenReport.run();
      const name = `Export-LeadGenerator.zip`;
      if (!this.fetchExcel.error) {
        this.exportResult = {
          name,
          content,
          base64: true,
          downloaded: false
        };

        const fileName = this.exportResult.name;
        const base64 = this.exportResult.base64;
        this.downloadFile(fileName, content, { base64 });
        this.exportResult.downloaded = true;
      }
    }

  }

  protected async downloadExcel() {
    await this.fetchExcel.run(this.filters.toDto());
  }

  protected mounted() {
    if (this.permissions.CanListFinancialInfo) {
      this.fetchReportContracts(this.filters);
    }
    this.disableFiltersWatcher = this.$watch("filters.dto", this.debouncedFetchReportContracts, { deep: true });
  }

  protected debouncedFetchReportContracts = Debounce(this.getReportContracts, 500);
  protected async getReportContracts() {
    const dtos = await this.fetchReportContracts(this.filters);
  }


  protected get filteredReportContracts(): ReportContract[] {
    return this.reportContracts
      .filter(s => {
        let orgsMatch = true;
        let dimensionMatch = true;
        if (this.filters.orgs.length > 0) {
          orgsMatch = this.filters.orgs.some(org => org.name === s.orgName);
        }
        if (this.filters.dimensions.length > 0) {
          const selectedDims = this.filters.dimensions.map(d => d.name);
          dimensionMatch = s.dimensions.some(ld => selectedDims.includes(ld.dimension));
        }
        const contractMatch = s.meetsFilter(this.searchText, []);
        return contractMatch && orgsMatch && dimensionMatch;
      });
  }

  protected async uploadReports() {
    const result = await this.fetchUploadReports.run();
  }


  protected fetchTCV = Execution.create(
    () => ReportsApi.getTCV(),
    ReportsApi
  );

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }

  protected serviceName(service: ServiceID): string {
    if (service === ServiceID.Connector) {
      return ServiceID.nameOf(ServiceID.AbapSuite);
    }
    return ServiceID.nameOf(service);
  }

  protected toggleExpansion(contract: ReportContract) {
    const index = this.expandedRows.indexOf(contract.id);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [contract.id];
    }
  }

  protected get expandedRowIds(): number[] {
    return this.expandedRows
      .filter(id => this.reportContracts.some(contract => contract.id === id));
  }

  protected get hasContracts(): boolean {
    return this.reportContracts.length > 0;
  }

  protected get firstLoad(): boolean {
    return !this.hasContracts;
  }

  protected async resetFilters() {
    try {
      const msg = `Are you sure you want to clear all current filter criteria? This action cannot be undone.`;
      await this.$confirm(msg, "Clear confirmation", {
        type: "warning",
        confirmButtonText: "Clear filters",
        cancelButtonText: "Cancel"
      });
      this.filters = ReportContractsFilters.create();
    } catch { }
  }

  protected get contractsStatus(): LicensingStatus[] {
    return this.filteredReportContracts.map(contract => contract.status);
  }

  protected viewContract(contractId: number) {
    this.$router.push({
      name: "report-contract-details",
      params: { id: contractId.toString() }
    });
  }

  public async onNotVisible() {
    this.disableFiltersWatcher();
    this.expandedRows = [];
    this.filters = ReportContractsFilters.create();
    this.filters.status = [];
  }

  public async onVisible() {
    this.expandedRows = this.filtersBackup.expand;
    this.filters = this.filtersBackup.filters;
    this.searchText = this.filtersBackup.searchText;
    await this.debouncedFetchReportContracts();
    this.disableFiltersWatcher = this.$watch("filters.dto", this.debouncedFetchReportContracts, { deep: true });
  }

  protected get permissions() {
    return {
      CanAddFinancialInfo: this.accessRole.can(this.serviceId, Permission.AddFinancialInfo),
      CanListFinancialInfo: this.accessRole.can(this.serviceId, Permission.ListFinancialInfo),
    }
  }


}