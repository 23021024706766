const requireModule = require.context('../../', true,  /\.store\.ts$/);
const modules: any = {};

requireModule.keys().forEach((filename: string) => {
  const moduleName = filename
    .split('/')
    .reverse()[0]
    .replace('.store.ts', '')
  modules[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default modules;