import { BackupFilters, IBackupFiltersDto } from "../Models/Backups/BackupFilters.model";
import { BackupHistoryPage } from "../Models/Backups/BackupHistoryPage.model";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { BackupLevel } from "../Models/Backups/BackupLevel.model";
import { EmoryBackupsStoreState } from "./EmoryBackups.state";
import EmoryBackupsApi from "../API/EmoryBackups.api";
import { Utils } from "@Core/Utils/Utils";
import { ActionContext } from "vuex";

const enum EmoryBackupsStoreCommit {
  SetBackupLevels = "_setBackupLevels",
  SetCurrentFilters = "_setCurrentFilters",
  SetCurrentBackupHistoryPage = "_setCurrentBackupHistoryPage"
}

declare type EmoryBackupsStoreContext = ActionContext<EmoryBackupsStoreState, any>;

@Module({ namespaced: true })
export default class EmoryBackupsStore extends VuexModule<EmoryBackupsStoreState> implements EmoryBackupsStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("EmoryBackups");

  public _backupLevels: BackupLevel[] = [];
  public _currentHistoryPage: BackupHistoryPage = null;
  public _currentFilters: IBackupFiltersDto = null;

  @Getter()
  public backupLevels(): BackupLevel[] {
    return this._backupLevels;
  }

  @Getter()
  public currentFilters(): IBackupFiltersDto {
    return this._currentFilters;
  }

  @Getter()
  public currentBackupHistoryPage(): BackupHistoryPage {
    return this._currentHistoryPage;
  }

  @Mutation()
  protected _setBackupLevels(levels: BackupLevel[]) {
    this._backupLevels = levels;
  }

  @Mutation()
  protected _setCurrentBackupHistoryPage(page: BackupHistoryPage) {
    this._currentHistoryPage = page;
  }

  @Mutation()
  protected _setCurrentFilters(filters: IBackupFiltersDto) {
    this._currentFilters = filters;
  }

  @Action({ useContext: true })
  public async clearEmoryBackupsStore(ctx: EmoryBackupsStoreContext): Promise<void> {
    ctx.commit(EmoryBackupsStoreCommit.SetBackupLevels, []);
    ctx.commit(EmoryBackupsStoreCommit.SetCurrentBackupHistoryPage, null);
    ctx.commit(EmoryBackupsStoreCommit.SetCurrentFilters, null);
  }

  @Action({ useContext: true })
  public async clearLastBackupHistorySearch(ctx: EmoryBackupsStoreContext): Promise<void> {
    ctx.commit(EmoryBackupsStoreCommit.SetCurrentBackupHistoryPage, null);
    ctx.commit(EmoryBackupsStoreCommit.SetCurrentFilters, null);
  } 

  @Action({ useContext: true, commit: EmoryBackupsStoreCommit.SetCurrentBackupHistoryPage })
  public async fetchBackups(ctx: EmoryBackupsStoreContext, filters: BackupFilters): Promise<BackupHistoryPage> {
    const filterDto = filters.toDto();
    ctx.commit(EmoryBackupsStoreCommit.SetCurrentFilters, filterDto);
    const dto = await EmoryBackupsApi.getBackups(filterDto);
    return BackupHistoryPage.fromDto(dto);
  }

  @Action({ commit: EmoryBackupsStoreCommit.SetBackupLevels })
  public async fetchBackupLevels(): Promise<BackupLevel[]> {
    const dtos = await EmoryBackupsApi.getBackupLevels();
    return dtos.map(BackupLevel.fromDto);
  }

}