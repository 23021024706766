import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";
import { Platform } from "@Module/Resources/Models/Platform.model";

const enum PackageTypeTab {
  Installation = "installation",
  Upgrade = "upgrade",
  Maintenance = "maintenance"
}

@Component
export default class PlatformSelector extends Vue {

  @Prop({ type: String, default: "Pick a Platform" })
  protected placeholder: string;

  @Prop({ type: Number, default: null })
  protected value: Number;

  @Prop({ type: Array , default: () => [] })
  protected platforms: Platform[];

  @Prop({ type: String, default: null })
  protected serviceId: ServiceID;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected allowBrowsing: boolean;
  
  protected searchText: string = null;

  protected onVersionSelected(version: Number): void {
    const selectedPlatform = version || null;
    this.$emit("input", selectedPlatform);
    this.$emit("update:value", selectedPlatform);
    this.$emit("select", selectedPlatform);
    this.$emit("change", selectedPlatform);
    this.searchText = null;
  } 
}