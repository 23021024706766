import { OrgAccessRequestNotification } from "@Module/Notifications/Models/OrgAccessRequestNotification.model"
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, MapGetter, Prop, Watch } from "types-vue"
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import Dialog from "@Core/Components/Dialog/Dialog";
import Vue from "vue"

@Component
export default class OrgAccessRequestRoleSelectionDialog extends Vue {
  
  @Prop({ type: Object, required: true })
  protected notification: OrgAccessRequestNotification;

  @Prop({ type: Object, default: null })
  protected value: Role;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  protected internalValue: Role = null;

  public open() {
    const dialog = this.$refs.dialog as Dialog;
    return dialog.open();
  }

  @Watch("internalValue")
  protected onInternalValueChange(newValue: Role) {
    this.$emit("input", newValue);
    this.$emit("update:value", newValue);
  }

  @Watch("value", { immediate: true })
  protected onValueChange(newValue: Role) {
    this.internalValue = newValue;
  }

  public get isOpened() {
    return (this.$refs.dialog as Dialog)?.isOpened ?? false;
  }

}