import RedirectCountdownMixin from "@Core/Mixins/RedirectCountdown.mixin";
import { Component, Prop } from "types-vue";
import { mixins } from "vue-class-component";

@Component
export default class RedirectAlert extends mixins(RedirectCountdownMixin) {

  @Prop({ type: String, default: null })
  protected title: string;
  
  @Prop({ type: String, default: null })
  protected description: string;

  @Prop({ type: String, default: "error" })
  protected type: "info" | "success" | "error" | "warning";

}