import LegalStore from "@Module/Legal/Store/Legal.store";
import { Component, MapAction } from "types-vue";
import Vue from "vue";

@Component
export default class Footer extends Vue {

  @MapAction(LegalStore.Mapping)
  protected openDataProtectionDialog: () => void;

  protected get termsAndConditionsUrl() {
    return process.env.VUE_APP_TERMS_AND_CONDITIONS_URL;
  }

}