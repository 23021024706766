import LayoutMixin from "@Core/Mixins/PageLayout.mixin";
import { Component, mixins, Prop } from "types-vue";

@Component
export default class ColumnsLayout extends mixins(LayoutMixin) {

  @Prop({ type: Boolean, default: true })
  protected showLeft: boolean;

  @Prop({ type: Boolean, default: true })
  protected showRight: boolean;

  @Prop({ type: String, default: "1rem" })
  protected colSpacing: string;

  @Prop({ type: Boolean, default: false })
  protected embedded: boolean;

  protected get columnsLayoutClass() {
    return {
      ...this.pageLayoutClass,
      "is-embedded": this.embedded
    }
  }

  protected get leftColStyle() {
    return {
      "min-width": "min-content",
      "margin-right": this.colSpacing,
    }
  }

  protected get rightColStyle() {
    return {
      "min-width": "min-content",
      "margin-left": this.colSpacing,
    }
  }

}