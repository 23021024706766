import { License } from "@Module/Licensing/Models/License.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class LicenseListRow extends Vue {

  @Prop({ type: Object, required: true })
  protected license: License;

  @Prop({ type: Boolean, default: false })
  protected selected: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected get rowClass() {
    return {
      "is-disabled": this.disabled
    }
  }

}