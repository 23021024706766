import { IBackupDto } from "../Backup.model";
import { BackupObject } from "../BackupObject.model";
import { BackupAwsCloudProvider } from "./BackupAwsCloudProvider.model";
import { BackupAzureCloudProvider } from "./BackupAzureCloudProvider.model";
import { BackupGCloudCloudProvider } from "./BackupGCloudCloudProvider.model";

export enum CloudProviderType {
  AWS = "AWS",
  Azure = "Azure",
  GCloud = "GCloud"
}

export namespace CloudProviderType {
  export const All = [ CloudProviderType.AWS, CloudProviderType.Azure, CloudProviderType.GCloud ];

  export function nameOf(origin: CloudProviderType): string {
    switch (origin) {
      case CloudProviderType.AWS: return "Amazon Web Services";
      case CloudProviderType.Azure: return "Microsoft Azure";
      case CloudProviderType.GCloud: return "Google Cloud Platform"
    }
  }
}

export interface BackupCloudProvider {
  readonly location: string;
  readonly path: string;
  readonly encryption?: string;
  readonly encryptionKeyName?: string;
  readonly providerName: string;

  buildLocationUrl(object?: BackupObject): string;
}

export namespace BackupCloudProvider {

  export class Factory {
    public static fromDto(dto: IBackupDto) {
      switch (dto.type as CloudProviderType) {
        case CloudProviderType.AWS: 
          return BackupAwsCloudProvider.fromDto(dto);
        case CloudProviderType.Azure:
          return BackupAzureCloudProvider.fromDto(dto);
        case CloudProviderType.GCloud:
          return BackupGCloudCloudProvider.fromDto(dto);
      }
    }
  }
}