import { FileSize } from "@Core/Utils/FileSize";

export interface IBackupObjectDto {
  LocalFile: string;
  RemoteObject: string;
  Size: number;
}

export class BackupObject {
  public readonly localPath: string;
  public readonly remotePath: string;
  public readonly size: FileSize;

  public static fromDto(dto: IBackupObjectDto): BackupObject {
    return new BackupObject(dto);
  }

  private constructor(dto: IBackupObjectDto) {
    this.localPath = dto.LocalFile;
    this.remotePath = dto.RemoteObject;
    this.size = FileSize.fromBytes(dto.Size);
  }

  public toString(): string {
    return `${this.size.value} ${this.size.unit}`;
  }

  public meetsFilter(search: string): boolean {
    const text = search?.toLowerCase() || "";
    return this.localPath.toLowerCase().includes(text)
        || this.remotePath.toLowerCase().includes(text);
  }
}