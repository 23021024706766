import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { InstallationOptions } from "element-ui";
import EnglishLocale from 'element-ui/lib/locale/lang/en';
import Locale from 'element-ui/src/locale';
import Vue from "vue";

export class AppConfig implements InstallationOptions {
  private static instance: AppConfig;

  private _locale: any;
  private _i18n: any;
  public size: UISize;
  public zIndex: number;

  private constructor() {
    this.zIndex = 3000;
    this.locale = EnglishLocale;
    this.i18n = null;
    this.size = "large";
  }

  public static get Instance() {
    return this.instance || (this.instance = Vue.observable(new AppConfig()));
  }

  public get locale(): any {
    return this._locale;
  }

  public set locale(locale: any) {
    Locale.use(locale);
    this._locale = locale;
  }

  public get i18n(): any {
    return this._i18n;
  }

  public set i18n(i18n: any) {
    Locale.i18n(i18n);
    this._i18n = i18n;
  }
}

export default AppConfig.Instance;