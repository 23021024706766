import { Component } from "types-vue";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component
export default class YesNoLabel extends Vue {

  @Prop({ type: Boolean, required: true })
  protected value: boolean;

  @Prop({ type: String, default: "Yes" })
  protected yesLabel: string;

  @Prop({ type: String, default: "No" })
  protected noLabel: string;

  @Prop({ type: String, default: "success" })
  protected yesType: "primary" | "secondary" | "inherit" | "danger" | "success";

  @Prop({ type: String, default: "danger" })
  protected noType: "primary" | "secondary" | "inherit" | "danger" | "success";

}