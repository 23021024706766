import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { LegalStoreState } from "./Legal.state";
import { Utils } from "@Core/Utils/Utils";

const enum LegalStoreCommit {
  SetDataProtectionDialogVisible = "_setDataProtectionDialogVisible"
}

@Module({ namespaced: true })
export default class LegalStore extends VuexModule<LegalStoreState> implements LegalStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Legal");
  
  public _dataProtectionDialogVisible: boolean = false;

  @Getter()
  public dataProtectionDialogVisible() { 
    return this._dataProtectionDialogVisible;
  }

  @Mutation()
  protected _setDataProtectionDialogVisible(visible: boolean) { 
    this._dataProtectionDialogVisible = visible;
  }

  @Action({ commit: LegalStoreCommit.SetDataProtectionDialogVisible })
  public setDataProtectionDialogVisible(visible: boolean): boolean {
    return visible;
  }

  @Action({ commit: LegalStoreCommit.SetDataProtectionDialogVisible })
  public openDataProtectionDialog(): boolean {
    return true;
  }

  @Action({ commit: LegalStoreCommit.SetDataProtectionDialogVisible })
  public closeDataProtectionDialog(): boolean {
    return false;
  }
  
}