const requireModule = require.context('../../Services', true,  /router\.ts$/);
let modules: any = [];

requireModule.keys().forEach((filename: string) => {
  const content = requireModule(filename).default || requireModule(filename)
  modules = [
    ...modules,
    ...content
  ]
});

export default modules;