import { CloudProviderType } from "@Service/Emory/Models/Backups/CloudProvider/BackupCloudProvider.model";
import { BackupFilters } from "@Service/Emory/Models/Backups/BackupFilters.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { BackupLevel } from "@Service/Emory/Models/Backups/BackupLevel.model";
import { BackupStatus } from "@Service/Emory/Models/Backups/Backup.model";
import EmoryBackupsStore from "@Service/Emory/Store/EmoryBackups.store";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import { DatePickerOptions } from "element-ui/types/date-picker";
import { FileSizeUnit } from "@Core/Utils/FileSize";
import { Execution } from "@Core/Models/Execution";
import { Utils } from "@Core/Utils/Utils";
import DayJS from "dayjs";
import Vue from "vue";

@Component
export default class EmoryBackupFiltersPanel extends Vue {

  @Prop({ type: Object, required: true })
  protected value: BackupFilters;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @MapGetter(EmoryBackupsStore.Mapping)
  protected backupLevels: BackupLevel[];

  @MapAction(EmoryBackupsStore.Mapping)
  private fetchBackupLevels: () => Promise<BackupLevel[]>;

  protected allBackupStatus = BackupStatus.All;
  protected allSizeUnits = FileSizeUnit.All;
  protected allCloudProviders = CloudProviderType.All.map(provider => ({
    label: CloudProviderType.nameOf(provider),
    value: provider
  }));
  protected getSizeUnitFullName = FileSizeUnit.getUnitFullName;
  protected getBackupLevels = Execution.create(() => this.fetchBackupLevels());

  protected levelsSearchText: string = null;

  protected mounted() {
    this.getBackupLevels.run();
  }

  protected get groupedBackupLevels(): Record<string, BackupLevel[]> {
    const filteredLevels = this.backupLevels.filter(level => {
      if (level.isLog) { return false; }
      const text = this.levelsSearchText?.toLowerCase() || "";
      return level.name.toLowerCase().includes(text) || level.label.toLowerCase().includes(text);
    });
    return Utils.groupBy(filteredLevels, level => level.platform);
  }

  protected async onLevelsDropdownVisibleChange(visible: boolean) {
    if (visible) { return; }
    await Utils.sleep(200);
    this.levelsSearchText = null;
  }

  protected get datePickerOptions(): DatePickerOptions {
    return {
      firstDayOfWeek: 1,
      disabledDate: (time) => time.getTime() > Date.now(),
      shortcuts: [{
        text: '30 min. ago',
        onClick: (picker) => picker.$emit("pick", DayJS().subtract(30, "minutes").toDate())
      }, {
        text: 'An hour ago',
        onClick: (picker) => picker.$emit("pick", DayJS().subtract(1, "hour").toDate())
      }, {
        text: 'A day ago',
        onClick: (picker) => picker.$emit("pick", DayJS().subtract(1, "day").toDate())
      }, {
        text: '2 days ago',
        onClick: (picker) => picker.$emit("pick", DayJS().subtract(2, "days").toDate())
      }, {
        text: '3 days ago',
        onClick: (picker) => picker.$emit("pick", DayJS().subtract(3, "days").toDate())
      }, {
        text: 'A week ago',
        onClick: (picker) => picker.$emit("pick", DayJS().subtract(1, "week").toDate())
      }]
    }
  }

}