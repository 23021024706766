import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import RespondInvitationPanel from "../RespondInvitationPanel/RespondInvitationPanel";
import { SignInChallenge } from "@Module/Auth/Models/SignIn/SignInChallenge.model";
import { NewPwdChallenge } from "@Module/Auth/Models/SignIn/NewPwdChallenge.model";
import { SignInResponse } from "@Module/Auth/Models/SignIn/SignInResponse.model";
import NewPwdChallengePanel from "../NewPwdChallengePanel/NewPwdChallengePanel";
import { ContractPurchase } from "@Module/Auth/Models/ContractPurchase.model";
import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { SignUp } from "@Module/Auth/Models/SignUp/SignUp.model";
import ForgotPwdPanel from "../ForgotPwdPanel/ForgotPwdPanel";
import SignUpPanel from "../SignUpPanel/SignUpPanel";
import SignInPanel from "../SignInPanel/SignInPanel";
import Vue from "vue";

export const enum AuthFormPanel {
  SignIn = "sign-in",
  SignUp = "sign-up",
  NewPwdChallenge = "new-pwd-challenge",
  ForgotPassword = "forgot-password",
  RespondInvitation = "respond-invitation",
  Recovery = "recovery"
}

@Component
export default class AuthController extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected context: string;

  @Prop({ type: Boolean, default: true })
  protected showTitle: boolean;

  @Prop({ type: Boolean, default: false })
  protected skippable: boolean;

  @Prop({ type: String, default: AuthFormPanel.SignIn })
  protected value: AuthFormPanel;
  
  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgInvitationLink: OrgInvitationNotificationLink;

  @MapGetter(LicensingStore.Mapping)
  protected contractPurchase: ContractPurchase;

  @MapAction(LicensingStore.Mapping)
  protected setContractPurchase: (contractPurchase: ContractPurchase | null) => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected setOrgInvitationLink: (link: OrgInvitationNotificationLink | null) => Promise<void>;

  protected currentPanel: AuthFormPanel = AuthFormPanel.SignIn;
  protected newPwdChallenge: NewPwdChallenge = null;
  protected busy: boolean = false;
  protected signUpModel: SignUp = null;
  protected email: string = "";

  @Watch("value", { immediate: true })
  protected onValueChange(newPanel: AuthFormPanel) {
    this.currentPanel = newPanel;
  }

  @Watch("currentPanel", { immediate: true })
  protected onCurrentPanelChange(panel: AuthFormPanel) {
    this.$emit("change", panel);
  }

  @Watch("busy", { immediate: true })
  protected onBusyChange(busy: boolean) {
    this.$emit("update:disabled", busy);
  }

  protected setCurrentPanel(panel: AuthFormPanel) {
    this.currentPanel = panel;
    this.$emit("input", panel);
  }

  protected get isInDialog(): boolean {
    return !!this.$parent.$refs.dialog;
  }

  protected get panel() {
    return {
      isSignIn: this.currentPanel === AuthFormPanel.SignIn,
      isSignUp: this.currentPanel === AuthFormPanel.SignUp,
      isNewPwdChallenge: this.currentPanel === AuthFormPanel.NewPwdChallenge,
      isRecovery: this.currentPanel === AuthFormPanel.Recovery,
      isForgotPwd: this.currentPanel === AuthFormPanel.ForgotPassword,
      isRespondInvitation: this.currentPanel === AuthFormPanel.RespondInvitation
    }
  }

  protected async onCancelInvitation() {
    try {
      const message = `<p>Are you sure you want to postpone your invitation to <strong>${this.orgInvitationLink.orgName}</strong> organization? <p/>` +
        `<p>Later, once authenticated, you will still be able to accept or decline the invitation from the notifications inbox.<p>`
      await this.$confirm(
        message,
        "Postpone invitation", {
          type: "warning",
          cancelButtonText: "Cancel",
          confirmButtonText: "Postpone invitation",
          dangerouslyUseHTMLString: true
        }
      );
      await this.setOrgInvitationLink(null);
    } catch {}
  }

  protected onSignInResult(result: SignInResponse | SignInChallenge) {
    if (result instanceof SignInChallenge && result.isNewPasswordChallenge) {
      this.newPwdChallenge = NewPwdChallenge.create(result);
      this.setCurrentPanel(AuthFormPanel.NewPwdChallenge);
    } else if (result instanceof SignInChallenge){
        this.email = result.username;
    }else {
      this.onSignInComplete()
    }
  }

  protected async onInvitationAccepted(orgInvitationLink: OrgInvitationNotificationLink) {
    if (orgInvitationLink.isSignUp) {
      this.onSignUpComplete();
    } else {
      this.onSignInComplete();
    }
  }

  protected async onSignInComplete() {
    if (!!this.orgInvitationLink && !this.orgInvitationLink.accepted) {
      this.orgInvitationLink.accepted = false;
      this.orgInvitationLink.isSignUp = false;
      this.setCurrentPanel(AuthFormPanel.RespondInvitation);
    } else {
      await this.setContractPurchase(null);
      this.$emit("signed-in");
    }
  }
  
  protected onSignUpResult(result: SignUp) {
    this.signUpModel = result;
    this.email = result.email;
    this.onSignUpComplete();
  }

  protected onCodeVerified() {
    this.setCurrentPanel(AuthFormPanel.SignIn);
  }
  
  protected async onSignUpComplete() {
    if (!!this.orgInvitationLink && !this.orgInvitationLink.accepted) {
      this.orgInvitationLink.accepted = true;
      this.orgInvitationLink.isSignUp = true;
      this.setCurrentPanel(AuthFormPanel.RespondInvitation);
    } else {
      await this.setContractPurchase(null);
      this.setCurrentPanel(AuthFormPanel.SignIn);
      this.$emit("signed-up");
    }
  }

  protected onForgotPasswordClick() {
    this.setCurrentPanel(AuthFormPanel.ForgotPassword);
  }

  protected onCancelForgotPwd() {
    this.setCurrentPanel(AuthFormPanel.SignIn);
  }

  protected toggleAuthPanel() {
    const targetPanel = this.panel.isSignIn 
      ? AuthFormPanel.SignUp 
      : AuthFormPanel.SignIn;
    this.setCurrentPanel(targetPanel);
  }

  protected goToSignIn() {
    this.setCurrentPanel(AuthFormPanel.SignIn);
  }

  public closeGuard(close: VoidFunction) {
    switch (this.currentPanel) {
      case AuthFormPanel.ForgotPassword:
        const forgotPwdPanel = this.$refs.forgotPwdPanel as ForgotPwdPanel;
        return forgotPwdPanel.closeGuard(close);
      case AuthFormPanel.NewPwdChallenge:
        const newPwdChallengePanel = this.$refs.newPwdChallengePanel as NewPwdChallengePanel;
        return newPwdChallengePanel.closeGuard(close);
      case AuthFormPanel.SignIn: 
        const signInPanel = this.$refs.signInPanel as SignInPanel;
        return signInPanel.closeGuard(close);
      case AuthFormPanel.SignUp: 
        const signUpPanel = this.$refs.signUpPanel as SignUpPanel;
        return signUpPanel.closeGuard(close);
      case AuthFormPanel.RespondInvitation:
        const respondInvitationPanel = this.$refs.respondInvitationPanel as RespondInvitationPanel;
        return respondInvitationPanel.closeGuard(close);
    }
  }

  protected beforeDestroy() {
    if (this.contractPurchase?.isError || this.contractPurchase?.isUpdate) {
      this.setContractPurchase(null);
    }
  }

}