import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class OrgRoleTag extends Vue {

  @Prop({ type: Object, required: true })
  protected role: Role;

  @Prop({ type: String, default: "mini" })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected showIcon: boolean;

  @Prop({ type: String })
  protected type: "info" | "success" | "danger" | "warning";

  protected get computedTagType(): string {
    return this.role.isNoAccess ? "disabled" : this.type;
  }

}