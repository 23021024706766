import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapGetter, Prop, Watch } from "types-vue";
import Vue from "vue";

@Component
export default class RedirectCountdownMixin extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;
  
  @Prop({ type: Number, default: 5 })
  protected seconds: number;

  @Prop({ type: String, default: "/" })
  protected redirectTarget: string;

  @Prop({ type: Function, default: null })
  protected redirectMethod: VoidFunction;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute?: ConsoleRoute;

  protected remainingSeconds: number = null;
  protected timeout: NodeJS.Timeout;

  protected created() {
    this.remainingSeconds = this.seconds;
  }

  @Watch("disabled", { immediate: true })
  protected onDisabledStateChange(disabled: boolean) {
    if (!disabled) {
      this.resume();
    } else {
      this.pause();
    }
  }

  private async countDown() {
    if (this.disabled) {
      this.pause();
    }

    this.remainingSeconds -= 1;
    if (this.remainingSeconds < 1) {
      this.redirect();
    }
  }

  public resume() {
    this.pause();
    this.timeout = setInterval(this.countDown, 1000);
  }

  public pause() {
    if (!!this.timeout) {
      clearInterval(this.timeout);
      this.timeout = null;
    }
  }

  public stop() {
    this.pause();
    this.remainingSeconds = this.seconds;
  }

  public redirect() {
    this.stop();

    if (this.redirectMethod) {
      this.redirectMethod();
    } else if (this.previousRoute?.path === this.redirectTarget) {
      this.$router.back();
    } else {
      this.$router.replace(this.redirectTarget).catch(e => e);
    }
  }

  protected beforeDestroy() {
    this.stop();
  }

}