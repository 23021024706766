import { Component, Prop } from "types-vue";
import { Release } from "@Module/Resources/Models/Release.model";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";


const enum PackageTypeTab {
  Installation = "installation",
  Upgrade = "upgrade",
  Maintenance = "maintenance"
}

@Component
export default class ReleaseSelector extends Vue {

  @Prop({ type: String, default: "Pick a release" })
  protected placeholder: string;

  @Prop({ type: [Object, Array], default: null })
  protected releases: Release[];

  @Prop({ type: Number, default: null })
  protected value: Number;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected allowBrowsing: boolean;

  protected searchText: string = null;

  protected onReleaseSelected(release: Number ): void {
    const selectedRelease = release || null;
    this.$emit("input", selectedRelease);
    this.$emit("update:value", selectedRelease);
    this.$emit("select", selectedRelease);
    this.$emit("change", selectedRelease);
    this.searchText = null;
  } 
}