import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { ServiceID } from "@Service/ServiceID";
import { INotificationDto, Notification } from "./Notification.model";

interface IContractPurchaseDetailsDto {
  contract_id: number;
  contract_origin: string;
  product_name: string;
  user_id: string;
}

type IContractPurchaseNotificationDto = INotificationDto<IContractPurchaseDetailsDto>;

export class ContractPurchaseNotification extends Notification {

  public readonly service: ServiceID;
  public readonly contractId: number;
  public readonly username: string;
  public readonly contractOrigin: ContractOrigin;

  private constructor(dto: IContractPurchaseNotificationDto) {
    super(dto);
    this.service = dto.details.product_name as ServiceID;
    this.contractId = dto.details.contract_id;
    this.contractOrigin = dto.details.contract_origin as ContractOrigin;
    this.username = dto.details.user_id;
  }

  public static fromDto(dto: IContractPurchaseNotificationDto): ContractPurchaseNotification {
    return new this(dto);
  }
}