import { TrialInfo } from "@Module/Trial/Models/TrialInfo.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class TrialStatus extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @Prop({ type: Object, required: true })
  protected trial: TrialInfo;

  @Prop({ type: Number, required: true })
  protected duration: number;

  @Prop({ type: String, default: "Trial" })
  protected title: string;

  @Prop({ type: Boolean, default: false })
  protected bordered: boolean;

  protected get progressStyle(): string {
    if (this.percentageUsed === 100) { return "exception" }
    else if (this.trial.remainingDays <= 5) { return "warning"; }
    else { return null; };
  }

  protected get percentageUsed(): number {
    const durSeconds = this.duration * 24 * 60 * 60;
    const used = durSeconds - this.trial.remainingSeconds;
    const rawPercentage = (100 * used) / durSeconds;
    return Math.min(Math.abs(rawPercentage), 100);
  }

  protected get expirationText(): string {
    if (this.percentageUsed === 100) {
      return `Expired ${this.trial.expiration.startOf("second").toNow()}`;
    } else {
      return `Expires ${this.trial.expiration.startOf("second").fromNow()}`;
    }
  }

  protected get trialStatusClass() {
    return {
      "is-bordered": this.bordered
    }
  }

}