import { ServiceID } from "@Service/ServiceID";
import { IPaymentDto } from "./Payment.model";
import DayJS, { Dayjs } from "dayjs";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import { Dimension, IDimensionUnitsDto } from "@Module/Licensing/Models/Dimension.model";
import { LeadGenerator } from "./LeadGenerator.model";
import { LeadGeneratorContract } from "./LeadGeneratorContract.model";
import { BillingPeriod, IBillingPeriodDto } from "./BillingPeriod.model";

export interface IReportFiltersDto {
  dimensions: Dimension[];
  product?: ServiceID,
  status?: string,
  origin?: string,
  withHosts?: boolean,
  isPoc?: boolean,
  currency?: string,
  leadGenerator?: LeadGeneratorContract,
  billingPeriods: BillingPeriod[],
}

export interface IReportContractDto {
  id: number;
  externalId: string;
  status: string;
  origin: string;
  creation: string;
  expiration: string;
  numLicenses: number;
  hideMRR: boolean;
  agreement?: string;
  totalAmount?: number;
  autorenewal?: boolean;
  paymentsNumber?: number;
  productName?: string;
  extendedContractId?: number;
  orgName?: string;
  awsFee?: number;
  currency?: string;
  isPoc?: boolean;
  revenue?:  number;
  payments: IPaymentDto[];
  dimensions?: IDimensionUnitsDto[];
  observations?: string;
  leadGenerator?: LeadGeneratorContract;
  billingPeriods?: IBillingPeriodDto[];
}

export class ReportContract {
  id: number;
  externalId: string;
  orgName: string;
  creation: Dayjs;
  extendedContractId?: number;
  expiration: Dayjs;
  numLicenses: number;
  agreement: string;
  hideMRR?: boolean;
  totalAmount?: number;
  autorenewal?: boolean;
  paymentsNumber?: number;
  isPoc?: boolean;
  productName?: string;
  observations?: string;
  awsFee?: number;
  currency?: string;
  revenue?:  number;
  payments: IPaymentDto[];
  dimensions?: IDimensionUnitsDto[];
  origin: ContractOrigin;
  status: LicensingStatus;
  leadGenerator?: LeadGeneratorContract;
  billingPeriods?: IBillingPeriodDto[];

 private constructor(dto: IReportContractDto) {
   this.id = dto.id;
   this.externalId = dto?.externalId;
   this.status = LicensingStatus.create(dto.status);
   this.origin = dto.origin as ContractOrigin;
   this.creation = DayJS(dto.creation);
   this.expiration = DayJS(dto.expiration);
   this.numLicenses = dto.numLicenses;
   this.hideMRR = dto?.hideMRR;
   this.agreement = dto?.agreement;
   this.extendedContractId = dto?.extendedContractId;
   this.orgName = dto?.orgName;
   this.autorenewal = dto?.autorenewal;
   this.currency = dto?.currency;
   this.observations = dto?.observations;
   this.productName = dto?.productName as ServiceID;
   this.totalAmount = dto?.totalAmount;
   this.paymentsNumber = dto?.paymentsNumber;
   this.awsFee = dto?.awsFee;
   this.isPoc = dto?.isPoc;
   this.revenue = dto?.revenue;
   this.payments = !dto.payments ? [] : dto.payments;
   this.dimensions = !dto.dimensions ? [] : dto.dimensions;
   this.leadGenerator = dto?.leadGenerator;
   this.billingPeriods = !dto.billingPeriods ? [] : dto.billingPeriods;
 }

 public static create(dto?: Partial<IReportContractDto>): ReportContract {
   return new ReportContract({
     id: dto?.id,
     externalId: dto?.externalId,
     status: dto?.status,
     origin: dto?.origin,
     creation: dto?.creation,
     expiration: dto?.expiration,
     autorenewal: dto?.autorenewal,
     extendedContractId: dto?.extendedContractId,
     numLicenses: dto?.numLicenses,
     agreement: dto?.agreement,
     hideMRR: dto?.hideMRR,
     isPoc: dto?.isPoc,
     currency: dto?.currency,
     observations: dto?.observations,
     orgName: dto?.orgName,
     productName: dto?.productName,
     totalAmount: dto?.totalAmount,
     paymentsNumber: dto?.paymentsNumber,
     dimensions: dto?.dimensions,
     awsFee: dto?.awsFee,
     revenue: dto?.revenue,
     payments: dto?.payments,
     leadGenerator: dto?.leadGenerator,
     billingPeriods: dto?.billingPeriods,

   });
 }

 public static fromDto(dto: IReportContractDto): ReportContract {
   return new ReportContract(dto);
 }

 public toDto(): IReportContractDto {
  return  {
    id: this.id,
    externalId: this.externalId,
    isPoc: this.isPoc,
    status: this.status.label,
    origin: this.origin,
    extendedContractId: this.extendedContractId,
    creation: this.creation.format(),
    expiration: this.expiration.format(),
    numLicenses: this.numLicenses,
    autorenewal: this.autorenewal,
    observations: this.observations ? this.observations : null, 
    agreement: this.agreement ? this.agreement : null,
    hideMRR: this.hideMRR,
    currency: this.currency,
    orgName: this.orgName,
    productName: this.productName,
    totalAmount: this.totalAmount,
    paymentsNumber: this.paymentsNumber,
    dimensions: this.dimensions,
    awsFee: this.awsFee,
    revenue: this.revenue,
    payments: this.payments,
    leadGenerator: this.leadGenerator ? this.leadGenerator : null,
    billingPeriods: this.billingPeriods,
  }
}

 public meetsFilter(search: string, status: string[]): boolean {
  const text = search?.toLowerCase() || "";
  if (!text) { return true; }
  const searchStatusLabel = this.status.label.toLowerCase().includes(text);
  const searchCode = this.externalId.toLowerCase().includes(text);
  const searchOrigin = this.origin.toLowerCase().includes(text);
  return searchStatusLabel || searchCode || searchOrigin;
}
}
