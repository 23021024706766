import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { Execution } from "@Core/Models/Execution";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { Contract, ContractNotificationsMap } from "@Module/Licensing/Models/Contract.model";
import { License } from "@Module/Licensing/Models/License.model";
import { LicensingStatusPhase } from "@Module/Licensing/Models/LicensingStatus.model";
import LicensingStore, { FetchByIdPayload, FetchLicenseByIdPayload, UpdateContractPayload } from "@Module/Licensing/Store/Licensing.store";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapAction, MapGetter, mixins, Prop } from "types-vue";
import Clone from "lodash.clonedeep";
import { Watch } from "vue-property-decorator";
import { Notify } from "@Core/Utils/Notify";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import OrgSwitchMixin from "@Module/Orgs/Mixins/OrgSwitch.mixin";
import { Utils } from "@Core/Utils/Utils";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";

const enum DetailsTab {
  Licenses = "licenses",
  Notifications = "notifications"
}

@Component
export default class ContractDetailsView extends mixins(OrgSwitchMixin) {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Object, default: () => LicenseFilters.create() })
  protected filters: LicenseFilters;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(LicensingStore.Mapping)
  protected contracts: ServiceMap<Contract[]>;
  
  @MapGetter(LicensingStore.Mapping)
  protected licenses: ServiceMap<License[]>;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgHasChildren: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(LicensingStore.Mapping)
  protected dimensionArrayParamSerializer: Serializer<Dimension[]>;

  @MapGetter(OrgsStore.Mapping)
  protected orgArrayParamSerializer: Serializer<Organization[]>;

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseSharingOutbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseReturnOutbox: LicenseReturnNotification[];

  @MapAction(NotificationsOutboxStore.Mapping)
  protected fetchPendingLicenseExchanges: () => Promise<void>;
  
  @MapAction(LicensingStore.Mapping)
  protected fetchContractById: (payload: FetchByIdPayload) => Promise<void>;

  @MapAction(LicensingStore.Mapping)
  protected fetchLicensesByContractId: (payload: FetchLicenseByIdPayload) => Promise<void>;

  @MapAction(LicensingStore.Mapping)
  private updateContract: (payload: UpdateContractPayload) => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<void>;

  protected error: ConsoleError = null;

  protected expandedRows: number[] = [];

  protected shouldDisplayTenantColumn: boolean = false;
  protected shouldDisplayOwnerColumn: boolean = false;

  protected currentTab: DetailsTab = DetailsTab.Licenses;
  protected notifications: ContractNotificationsMap = {
    expiration: false
  };

  protected getPendingExchanges = Execution.create(() => {
    return Promise.all([
      this.fetchInbox(),
      this.fetchPendingLicenseExchanges()
    ]);
  });

  protected editContract = Execution
    .create((contract: Contract, notifications: ContractNotificationsMap) => this.updateContract({
      service: this.serviceId,
      data: {
        id: contract.id,
        dto: { expirationNotifications: notifications.expiration }
      }
    }));

  protected getContractById = Execution.create(
    (contractId: number) => this.fetchContractById({ 
      service: this.serviceId, 
      data: contractId 
    }));
    
  protected getLicensesByContractId = Execution.create(
    (contractId: number) => this.fetchLicensesByContractId({ 
      service: this.serviceId, 
      data: contractId
    }));

  protected created() {
    if (this.permissions.CanShareLicenses) {
      this.getPendingExchanges.run();
    }
    this.updateLicenseSharingColumns();
  }

  protected updateLicenseSharingColumns() {
    this.shouldDisplayTenantColumn = this.currentOrgHasChildren || this.serviceLicenses.some(license => license.isShared && !license.isSharedToOrg(this.currentOrg));
    this.shouldDisplayOwnerColumn = !!this.currentOrg.parentId || this.serviceLicenses.some(license => !license.isOwnedByOrg(this.currentOrg));
  }

  protected get isShowingBothSharingColumns(): boolean {
    return this.shouldDisplayOwnerColumn && this.shouldDisplayTenantColumn;
  }
  
  @Watch("$route.params.id", { immediate: true })
  protected async onIdParamChange() {
    this.error = null;
    this.getContractById.reset();
    this.getLicensesByContractId.reset();
    await this.$nextTick();
    
    const contractId = Number(this.$route.params.id);
    if (isNaN(contractId)) {
      const errorMsg = `Invalid contract identifier '${this.$route.params.id}'.`;
      this.error = new ConsoleError(errorMsg, { fatal: true, meta: { contractId: this.$route.params.id } });
      return;
    }
  
    const contract = this.serviceContracts.some(contract => contract.id === contractId);
    if (!contract) {
      await this.getContractById.run(contractId);
      if (!!this.getContractById.error) {
        const errorMsg = `Contract with identifier ${this.$route.params.id} was not found.`;
        this.error = new ConsoleError(errorMsg, { fatal: true, meta: { contractId: this.$route.params.id } });
        return;
      }
    }

    const expNotif = this.$route.query.expnotif as string;
    const validExpNotifParam = ["true", "false"].includes(expNotif);
    if (validExpNotifParam) {
      this.currentTab = DetailsTab.Notifications;
      this.onExpirationNotificationsChange(expNotif === "true");
    }
    if (!!this.$route.query.expnotif) {
      const query = Clone(this.$route.query);
      delete query.expnotif;
      this.$router.replace({ query }).catch(e => e);
    }

    await this.fetchContractLicenses(contractId);
  }

  @Watch("contractLicenses")
  protected async onTableDataChange(data: License[]) {
    this.expandedRows = this.expandedRows.filter(id => data.some(license => license.id === id));
    if (data.length === 1 && this.expandedRows.length === 0) {
      this.expandedRows = [data[0].id];
    }
    await Utils.sleep(100);
    await this.$nextTick();
    this.updateLicenseSharingColumns();
  }

  protected get serviceContracts(): Contract[] {
    return this.contracts[this.serviceId] || [];
  }

  protected get serviceLicenses(): License[] {
    return this.licenses[this.serviceId] || [];
  }

  protected get contract(): Contract {
    const contractId = Number(this.$route.params.id);
    return this.serviceContracts.find(contract => contract.id === contractId);
  }

  protected get contractLicenses(): License[] {
    const contractId = Number(this.$route.params.id);
    return this.serviceLicenses.filter(license => license.contractId === contractId);
  }

  protected get filteredContractLicenses(): License[] {
    return this.contractLicenses.filter(license => license.meetFilters(this.filters));
  }

  protected get firstLoad(): boolean {
    return !this.hasLicenses && this.getLicensesByContractId.loading;
  }

  protected get hasLicenses(): boolean {
    return this.contractLicenses.length > 0;
  }

  protected toggleExpansion(license: License) {
    const index = this.expandedRows.indexOf(license.id);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [license.id];
    }
  }

  protected get expandedRowIds(): number[] {
    return this.filteredContractLicenses.length === 0 ? [] : this.expandedRows;
  }

  protected get currentExpNotificationStep(): number {
    switch (this.contract.status.phase) {
      case LicensingStatusPhase.Active: return 1;
      case LicensingStatusPhase.InGrace: return 3;
      case LicensingStatusPhase.Expired: return 4;
    }
  }

  protected clearFilters() {
    this.filters.reset();
  }

  protected shouldShowExchangeLoading(license: License): boolean {
    return this.getPendingExchanges.loading
      && !this.getLicenseExchangePending(license);
  }

  protected getLicenseExchangePending(license: License) {
    return this.getLicenseSharingPending(license)
        || this.getLicenseReturnPending(license);
  }

  protected getLicenseSharingPending(license: License) {
    return this.orgLicenseSharingOutbox.find(noti => noti.isLicenseIncluded(license));
  }

  protected getLicenseReturnPending(license: License) {
    return this.orgLicenseReturnOutbox.find(noti => noti.isLicenseIncluded(license));
  }

  protected onBackClick() {
    this.$emit("back", this.previousRoute);
  }

  @Watch("permissions", {immediate: true, deep: true })
  protected onPermissionsChange() {
    this.currentTab = this.permissions.CanListLicenses 
      ? DetailsTab.Licenses
      : DetailsTab.Notifications; 
  }

  @Watch("permissions.CanListLicenses")
  protected onListLicensesPermissionChange() {
    this.fetchContractLicenses(this.contract?.id);
  } 

  protected async fetchContractLicenses(contractId: number = Number(this.$route.params.id)) {
    if (this.permissions.CanListLicenses) {
      return this.getLicensesByContractId.run(contractId);
    }
  }

  protected async onExpirationNotificationsChange(newValue: boolean) {
    if (this.contract.status.isFinished
      || !this.permissions.CanEditContractNotifications
      || newValue === this.contract.notifications.expiration) {
      return;
    }
    const currentNotif = Clone(this.contract.notifications);
    await this.editContract.run(this.contract, {...currentNotif, expiration: newValue });
    if (!this.editContract.error) {
      Notify.Success({
        title: `Expiration notifications ${newValue ? 'enabled' : 'disabled'}`,
        message: `Notifications on contract expiration has been successfully ${newValue ? 'enabled' : 'disabled'}.`,
        duration: 5000
      });
    } else {
      Notify.Error({
        title: `Expiration notifications error`,
        message: `An error occurred while ${newValue ? 'enabling' : 'disabling'} expiration notifications.`,
        duration: 5000
      });
    }
  }

  protected onViewLicenseClick(license: License) {
    this.$emit("select", license);
  }

  protected get licenseDimensionsCountMap(): Record<string, number> {
    return this.filteredContractLicenses.reduce((m, curr) => {
      const dimCount = m[curr.dimension] ?? 0;
      m[curr.dimension] = dimCount + 1;
      return m;
    }, {} as Record<string, number>);
  }

  protected get permissions() {
    return {
      CanEditContractNotifications: this.accessRole.can(this.serviceId, Permission.EditContractNotifications),
      CanEditContract: this.accessRole.can(this.serviceId, Permission.EditContract),
      CanListLicenses: this.accessRole.can(this.serviceId, Permission.ListLicenses),
      CanListHosts: this.accessRole.can(this.serviceId, Permission.ListHosts),
      CanShareLicenses: this.accessRole.can(this.serviceId, Permission.ShareLicenses)
    }
  }

  protected get chanShareLicenses(): boolean {
    const canShare = this.currentOrgHasChildren && this.contractLicenses.some(license => license.status.isActiveOrInGrace);
    const canReturnToParent = this.contractLicenses.some(license => license.isSharedToOrg(this.currentOrg));
    const canReturnFromChild = this.contractLicenses.some(license => license.isShared && !license.isSharedToOrg(this.currentOrg));
    return this.permissions.CanShareLicenses && (canShare || canReturnToParent || canReturnFromChild);
  }

  protected licenseSlotScope(license: License) {
    return {
      ...this.slotScope,
      license,
      exchangeStatus: {
        loading: this.getPendingExchanges.loading,
        isShared: license.isShared,
        isSharedToCurrentOrg: license.isShared && license.isSharedToOrg(this.currentOrg),
        isPending: !!this.getLicenseExchangePending(license),
        sharing: this.getLicenseSharingPending(license),
        return: this.getLicenseReturnPending(license)
      }
    }
  }

  protected get slotScope() {
    return {
      error: this.getContractById.error || this.getLicensesByContractId.error,
      loading: this.getContractById.loading || this.getLicensesByContractId.loading,
      hasRecords: this.hasLicenses,
      data: { 
        original: this.serviceLicenses, 
        filtered: this.filteredContractLicenses
      }
    }
  }

  protected sortByOwner(a: License, b: License): number {
    return a.owner.name.localeCompare(b.owner.name);
  }

  protected sortByTenant(a: License, b: License): number {
    return a.tenant?.name.localeCompare(b.tenant?.name) ?? -1;
  }

}