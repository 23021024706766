import { ServiceID } from "@Service/ServiceID";
import { Console } from "vue-router";

import ServiceViewport from "@Core/Components/ServiceViewport/ServiceViewport.vue";
import Viewport from "@Core/Components/Viewport/Viewport.vue";

import EmoryHub from './Views/Hub/EmoryHub.vue';
import EmoryContracts from './Views/Contracts/EmoryContracts.vue';
import EmoryContractDetails from './Views/ContractDetails/EmoryContractDetails.vue';
import EmoryLicenseDetails from './Views/LicenseDetails/EmoryLicenseDetails.vue';
import EmoryLicenses from './Views/Licenses/EmoryLicenses.vue';
import EmoryBackupHistory from './Views/BackupHistory/EmoryBackupHistory.vue';
import EmoryBackupDetails from './Views/BackupDetails/EmoryBackupDetails.vue';
import EmoryPackages from "./Views/Packages/EmoryPackages.vue";
import EmoryDocumentation from "./Views/Documentation/EmoryDocumentation.vue";

const routes: Console.RouteConfig[] = [
  {
    path: "/backup",
    name: "backup",
    component: ServiceViewport,
    redirect: { name: "backup-home" },
    meta: {
      authRequired: true,
      appInfo: {
        version: "2.5.0",
        serviceId: ServiceID.Emory,
        summary: "CxLink Backup is a cutting-edge cloud-native solution designed to protect your business-critical data. It offers comprehensive functionalities including backups, restores, system copies, and disaster recovery, all with robust multi-cloud support across AWS, Azure, and Google Cloud. As a SAP-certified solution, CxLink Backup seamlessly integrates with the most common enterprise databases, including all SAP databases, ensuring reliability and efficiency for your data management needs.",
        purchaseMethods: [
          { label: "AWS Marketplace", url: process.env.VUE_APP_MARKETPLACE_URL_EMORY },
          { label: "Contact us by email", url: `mailto:${process.env.VUE_APP_CONTACT_EMAIL}` } 
        ]
      },
      menu: {
        label: ServiceID.nameOf(ServiceID.Emory),
        groupId: "service",
        icon: {
          type: "custom",
          name: "icons/backup.svg"
        }
      }
    },
    children: [
      {
        path: "home",
        name: "backup-home",
        component: EmoryHub,
        meta: {
          authRequired: true,
          menu: {
            label: "Home",
            icon: {
              type: "ion-icon",
              name: "bookmark"
            }
          }
        }
      },
      {
        path: "/backup/licensing",
        name: "backup-licensing",
        component: Viewport,
        redirect: { name: "backup-contracts" },
        meta: {
          authRequired: true,
          breadcrumb: {
            hidden: true
          },
          menu: {
            label: "Licensing",
            icon: {
              type: "ion-icon",
              name: "id-card"
            }
          }
        },
        children: [
          {
            path: "/backup/contracts",
            name: "backup-contracts",
            component: EmoryContracts,
            meta: {
              authRequired: true,
              permissions: "emory:ListContracts",
              menu: {
                label: "Contracts",
                icon: {
                  type: "ion-icon",
                  name: "receipt-outline"
                }
              }
            }
          },
          {
            path: "/backup/contracts/:id",
            name: "backup-contract-details",
            component: EmoryContractDetails,
            meta: {
              authRequired: true,
              permissions: "emory:ListContracts",
              breadcrumb: {
                prependPaths: ["/backup/contracts"]
              },
              menu: {
                hidden: true,
                selectionPath: "/backup/contracts",
                label: "Contract Details",
                icon: {
                  type: "ion-icon",
                  name: "receipt-outline"
                }
              }
            }
          },
          {
            path: "/backup/licenses",
            name: "backup-licenses",
            component: EmoryLicenses,
            meta: {
              authRequired: true,
              permissions: "emory:ListLicenses",
              menu: {
                label: "Licenses",
                icon: {
                  type: "ion-icon",
                  name: "reader-outline"
                }
              }
            }
          },
          {
            path: "/backup/licenses/:id",
            name: "backup-license-details",
            component: EmoryLicenseDetails,
            meta: {
              authRequired: true,
              permissions: "emory:ListLicenses",
              breadcrumb: {
                prependPaths: ["/backup/licenses"]
              },
              menu: {
                hidden: true,
                selectionPath: "/backup/licenses",
                label: "License Details",
                icon: {
                  type: "ion-icon",
                  name: "reader-outline"
                }
              }
            }
          }
        ]
      },
      {
        path: "resources",
        name: "backup-resources",
        component: Viewport,
        redirect: { name: "backup-downloads", append: false},
        meta: {
          authRequired: true,
          breadcrumb: {
            hidden: true
          },
          menu: {
            label: "Resources",
            icon: {
              type: "ion-icon",
              name: "library-outline"
            }
          }
        },
        children: [
          {
            path: "/backup/downloads",
            name: "backup-packages",
            component: EmoryPackages,
            meta: {
              authRequired: true,
              permissions: "emory:DownloadPackages",
              menu: {
                label: "Downloads",
                icon: {
                  type: "ion-icon",
                  name: "archive-outline"
                }
              }
            }
          },
        ]
      },
      {
        path: "history",
        name: "backup-history",
        component: EmoryBackupHistory,
        meta: {
          authRequired: true,
          permissions: "emory:ListBackups",
          menu: {
            label: "Backup History",
            icon: {
              type: "ion-icon",
              name: "file-tray-full"
            }
          }
        }
      }, 
      {
        path: "history/:id",
        name: "backup-details",
        component: EmoryBackupDetails,
        meta: {
          authRequired: true,
          permissions: "emory:ListBackups",
          breadcrumb: {
            prependPaths: ["/backup/history"]
          },
          menu: {
            hidden: true,
            selectionPath: "/backup/history",
            label: "Backup Details",
            icon: {
              type: "ion-icon",
              name: "file-tray-full"
            }
          }
        }
      }
    ]
  }
];

export default routes
