import { UIContentSizeMode } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { TrialInfo } from "@Module/Trial/Models/TrialInfo.model";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import TrialStore from "@Module/Trial/Store/Trial.store";
import { Component, MapGetter } from "types-vue";
import { ServiceMap } from "@Service/ServiceID";
import Vue from "vue";

@Component
export default class LayoutMixin extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(AppSettingsStore.Mapping)
  protected hiddenBreadcrumb: boolean;

  @MapGetter(AppSettingsStore.Mapping)
  protected uiContentSizeMode: UIContentSizeMode;

  @MapGetter(TrialStore.Mapping)
  protected trialInfo: ServiceMap<TrialInfo>;

  @MapGetter(NavigationStore.Mapping)
  protected docsPanelVisible: boolean;

  protected get currentServiceTrialInfo(): TrialInfo {
    return this.trialInfo[this.currentRoute.serviceId || ""] || null;
  }

  protected get hasActiveTrial(): boolean {
    return this.currentServiceTrialInfo?.isActive ?? false;
  }

  protected redirectToNonLegacyService() {
    const redirect = (this.currentRoute.service.meta.appInfo?.legacy as any)?.redirect;
    if (!!redirect) {
      this.$router.push(redirect);
    }
  }

  protected get pageLayoutClass() {
    return { 
      "is-airy": this.uiContentSizeMode === "airy",
      "is-compact": this.uiContentSizeMode === "compact",
      "is-docs-embedded": this.docsPanelVisible
    };
  }

}