import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { ConsoleError } from "@Core/Errors/ConsoleError";

interface SendOrgInvitationApiErrorMeta {
  organization: Organization;
  username: string;
  role: Role;
}

export class SendOrgInvitationApiError extends ConsoleError<SendOrgInvitationApiErrorMeta> {}