export class HostFilters {
  public search: string;
  public hostnames: string[];
  public externalIds: string[];

  public static create(): HostFilters {
    return new HostFilters();
  }
  
  protected constructor() {
    this.initialize();
  }

  public reset() { 
    this.initialize();
  }

  protected initialize() {
    this.search = null;
    this.hostnames = [];
    this.externalIds = [];
  }

  public get isFiltering(): boolean {
    return this.search !== null
        || this.externalIds.length > 0
        || this.hostnames.length > 0;
  }
}