import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class NoOrgAlert extends Vue {

  @Prop({ type: String })
  protected title: string;

  @Prop({ type: String })
  protected description: string;

  @Prop({ type: Boolean, default: false })
  protected floating: boolean;

  @Prop({ type: Boolean, default: false })
  protected plain: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: Boolean, default: true })
  protected showIcon: boolean;

  @Prop({ type: Boolean, default: true })
  protected showSetupButton: boolean;
  
  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  protected get shouldShowSetupButton(): boolean {
    return !this.currentRoute.isOrgConfig && this.showSetupButton;
  }

  protected goToOrganizationSetup() {
    this.$router.push(ConsoleRoute.OrgConfigPath);
  }

}