import { IOrganizationDto, Organization } from "@Module/Orgs/Models/Organization.model";
import { INotificationDto, Notification } from "./Notification.model";
import { License } from "@Module/Licensing/Models/License.model";
import { ServiceID } from "@Service/ServiceID";

interface LicenseReturnSummary {
  id: number;
  dimension: string;
}

export interface ILicenseReturnDetailsDto {
  voluntary_return: boolean;
  destination_org: IOrganizationDto;
  source_org: IOrganizationDto;
  licenses: LicenseReturnSummary[];
  product: string;
}

type ILicenseReturnNotificationDto = INotificationDto<ILicenseReturnDetailsDto>;

export class LicenseReturnNotification extends Notification {

  public readonly organization: Organization;
  public readonly sourceOrg: Organization;
  public readonly destinationOrg: Organization;
  public readonly voluntaryReturn: boolean;
  public readonly licenseSummary: LicenseReturnSummary[];
  public readonly service: ServiceID;

  private constructor(dto: ILicenseReturnNotificationDto) {
    super(dto);
    this.service = dto.details.product as ServiceID;
    this.licenseSummary = dto.details.licenses;
    this.sourceOrg = Organization.fromDto(dto.details.source_org);
    this.voluntaryReturn = dto.details.voluntary_return;
    this.destinationOrg = Organization.fromDto(dto.details.destination_org);
  }

  public static fromDto(dto: ILicenseReturnNotificationDto): LicenseReturnNotification {
    return new this(dto);
  }

  public isLicenseIncluded(license: License): boolean {
    return this.licenseSummary.some(licenseSummary => licenseSummary.id === license.id);
  }

  public isDestinatedTo(org?: Organization): boolean {
    return this.destinationOrg.id === org?.id;
  }

  public isFrom(org?: Organization): boolean {
    return this.sourceOrg.id === org?.id;
  }

  public isInvolved(...orgs: Organization[]): boolean {
    return orgs.some(org => this.isDestinatedTo(org) || this.isFrom(org));
  }

  public get summary(): Record<string, number[]> {
    return this.licenseSummary.reduce((map, license) => {
      const currLicenses = map[license.dimension] ?? [];
      currLicenses.push(license.id);
      map[license.dimension] = currLicenses;
      return map;
    }, {} as Record<string, number[]>);
  }

  public toDto(): INotificationDto<ILicenseReturnDetailsDto> {
    return {
      ...super.toDto(),
      details: {
        product: this.service,
        licenses: this.licenseSummary,
        voluntary_return: this.voluntaryReturn,
        source_org: this.sourceOrg.toDto(),
        destination_org: this.destinationOrg.toDto()
      }
    }
  }
}