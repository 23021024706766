import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { User } from "@Module/Users/Models/User.model";
import UsersStore from "@Module/Users/Store/Users.store";
import { ServiceID } from "@Service/ServiceID";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapAction, MapGetter } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Watch } from "vue-property-decorator";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import AuthStore from "@Module/Auth/Store/Auth.store";
import Vue from "vue";

@Component
export default class UsersManager extends Vue {
  
  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(UsersStore.Mapping)
  protected users: User[];

  @MapGetter(OrgsStore.Mapping)
  protected orgArrayParamSerializer: Serializer<Organization[]>;

  @MapAction(UsersStore.Mapping)
  protected fetchUsers: () => Promise<void>;
  
  @MapAction(UsersStore.Mapping)
  protected deleteUser: (user: User) => Promise<void>;

  protected searchText: string = null;
  protected expandedRows: string[] = [];
  protected orgs: Organization[] = [];

  protected getUsers = Execution.create(() => this.fetchUsers());
  protected removeUser = Execution.create((user: User) => this.deleteUser(user));

  @Watch("users", { immediate: true })
  protected onTableDataChange(data: User[]) {
    if (data.length === 1) {
      this.expandedRows = [data[0].username];
    }
  }

  protected mounted() {
    this.getUsers.run();
  }

  protected get filteredUsers(): User[] {
    return this.users.filter(user => user.meetsFilter(this.searchText, this.orgs));
  }

  protected get universalAccessUsersCount(): number {
    return this.filteredUsers.filter(user => user.universalOrgAccess).length;
  }

  protected get firstLoad(): boolean {
    return this.getUsers.loading && !this.hasUsers;
  }

  protected get hasUsers(): boolean {
    return this.users.length > 0;
  }

  protected clearFilters() {
    this.searchText = null;
    this.orgs = [];
  }

  protected async onDeleteClick(user: User) {
    try {
      await this.$confirm(
        "Are you sure you want to delete this user? This action can not be undone.", 
        "Delete confirmation", {
          confirmButtonText: "Delete user",
          cancelButtonText: "Cancel",
          type: "warning"
      });
      await this.removeUser.run(user);
      if (!!this.removeUser.error) {
        Notify.Error({
          title: "User deletion error",
          message: this.removeUser.error.message,
          duration: 3000
        });
      } else {
        Notify.Success({
          title: "User deleted",
          message: "The user was successfully deleted.",
          duration: 3000
        });
      }
    } catch {}
  }

  protected toggleExpansion(contract: User) {
    const index = this.expandedRows.indexOf(contract.username);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [contract.username];
    }
  }

  protected get expandedRowIds(): string[] {
    return this.expandedRows
      .filter(username => this.filteredUsers.some(contract => contract.username === username));
  }

  protected sortByOrgAccess(a: User, b: User): number {
    const aScore = a.allOrgRoles.length + (a.universalOrgAccess ? Infinity : 0);
    const bScore = b.allOrgRoles.length + (b.universalOrgAccess ? Infinity : 0);
    return aScore - bScore;
  }

  protected viewDetails(user: User) {
    this.$router.push({ 
      name: "cxlink-user-details", 
      params: { username: user.username }
    });
  }

  protected get permissions() {
    return {
      CanCreateAppUsers: this.accessRole.can(ServiceID.Console, Permission.CreateAppUsers),
      CanEditAppUsers: this.accessRole.can(ServiceID.Console, Permission.EditAppUsers),
      CanDeleteAppUsers: this.accessRole.can(ServiceID.Console, Permission.DeleteAppUsers)
    }
  }

}