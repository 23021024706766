<template functional>
  <el-skeleton
    class="notification-skeleton"
    :loading="props.loading"
    :throttle="props.throttle"
    animated>
    <div slot="template" class="m-y-4">
      <div v-for="index in props.repeat" :key="index">
        <div class="flex-y space-y-8">
          <el-skeleton-item v-if="!props.hideTypeLabel" variant="h3" style="width: 15rem;" />
          <el-skeleton-item variant="p" style="width: 80%;" />
          <el-skeleton-item class="details" variant="button" style="height: 44px; width: 100%;" />
          <div class="flex content-space-between footer">
            <el-skeleton-item variant="caption" style="width: 18rem;" />
            <el-skeleton-item variant="caption" style="width: 8rem;" />
          </div>
        </div>
        <el-divider class="separator" v-if="index < props.repeat" />
      </div>
    </div>
    <slot />
  </el-skeleton>
</template>

<style lang="scss" scoped>
.notification-skeleton {
  .separator {
    margin: 13px 0;
  }
  .details, .footer {
    margin-top: 2px;
  }
}
</style>

<script>
export default {
  props: { 
    hideTypeLabel: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    repeat: {
      type: Number,
      default: 1
    },
    throttle: {
      type: Number
    }
  }
}
</script>