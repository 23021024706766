import { ForgotPwd } from "@Module/Auth/Models/ForgotPwd/ForgotPwd.model";
import NewPwdForm from "../NewPwdForm/NewPwdForm";
import AuthApi from "@Module/Auth/API/Auth.api";
import { ElForm } from "element-ui/types/form";
import { Utils } from "@Core/Utils/Utils";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";
import { Notify } from "@Core/Utils/Notify";
import { Recovery } from "@Module/Auth/Models/Recovery.model";

@Component
export default class RecoveryPanel extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: false })
  protected email: string;

  protected recoveryModel: Recovery = Recovery.create();
  protected loading: boolean = false;
  protected error: Error = null;

  protected validationRules = {
    verificationcode: [
      { required: true, message: "Please enter your verification code.", trigger: "change" },
      { required: true, message: "Please enter your verification code.", trigger: "blur" },
      { validator: this.onlyDigits, trigger: "blur" },
    ]
  }

  @Watch("email", { immediate: true })
  protected onBusyChange(busy: boolean) {
    this.recoveryModel.username = this.email;
  }

  protected onlyDigits(rule: any, value: string, callback: (error?: Error) => void): void {
    const codePattern = /^[0-9]{6,6}$/;
    return codePattern.test(value) 
      ? callback() 
      : callback(new Error("Invalid verification code."));
  }

  protected setLoading(loading: boolean) {
    this.loading = loading;
    this.$emit("update:disabled", loading);
  }

  protected async sendToServer() {
    try {
      this.error = null;
      this.setLoading(true);
      await AuthApi.verifyCode(this.recoveryModel);;
        Notify.Success({
          title: "Account verified successfully", 
          message: "You can login now."
        });
        await Utils.sleep(250);
        this.$emit("success");
      } catch (error) {
      this.error = error;
    } finally {
      this.setLoading(false);
    }
  }

  public async closeGuard(close: VoidFunction) {    
    try {
      const message = "<p>Do you really want to finish the current password recovery process?</p>"
        + "<p>You can start the password recovery process again at any time.</p>";
      await this.$confirm(message, "Finish password recovery", {
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Finish",
        dangerouslyUseHTMLString: true
      });
      return close();
    } catch {}
  }
}