export enum ContractOrigin {
  Linke = "Linke",
  Trial = "Trial",
  AWS = "AWS",
  SAP = "SAP App Center",
  Syntax = "Syntax",
  MSP = "MSP",
  Extended = "Contract Extension",
  Lab = "Lab",
  AMS = "AMS",
}

export namespace ContractOrigin {
  export const All = [ContractOrigin.AWS, ContractOrigin.Linke, ContractOrigin.SAP, ContractOrigin.Trial, ContractOrigin.Syntax, ContractOrigin.MSP, ContractOrigin.Extended, ContractOrigin.Lab];
  export const OnlyLinke = [ContractOrigin.Linke, ContractOrigin.Extended, ContractOrigin.MSP, ContractOrigin.Syntax, ContractOrigin.Lab, ContractOrigin.AMS];

  export function nameOf(origin: ContractOrigin): string {
    switch (origin) {
      case ContractOrigin.SAP: return "SAP";
      case ContractOrigin.Linke: return "Direct Selling";
      case ContractOrigin.Syntax: return "Syntax - Internal Customer";
      case ContractOrigin.Lab: return "Syntax - Internal Lab";
      case ContractOrigin.AMS: return "Syntax - AMS";



      default: return origin;

    }
  }
}