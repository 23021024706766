import { Contract } from "@Module/Licensing/Models/Contract.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { Payment } from "@Module/Reports/Models/Payment.model";
import { IReportContractDto, ReportContract } from "@Module/Reports/Models/ReportContract.model";
import ReportsStore from "@Module/Reports/Store/Reports.store";
import { DatePickerOptions } from "element-ui/types/date-picker";
import DayJS from "dayjs";
import { Component, MapAction, MapGetter, Prop, Watch} from "types-vue";
import Vue from "vue";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { cloneDeep } from "lodash";
import ReportsApi from "@Module/Reports/API/Reports.api";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import { LeadGenerator } from "@Module/Reports/Models/LeadGenerator.model";
import { LeadGeneratorContract } from "@Module/Reports/Models/LeadGeneratorContract.model";
import { ServiceID } from "@Service/ServiceID";

@Component
export default class ReportsDialog extends Vue {
  @MapGetter(ReportsStore.Mapping)
  protected reportsDialogVisible: boolean;

@Prop({ type: Object, default: null })
  protected contract: ReportContract;

  @MapAction(ReportsStore.Mapping)
  protected updateReportContract: (contract: IReportContractDto) => Promise<IReportContractDto>;

  @MapAction(ReportsStore.Mapping)
  protected setReportsDialogVisible: (visible: boolean) => void
  protected reportContract: ReportContract = ReportContract.create()
  protected getLeadGenerators = Execution.create(() => ReportsApi.getLeadGenerators());
  protected leadGenSelector: LeadGenerator[] = []
  protected leadGenID: Number = null;
  protected leadGenObservations: string = "";
  protected extendedContractId: number = null;
  protected currencyOptions: string[] = ["USD", "EUR"];
  protected update = Execution.create((reportContract: IReportContractDto) => this.updateReportContract(reportContract));

  protected async fetchLeadGen(){
    const dtos = await this.getLeadGenerators.run();
    this.leadGenSelector = dtos.map(dto => LeadGenerator.fromDto(dto));
  }

  @Watch("sessionToken", { immediate: true })
  protected async onSessionChange(token: SessionToken) {
    if (!!token && !token.hasExpired) {
      await this.fetchLeadGen();
    }
  }

  async mounted() {
   await this.fetchLeadGen()
  }

  async open(contract: ReportContract) {
    this.reportContract.payments.map(payment => { 
      if (payment.date != null){
        payment.date = DayJS(payment.date).locale("es").toDate(); return payment }
      })    
    this.reportContract = cloneDeep(contract);
    if (this.reportContract.extendedContractId){
      this.extendedContractId = this.reportContract.extendedContractId
    }
    if (this.reportContract.leadGenerator != null) {
      this.leadGenID = this.reportContract.leadGenerator.leadGeneratorID;
      this.leadGenObservations = this.reportContract.leadGenerator.observations;
    }
    this.setReportsDialogVisible(true)
  }

  protected onClosed() {
    this.reportContract = ReportContract.create();
    this.extendedContractId = null
    this.update.reset();
    this.setReportsDialogVisible(false)
  }

  protected addPaymentForm() {
      this.reportContract.payments.push(Payment.create({ number: this.reportContract.payments.length + 1, billingPeriodId: this.reportContract.id}))
  }

  protected removePaymentForm() {
    this.reportContract.payments.pop()
  }

  async onOpen() {
    this.setReportsDialogVisible(true)
    this.reportContract.payments.map(payment => { 
      if (payment.date != null){
        payment.date = DayJS(payment.date).locale("es").toDate(); return payment }
      })    
  }

  private async createElement() {
   
      if (this.extendedContractId) {
        this.reportContract.extendedContractId = this.extendedContractId;
      } 
    if (this.leadGenID) {
    this.reportContract.leadGenerator = LeadGeneratorContract.create({ 
      id: this.reportContract.leadGenerator?.id, leadGeneratorID: this.leadGenID, contractID: this.reportContract.id, observations: this.leadGenObservations })
    }
    await this.update.run(this.reportContract.toDto());
      if (!this.update.error) {
        Notify.Success({
          title: "Contract updated",
          message: "Contract successfully updated.",
          duration: 3000
        });
        (this.$refs.dialog as Dialog).success(true);
      }
}

  protected get datePickerOptions(): DatePickerOptions {
    return {
      shortcuts: [{
        text: 'In 1 Week',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "weeks").toDate())
      }, {
        text: 'In 15 Days',
        onClick: (picker) => picker.$emit("pick", DayJS().add(15, "days").toDate())
      }, {
        text: 'In 1 Month',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "month").toDate())
      }, {
        text: 'In 1 Year',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "year").toDate())
      }]
    }
  }

}