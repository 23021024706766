import { License } from "@Module/Licensing/Models/License.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class LicenseAvailabilityTag extends Vue {

  @Prop({ type: Object, required: true })
  protected license: License;

  @Prop({ type: String, default: "mini" })
  protected size: UISize | "tiny";

  protected get availableSlots(): number {
    return this.license.maxHosts - this.license.hostCount;
  }

  protected get type(): "info" | "success" | "danger" | "warning" | "" {
    if (this.availableSlots > 0 && this.license.status.isActive) { 
      return "success"; 
    } else { 
      return "danger"; 
    }
  }
}