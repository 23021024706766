import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import modulesRoutes from './modules';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'App',
    redirect: { name: "cxlink" }
  },
  ...modulesRoutes
];

const router = new VueRouter({
  routes,
  mode: 'hash'
});

export default router;
