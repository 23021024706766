import LicensingStore, { FetchLicensesPayload, LicensingStoreAction } from "@Module/Licensing/Store/Licensing.store";
import NotificationsInboxStore, { NotificationsInboxStoreAction } from "./NotificationsInbox.store";
import { NotificationMap, NotificationsOutboxStoreState } from "./NotificationsOutbox.state";
import { OrgAccessRequestNotification } from "../Models/OrgAccessRequestNotification.model";
import { LicenseSharingNotification } from "../Models/LicenseSharingNotification.model";
import { OrgInvitationNotification } from "../Models/OrgInvitationNotification.model";
import { LicenseReturnNotification } from "../Models/LicenseReturnNotification.model";
import { Notification, NotificationType } from "../Models/Notification.model";
import { Action, Getter, Module, Mutation, Vue, VuexModule } from "types-vue";
import { ContractPurchase } from "@Module/Auth/Models/ContractPurchase.model";
import OrgsStore, { OrgsStoreGetter } from "@Module/Orgs/Store/Orgs.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { NotificationFactory } from "../Models/Notification.factory";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { License } from "@Module/Licensing/Models/License.model";
import NotificationsApi from "../API/Notifications.api";
import { ServiceID } from "@Service/ServiceID";
import { Utils } from "@Core/Utils/Utils";
import { ActionContext } from "vuex";

declare type NotificationsOutboxStoreContext = ActionContext<NotificationsOutboxStoreState, any>;

const enum NotificationsOutboxStoreCommit {
  SetOrgOutboxNotifications = "_setOrgOutboxNotifications",
  PutOrgOutboxNotifications = "_putOrgOutboxNotifications",
  SetMyOutboxNotifications = "_setMyOutboxNotifications",
  PutMyOutboxNotifications = "_putMyOutboxNotifications",
  DeleteNotification = "_deleteNotification",
  ClearOrgOutboxNotifications = "_clearOrgOutboxNotifications",
  ClearMyOutboxNotifications = "_clearMyOutboxNotifications"
}

const enum NotificationsOutboxStoreAction {
  FetchMyOutbox = "fetchMyOutbox",
  FetchOrgOutbox = "fetchOrgOutbox",
  FetchPendingLicenseExchanges = "fetchPendingLicenseExchanges"
}

export type SendOrgInvitationPayload = {
  email: string;
  role: Role;
}

export type SendLicenseExchangePayload = {
  owner: Organization;
  tenant: Organization;
  service: ServiceID;
  toShare: License[];
  toReturn: License[];
  returnImmediately: boolean;
  voluntaryReturn: boolean;
}

type SetNotificationsPayload = {
  type: NotificationType;
  notifications: Notification[];
}

@Module({ namespaced: true })
export default class NotificationsOutboxStore extends VuexModule<NotificationsOutboxStoreState> implements NotificationsOutboxStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("NotificationsOutbox");

  public _orgOutbox: NotificationMap = {} as NotificationMap;
  public _myOutbox: NotificationMap = {} as NotificationMap;

  // SENT FROM ORG

  @Getter()
  public orgInvitationsOutbox(): OrgInvitationNotification[] {
    return this._orgOutbox[NotificationType.OrgInvitation] as OrgInvitationNotification[] || [];
  }

  @Getter()
  public orgLicenseSharingOutbox(): LicenseSharingNotification[] {
    return this._orgOutbox[NotificationType.LicenseSharing] as LicenseSharingNotification[] || [];
  }

  @Getter()
  public orgLicenseReturnOutbox(): LicenseReturnNotification[] {
    return this._orgOutbox[NotificationType.LicenseReturn] as LicenseReturnNotification[] || [];
  }

  @Getter()
  public orgOutbox(): Notification[] {
    return Object.values(this._orgOutbox).flat();
  }

  // SENT BY ME

  @Getter()
  public myInvitationsOutbox(): OrgInvitationNotification[] {
    return this._myOutbox[NotificationType.OrgInvitation] as OrgInvitationNotification[] || [];
  }

  @Getter()
  public myOrgRequestsOutbox(): OrgAccessRequestNotification[] {
    return this._myOutbox[NotificationType.OrgAccessRequest] as OrgAccessRequestNotification[] || [];
  }

  @Getter()
  public myLicenseSharingOutbox(): LicenseSharingNotification[] {
    return this._myOutbox[NotificationType.LicenseSharing] as LicenseSharingNotification[] || [];
  }

  @Getter()
  public myLicenseReturnOutbox(): LicenseReturnNotification[] {
    return this._myOutbox[NotificationType.LicenseReturn] as LicenseReturnNotification[] || [];
  }

  @Getter()
  public notificationsSentByMe(): Notification[] {
    return Object.values(this._myOutbox).flat();
  }

  // MUTATIONS
  @Mutation()
  protected _setOrgOutboxNotifications(payload: SetNotificationsPayload) {
    payload.notifications.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._orgOutbox, payload.type, payload.notifications);
  }

  @Mutation()
  protected _putOrgOutboxNotifications(payload: SetNotificationsPayload) {
    const notifications = this._orgOutbox[payload.type] || [];
    for (const newNotif of payload.notifications) {
      const existingNotif = notifications.find(notif => notif.id === newNotif.id)
      if (!!existingNotif) {
        Object.assign(existingNotif, newNotif);
      } else {
        notifications.push(newNotif);
      }
    }
    notifications.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._orgOutbox, payload.type, notifications);
  }

  @Mutation()
  protected _clearOrgOutboxNotifications() {
    this._orgOutbox = {} as NotificationMap;
  }

  @Mutation()
  protected _clearMyOutboxNotifications() {
    this._myOutbox = {} as NotificationMap;
  }

  @Mutation()
  protected _deleteNotification(notification: Notification) {
    let notifIndex = (this._orgOutbox[notification.type] || []).findIndex(n => n.id === notification.id);
    if (notifIndex !== -1) {
      this._orgOutbox[notification.type].splice(notifIndex, 1);
    }
    notifIndex = (this._myOutbox[notification.type] || []).findIndex(n => n.id === notification.id);
    if (notifIndex !== -1) {
      this._myOutbox[notification.type].splice(notifIndex, 1);
    }
  }

  @Mutation()
  protected _setMyOutboxNotifications(payload: SetNotificationsPayload) {
    payload.notifications.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._myOutbox, payload.type, payload.notifications);
  }

  @Mutation()
  protected _putMyOutboxNotifications(payload: SetNotificationsPayload) {
    const notifications = this._myOutbox[payload.type] || [];
    for (const newNotif of payload.notifications) {
      const existingNotif = notifications.find(notif => notif.id === newNotif.id)
      if (!!existingNotif) {
        Object.assign(existingNotif, newNotif);
      } else {
        notifications.push(newNotif);
      }
    }
    notifications.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._myOutbox, payload.type, notifications);
  }

  // ACTIONS

  @Action({ useContext: true })
  public async fetchPendingLicenseExchanges(ctx: NotificationsOutboxStoreContext): Promise<void> {
    const notifTypes = [ NotificationType.LicenseSharing, NotificationType.LicenseReturn ];
    await Promise.all(notifTypes.map(type => ctx.dispatch(NotificationsOutboxStoreAction.FetchOrgOutbox, type)));  
  }

  @Action({ commit: NotificationsOutboxStoreCommit.SetOrgOutboxNotifications })
  public async fetchOrgOutbox(type: NotificationType): Promise<SetNotificationsPayload> {
    const dtos = await NotificationsApi.getOrgOutboxNotifications(type);
    const notifications = dtos.map(NotificationFactory.fromDto);
    return { type, notifications };
  }

  @Action({ commit: NotificationsOutboxStoreCommit.SetMyOutboxNotifications })
  public async fetchMyOutbox(type: NotificationType): Promise<SetNotificationsPayload> {
    const dtos = await NotificationsApi.getMyOutboxNotifications(type);
    const notifications = dtos.map(NotificationFactory.fromDto);
    return { type, notifications };
  }

  @Action({ commit: NotificationsOutboxStoreCommit.DeleteNotification })
  public async cancelNotification(notification: Notification): Promise<Notification> {
    const dto = await NotificationsApi.cancelNotification(notification);
    return NotificationFactory.fromDto(dto);
  }

  @Action({ useContext: true })
  public async sendOrgAccessRequest(ctx: NotificationsOutboxStoreContext, organization: Organization): Promise<Notification> {
    const dto = await NotificationsApi.sendOrgAccessRequest(organization);
    const notification = NotificationFactory.fromDto(dto);
    const commitPayload: SetNotificationsPayload = { type: NotificationType.OrgAccessRequest, notifications: [notification] };
    ctx.commit(NotificationsOutboxStoreCommit.PutMyOutboxNotifications, commitPayload);
    ctx.commit(NotificationsOutboxStoreCommit.PutOrgOutboxNotifications, commitPayload);
    return notification;
  }

  @Action({ useContext: true })
  public async sendOrgInvitation(ctx: NotificationsOutboxStoreContext, payload: SendOrgInvitationPayload): Promise<Notification> {
    const currentOrg: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    const dto = await NotificationsApi.sendOrgInvitation(payload.email, currentOrg, payload.role);
    const notification = NotificationFactory.fromDto(dto);
    const commitPayload: SetNotificationsPayload = { type: NotificationType.OrgInvitation, notifications: [notification] };
    ctx.commit(NotificationsOutboxStoreCommit.PutMyOutboxNotifications, commitPayload);
    ctx.commit(NotificationsOutboxStoreCommit.PutOrgOutboxNotifications, commitPayload);
    return notification;
  }

  @Action({ useContext: true })
  public async sendContractPurchase(ctx: NotificationsOutboxStoreContext, contractPurchase: ContractPurchase): Promise<Notification> {
    const dto = await NotificationsApi.sendContractPurchase(contractPurchase);
    const notification = NotificationFactory.fromDto(dto);
    const addNotifToInbox = NotificationsInboxStore.Mapping.rootAction(NotificationsInboxStoreAction.AddNotification);
    await addNotifToInbox(ctx, [notification]);
    return notification;
  }

  @Action({ useContext: true })
  public async sendLicenseExchange(ctx: NotificationsOutboxStoreContext, payload: SendLicenseExchangePayload): Promise<void> {
    await NotificationsApi.sendLicenseExchange(payload);
    const fetchLicensesPayload: FetchLicensesPayload = { service: payload.service };

    const fetchLicenses = payload.returnImmediately 
      ? LicensingStore.Mapping.rootAction(LicensingStoreAction.FetchAllLicenses)(ctx, fetchLicensesPayload) 
      : Promise.resolve();
    const fetchPendingLicenseExchanges = ctx.dispatch(NotificationsOutboxStoreAction.FetchPendingLicenseExchanges);
    await Promise.all([ fetchPendingLicenseExchanges, fetchLicenses ]);
  }

  @Action({ useContext: true })
  public async clearNotificationsStore(ctx: NotificationsOutboxStoreContext): Promise<void> {
    ctx.commit(NotificationsOutboxStoreCommit.ClearMyOutboxNotifications);
    ctx.commit(NotificationsOutboxStoreCommit.ClearOrgOutboxNotifications);
  }

}