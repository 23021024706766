import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class ConfigItem extends Vue {

  @Prop({ type: String, default: null })
  protected title: string;

  @Prop({ type: String, default: null })
  protected description: string;

  @Prop({ type: [String, Number], default: "2px" })
  protected spacing: string | number;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: "horizontal" })
  protected direction: "horizontal" | "vertical";

  protected get configItemClass() {
    return {
      "is-horizontal": this.direction === "horizontal",
      "is-vertical": this.direction === "vertical",
      "is-disabled": this.disabled
    }
  }

}