import { INotificationDto, NotificationType } from "./Notification.model";
import { OrgAccessRequestNotification } from "./OrgAccessRequestNotification.model";
import { LicenseSharingNotification } from "./LicenseSharingNotification.model";
import { OrgInvitationNotification } from "./OrgInvitationNotification.model";
import { LicenseReturnNotification } from "./LicenseReturnNotification.model";
import { ContractPurchaseNotification } from "./ContractPurchaseNotification.model";

export class NotificationFactory {

  public static fromDto(dto: INotificationDto<any>) {
    switch (dto.type as NotificationType) {
      case NotificationType.LicenseSharing: return LicenseSharingNotification.fromDto(dto);
      case NotificationType.LicenseReturn: return LicenseReturnNotification.fromDto(dto);
      case NotificationType.OrgAccessRequest: return OrgAccessRequestNotification.fromDto(dto);
      case NotificationType.OrgInvitation: return OrgInvitationNotification.fromDto(dto);
      case NotificationType.ContractPurchase: return ContractPurchaseNotification.fromDto(dto);
      default: throw new Error("Unknown notification type.");
    }
  }
}