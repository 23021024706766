import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

const enum Status {
  Expanded = "expanded",
  Collapsed = "collapsed"
}

@Component
export default class CollapsableCard extends Vue {

  @Prop({ type: String })
  protected title: string;

  @Prop({ type: Boolean })
  protected plain: Boolean;
  
  @Prop({ type: Boolean })
  protected bordered: boolean;

  @Prop({ type: Boolean, default: false })
  protected separated: boolean;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: true })
  protected defaultCollapsed: boolean;

  protected expanded: Status = Status.Expanded;

  protected created() {
    if (this.defaultCollapsed) {
      this.expanded = Status.Collapsed;
    }
  }

  protected get isExpanded(): boolean {
    return this.expanded === Status.Expanded;
  }

  protected get classBinding() {
    return {
      "is-expanded": this.isExpanded,
      "is-loading": this.loading,
      "is-separated": this.separated
    }
  }

  protected get slotScope() {
    return {
      expanded: this.isExpanded,
      disabled: this.disabled,
      loading: this.loading
    }
  }

  protected onCollapseStatusChange(status: Status) {
    this.$emit("expand", status === Status.Expanded);
  }

}