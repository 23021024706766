import DayJS, { Dayjs } from "dayjs";
import { IResourceDto } from "./Resource.model";
import { ServiceID } from "@Service/ServiceID";
import { IPlatformDto, Platform } from "./Platform.model";

export interface ICreateProductVersionDto {
  product: ServiceID,
  version: string,
  description: string,
}

export interface IProductVersionDto {
  id: number;
  version: string;
  description: string;
  platforms:  IPlatformDto[]
}

export class ProductVersion {
   id: number;
   version: string;
   description: string;
   platforms: IPlatformDto[]
 ;

  private constructor(dto: IProductVersionDto) {
    this.id = dto.id;
    this.version = dto?.version;
    this.description = dto.description;
    this.platforms = dto.platforms;
  }

  public static create(dto?: Partial<IProductVersionDto>): ProductVersion {
    return new ProductVersion({
      id: dto?.id,
      version: dto?.version,  
      description: dto?.description,
      platforms: dto?.platforms
    });
  }

  public static fromDto(dto: IProductVersionDto): ProductVersion {
    return new ProductVersion(dto);
  }

  public toDto(): IProductVersionDto {
    return  {
      id: this.id,
      version: this.version,
      description: this.description,
      platforms: this.platforms
    };
  }
}