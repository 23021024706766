import NotificationsOutboxStore, { SendLicenseExchangePayload } from "@Module/Notifications/Store/NotificationsOutbox.store";
import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import LicenseTransfer, { ExchangeMode, LicenseTransferChangeEvent } from "../LicenseTransfer/LicenseTransfer";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { License } from "@Module/Licensing/Models/License.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { ServiceID } from "@Service/ServiceID";
import { Notify } from "@Core/Utils/Notify";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

const enum Tabs {
  Exchange = "transfer",
  Outbox = "outbox",
  Inbox = "inbox"
}

@Component
export default class LicenseTransferDialog extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Array, required: true })
  protected licenses: License[];

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgHasChildren: boolean;

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseSharingOutbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseReturnOutbox: LicenseReturnNotification[];

  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseSharingInbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseReturnInbox: LicenseReturnNotification[];

  @MapAction(NotificationsOutboxStore.Mapping)
  protected fetchPendingLicenseExchanges: () => Promise<void>;
  
  @MapAction(NotificationsOutboxStore.Mapping)
  protected sendLicenseExchange: (payload: SendLicenseExchangePayload) => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<void>;

  protected exchangeMode: ExchangeMode = null;

  protected exchange: LicenseTransferChangeEvent = null;
  protected currentTab: Tabs = Tabs.Exchange;

  protected get selectedExchangeMode() {
    return this.exchangeMode 
      ?? (this.licensesSharedWithMe.length > 0 
          ? ExchangeMode.SharedWithMe 
          : ExchangeMode.SharedByMe);
  }

  protected sendLicenseExchangeNotifications = Execution.create(
    async (paylaod: SendLicenseExchangePayload) => this.sendLicenseExchange(paylaod)
  );

  protected getPendingExchanges = Execution.create(() => {
    return Promise.all([
      this.fetchInbox(),
      this.fetchPendingLicenseExchanges()
    ]);
  });

  public open(): Promise<boolean> {
    this.getPendingExchanges.run();
    return (this.$refs.dialog as Dialog).open();
  }

  public backToExchangeModeSelection() {
    this.exchangeMode = null
    this.exchange = null
  }

  protected get serviceName(): string {
    return ServiceID.nameOf(this.serviceId);
  } 

  @Watch("currentTab", { immediate: true })
  protected async onTabChange(tab: Tabs) {
    if (tab === Tabs.Exchange) {
      await this.$nextTick();
      (this.$refs.transfer as LicenseTransfer)?.focusIfNoOwner();
    }
  }

  protected async selectExchangeMode(mode: ExchangeMode) {
    this.exchangeMode = mode;
    await Utils.sleep(350);
    this.$nextTick();
    (this.$refs.transfer as LicenseTransfer)?.focusIfNoOwner();
  }

  protected get pendingExchangeOutbox(): LicenseSharingNotification[] | LicenseReturnNotification[] {
    return [...this.orgLicenseSharingOutbox, ...this.orgLicenseReturnOutbox]
      .filter(exchange => exchange.service === this.serviceId);
  }

  protected get pendingExchangeInbox(): LicenseSharingNotification[] | LicenseReturnNotification[] {
    return [...this.licenseSharingInbox, ...this.licenseReturnInbox]
      .filter(exchange => exchange.service === this.serviceId);
  }

  protected get licensesSharedWithMe(): License[] {
    return this.licenses.filter(license => license.isSharedToOrg(this.currentOrg));
  }

  protected get licensesSharedByMe(): License[] {
    return this.licenses.filter(license => license.isShared && !license.isSharedToOrg(this.currentOrg));
  }

  protected get outboxFirstLoad(): boolean {
    return this.getPendingExchanges.loading && this.pendingExchangeOutbox.length === 0;
  }

  protected get inboxFirstLoad(): boolean {
    return this.getPendingExchanges.loading && this.pendingExchangeInbox.length === 0;
  }

  protected get hasUncommittedChanges(): boolean {
    return this.exchange?.didChange ?? false;
  }

  protected get isInLicenseExchangeTab(): boolean {
    return this.currentTab === Tabs.Exchange;
  }

  protected get isInOutboxTab(): boolean {
    return this.currentTab === Tabs.Outbox;
  }

  protected get isInInboxTab(): boolean {
    return this.currentTab === Tabs.Inbox;
  }

  protected get canChooseExchangeMode(): boolean {
    return this.licensesSharedWithMe.length > 0 && this.currentOrgHasChildren;
  }

  protected exchangeModeLabels(mode: ExchangeMode): { title: string, description: string } {
    switch (mode) {
      case ExchangeMode.SharedWithMe: return { 
        title: "Manage shares with my organization", 
        description: "Return licenses shared by parent organizations." 
      }
      case ExchangeMode.SharedByMe: return { 
        title: "Manage my license shares", 
        description: "Claim ownership or share licenses with child organizations." 
      }
    }
  }

  public reset() {
    this.currentTab = Tabs.Exchange;
    this.getPendingExchanges.reset();
    this.sendLicenseExchangeNotifications.reset();
    this.exchangeMode = null;
  }

  public focus() {
    (this.$refs.transfer as LicenseTransfer)?.focusIfNoOwner();
  }

  protected discardUncommittedChanges() {
    (this.$refs.transfer as LicenseTransfer)?.reset();
  }

  protected async confirmExchange() {
    try {
      const hasReturnWithHosts = this.exchange.toReturn.length > 0 && this.exchange.toReturn.some(license => license.hostCount > 0);
      const message = "<p>Are you sure you want to perform the selected license exchange?</p>" 
        + (hasReturnWithHosts ? "<p class='text-semibold'>Returning licenses will cause all license hosts to be automatically unregistered.</p>" : "");
      await this.$confirm(message,
        "Exchange confirmation", {
          type: "warning",
          cancelButtonText: "Cancel",
          confirmButtonText: "Exchange licenses",
          dangerouslyUseHTMLString: true
        }
      );
      await this.executeExchange();
    } catch {}
  }

  protected async executeExchange() {
    await this.sendLicenseExchangeNotifications.run(this.exchange);
    if (!this.sendLicenseExchangeNotifications.error) {
      Notify.Success({
        title: "License exchange completed",
        message: "Your license exchange has been successfully completed.",
        duration: 6000
      });
      if (this.exchange.returnImmediately) {
        await this.$nextTick();
        (this.$refs.transfer as LicenseTransfer)?.initialize();
      }
    }
  }
  
  protected get dialogWidth(): string {
    if (!this.exchangeMode && this.canChooseExchangeMode) {
      return "45rem"
    } else {
      return "70rem";
    }
  }
}