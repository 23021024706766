export type UIMode = "plain" | "cards";
export type UIContentSizeMode = "compact" | "airy";
export type UISize = "large" | "medium" | "small" | "mini";
export type UIPlacement = "top" | "top-start" | "top-end" | "bottom" | "bottom-start" | "bottom-end" | "left" | "left-start" | "left-end" | "right" | "right-start" | "right-end";

export const AppSettingsDefaults: AppSettingsStoreState = {
  _collapsedMenu: false,
  _hiddenBreadcrumb: false,
  _expandSubmenus: true,
  _uiSize: "large",
  _uiMode: "cards",
  _uiContentSizeMode: "airy",
  _hiddenLoadingIndicator: false,
}

export interface AppSettingsStoreState {
  _collapsedMenu: boolean;
  _hiddenBreadcrumb: boolean;
  _hiddenLoadingIndicator: boolean;
  _expandSubmenus: boolean;
  _uiSize: UISize;
  _uiMode: UIMode;
  _uiContentSizeMode: UIContentSizeMode;
}