import { IOrganizationRoleDto, OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { ServiceID } from "@Service/ServiceID";
import DayJS, { Dayjs } from "dayjs";
import Clone from "lodash.clonedeep";
import IsEqual from "lodash.isequal";

export interface IUserDto {
  username: string;
  cognito_id: string;
  agree_comm: boolean;
  creation_date: string;
  update_date: string;
  name: string;
  surname: string;
  org_roles: IOrganizationRoleDto[];
  preview_access: string[];
  universal_org_access: boolean;
}

export class User {
  username: string;
  cognitoId: string;
  firstName: string;
  lastName: string;
  creation: Dayjs;
  modification: Dayjs;
  agreesCommunications: boolean = false;
  previewsAccess: ServiceID[] = [];
  universalOrgAccess: boolean = false;
  mainOrgRole?: OrganizationRole;
  allowedOrgRoles: OrganizationRole[];

  readonly remote: IUserDto;

  private constructor(dto?: IUserDto) {
    this.username = dto?.username;
    this.cognitoId = dto?.cognito_id;
    this.firstName = dto?.name;
    this.lastName = dto?.surname;
    this.creation = !!dto ? DayJS(dto.creation_date) : DayJS();
    this.modification = !!dto ? DayJS(dto.update_date) : DayJS();
    this.agreesCommunications = dto?.agree_comm ?? false;
    this.previewsAccess = (dto?.preview_access ?? []) as ServiceID[];
    this.universalOrgAccess = dto?.universal_org_access ?? false;
    
    const userOrgRoles = dto?.org_roles || [];

    const mainOrgRoleDto = userOrgRoles.find(dto => dto.is_main);
    this.mainOrgRole = !!mainOrgRoleDto ? OrganizationRole.fromDto(mainOrgRoleDto) : undefined; 

    const allowedOrgRoleDtos = userOrgRoles.filter(dto => !dto.is_main) || [];
    this.allowedOrgRoles = allowedOrgRoleDtos.map(OrganizationRole.fromDto);
    this.allowedOrgRoles.sort((a, b) => a.organization.name.localeCompare(b.organization.name));

    this.remote = dto;
    if (!!this.remote) {
      this.remote.preview_access = this.previewsAccess;
      this.remote.creation_date = this.creation.toISOString();
      this.remote.update_date = this.modification.toISOString();
      this.remote.org_roles = this.remote.org_roles.length > 0 ? this.remote.org_roles : undefined;
    }
  }

  public static fromDto(dto: IUserDto): User {
    return new User(dto);
  }

  public static create(): User {
    return new User();
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  public get hasPreviews(): boolean {
    return this.previewsAccess.length > 0;
  }

  public get isRemote(): boolean {
    return !!this.remote;
  }

  public get allOrgRoles(): OrganizationRole[] {
    return !!this.mainOrgRole && this.mainOrgRole.isFulfilled 
      ? [this.mainOrgRole, ...this.allowedOrgRoles] 
      : this.allowedOrgRoles;
  }

  public getOrgRoleForOrg(org: Organization): OrganizationRole | null {
    return this.allOrgRoles.find(orgRole => orgRole.organization.id === org.id) || null;
  }

  public getRemoteOrgRoleForOrg(org: Organization): OrganizationRole | null {
    const orgRoleDto = this.remote.org_roles.find(orgRole => orgRole.org.id === org.id);
    return !!orgRoleDto ? OrganizationRole.fromDto(orgRoleDto) : null;
  }

  public discardUncommittedChanges() {
    Object.assign(this, new User(this.remote));
  }

  public get hasUncommittedChanges(): boolean {
    if (!this.isRemote) { return true; }
    const dto: IUserDto = JSON.parse(JSON.stringify(this.toDto()));
    const remote: IUserDto = JSON.parse(JSON.stringify(this.remote));
    dto.org_roles?.sort((a, b) => a.org.id - b.org.id);
    remote.org_roles?.sort((a, b) => a.org.id - b.org.id);
    return !IsEqual(dto, remote);
  }

  public meetsFilter(search: string, orgs?: Organization[]): boolean {
    let hasAccessToOrgs: boolean = true;
    if (!!orgs && orgs.length > 0 && !this.universalOrgAccess) {
      hasAccessToOrgs = this.allOrgRoles.some(orgRole => orgs.some(org => org.id === orgRole.organization.id))
    }
    const text = search?.toLowerCase() || "";
    const textMatch = this.username.toLowerCase().includes(text) || this.fullName.toLowerCase().includes(text);
    return hasAccessToOrgs && textMatch;
  }

  public clone(): User {
    return Clone(this);
  }

  public toDto(): IUserDto {
    const orgRoles = !this.universalOrgAccess 
      ? this.allOrgRoles.map(orgRole => orgRole.toDto()) 
      : !!this.mainOrgRole ? [this.mainOrgRole.toDto()] : [];
    return {
      username: this.username,
      cognito_id: this.cognitoId,
      agree_comm: this.agreesCommunications,
      name: this.firstName,
      surname: this.lastName,
      preview_access: this.previewsAccess,
      universal_org_access: this.universalOrgAccess,
      creation_date: this.creation.toISOString(),
      update_date: this.modification.toISOString(),
      org_roles: orgRoles.length > 0 ? orgRoles : undefined
    };
  }

}