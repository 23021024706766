import { OrgInvitationNotification } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import { Notification, NotificationType } from "@Module/Notifications/Models/Notification.model";
import { NotificationFactory } from "@Module/Notifications/Models/Notification.factory";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, MapAction, MapGetter } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import Vue from "vue";

interface DialogFilters {
  search: string;
  roles: Role[];
}

@Component
export default class OrgInvitationsOutboxDialog extends Vue {

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(AuthStore.Mapping)
  protected roles: Role[];

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgInvitationsOutbox: OrgInvitationNotification[];

  @MapAction(NotificationsOutboxStore.Mapping)
  protected fetchOrgOutbox: (type: NotificationType) => Promise<Notification[]>;

  protected getOrgInvitationsOutbox = Execution.create(
    () => this.fetchOrgOutbox(NotificationType.OrgInvitation)
  );

  protected filters: DialogFilters = {
    search: null,
    roles: []
  }

  protected get firstLoad(): boolean {
    return this.orgInvitationsOutbox.length === 0 
        && this.getOrgInvitationsOutbox.loading;
  }

  protected get isFiltering(): boolean {
    return !!this.filters.search 
      || (!!this.filters.roles && this.filters.roles.length > 0);
  }

  protected get hasNotifications(): boolean {
    return this.orgInvitationsOutbox.length > 0;
  }

  protected async open(): Promise<boolean> {
    this.clearFilters();
    this.getOrgInvitationsOutbox.run();
    return (this.$refs.dialog as Dialog).open();
  }

  protected clearFilters() {
    this.filters = { search: null, roles: [] }
  }

  protected get filteredOrgInvitationsOutbox(): OrgInvitationNotification[] {
    return this.orgInvitationsOutbox
      .filter(notification => notification.meetFilters(this.filters.search, this.filters.roles));
  }

  protected onDialogClosed() {
    this.orgInvitationsOutbox.forEach(notification => {
      if (!notification.hasUncommittedChanges) return;
      else this.discardNotificationChanges(notification);
    })
  }

  protected discardNotificationChanges(notification: Notification) {
    Object.assign(notification, NotificationFactory.fromDto(notification.remote));
  }

}