import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class Title extends Vue {

  @Prop({ type: String, default: null })
  protected title: string;

  @Prop({ type: String, default: null })
  protected back: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected allowNavigation: boolean;

  @Prop({ type: Boolean, default: false })
  protected autoBack: boolean;

  @Prop({ type: String, default: "inherit" })
  protected iconSize: string;

  @Prop({ type: String, default: "4px" })
  protected iconSpacing: string;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  protected get titleClass() {
    return {
      "is-disabled": this.disabled
    }
  }

  protected onBack() {
    if (this.disabled) { return; }
    this.$emit("back"); 
  }

}