import { OrgAccessRequestNotification } from "@Module/Notifications/Models/OrgAccessRequestNotification.model";
import { OrgInvitationNotification } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class OrgSuggestionList extends Vue {

  @Prop({ type: Array, default: () => [] })
  protected suggestions: Organization[];

  @Prop({ type: Boolean, default: false })
  protected loading: Boolean;

  @Prop({ type: Array, default: () => [] })
  protected requestNotifications: OrgAccessRequestNotification[];

  @Prop({ type: Array, default: () => [] })
  protected invitationNotifications: OrgInvitationNotification[];

  protected searchQuery: string = null;

  protected getOrgAccessNotification(org: Organization) {
    return this.requestNotifications.find(n => n.organization.id === org.id)
  }

  protected getOrgInvitationNotification(org: Organization) {
    return this.invitationNotifications.find(n => n.organization.id === org.id)
  }

  protected get filteredSuggestions(): Organization[] {
    return this.suggestions.filter(org => org.meetsSearch(this.searchQuery));
  }
  
  
}