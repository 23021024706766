import { BackupAwsCloudProvider } from "@Service/Emory/Models/Backups/CloudProvider/BackupAwsCloudProvider.model";
import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class BackupAwsSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected aws: BackupAwsCloudProvider;

  protected get bucketPath(): string {
    if (this.$mq.md) return Utils.smartTrim(this.aws.path, 15);
    else if (this.$mq.lg) return Utils.smartTrim(this.aws.path, 18);
    else return Utils.smartTrim(this.aws.path, 22);
  }

}