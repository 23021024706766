import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";

export interface ISignUpDto {
  email: string;
  password: string;
  username: string;
  surname: string;
  orgname?: string;
  agreeCommunications: boolean;
  invitationId?: number;
}

export class SignUp {
  public email: string;
  public password: string;
  public name: string;
  public surname: string;
  public agreeCommunications: boolean;
  public agreeDataProtectionPolicy: boolean;
  public invitation: OrgInvitationNotificationLink;

  private constructor() {
    this.email = null;
    this.password = null;
    this.name = null;
    this.surname = null;
    this.agreeCommunications = false;
    this.agreeDataProtectionPolicy = false;
    this.invitation = null;
  }

  protected get fullName(): string {
    return `${this.name} ${this.surname}`.trim();
  }

  public static create(): SignUp {
    return new SignUp();
  }

  public toDto(): ISignUpDto {
    return {
      email: this.email,
      password: this.password,
      username: this.name,
      surname: this.surname,
      agreeCommunications: this.agreeCommunications,
      invitationId: this.invitation?.notificationId
    }
  }

}  