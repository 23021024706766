import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Debounce from "lodash.debounce";
import IsEqual from "lodash.isequal";
import Clone from "lodash.clonedeep";
import Vue from "vue";

type ParamValue = string | string[];

export interface Serializer<T> {
  deserialize(param: ParamValue, defVal?: T): T;
  serialize(value: T): ParamValue;
}

export const DateSerializer: Serializer<Date> = {
  deserialize(param?: string): Date { 
    return isNaN(Number(param)) ? null : new Date(Number(param)); 
  },
  serialize(value?: Date): string | undefined { 
    return value?.getTime().toString() || undefined; 
  }
}

export const StringSerializer: Serializer<string> = {
  deserialize(param: ParamValue, defVal: string = null): string { 
    return !!param ? Utils.isArray(param) ? param.join(", ") : param : defVal; 
  },
  serialize(value: any): ParamValue { 
    return !!value ? String(value) : undefined; 
  }
}

export const NumberSerializer: Serializer<number> = {
  deserialize(param: ParamValue, defVal: number = 0): number { 
    return isNaN(Number(param)) ? defVal : Number(param); 
  },
  serialize(value: number): ParamValue { 
    return !!value ? String(value) : undefined; 
  }
}

export const StringArraySerializer: Serializer<string[]> = {
  deserialize(param: ParamValue): string[] { 
    return !!param ? Utils.isArray(param) ? param : [param] : []; 
  },
  serialize(value: string[]): ParamValue { 
    return value || undefined; 
  }
}

export const NumberArraySerializer: Serializer<number[]> = {
  deserialize(param: ParamValue): number[] { 
    return !!param ? Utils.isArray(param) ? param.map(Number) : [Number(param)] : []; 
  },
  serialize(value: number[]): ParamValue { 
    return value.map(String) || undefined; 
  }
}

export const BooleanSerializer: Serializer<boolean> = {
  deserialize(param: ParamValue): boolean { 
    return param === "true" 
  },
  serialize(value: boolean): ParamValue { 
    return value === true ? "true" : undefined; 
  }
}

@Component
export default class QueryParam extends Vue {

  @Prop({ type: String, required: true })
  protected name: string;

  @Prop({ default: null })
  protected default: any;

  @Prop({ required: true, default: null })
  protected value: any;

  @Prop({ type: Number, default: 0 })
  protected delay: number;

  @Prop({ type: String, default: "string" })
  protected type: "string" | "number-array" | "string-array" | "boolean" | "number" | "date";

  @Prop({ type: Object, default: null })
  protected serializer: Serializer<unknown>;

  protected init: boolean = false;

  protected created() {
    this.$watch("value", this.debouncedSerialize, { deep: true, immediate: true });
    this.$watch(`$route.query.${this.name}`, this.deserialize, { immediate: true });
  }

  protected get typeSerializer(): Serializer<unknown> | undefined {
    switch (this.type) {
      case "number-array": return NumberArraySerializer;
      case "string-array": return StringArraySerializer;
      case "boolean": return BooleanSerializer;
      case "number": return NumberSerializer;
      case "date": return DateSerializer;
      default: return StringSerializer;
    }
  }

  private deserialize(paramVal: string | string[] = this.$route.query[this.name], oldParamVal: string | string[]) {
    if (IsEqual(paramVal, oldParamVal)) { return; }
    const serializer = !!this.serializer ? this.serializer : this.typeSerializer;
    let newValue = serializer.deserialize(paramVal, this.default);
    
    if (!this.init) {
      newValue = newValue ?? this.value;
      this.serialize(newValue);
    } 
    
    if (!IsEqual(newValue, this.value)) {
      this.$emit("input", newValue);
      this.$emit("update:value", newValue);
    }
    
    this.init = true;
  }

  private get debouncedSerialize(): (value?: any) => void {
    return Debounce(this.serialize, this.delay);
  }

  private async serialize(value: any = this.value) {
    const query = Clone(this.$route.query);
    const serializer = !!this.serializer ? this.serializer : this.typeSerializer;
    query[this.name] = serializer.serialize(value);
    this.$router.replace({ query }).catch(e => e);
  }

}