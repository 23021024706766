import { Contract } from "@Module/Licensing/Models/Contract.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { Component, MapGetter, Prop } from "types-vue";
import AuthStore from "@Module/Auth/Store/Auth.store";
import Vue from "vue";

@Component
export default class ContractSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected contract: Contract;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  protected get permissions() {
    return {
      CanEditContractNotifications: this.accessRole
        .can(this.contract.serviceId, Permission.EditContractNotifications)
    }
  }
  
}