import { BooleanCookie } from "@Core/Models/Cookies/BooleanCookie.model";
import { TrialInfo } from "@Module/Trial/Models/TrialInfo.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";
import { Watch } from "vue-property-decorator";

@Component
export default class TrialCounter extends Vue {

  @Prop({ type: Object, required: true })
  protected trial: TrialInfo;

  @Prop({ type: Number, required: true })
  protected duration: number;

  @Prop({ type: String, default: "Trial status" })
  protected title: string;

  @Prop({ type: Boolean, default: null })
  protected plain: Boolean;

  @Prop({ type: Boolean, default: true })
  protected compactable: boolean;

  protected compactCookie = BooleanCookie.create("console.comptact-trial");

  @Watch("compactable", { immediate: true })
  protected onCompactablePropChange(compactable: boolean) {
    if (!compactable) {
      this.compactCookie.store(false);
    }
  }

  protected get alertStyle(): string {
    if (this.percentageUsed === 100) { return "error" }
    else if (this.trial.remainingDays <= 5) { return "warning"; }
    else { return "info" };
  }

  protected get progressStyle(): string {
    if (this.percentageUsed === 100) { return "exception" }
    else if (this.trial.remainingDays <= 5) { return "warning"; }
    else { return null; };
  }

  protected get percentageUsed(): number {
    const durSeconds = this.duration * 24 * 60 * 60;
    const used = durSeconds - this.trial.remainingSeconds;
    const rawPercentage = (100 * used) / durSeconds;
    return Math.min(Math.abs(rawPercentage), 100);
  }

  protected get description(): string {
    if (this.percentageUsed === 100) {
      return `Expired ${this.trial.expiration.startOf("second").toNow()}`;
    } else {
      return `Expires ${this.trial.expiration.startOf("second").fromNow()}`;
    }
  }

  protected get trialCounterClass() {
    return {
      "is-compact": this.compactCookie.value
    }
  }

}