import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import { SignUp } from "@Module/Auth/Models/SignUp/SignUp.model";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";
import LegalStore from "@Module/Legal/Store/Legal.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { ElForm } from "element-ui/types/form";
import Vue from "vue";

@Component
export default class SignUpPanel extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected skippable: boolean;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgInvitationLink: OrgInvitationNotificationLink;

  @MapAction(LegalStore.Mapping)
  protected openDataProtectionDialog: () => void;

  @MapAction(AuthStore.Mapping)
  protected signUp: (model: SignUp) => Promise<void>;

  protected signingUp: boolean = false;
  protected signUpError: Error = null;
  protected signUpModel: SignUp = SignUp.create();

  protected get signUpDto() {
    return this.signUpModel.toDto()
  }

  @Watch("orgInvitationLink", { immediate: true })
  protected async onInvitationChange(link: OrgInvitationNotificationLink) {
    this.signUpModel.invitation = link ?? null;
    this.signUpModel.email = link?.email ?? null;
    if (!link) {
      await this.$nextTick();
      (this.$refs.form as ElForm).clearValidate();
    }
  }

  protected get canSignUp(): boolean {
    return !!this.signUpModel.email
      && !!this.signUpModel.name
      && !!this.signUpModel.surname
      && this.signUpModel.agreeDataProtectionPolicy;
  }

  protected get isDisabled(): boolean {
    return this.disabled || this.signingUp;
  }

  protected setSigningUp(signingUp: boolean) {
    this.signingUp = signingUp;
    this.$emit("update:disabled", signingUp);
  }

  protected get formWithErrors(): boolean {
    return (this.$refs.form as any).fields?.some((item: any) => item.validateState === "error") || false;
  }

  protected async onSubmitClick() {
    try {
      await (this.$refs.form as ElForm).validate();
      await this.performSignUp();
    } catch (error) {}
  }

  protected async performSignUp() {
    try {
      this.signUpError = null;
      this.setSigningUp(true);
      await this.signUp(this.signUpModel);
    } catch (error) {
      this.signUpError = error;
    } finally {
      this.setSigningUp(false);
      if (!this.signUpError) {
        this.$emit("success", this.signUpModel);
      }
    }
  }

  protected validationRules = {
    email: [
      { type: "email", message: "Please enter a valid email address.", trigger: ["change", "blur"] },
      { required: true, message: "Please enter your email address.", trigger: ["change", "blur"] }
    ],
    name: [{ required: true, message: "Please enter your name.", trigger: ["change", "blur"] }],
    surname: [{ required: true, message: "Please enter your surname.", trigger: ["change", "blur"] }],
    organization: [{ required: true, message: "Please enter your organization name.", trigger: ["change", "blur"] }],
    agreeDataProtectionPolicy: [{ validator: this.checkboxMustBeChecked, trigger: "change" }]
  }

  protected checkboxMustBeChecked(rule: any, value: any, callback: (error?: Error) => void): void {
    if (value !== true) {
      return callback(new Error("You have to accept our data protection policy."));
    } else {
      return callback();
    }
  }

  public closeGuard(close: VoidFunction) {
    if (this.authStatus === AuthStatus.SigningUp) return;
    else return close();
  }
  
}