import ResourcesStore, { ResourcesStoreAction, SetResourcePermissionsPayload } from "@Module/Resources/Store/Resources.store";
import { Dimension, IDimensionUnits } from "@Module/Licensing/Models/Dimension.model";
import { Contract, IContractDto, IUpdateContractDto } from "../Models/Contract.model";
import { ContractPurchase } from "@Module/Auth/Models/ContractPurchase.model";
import { ServiceID, ServiceMap, ServicePayload } from "@Service/ServiceID";
import OrgsStore, { OrgsStoreGetter } from "@Module/Orgs/Store/Orgs.store";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import { LicenseFactory } from "../Models/License.factory";
import { LicensingStoreState } from "./Licensing.state";
import { License } from "../Models/License.model";
import LicensingApi from "../API/Licensing.api";
import { Utils } from "@Core/Utils/Utils";
import { ActionContext } from "vuex";
import Vue from "vue";

export const enum LicensingStoreGetter {
  AloLicenses = "aloLicenses"
}

const enum LicensingStoreCommit {
  SetContracts = "_setContracts",
  SetLicenses = "_setLicenses",
  SetDimensions = "_setDimensions",
  AddContracts = "_addContracts",
  AddLicenses = "_addLicenses",
  SetContractsMap = "_setContractsMap",
  SetLicensesMap = "_setLicensesMap",
  SetContractPurchase = "_setContractPurchase"
}

export const enum LicensingStoreAction {
  FetchContractById = "fetchContractById",
  FetchAllContracts = "fetchAllContracts",
  FetchAllLicenses = "fetchAllLicenses",
  FetchLicensesByContractId = "fetchLicensesByContractId",
  AddContracts = "addContracts",
  FetchLicenseById = "fetchLicenseById",
  SetContractPurchase = "setContractPurchase"
}

type DimensionsPayload = ServicePayload<Dimension[]>;
type ContractsPayload = ServicePayload<Contract[]>;
type LicensesPayload = ServicePayload<License[]>;

export type FetchByIdPayload = ServicePayload<number>;

export type ContractsDtoPayload = ServicePayload<IContractDto[]>;
export type FetchLicensesPayload = { service: ServiceID };
export type FetchContractByIdPayload = FetchByIdPayload;
export type FetchLicenseByIdPayload = FetchByIdPayload;
export type RegisterEncryptedHostPayload = ServicePayload<number> & { key: string };
export type RegisterUnencryptedHostPayload = ServicePayload<number> & { registration: object };
export type UnregisterHostPayload = ServicePayload<number>;
export type CreateLinkeContractPayload = ServicePayload<{ org: Organization, 
  origin: string, 
  expiration: Date, 
  dimensions: IDimensionUnits[], 
  poc: boolean, 
  extendedContractId: number }>;
export type UpdateContractPayload = ServicePayload<{ id: number, dto: IUpdateContractDto }>;

type StoreContext = ActionContext<LicensingStoreState, any>;

@Module({ namespaced: true })
export default class LicensingStore extends VuexModule<LicensingStoreState> implements LicensingStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Licensing");

  public _contractsMap: ServiceMap<Contract[]> = {};
  public _licensesMap: ServiceMap<License[]> = {};
  public _dimensionsMap: ServiceMap<Dimension[]> = {};

  public _contractPurchase: ContractPurchase = null;

  @Getter()
  public dimensionArrayParamSerializer(): Serializer<Dimension[]> {
    return {
      serialize: (value: Dimension[]): string[] => {
        return value.map(dim => dim.name);
      },
      deserialize: (param: string | string[]): Dimension[] => {
        if (!param) return [];
        const dimNames = Array.isArray(param) ? param : [param];
        return dimNames.map(Dimension.create);
      }
    }
  }

  @Getter()
  public contractPurchase(): ContractPurchase {
    return this._contractPurchase;
  }

  @Getter()
  protected contracts(): ServiceMap<Contract[]> { 
    return this._contractsMap; 
  }

  @Getter()
  protected licenses(): ServiceMap<License[]> { 
    return this._licensesMap; 
  }

  @Getter()
  protected dimensions(): ServiceMap<Dimension[]> { 
    return this._dimensionsMap; 
  }

  @Getter()
  protected emoryContracts(): Contract[] { 
    return this._contractsMap[ServiceID.Emory] || []; 
  }

  @Getter()
  protected connectorContracts(): Contract[] { 
    return this._contractsMap[ServiceID.Connector] || []; 
  }

  @Getter()
  protected abapSuiteContracts(): Contract[] { 
    return this._contractsMap[ServiceID.AbapSuite] || []; 
  }

  @Getter()
  protected emoryLicenses(): License[] { 
    return this._licensesMap[ServiceID.Emory] || []; 
  }

  @Getter()
  protected connectorLicenses(): License[] { 
    return this._licensesMap[ServiceID.Connector] || []; 
  }

  @Getter()
  protected abapSuiteLicenses(): License[] { 
    return this._licensesMap[ServiceID.AbapSuite] || []; 
  }

  @Mutation()
  protected _setContractPurchase(contractPurchase: ContractPurchase) {
    this._contractPurchase =  contractPurchase;
  }

  @Mutation()
  protected _setDimensions(payload: DimensionsPayload) {
    Vue.set(this._dimensionsMap, payload.service, payload.data);
  }

  @Mutation()
  protected _setContracts(payload: ContractsPayload) {
    const contracts = payload.data.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._contractsMap, payload.service, contracts);
  }

  @Mutation()
  protected _setLicenses(payload: LicensesPayload) {
    const licenses = payload.data.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._licensesMap, payload.service, licenses);
  }

  @Mutation()
  protected _addContracts(payload: ContractsPayload) {
    const serviceContracts = this._contractsMap[payload.service] || [];
    for (const newContract of payload.data) {
      const currentContract = serviceContracts.find(c => c.id === newContract.id);
      if (!!currentContract) {
        Object.assign(currentContract, newContract);
      } else {
        serviceContracts.push(newContract);
      }
    }
    serviceContracts.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._contractsMap, payload.service, serviceContracts);
  }

  @Mutation()
  protected _addLicenses(payload: LicensesPayload) {
    const serviceLicenses = this._licensesMap[payload.service] || [];
    for (const newLicense of payload.data) {
      const currentLicense = serviceLicenses.find(l => l.id === newLicense.id);
      if (!!currentLicense) {
        Object.assign(currentLicense, newLicense);
      } else {
        serviceLicenses.push(newLicense);
      }
    }
    serviceLicenses.sort((a, b) => a.expiration.diff(b.expiration));
    Vue.set(this._licensesMap, payload.service, serviceLicenses);
  }

  @Mutation()
  protected _setContractsMap(map: ServiceMap<Contract[]>) {
    this._contractsMap = map;
  }

  @Mutation()
  protected _setLicensesMap(map: ServiceMap<License[]>) {
    this._licensesMap = map;
  }

  @Action({ commit: LicensingStoreCommit.SetContractPurchase })
  public async setContractPurchase(contractPurchase: ContractPurchase | null): Promise<ContractPurchase | null> {
    return contractPurchase;
  }

  @Action({ useContext: true })
  public async clearLicensingStore(ctx: StoreContext): Promise<void> {
    ctx.commit(LicensingStoreCommit.SetContractsMap, {});
    ctx.commit(LicensingStoreCommit.SetLicensesMap, {});
    ctx.commit(LicensingStoreCommit.SetContractPurchase, null);
  }

  @Action({ commit: LicensingStoreCommit.SetDimensions })
  public async fetchAllDimensions(service: ServiceID): Promise<DimensionsPayload> {
    const dtos = await LicensingApi.getDimensions(service);
    const dimensions = dtos.map(Dimension.fromDto);
    return { service, data: dimensions };
  }

  @Action({ commit: LicensingStoreCommit.SetContracts })
  public async fetchAllContracts(service: ServiceID): Promise<ContractsPayload> {
    const dtos = await LicensingApi.getContracts(service);
    const contracts = dtos.map(dto => Contract.fromDto(dto, service));
    return { service, data: contracts };
  }

  @Action({ commit: LicensingStoreCommit.AddContracts })
  public async fetchContractById(payload: FetchContractByIdPayload): Promise<ContractsPayload> {
    const dto = await LicensingApi.getContractById(payload.service, payload.data);
    const contract = Contract.fromDto(dto, payload.service);
    return { service: payload.service, data: [contract] };
  }

  @Action({ commit: LicensingStoreCommit.SetLicenses })
  public async fetchAllLicenses(payload: FetchLicensesPayload): Promise<ServicePayload<License[]>> {
    const dtos = await LicensingApi.getLicenses(payload.service);
    const licenses = dtos.map(dto => LicenseFactory.fromDto(dto, payload.service));
    return { service: payload.service, data: licenses };
  }

  @Action({ commit: LicensingStoreCommit.AddLicenses })
  public async fetchLicensesByContractId(payload: FetchLicenseByIdPayload): Promise<ServicePayload<License[]>> {
    const dtos = await LicensingApi.getLicensesByContractId(payload.service, payload.data);
    const licenses = dtos.map(dto => LicenseFactory.fromDto(dto, payload.service));
    return { service: payload.service, data: licenses };
  }

  @Action({ commit: LicensingStoreCommit.AddLicenses })
  public async fetchLicenseById(payload: FetchLicenseByIdPayload): Promise<ServicePayload<License[]>> {
    const dto = await LicensingApi.getLicenseById(payload.service, payload.data);
    const license = LicenseFactory.fromDto(dto, payload.service);
    return { service: payload.service, data: [license] };
  }

  @Action({ useContext: true })
  public async registerEncryptedHost(ctx: StoreContext, payload: RegisterEncryptedHostPayload): Promise<string> {
    const org: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    const response = await LicensingApi.registerEncryptedHost(payload.service, org, payload.data, payload.key);
    const license = LicenseFactory.fromDto(response.license, payload.service);
    const addLicensePayload: LicensesPayload = { service: payload.service, data: [license] };
    ctx.commit(LicensingStoreCommit.AddLicenses, addLicensePayload);
    return response.encryptedHost;
  }

  @Action({ useContext: true })
  public async registerUnencryptedHost(
    ctx: StoreContext, 
    payload: RegisterUnencryptedHostPayload
  ): Promise<string> {
    const org: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    const response = await LicensingApi.registerUnencryptedHost(payload.service, org, payload.data, payload.registration);
    await ctx.dispatch(LicensingStoreAction.FetchLicenseById, { 
      service: payload.service, 
      data: payload.data
    } as FetchLicenseByIdPayload); 
    return response;
  }

  @Action({ commit: LicensingStoreCommit.AddLicenses })
  public async unregisterHost(payload: UnregisterHostPayload): Promise<ServicePayload<License[]>> {
    const dto = await LicensingApi.unregisterHost(payload.service, payload.data);
    const license = LicenseFactory.fromDto(dto, payload.service);
    return { service: payload.service, data: [license] };
  }

  @Action({ useContext: true })
  public async createLinkeContract(
    ctx: StoreContext, 
    payload: CreateLinkeContractPayload
  ): Promise<void> {
    const org: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    const contractDto = await LicensingApi.createLinkeContract(
      payload.data.org, 
      payload.data.origin,
      payload.service, 
      payload.data.expiration, 
      payload.data.dimensions,
      payload.data.poc,
      payload.data.extendedContractId
    );
    if (org.id === payload.data.org.id) {
      const contract = Contract.fromDto(contractDto, payload.service);
      ctx.commit(LicensingStoreCommit.AddContracts, { service: payload.service, data: [contract] } as ContractsPayload);
      const setResPermissions = ResourcesStore.Mapping.rootAction(ResourcesStoreAction.SetResourcePermissions);
      await setResPermissions(ctx, { service: payload.service, data: { canDownload: true } } as SetResourcePermissionsPayload);
    }
  }

  @Action({ useContext: true, commit: LicensingStoreCommit.AddContracts })
  public async updateContract(ctx: StoreContext, payload: UpdateContractPayload): Promise<ContractsPayload> {
    const dto = await LicensingApi.updateContract(payload.data.id, payload.data.dto);

    const fetchContractLicensesPayload: FetchLicenseByIdPayload = { service: payload.service, data: payload.data.id };
    await ctx.dispatch(LicensingStoreAction.FetchLicensesByContractId, fetchContractLicensesPayload);

    return { service: payload.service, data: [Contract.fromDto(dto, payload.service)] };
  }

  @Action({ commit: LicensingStoreCommit.AddContracts })
  public async addContracts(payload: ContractsDtoPayload): Promise<ContractsPayload> {
    return { 
      service: payload.service, 
      data: payload.data.map(dto => Contract.fromDto(dto, payload.service)) 
    };
  }
}