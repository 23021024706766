import { Organization } from "@Module/Orgs/Models/Organization.model";
import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ServiceID } from "@Service/ServiceID";

interface RegisterHostApiErrorMeta {
  service: ServiceID;
  org: Organization;
  key?: string;
  licenseId?: number;
  registration?: object;
}

export class RegisterHostApiError extends ConsoleError<RegisterHostApiErrorMeta> {}