import { render, staticRenderFns } from "./OrgRoleSummary.vue?vue&type=template&id=209f43b4&"
import script from "./OrgRoleSummary.ts?vue&type=script&lang=ts&"
export * from "./OrgRoleSummary.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports