import { ContractsSummaryFilters, IContractsSummaryFiltersDto } from "@Module/Licensing/Models/ContractsSummaryFilters.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { LicensingSummary } from "@Module/Licensing/Models/LicensingSummary.model";
import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import LicensingApi from "@Module/Licensing/API/Licensing.api";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import { Component, MapGetter } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Debounce from "lodash.debounce";
import Vue from "vue";

@Component
export default class ContractsSummaryView extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected orgArrayParamSerializer: Serializer<Organization[]>;

  protected disableFiltersWatcher: VoidFunction = null;
  protected expandedRows: number[] = [];
  protected licensingSummary: LicensingSummary[] = [];
  protected searchText: string = null;
  protected filters: ContractsSummaryFilters = ContractsSummaryFilters.create();

  protected filtersBackup = {
    expand: [],
    searchText: null,
    filters: ContractsSummaryFilters.create()
  }

  protected getSummary = Execution.create(
    (filters: IContractsSummaryFiltersDto) => LicensingApi.getLicensingSummary(filters), 
    LicensingApi
  );

  protected mounted() {
    this.fetchSummary();
    this.disableFiltersWatcher = this.$watch("filters.dto", this.debouncedFetchSummary, { deep: true });
  }

  protected debouncedFetchSummary = Debounce(this.fetchSummary, 500);
  protected async fetchSummary() {
    const dtos = await this.getSummary.run(this.filters.toDto());
    if (!this.getSummary.error) {
      this.licensingSummary = dtos.map(LicensingSummary.fromDto);
    }
  }

  protected get filteredLicensingSummary(): LicensingSummary[] {
    return this.licensingSummary
      .filter(s => {
        let orgsMatch = true;
        let dimensionMatch = true;
        if (this.filters.orgs.length > 0) {
          orgsMatch = this.filters.orgs.some(org => org.uuid === s.organization?.uuid);
        }
        if (this.filters.dimensions.length > 0) {
          const selectedDims = this.filters.dimensions.map(d => d.name);
          dimensionMatch = s.licenseDimensions.some(ld => selectedDims.includes(ld.dimension));
        }
        const contractMatch = s.contract.meetsFilter(this.searchText, []);
        return contractMatch && orgsMatch && dimensionMatch;
      });
  }

  protected serviceName(servie: ServiceID): string {
    return ServiceID.nameOf(servie);
  }

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }

  protected toggleExpansion(summary: LicensingSummary) {
    const index = this.expandedRows.indexOf(summary.contract.id);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [summary.contract.id];
    }
  }

  protected get expandedRowIds(): number[] {
    return this.expandedRows
      .filter(id => this.licensingSummary.some(summary => summary.contract.id === id));
  }

  protected get hasSummary(): boolean {
    return this.licensingSummary.length > 0;
  }

  protected get firstLoad(): boolean {
    return !this.hasSummary && this.getSummary.loading;
  }

  protected async resetFilters() {
    try {
      const msg = `Are you sure you want to clear all current filter criteria? This action cannot be undone.`;
      await this.$confirm(msg, "Clear confirmation", {
        type: "warning",
        confirmButtonText: "Clear filters",
        cancelButtonText: "Cancel" 
      });
      this.filters = ContractsSummaryFilters.create();
    } catch {}
  }

  protected get contractsStatus(): LicensingStatus[] {
    return this.filteredLicensingSummary.map(summary => summary.contract.status);
  }

  public async onNotVisible() {
    this.disableFiltersWatcher();
    this.filtersBackup = {
      expand: this.expandedRows,
      filters: this.filters,
      searchText: this.searchText
    };
    this.expandedRows = [];
    this.filters = ContractsSummaryFilters.create();
    this.filters.status = [];
  } 

  public async onVisible() {
    this.expandedRows = this.filtersBackup.expand;
    this.filters = this.filtersBackup.filters;
    this.searchText = this.filtersBackup.searchText;
    await this.debouncedFetchSummary();
    this.disableFiltersWatcher = this.$watch("filters.dto", this.debouncedFetchSummary, { deep: true });
  }

}