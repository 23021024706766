import DayJS, { Dayjs } from "dayjs";
import Clone from "lodash.clonedeep";
import IsEqual from "lodash.isequal";

export interface IOrganizationDto {
  id: number;
  name: string;
  uuid: string;
  is_test: boolean;
  domain_name?: string;
  parent_id?: number;
  updated_at: string;
  created_at: string;
}

export interface IUpdateOrganizationDto {
  is_test: boolean;
  domain_name: string;
}

export class Organization {
  public readonly id: number;
  public readonly name: string;
  public readonly uuid: string;
  public readonly isTest: boolean;
  public readonly domain?: string;
  public readonly parentId?: number;
  public readonly creation: Dayjs;
  public readonly modification: Dayjs;
  public readonly remote?: IOrganizationDto;

  private constructor(dto: IOrganizationDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.uuid = dto.uuid;
    this.isTest = dto.is_test;
    this.parentId = dto.parent_id;
    this.domain = dto.domain_name;
    this.creation = DayJS(dto.created_at);
    this.modification = DayJS(dto.updated_at);
    if (dto.id !== undefined) {
      this.remote = dto;
      this.remote.uuid = this.uuid;
      this.remote.created_at = this.creation.toISOString();
      this.remote.updated_at = this.modification.toISOString();
    }
  }

  public static create(dto?: Partial<IOrganizationDto>): Organization {
    return new Organization({ 
      id: undefined, 
      uuid: dto?.uuid,  
      name: dto?.name, 
      is_test: false,
      created_at: DayJS().toISOString(),
      updated_at: DayJS().toISOString(),
      parent_id: dto?.parent_id,
      domain_name: dto?.domain_name
    });
  }

  public static fromDto(dto: IOrganizationDto): Organization {
    return new Organization(dto);
  }

  public meetsSearch(query: string): boolean {
    const text = query ? query.toLowerCase() : "";
    const meetsName = this.name.toLowerCase().includes(text);
    const meetsUuid = this.uuid.toLowerCase().includes(text);
    const meetsDomain = this.domain?.toLowerCase().includes(text) ?? false;
    return meetsName || meetsUuid || meetsDomain;
  }

  public get isRemote(): boolean {
    return this.id !== undefined;
  }

  public discardUncommittedChanges() {
    Object.assign(this, new Organization(this.remote));
  }

  public get hasUncommittedChanges(): boolean {
    if (!this.isRemote) { return true; }
    const dto = JSON.parse(JSON.stringify(this.toDto()));
    const remote = JSON.parse(JSON.stringify(this.remote));
    return !IsEqual(dto, remote);
  }

  public clone(): Organization {
    return Clone(this);
  }

  public toUpdateDto(): IUpdateOrganizationDto {
    const { is_test, domain_name } = this.toDto();
    return { is_test: is_test !== this.remote?.is_test ? is_test : undefined, domain_name };
  }

  public toDto(): IOrganizationDto {
    return  {
      id: this.id ?? undefined,
      name: this.name,
      uuid: this.uuid,
      is_test: this.isTest,
      parent_id: this.parentId,
      domain_name: this.domain?.toLowerCase() || undefined,
      created_at: this.remote?.created_at,
      updated_at: this.remote?.updated_at,
    };
  }
  
}