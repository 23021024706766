import { AbapSuiteLicenseFilters } from "@Service/AbapSuite/Models/Filters/AbapSuiteLicenseFilters.model";
import { AbapSuiteLicense } from "@Service/AbapSuite/Models/AbapSuiteLicense.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Component, MapGetter } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";

@Component
export default class AbapSuiteLicenses extends Vue {
  protected serviceId: ServiceID = ServiceID.AbapSuite;
  protected filters = AbapSuiteLicenseFilters.create();

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  protected viewDetails(license: AbapSuiteLicense) {
    this.$router.push({ 
      name: "abap-suite-license-details", 
      params: { id: license.id.toString() }
    });
  }

  protected viewContract(license: AbapSuiteLicense) {
    this.$router.push({
      name: "abap-suite-contract-details",
      params: { id: license.contractId.toString() }
    });
  }

  protected get permissions() {
    return {
      CanListContracts: this.accessRole.can(this.serviceId, Permission.ListContracts),
      CanRegisterHosts: this.accessRole.can(this.serviceId, Permission.RegisterHost),
      CanPurchaseContracts: this.accessRole.can(this.serviceId, Permission.PurchaseContract)
    }
  }

}