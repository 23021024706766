import { ContractFilters } from "@Module/Licensing/Models/ContractFilters.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { ServiceID } from "@Service/ServiceID";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class ContractFiltersPanel extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Object, required: true })
  protected value: ContractFilters;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

}