import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import OrgsStore, { MAX_ORGS_HISTORY_SIZE } from "@Module/Orgs/Store/Orgs.store";
import { Component, MapAction, MapGetter, mixins, Prop } from "types-vue";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import OrgRoleSelector from "../OrgRoleSelector/OrgRoleSelector";
import OrgSwitchMixin from "@Module/Orgs/Mixins/OrgSwitch.mixin";

@Component
export default class OrgSwitcher extends mixins(OrgSwitchMixin) {

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected defaultOrgRole: OrganizationRole;

  @MapGetter(OrgsStore.Mapping)
  protected canSwitchOrg: boolean;
  
  @MapGetter(OrgsStore.Mapping)
  protected orgRolesHistory: OrganizationRole[];

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRole: OrganizationRole;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRoleParent: OrganizationRole;

  @MapGetter(OrgsStore.Mapping)
  protected orgRoles: OrganizationRole[];

  @MapAction(OrgsStore.Mapping)
  protected removeOrgFromOrgRolesHistory: (org: Organization) => Promise<void>;

  protected orgsHistoryMaxSize: number = MAX_ORGS_HISTORY_SIZE;

  protected mockedOrgRole: OrganizationRole = null;

  protected created() {
    this.mockedOrgRole = Object.assign({}, this.currentOrgRole);
  }

  public blur() {
    (this.$refs.orgRoleSelector as OrgRoleSelector).blur();
  }

  protected async onOrgRoleSelected(orgRole: OrganizationRole) {
    if (this.currentOrgRole.organization.id === orgRole.organization.id) { return; }
    this.$emit("select", orgRole);
    this.switchOrgRole(orgRole);
  }

}