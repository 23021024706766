import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import OrgRoleSelector from "../OrgRoleSelector/OrgRoleSelector";
import { Component, MapGetter, Prop, Watch } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import Vue from "vue";

type OrgSelectorValue = Organization | Organization[];
type OrgRoleSelectorValue = OrganizationRole | OrganizationRole[];

@Component
export default class OrgSelector extends Vue {

  @Prop({ type: [Object, Array], default: null })
  protected value: OrgSelectorValue;

  @Prop({ type: Array })
  protected data: Organization[];

  @Prop({ type: Boolean })
  protected includeNoAccessRoles: boolean;

  @Prop({ type: String })
  protected noDataText: string;

  @Prop({ type: String })
  protected noMatchText: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: Boolean, default: false })
  protected collapseTags: boolean;

  @Prop({ type: Boolean, default: false })
  protected childInset: boolean;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected showRole: boolean;

  @Prop({ type: Boolean, default: false })
  protected hierarchical: boolean;
  
  @Prop({ type: Boolean, default: false })
  protected noExpand: boolean;

  @Prop({ type: String, default: "Select an organization" })
  protected placeholder: string;

  @Prop({ type: Array, default: () => [] })
  protected disabledItems: Organization[];

  @Prop({ type: Boolean, default: false })
  protected defaultExpandAll: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected orgRoles: OrganizationRole[];

  @MapGetter(OrgsStore.Mapping)
  protected findOrgRoleHierarchy: (org: number | Organization) => OrganizationRole;

  protected orgRole: OrgRoleSelectorValue = null;

  @Watch("value", { immediate: true })
  protected onValueChange(newValue: OrgSelectorValue) {
    if (!newValue) {
      this.orgRole = null;
    } else if (Array.isArray(newValue)) {
      this.orgRole = this.orgRoles
        .filter(o => newValue.some(org => org.id === o.organization.id))
    } else {
      this.orgRole = this.orgRoles
        .find(or => or.organization.id === newValue.id);
    }
  }

  protected onOrgRoleChange(selection: OrgRoleSelectorValue) {
    let newValue: OrgSelectorValue = this.multiple ? [] : null;
    if (!!selection) {
      newValue = Array.isArray(selection) 
        ? selection.map(orgRole => orgRole.organization) 
        : selection.organization;
    }

    this.$emit("input", newValue);
    this.$emit("select", newValue);
    this.$emit("change", newValue);
  }

  protected get disabledOrgRoles(): OrganizationRole[] {
    return (this.orgRoleSelectorData || this.orgRoles)
      .filter(orgRole => this.disabledItems.some(item => orgRole.organization.id === item.id))
  }

  protected get orgRoleSelectorData(): OrganizationRole[] {
    return this.data?.map(org => {
      const orgRole = this.hierarchical 
        ? this.findOrgRoleHierarchy(org) 
        : this.orgRoles.find(orgRole => orgRole.organization.id === org.id);
      return !!orgRole ? orgRole : OrganizationRole.noAccess(org);
    });
  }

  public focus() {
    (this.$refs.orgRoleSelector as OrgRoleSelector).focus();
  }

  public blur() {
    (this.$refs.orgRoleSelector as OrgRoleSelector).blur();
  }
}