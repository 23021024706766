import { BackupLifecycle } from "@Service/Emory/Models/Backups/BackupLifecycle.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class BackupLifecycleSummary extends Vue {
  
  @Prop({ type: Object, required: true })
  protected lifecycle: BackupLifecycle;

  @Prop({ type: String })
  protected expirationFormat: string;

}