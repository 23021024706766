import { Resource, ResourceType } from "@Module/Resources/Models/Resource.model";
import { UISize, UIPlacement } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import { Release } from "@Module/Resources/Models/Release.model";
import ResourcesApi from "@Module/Resources/API/Resources.api";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { ServiceID } from "@Service/ServiceID";
import { Notify } from "@Core/Utils/Notify";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import Vue from "vue";
import { Execution } from "@Core/Models/Execution";
import ResourcesStore from "@Module/Resources/Store/Resources.store";
import { WizardConfig } from "@Module/Resources/Models/WizardConfig.model";
import { Dimension } from "@Module/Licensing/Models/Dimension.model";

@Component
export default class ReleasesList extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Number, default: null})
  protected selectedVersion: Number;

  @Prop({ type: Object, default: null})
  protected selectedDimension: Dimension;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected label: string;


  @Prop({type: Array})
  protected releases: Release[] 


  @Prop({ type: String, default: true })
  protected loading: boolean; 

  protected getResourceLink = Execution.create(ResourcesApi.getResourceLink, ResourcesApi);



  protected async downloadResource(resId: number) {
    try {
      const resourceLink = await this.getResourceLink.run(resId, this.serviceId);
      window.open(resourceLink);
    } catch (error) {
      let message = "An error occurred starting the resource download. Please, try again later.";
      Notify.Error({ title: "Download error", message });
    }
  } 

  }

  