import { OrgAccessRequestNotification } from "@Module/Notifications/Models/OrgAccessRequestNotification.model";
import { OrgInvitationNotification } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { Notification } from "@Module/Notifications/Models/Notification.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapAction, MapGetter, mixins, Prop } from "types-vue";
import AppRestartMixin from "@Core/Mixins/AppRestart.mixin";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { Utils } from "@Core/Utils/Utils";

@Component
export default class OrgSuggestionListItem extends mixins(AppRestartMixin) {

  @Prop({ type: Object, required: true })
  protected organization: Organization;

  @Prop({ type: Object, default: null })
  protected accessRequestNotif: OrgAccessRequestNotification;

  @Prop({ type: Object, default: null })
  protected invitationNotif: OrgInvitationNotification;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;
  
  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapAction(OrgsStore.Mapping)
  protected fetchOrgRoles: () => Promise<void>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected sendOrgAccessRequest: (organization: Organization) => Promise<Notification>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected cancelNotification: (notification: Notification) => Promise<Notification>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptNotification: (notification: Notification) => Promise<Notification>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected declineNotification: (notification: Notification) => Promise<Notification>;

  protected requestAccess = Execution.create(async () => {
    try {
      await this.sendOrgAccessRequest(this.organization);
      Notify.Success({
        title: "Organization access requested",
        message: `Access to ${this.organization.name} organization successfully requested.`
      });
    } catch (error) {
      Notify.Error({
        title: "Organization access error",
        message: error.message
      });
    }
  });

  protected cancelRequestAccess = Execution.create(async () => {
    try {
      await this.cancelNotification(this.accessRequestNotif);
      Notify.Success({
        title: "Access request canceled",
        message: `Access request to ${this.organization.name} organization successfully canceled.`
      });
    } catch (error) {
      Notify.Error({
        title: "Access request cancel error",
        message: error.message
      });
    }
  });

  protected acceptInvitation = Execution.create(async () => {
    try {
      const message = `Your invitation to access ${this.invitationNotif.organization.name} organization as ${this.invitationNotif.role.name} has been successfully accepted.`;
      await this.acceptNotification(this.invitationNotif);
      this.onInvitationAccepted();
      await Utils.sleep(100);
      Notify.Success({
        title: "Invitation accepted", 
        message,
        duration: 6000
      });
    } catch (error) {
      Notify.Error({
        title: "Invitation response error", 
        message: error.message
      });
    }
  });

  protected declineInvitation = Execution.create(async () => {
    try {
      const message = `Your invitation to access ${this.invitationNotif.organization.name} organization as ${this.invitationNotif.role.name} has been successfully declined.`;
      await this.declineNotification(this.invitationNotif);
      Notify.Success({
        title: "Invitation declined", 
        message,
        duration: 6000
      });
    } catch (error) {
      Notify.Error({
        title: "Invitation response error", 
        message: error.message
      });
    }
  });

  protected get loading(): boolean {
    return this.requestAccess.loading 
        || this.cancelRequestAccess.loading
        || this.acceptInvitation.loading
        || this.declineInvitation.loading;
  }

  protected onInvitationAccepted() {
    if (this.noOrganization) {
      this.promptAppRestart();
    } else {
      this.fetchOrgRoles();
    }
  }

}