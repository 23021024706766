import { UIContentSizeMode, UIMode, UISize } from '../../Store/AppSettings/AppSettings.state';
import AppSettingsStore from '../../Store/AppSettings/AppSettings.store';
import { Component, MapAction, MapGetter, mixins, Watch } from 'types-vue';
import { Notify } from '@Core/Utils/Notify';
import { Map } from '@Core/Models/Map';
import NavigationStore from '@Core/Store/Navigation/Navigation.store';
import { ConsoleRoute } from '@Core/Models/ConsoleRoute';
import StateCleanerMixin from '@Module/Auth/Mixins/StateCleaner.mixin';

@Component
export default class AppSettings extends mixins(StateCleanerMixin) {
  
  @MapGetter(AppSettingsStore.Mapping)
  protected collapsedMenu: boolean;

  @MapGetter(AppSettingsStore.Mapping)
  protected expandSubmenus: boolean;
  
  @MapGetter(AppSettingsStore.Mapping)
  protected hiddenBreadcrumb: boolean;
  
  @MapGetter(AppSettingsStore.Mapping)
  protected hiddenLoadingIndicator: boolean;

  @MapGetter(AppSettingsStore.Mapping)
  protected uiContentSizeMode: UIContentSizeMode;

  @MapGetter(AppSettingsStore.Mapping)
  protected uiMode: UIMode;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapAction(AppSettingsStore.Mapping)
  protected setHiddenBreadcrumb: (hidden: boolean) => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected setExpandSubmenus: (expand: boolean) => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected setHiddenLoadingIndicator: (hidden: boolean) => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected toggleCollapsedMenu: () => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected setUiContentSizeMode: (mode: UIContentSizeMode) => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected setUiMode: (mode: UIMode) => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected setUiSize: (size: UISize) => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected restoreAppSettingDefaults: () => Promise<void>;

  protected model = {
    uiContentSizeMode: null,
    uiMode: null,
    uiSize: "large"
  }

  protected get cookiesEnabled(): boolean {
    return navigator.cookieEnabled;
  }

  @Watch("uiContentSizeMode", { immediate: true })
  protected onContentSizeModeChange(uiContentSizeMode: UIContentSizeMode) {
    this.model.uiContentSizeMode = uiContentSizeMode;
  }

  @Watch("uiMode", { immediate: true })
  protected onUiModeChange(uiMode: UIContentSizeMode) {
    this.model.uiMode = uiMode;
  }

  protected async onRestoreDefaultsClick() {
    try {
      await this.$confirm(
        "All your preferences will be restored to their default values. Are you sure you want to restore your application settings?", 
        'Restore confirmation', {
          confirmButtonText: 'Restore',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      );
      await this.restoreAppSettingDefaults();
      Notify.Success({
        title: "Settings restored",
        message: "Your preferences has been restored to their default values."
      });
    } catch {}
  }

  protected async onClearCacheClick() {
    try {
      await this.$confirm(
        "All the application cache will be deleted. Are you sure you want to clear the application cache?", 
        'Clear confirmation', {
          confirmButtonText: 'Clear cache',
          cancelButtonText: 'Cancel',
          type: 'warning'
        });
        await this.clearState();
        Notify.Success({ 
          title: "Cache cleared", 
          message: "All the application cache has been successfully deleted.", 
          duration: 3000 
        });
    } catch (error) {}
  }

  protected get pageClass(): Map<boolean> {
    return { 
      "is-plain": this.uiMode === "plain" 
    }
  }

}