export enum LogLevel {
  Trace, Debug, Info, Warn, Error
}

export interface LogStyle {
  foreground: string;
  background: string;
}

export interface LoggerConfig {
  logLevel?: LogLevel,
  prependLevel?: boolean;
  style?: LogStyle;
  prefix?: string;
}