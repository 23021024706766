import { Component, MapAction, mixins } from "types-vue";
import StateCleanerMixin from "./StateCleaner.mixin";
import AuthStore from "../Store/Auth.store";

@Component
export default class SignOutMixin extends mixins(StateCleanerMixin) {

  @MapAction(AuthStore.Mapping)
  protected signOut: (expired?: boolean) => Promise<void>;

  protected async performSignOut(expired?: boolean) {
    this.$Progress?.start();
    await this.signOut(expired);
    await this.clearState();
    this.$Progress?.finish();
  }

}