import NotificationsOutboxStore, { SendOrgInvitationPayload } from "@Module/Notifications/Store/NotificationsOutbox.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { Execution } from "@Core/Models/Execution";
import { ElForm } from "element-ui/types/form";
import { Notify } from "@Core/Utils/Notify";
import Vue from "vue";

interface InviteMemberFormModel {
  email: string;
  role: Role;
}

@Component
export default class InviteMember extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @MapGetter(AuthStore.Mapping)
  protected roles: Role[];

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected sendOrgInvitation: (payload: SendOrgInvitationPayload) => Promise<void>

  protected model: InviteMemberFormModel = { 
    email: null,
    role: null
  };

  protected inviteToOrg = Execution.create(this.doInvite);

  protected doInvite() {
    return this.sendOrgInvitation(this.model);
  }

  protected get validationRules() {
    return {
      email: [{ type: "email", message: "Please enter a valid email address.", trigger: ["change", "blur"] }]
    }
  }

  protected onFieldClear() {
    (this.$refs.form as ElForm)?.clearValidate();
  }

  protected get formWithErrors(): boolean {
    return (this.$refs.form as any).fields?.some((item: any) => item.validateState === "error") || false;
  }

  protected async onInviteClick() {
    try { 
      await this.$confirm(
        `Are you sure you want to invite <strong>${this.model.email}</strong> to <strong>${this.currentOrg.name}</strong> organization as <strong>${this.model.role.name}</strong>?`,
        "Invitation confirmation", {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Send invitation",
          cancelButtonText: "Cancel",
          type: "warning"
      });
    } catch (error) { return; }

    this.inviteToOrg.reset();
    await this.inviteToOrg.run();
    if (!this.inviteToOrg.error) {
      Notify.Success({
        title: "Invitation Sent",
        message: `An invitation to your organization has been sent to ${this.model.email}.`
      });
      this.model = { 
        email: null,
        role: this.model.role 
      };
    }
  }
  
}