import { CloudProviderType } from "./CloudProvider/BackupCloudProvider.model";
import { FileSizeFilter } from "../FileSizeFilter.model";

export interface IBackupFiltersDto {
  min_start_date?: string;
  max_start_date?: string;
  min_end_date?: string;
  max_end_date?: string;
  min_size?: number;
  max_size?: number;
  statuses?: string;
  levels?: string;
  text?: string;
  show_log_level: boolean;
  page_number: number;
  page_size: number;
  sort_by: string;
  sort_dir: SortOrder;
  types: string;
}

export const enum SortOrder {
  Ascending = "ASC",
  Descending = "DESC"
}

interface MinMax<T> {
  min: T; 
  max: T;
}

export class BackupFilters {
  public size: MinMax<FileSizeFilter>;
  public endDate: MinMax<Date>;
  public startDate: MinMax<Date>;
  public search: string | null;
  public status: string[];
  public page: number | null;
  public pageSize: number;
  public levels: number[];
  public showLogLevel: boolean;
  public sorting: { prop: string | null, order: SortOrder | null };
  public types: CloudProviderType[];

  public static create(): BackupFilters {
    return new BackupFilters();
  }

  private constructor() {
    this.size = { min: FileSizeFilter.create(), max: FileSizeFilter.create() };
    this.endDate = { min: null, max: null };
    this.startDate = { min: null, max: null };
    this.search = null;
    this.status = [];
    this.page = 1;
    this.pageSize = 10;
    this.levels = [];
    this.showLogLevel = false;
    this.sorting = { prop: "start_date", order: SortOrder.Descending };
    this.types = [];
  }

  public reset() {
    this.size = { min: FileSizeFilter.create(), max: FileSizeFilter.create() };
    this.endDate = { min: null, max: null };
    this.startDate = { min: null, max: null };
    this.search = null;
    this.status = [];
    this.levels = [];
    this.showLogLevel = false;
    this.types = [];
  }

  public get isFiltering(): boolean {
    return !!this.startDate.min || !!this.startDate.max
        || !!this.endDate.min || !!this.endDate.max
        || !!this.size.min.bytes || !!this.size.max.bytes
        || this.showLogLevel
        || !!this.search || this.status.length > 0 || this.levels.length > 0 || this.types.length > 0;
  }

  public get dto(): IBackupFiltersDto {
    return this.toDto();
  }

  public toDto(): IBackupFiltersDto {
    return {
      min_start_date: this.startDate.min?.toISOString(),
      max_start_date: this.startDate.max?.toISOString(),
      min_end_date: this.endDate.min?.toISOString(),
      max_end_date: this.endDate.max?.toISOString(),
      min_size: Math.round(this.size.min.bytes) || undefined,
      max_size: Math.round(this.size.max.bytes) || undefined,
      statuses: this.status.length > 0 ? this.status.join(",") : undefined,
      // levels: this.levels.length > 0 ? this.levels.map(l => l.id).join(",") : undefined,
      levels: this.levels.length > 0 ? this.levels.join(",") : undefined,
      show_log_level: this.showLogLevel,
      text: this.search || undefined,
      sort_by: this.sorting.prop || undefined,
      sort_dir: this.sorting.order || undefined,
      page_number: this.page,
      page_size: this.pageSize,
      types: this.types.length > 0 ? this.types.join(",") : undefined,
    };
  }
  
}