import FileDownloadMixin from "@Core/Mixins/FileDownload.mixin";
import { ExportFormat } from "@Core/Models/ExportFormat.model";
import LicensingApi from "@Module/Licensing/API/Licensing.api";
import { Component, mixins, Prop } from "types-vue";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { ServiceID } from "@Service/ServiceID";

interface FileRequestResult {
  name: string;
  content: string;
  base64: boolean;
  downloaded: boolean;
}

const enum ExportStep { 
  Configuration, 
  Download 
}

@Component
export default class ExportLicensingSummaryDialog extends mixins(FileDownloadMixin) {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  protected exportResult: FileRequestResult = null;
  protected exportFormat = ExportFormat.CSV;
  protected exportSummary = Execution.create(LicensingApi.exportLicensingSummary, LicensingApi);

  public open(): Promise<boolean> {
    return (this.$refs.dialog as Dialog).open();
  }

  protected get serviceName(): string {
    return ServiceID.nameOf(this.serviceId);
  }

  protected async startExport() {
    this.exportSummary.reset();
    const content = await this.exportSummary.run(this.serviceId, this.exportFormat);
    const name = `Export-${new Date().getTime()}.zip`;
    if (!this.exportSummary.error) {
      this.exportResult = { 
        name, content, 
        base64: true, 
        downloaded: false 
      };
    }
  }

  protected async downloadLicenseFile(): Promise<void> {
    const content = this.exportResult.content;
    const fileName = this.exportResult.name;
    const base64 = this.exportResult.base64;
    this.downloadFile(fileName, content, { base64 });
    this.exportResult.downloaded = true;
  }

  protected get exportComplete(): boolean {
    return this.currentExportStep === ExportStep.Download;
  }

  protected get forgotDownload(): boolean {
    return !!this.exportResult && !this.exportResult.downloaded;
  }

  protected get currentExportStep(): ExportStep {
    return !this.exportResult ? ExportStep.Configuration : ExportStep.Download;
  }

  protected onClosed() {
    this.exportResult = null;
    this.exportSummary.reset();
    this.exportFormat = ExportFormat.CSV
  }

  protected async dialogCloseGuard(close: VoidFunction) {
    if (this.exportSummary.loading) {
      try {
        await this.$confirm(
          "Your summary is still being exported, if you close the dialog you will not be able to download the resulting file.",
          "Export warning",
          { confirmButtonText: "Close and skip download", cancelButtonText: "Cancel", type: "warning" });
      } catch { return; }
    }
    if (this.forgotDownload) {
      try {
        await this.$confirm(
          "The export process is complete, however you have not downloaded the resulting file.",
          "Export download warning",
          { confirmButtonText: "Close and skip download", cancelButtonText: "Cancel", type: "warning" });
      } catch { return; }
    }
    close()
  }

}