import { AwsRegion } from "@Core/Models/AwsRegion.model";
import { Utils } from "@Core/Utils/Utils";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class AwsRegionSelector extends Vue {

  @Prop({ type: Object, default: null })
  protected value: AwsRegion;

  @Prop({ type: String, default: "Select an AWS region" })
  protected placeholder: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Function, default: () => () => true })
  protected filter: (region: AwsRegion) => boolean;

  protected searchText: string = null;

  protected onRegionSelected(region: AwsRegion) {
    const selectedRegion = region || null;
    this.$emit("input", selectedRegion);
    this.$emit("update:value", selectedRegion);
    this.$emit("select", selectedRegion);
    this.searchText = null;
  }

  protected get filteredRegions(): Record<string, AwsRegion[]> {
    return Object.keys(AwsRegion.AllByRegion)
      .reduce((regionsMap, regionGroup) => {
        const regions = AwsRegion.AllByRegion[regionGroup] as AwsRegion[];
        const filteredRegions = regions.filter(region => {
          const meetsFilter = this.filter(region);
          if (!meetsFilter) { return false; }
          const text = this.searchText?.toLowerCase() || "";
          return region.name.toLowerCase().includes(text) || region.code.toLowerCase().includes(text);
        });
        if (filteredRegions.length > 0) {
          regionsMap[regionGroup] = filteredRegions;
        }
        return regionsMap;
      }, {} as Record<string, AwsRegion[]>);
  }

  protected async onVisibleChange(visible: boolean) {
    if (visible) { return; }
    await Utils.sleep(200);
    this.searchText = null;
  }

}