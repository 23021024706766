import { OrgInvitationNotificationLink, OrgInvitationNotification } from "../Models/OrgInvitationNotification.model";
import LicensingStore, { FetchByIdPayload, LicensingStoreAction } from "@Module/Licensing/Store/Licensing.store";
import { OrgAccessRequestNotification } from "../Models/OrgAccessRequestNotification.model";
import { ContractPurchaseNotification } from "../Models/ContractPurchaseNotification.model";
import { LicenseSharingNotification } from "../Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "../Models/LicenseReturnNotification.model";
import UsersStore, { UsersStoreAction } from "@Module/Users/Store/Users.store";
import { FetchLicensesPayload } from "@Module/Licensing/Store/Licensing.store";
import { Notification, NotificationType } from "../Models/Notification.model";
import OrgsStore, { OrgsStoreGetter } from "@Module/Orgs/Store/Orgs.store";
import { NotificationsInboxStoreState } from "./NotificationsInbox.state";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { NotificationFactory } from "../Models/Notification.factory";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { OrgsStoreAction } from "@Module/Orgs/Store/Orgs.store";
import NotificationsApi from "../API/Notifications.api";
import { Utils } from "@Core/Utils/Utils";
import { ActionContext } from "vuex";

declare type NotificationsInboxStoreContext = ActionContext<NotificationsInboxStoreState, any>;

const enum NotificationsInboxStoreCommit {
  SetInboxNotifications = "_setInboxNotifications",
  PutInboxNotifications = "_putInboxNotifications",
  DeleteInboxNotification = "_deleteInboxNotification",
  SetOrgInvitationLink = "_setOrgInvitationLink"
}

export const enum NotificationsInboxStoreAction {
  FetchInbox = "fetchInbox",
  AddNotification = "addNotification"
}

export type AcceptOrgAccessRequestPayload = {
  notification: OrgAccessRequestNotification;
  role: Role;
}

export type AcceptContractPurchasePayload = {
  notification: ContractPurchaseNotification;
  organization: Organization;
}

@Module({ namespaced: true })
export default class NotificationsInboxStore extends VuexModule<NotificationsInboxStoreState> implements NotificationsInboxStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("NotificationsInbox");

  public _inbox: Notification[] = [];
  public _orgInvitationLink: OrgInvitationNotificationLink = null;

  @Getter()
  public orgInvitationLink(): OrgInvitationNotificationLink {
    return this._orgInvitationLink;
  }

  @Getter()
  public hasInboxNotifications(): boolean {
    return this._inbox.length > 0;
  }

  @Getter()
  public inbox(): Notification[] {
    return this._inbox;
  }

  @Getter()
  public licenseSharingInbox(): LicenseSharingNotification[] {
    return this._inbox.filter(n => n.type === NotificationType.LicenseSharing) as LicenseSharingNotification[];
  }

  @Getter()
  public licenseReturnInbox(): LicenseReturnNotification[] {
    return this._inbox.filter(n => n.type === NotificationType.LicenseReturn) as LicenseReturnNotification[];
  }

  @Getter()
  public orgInvitationsInbox(): OrgInvitationNotification[] {
    return this._inbox.filter(n => n.type === NotificationType.OrgInvitation) as OrgInvitationNotification[];
  }

  @Getter()
  public orgAccessRequestInbox(): OrgAccessRequestNotification[] {
    return this._inbox.filter(n => n.type === NotificationType.OrgAccessRequest) as OrgAccessRequestNotification[];
  }

  @Mutation()
  public _setOrgInvitationLink(link: OrgInvitationNotificationLink) {
    this._orgInvitationLink = link;
  }

  @Mutation()
  protected _setInboxNotifications(notifications: Notification[]) {
    this._inbox = notifications.sort((a, b) => a.expiration.diff(b.expiration));
  }

  @Mutation()
  protected _putInboxNotifications(notifications: Notification[]) {
    for (const newNotif of notifications) {
      const existingNotif = this._inbox.find(notif => notif.id === newNotif.id);
      if (!!existingNotif) {
        Object.assign(existingNotif, newNotif);
      } else {
        this._inbox.push(newNotif);
      }
    }
    this._inbox.sort((a, b) => a.expiration.diff(b.expiration));
  }

  @Mutation()
  protected async _deleteInboxNotification(notification: Notification): Promise<void> {
    const notifIndex = this._inbox.findIndex(n => n.id === notification.id);
    if (notifIndex !== -1) {
      this._inbox.splice(notifIndex, 1);
    }
  }

  @Action({ commit: NotificationsInboxStoreCommit.SetOrgInvitationLink })
  public async setOrgInvitationLink(link: OrgInvitationNotificationLink | null): Promise<OrgInvitationNotificationLink | null> {
    return link;
  }

  @Action({ commit: NotificationsInboxStoreCommit.SetInboxNotifications })
  public async fetchInbox(): Promise<Notification[]> {
    const dtos = await NotificationsApi.getNotificationsInbox();
    return dtos.map(NotificationFactory.fromDto);
  }

  @Action({ commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async acceptNotification(notification: Notification): Promise<Notification> {
    const dto = await NotificationsApi.acceptNotification(notification);
    return NotificationFactory.fromDto(dto);
  }

  @Action({ useContext: true, commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async acceptExchangeNotification(
    ctx: NotificationsInboxStoreContext,
    notification: LicenseSharingNotification | LicenseReturnNotification
  ): Promise<Notification> {
    const dto = await NotificationsApi.acceptNotification(notification);
    
    const fetchLicenses = LicensingStore.Mapping.rootAction(LicensingStoreAction.FetchAllLicenses);
    const fetchLicensesPayload: FetchLicensesPayload = { service: notification.service };
    await fetchLicenses(ctx, fetchLicensesPayload);
    
    return NotificationFactory.fromDto(dto);
  }

  @Action({ commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async acceptOrgInvitationLink(link: OrgInvitationNotificationLink): Promise<Notification> {
    const dto = await NotificationsApi.acceptOrgInvitationLink(link);
    return NotificationFactory.fromDto(dto);
  }

  @Action({ useContext: true, commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async acceptOrgInvitationNotification(
    ctx: NotificationsInboxStoreContext, 
    notification: OrgInvitationNotification
  ): Promise<Notification> {
    const dto = await NotificationsApi.acceptNotification(notification);
    
    const hasNoOrgs: boolean = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.NoOrganization)(ctx);
    if (!hasNoOrgs) {
      const fetchOrgRoles = OrgsStore.Mapping.rootAction(OrgsStoreAction.FetchOrgRoles);
      await fetchOrgRoles(ctx);
    }
    
    return NotificationFactory.fromDto(dto);
  }

  @Action({ useContext: true, commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async acceptOrgAccessRequestNotification(ctx: NotificationsInboxStoreContext, payload: AcceptOrgAccessRequestPayload): Promise<Notification> {
    const dto = await NotificationsApi.acceptOrgRequestNotification(payload.notification, payload.role);

    const fetchOrgUsers = UsersStore.Mapping.rootAction(UsersStoreAction.FetchOrgUsers);
    fetchOrgUsers(ctx);

    return NotificationFactory.fromDto(dto);
  }

  @Action({ useContext: true, commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async acceptContractPurchaseNotification(
    ctx: NotificationsInboxStoreContext, 
    payload: AcceptContractPurchasePayload
  ): Promise<Notification> {
    const dto = await NotificationsApi.acceptContractPurchaseNotification(payload.notification, payload.organization);
    
    const currentOrg: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    if (currentOrg.id === payload.organization.id) {
      const fetchByIdPayload: FetchByIdPayload = { service: payload.notification.service, data: payload.notification.contractId };
      const fetchContractById = LicensingStore.Mapping.rootAction(LicensingStoreAction.FetchContractById)(ctx, fetchByIdPayload);
      const fetchLicensesByContractId = LicensingStore.Mapping.rootAction(LicensingStoreAction.FetchLicensesByContractId)(ctx, fetchByIdPayload);
      await Promise.all([ fetchContractById, fetchLicensesByContractId ]);
    }

    return NotificationFactory.fromDto(dto);
  }

  @Action({ commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async declineNotification(notification: Notification): Promise<Notification> {
    const dto = await NotificationsApi.declineNotification(notification);
    return NotificationFactory.fromDto(dto);
  }

  @Action({ commit: NotificationsInboxStoreCommit.DeleteInboxNotification })
  public async declineOrgInvitationLink(link: OrgInvitationNotificationLink): Promise<Notification> {
    const dto = await NotificationsApi.declineOrgInvitationLink(link);
    return NotificationFactory.fromDto(dto);
  }

  @Action({ useContext: true })
  public async clearNotificationInboxStore(ctx: NotificationsInboxStoreContext): Promise<void> {
    ctx.commit(NotificationsInboxStoreCommit.SetInboxNotifications, []);
    ctx.commit(NotificationsInboxStoreCommit.SetOrgInvitationLink, null);
  }

  @Action({ commit: NotificationsInboxStoreCommit.PutInboxNotifications })
  public async addNotification(notifications: Notification): Promise<Notification[]> {
    return [notifications];
  }

}