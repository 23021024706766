import { IDimensionUnitsDto } from "@Module/Licensing/Models/Dimension.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ServiceID } from "@Service/ServiceID";

interface StartTrialApiErrorMeta {
  org: Organization;
  service: ServiceID;
  dimensions: IDimensionUnitsDto[];
}

export class StartTrialApiError extends ConsoleError<StartTrialApiErrorMeta> {}