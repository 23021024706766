import { Utils } from "@Core/Utils/Utils";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

export interface ListItem<T = object> {
  loading: boolean;
  disabled: boolean;
  data: T;
}

export type ItemSelectionHandler = (item: object) => void | Promise<void>;

@Component
export default class List extends Vue {

  @Prop({ required: true })
  protected data: object[];

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: Boolean, default: true })
  protected selectable: boolean;

  @Prop({ type: Boolean, default: false })
  protected separated: boolean;

  @Prop({ type: String, default: "id" })
  protected idKeyPath: string;

  @Prop({ type: String, default: "label" })
  protected labelKeyPath: string;

  @Prop({ type: String, default: "description" })
  protected descriptionKeyPath: string;

  @Prop({ type: Function, default: null })
  protected select: ItemSelectionHandler;

  @Prop({ type: [String, Number], default: "6px" })
  protected spacing: string | number;

  protected listItems: ListItem[] = [];

  @Watch("data", { immediate: true })
  protected onDataChange(data: object[]) {
    this.listItems = data
      .map(item => ({ loading: false, disabled: this.disabled, data: item }));
  }
  
  @Watch("disabled", { immediate: true })
  protected onDisablilityChange(disabled: boolean) {
    this.listItems.forEach(item => item.disabled = disabled);
  }

  protected get listClass() {
    return {
      "is-selectable": this.selectable,
      "is-separated": this.separated,
      "is-compact": this.compact
    }
  }

  protected getId(item: object): string {
    return Utils.getNestedProperty(item, this.idKeyPath);
  }

  protected async onListItemSelected(item: ListItem) {
    item.loading = true;
    this.$emit("select", item.data);
    await this.select?.(item.data);
    item.loading = false;
  }

}