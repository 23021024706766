import { IOrganizationDto, Organization } from "@Module/Orgs/Models/Organization.model";
import { IDimensionUnits, IDimensionUnitsDto } from "./Dimension.model";
import { Contract, IContractDto } from "./Contract.model";
import { ServiceID } from "@Service/ServiceID";

export interface ILicensingSummaryDto {
  Contract: IContractDto;
  Org: IOrganizationDto;
  Product: string;
  LicenseDimensions: IDimensionUnitsDto[];
}

export class LicensingSummary {
  contract: Contract;
  organization?: Organization;
  service: ServiceID;
  licenseDimensions: IDimensionUnits[];

  public static fromDto(dto: ILicensingSummaryDto): LicensingSummary {
    return new LicensingSummary(dto);
  }

  private constructor(dto: ILicensingSummaryDto) {
    this.contract = Contract.fromDto(dto.Contract, dto.Product as ServiceID);
    this.organization = !!dto.Org ? Organization.fromDto(dto.Org) : null;
    this.service = dto.Product as ServiceID;
    this.licenseDimensions = dto.LicenseDimensions;
  }

  public get licensesCount(): number {
    return this.licenseDimensions.reduce((acc, curr) => {
      acc + curr.units;
      return acc;
    }, 0);
  }
  
}