import { Organization } from "@Module/Orgs/Models/Organization.model";
import { LicensingStatusPhase } from "./LicensingStatus.model";
import { ServiceID } from "@Service/ServiceID";
import { Dimension } from "./Dimension.model";

export interface IContractsSummaryFiltersDto {
  status: string;
  product: string;
  withTests: boolean;
  withHosts: boolean;
  onlyPocs: boolean;
  origin: string;
}

export class ContractsSummaryFilters {
  status: LicensingStatusPhase[];
  services: ServiceID[];
  withTests: boolean;
  origins: string[];
  orgs: Organization[];
  dimensions: Dimension[];
  onlyWithHosts: boolean;
  onlyPocs: boolean;

  private constructor() {
    this.status = [];
    this.services = [];
    this.withTests = false;
    this.onlyWithHosts = false;
    this.onlyPocs = false;
    this.dimensions = [];
    this.origins = [];
    this.orgs = [];
  }

  public static create(): ContractsSummaryFilters {
    return new ContractsSummaryFilters();
  }

  protected get dto(): IContractsSummaryFiltersDto {
    return this.toDto();
  }

  public get isFiltering(): boolean {
    return this.status.length > 0
      || this.services.length > 0
      || this.dimensions.length > 0
      || this.origins.length > 0
      || this.withTests
      || this.onlyWithHosts
      || this.onlyPocs;
  }

  public toDto(): IContractsSummaryFiltersDto {
    return {
      withTests: this.withTests,
      withHosts: this.onlyWithHosts,
      onlyPocs: this.onlyPocs,
      product: this.services.length > 0 ? this.services.join(",") : undefined,
      status: this.status.length > 0 ? this.status.join(",") : undefined,
      origin: this.origins.length > 0 ? this.origins.join(",") : undefined
    }
  }

}