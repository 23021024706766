
import { ResourcePermissions } from '@Module/Resources/Models/ResourcePermissions.model';
import EmoryTrialDialog from '@Service/Emory/Components/TrialDialog/EmoryTrialDialog';
import { Permission } from '@Module/Auth/Models/Roles/Permissions.model';
import { CustomHubResource } from '@Core/Models/CustomHubResource.model';
import { AccessRole } from '@Module/Auth/Models/Roles/AccessRole.model';
import { SessionToken } from '@Module/Auth/Models/SessionToken.model';
import ResourcesStore from '@Module/Resources/Store/Resources.store';
import { Component, MapAction, MapGetter, Watch } from 'types-vue';
import { TrialInfo } from '@Module/Trial/Models/TrialInfo.model';
import TrialStore from '@Module/Trial/Store/Trial.store';
import OrgsStore from '@Module/Orgs/Store/Orgs.store';
import AuthStore from '@Module/Auth/Store/Auth.store';
import { Execution } from '@Core/Models/Execution';
import { ServiceID } from '@Service/ServiceID';
import { Notify } from '@Core/Utils/Notify';
import Vue from 'vue';

const enum ResourceTypeTab {
  Packages = "packages",
  Documentation = "docs",
  Other = "other"
}

const enum CustomResourcesType {
  OnlineDocs = "docs.online"
}

@Component
export default class EmoryHub extends Vue {

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapGetter(TrialStore.Mapping)
  protected emoryTrialInfo: TrialInfo;

  @MapGetter(ResourcesStore.Mapping)
  protected emoryResourcePermissions: ResourcePermissions;
  
  @MapAction(AuthStore.Mapping)
  protected openAuthDialog: (context?: string) => Promise<boolean>;

  @MapAction(TrialStore.Mapping)
  protected fetchTrialInfo: (service: ServiceID) => Promise<TrialInfo>;

  @MapAction(ResourcesStore.Mapping)
  protected fetchResourcePermissions: (service: ServiceID) => Promise<ResourcePermissions>;

  protected serviceId: ServiceID = ServiceID.Emory;

  protected getResPermissions = Execution
    .create(() => this.fetchResourcePermissions(this.serviceId));
  protected getTrialStatus = Execution
    .create(() => this.fetchTrialInfo(this.serviceId));

  @Watch("sessionToken", { immediate: true })
  protected async onSessionChange(token: SessionToken) {
    if (!!token && !token.hasExpired && !this.noOrganization) {
      this.getResPermissions.run();
    } else {
      this.getResPermissions.reset();
    }
  } 

  protected async startTrial() {
    const ctx = "Sign into your account to start your trial period.";
    if ((!!this.sessionToken && !this.sessionToken.hasExpired) || (await this.openAuthDialog(ctx))) {
      let trialInfo = this.emoryTrialInfo;
      if (!trialInfo) {
        trialInfo = await this.getTrialStatus.run();
      }
      if (trialInfo.canApply) {
        (this.$refs.trialDialog as EmoryTrialDialog).open();
      } else {
        Notify.Error({
          title: "Trial period",
          message: trialInfo.statusMessage,
          duration: 6000
        });
      }
    }
  }

  protected signInForDownloads() {
    const ctx = "Sign into your account to download resources from this service.";
    this.openAuthDialog(ctx);
  }

  protected openIssueManager() {
    window.open(process.env.VUE_APP_SERVICE_URL_ISSUES);
  }

  protected get permissions() {
    return {
      CanPurchaseContracts: this.accessRole.can(this.serviceId, Permission.PurchaseContract),
      CanRequestTrial: this.accessRole.can(this.serviceId, Permission.RequestTrial),
      CanManageIssues: this.accessRole.can(this.serviceId, Permission.ManageIssues),
      CanDownloadPackages: this.accessRole.can(this.serviceId, Permission.DownloadPackages),
      CanDownloadOtherPackages: this.accessRole.can(this.serviceId, Permission.DownloadOtherResources)
    }
  }

}