import OrgsLicensingSummaryView from "@Module/Orgs/Components/OrgLicensingSummaryView/OrgsLicensingSummaryView";
import LicensingSummaryView from "@Module/Licensing/Components/ContractsSummaryView/ContractsSummaryView";
import { Component, Watch } from "types-vue";
import Vue from "vue";

const enum SummaryTab {
  Contracts = "contracts",
  Organizations = "organizations"
}

type CurrentPanel = OrgsLicensingSummaryView | LicensingSummaryView;

@Component
export default class LicensingSummary extends Vue {

  protected currentTab: SummaryTab = null;

  @Watch("$route.query.tab", { immediate: true })
  protected onTabQueryChange(tab: SummaryTab) {
    if (!!this.currentTab) { return; }
    this.currentTab = tab || SummaryTab.Contracts;
  }

  @Watch("currentTab")
  protected async onTabClick(newTab: SummaryTab, oldTab: SummaryTab) {
    const visible: CurrentPanel = (newTab === SummaryTab.Contracts 
      ? this.$refs.contractsSummary 
      : this.$refs.orgsLicensingSummary) as CurrentPanel;
    const hidden: CurrentPanel = (oldTab === SummaryTab.Contracts 
      ? this.$refs.contractsSummary 
      : this.$refs.orgsLicensingSummary) as CurrentPanel;

    await hidden?.onNotVisible();
    await visible?.onVisible();
  }

}