export const enum RunningMode {
  Local = "local",
  Development = "development",
  Production = "production"
}

export class Env {

  public static get RunningMode(): RunningMode {
    return process.env.VUE_APP_ENV as RunningMode;
  }

  public static get isLocal(): boolean { return Env.RunningMode === RunningMode.Local; }
  public static get isDevelopment(): boolean { return Env.RunningMode === RunningMode.Development; }
  public static get isProduction(): boolean { return Env.RunningMode === RunningMode.Production; }

}