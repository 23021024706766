import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { Execution } from "@Core/Models/Execution";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { Contract, ContractNotificationsMap } from "@Module/Licensing/Models/Contract.model";
import { License } from "@Module/Licensing/Models/License.model";
import { LicensingStatusPhase } from "@Module/Licensing/Models/LicensingStatus.model";
import LicensingStore, { FetchByIdPayload, FetchLicenseByIdPayload, UpdateContractPayload } from "@Module/Licensing/Store/Licensing.store";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapAction, MapGetter, mixins, Prop } from "types-vue";
import Clone from "lodash.clonedeep";
import { Watch } from "vue-property-decorator";
import { Notify } from "@Core/Utils/Notify";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import OrgSwitchMixin from "@Module/Orgs/Mixins/OrgSwitch.mixin";
import { Utils } from "@Core/Utils/Utils";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import ReportsStore from "@Module/Reports/Store/Reports.store";
import { ReportContract } from "@Module/Reports/Models/ReportContract.model";

const enum DetailsTab {
  Licenses = "licenses",
  Notifications = "notifications"
}

@Component
export default class ContractReportDetails extends mixins(OrgSwitchMixin) {
  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgHasChildren: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(OrgsStore.Mapping)
  protected orgArrayParamSerializer: Serializer<Organization[]>;

  @MapAction(ReportsStore.Mapping)
  protected fetchReportContract: (contractId: number) => Promise<ReportContract>;

  @MapAction(LicensingStore.Mapping)
  protected fetchLicensesByContractId: (payload: FetchLicenseByIdPayload) => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<void>;

  protected error: ConsoleError = null;
  protected contract: ReportContract = null;
  protected expandedRows: number[] = [];
  protected shouldDisplayTenantColumn: boolean = false;
  protected shouldDisplayOwnerColumn: boolean = false;

  protected getContractById = Execution.create(
    (contractId: number) => this.fetchReportContract(contractId));
  

  async mounted() {
    this.contract = await this.getContractById.run(Number(this.$route.params.id));
  }


  protected get contractCode(): string {
    if (this.$mq.md) return Utils.smartTrim(this.contract.externalId, 14);
    else return Utils.smartTrim(this.contract.externalId, 16);
  }

  @Watch("$route.params.id", { immediate: true })
  protected async onIdParamChange() {
    if (!!this.contract && this.contract.id != Number(this.$route.params.id)){
      const contractId = Number(this.$route.params.id);
      if (isNaN(contractId)) {
        const errorMsg = `Invalid contract identifier '${this.$route.params.id}'.`;
        this.error = new ConsoleError(errorMsg, { fatal: true, meta: { contractId: this.$route.params.id } });
        return;
      }

      this.contract = await this.getContractById.run(contractId);
      if (!!this.getContractById.error) {
        const errorMsg = `Contract with identifier ${this.$route.params.id} was not found.`;
        this.error = new ConsoleError(errorMsg, { fatal: true, meta: { contractId: this.$route.params.id } });
        return;
      }
    
    };
    this.error = null;
    await this.$nextTick();

   
      

    const expNotif = this.$route.query.expnotif as string;
    const validExpNotifParam = ["true", "false"].includes(expNotif);
    if (!!this.$route.query.expnotif) {
      const query = Clone(this.$route.query);
      delete query.expnotif;
      this.$router.replace({ query }).catch(e => e);
    }    
  }

  protected onBackClick() {
    this.$router.push({
      name: "cxlink-financial-report",
    });
  }

  @Watch("permissions", {immediate: true, deep: true })
  protected onPermissionsChange() {
    this.getContractById.run(Number(this.$route.params.id));
  }

  protected paymentsNumber(paymentNumber ): string {
    return "Payment " + paymentNumber
  }


  protected get permissions() {
    return {
      CanListFinancialInfo: this.accessRole.can(ServiceID.Console, Permission.ListFinancialInfo)
    }
  }

  protected onViewContract(contractId: number) {
    this.$router.push({
      name: "report-contract-details",
      params: { id: contractId.toString() }
    });
  }

  protected get slotScope() {
    return {
      error: this.getContractById.error,
      loading: this.getContractById.loading,
    }
  }

}