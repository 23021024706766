import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";
import { LeadGenerator } from "@Module/Reports/Models/LeadGenerator.model";


@Component
export default class LeadGeneratorSelector extends Vue {

  @Prop({ type: String, default: "Pick a lead generator" })
  protected placeholder: string;

  @Prop({ type: [Object, Array], default: null })
  protected leadGenerators: LeadGenerator[];

  @Prop({ type: Number, default: null })
  protected value: Number;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected allowBrowsing: boolean;

  protected searchText: string = null;


  protected onLeadGeneratorSelected(leadGenerator: Number ): void {
    const selectedLeadGenerator = leadGenerator || null;
    this.$emit("input", selectedLeadGenerator);
    this.$emit("update:value", selectedLeadGenerator);
    this.$emit("select", selectedLeadGenerator);
    this.$emit("change", selectedLeadGenerator);
    this.searchText = null;
  } 
}