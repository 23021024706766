import { ServiceID } from "@Service/ServiceID";
import DayJS, { Dayjs } from "dayjs";

export enum TrialStatus {
  Available = "Available",
  Active = "Active",
  Expired = "Expired",
  NotAvailable = "Not Available"
} 

export interface ITrialInfoDto {
  status: string;
  expiration: string;
}

export class TrialInfo {
  public readonly status: TrialStatus;
  public readonly expiration: Dayjs;
  public readonly service: ServiceID;

  private constructor(dto: ITrialInfoDto, service: ServiceID) {
    this.status = dto.status as TrialStatus;
    this.expiration = DayJS(dto.expiration);
    this.service = service;
  }

  public static fromDto(dto: ITrialInfoDto, service: ServiceID): TrialInfo {
    return new TrialInfo(dto, service);
  }

  public get canApply(): boolean { 
    return this.status === TrialStatus.Available; 
  }

  public get isActive(): boolean { 
    return this.status === TrialStatus.Active 
      && !!this.expiration && DayJS().isBefore(this.expiration); 
  }

  public get statusMessage(): string {
    switch (this.status) {
      case TrialStatus.Expired: return "You have used your trial period and it has already expired.";
      case TrialStatus.Active: return `Your trial period is currently active. It will expire ${this.remainingTime}.`;
      case TrialStatus.NotAvailable: return `Your trial period is not available.`;
      default: return `You are eligible for trial period.`;
    }
  }

  public get remainingTime(): string {
    return this.expiration.startOf("day").fromNow();
  }

  public get remainingDays(): number {
    return this.expiration.diff(DayJS(), "days");
  }

  public get remainingSeconds(): number {
    return this.expiration.diff(DayJS(), "seconds");
  }

}