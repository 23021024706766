import { BooleanCookie } from "@Core/Models/Cookies/BooleanCookie.model";
import { SessionInitError } from "@Module/Auth/Errors/SessionInitError";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapAction, MapGetter } from "types-vue";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import DayJS, { Dayjs } from "dayjs";
import Vue from "vue";

@Component
export default class NotificationCenter extends Vue {

  @MapGetter(AuthStore.Mapping)
  protected sessionRestoreError: SessionInitError;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapAction(AuthStore.Mapping)
  protected openAuthDialog: (context?: string) => Promise<boolean>;

  protected maintenanceCookie = BooleanCookie.create("console.maintenance-notified", { expiration: 0 }); 
  protected cookiesPolicyCookie = BooleanCookie.create("console.cookies-notified"); 

  protected childrenCount: number = null;

  protected maintenanceDurationHours: number = 4;
  protected maintenanceStart: Dayjs = null;
  protected maintenanceEnd: Dayjs = null;

  protected mounted()  {
    this.updateNotificationsCount();
    this.maintenanceStart = DayJS("2022-03-31T05:00:00.000Z");
    this.maintenanceEnd = this.maintenanceStart.add(this.maintenanceDurationHours, "hours");
  }

  protected updated() {
    this.updateNotificationsCount();
  }

  protected get hasNotifications(): boolean {
    return this.childrenCount > 0;
  }
  
  protected updateNotificationsCount() {
    const container = this.$refs.container as Element;
    this.childrenCount = container.childElementCount;
  }

  protected reloadPage() {
    window.location.reload();
  }

  protected get isSignedIn() {
    return this.authStatus === AuthStatus.SignedIn;
  }

  protected get shouldShowNoOrganizationAlert(): boolean {
    return this.isSignedIn 
        && this.noOrganization 
        && !this.currentRoute.isOrgConfig 
        && !this.currentRoute.isAuth;
  }

  protected get isInMaintenance(): boolean {
    const now = DayJS();
    return now.isAfter(this.maintenanceStart) 
        && now.isBefore(this.maintenanceEnd);
  }

}