import { Utils } from "@Core/Utils/Utils";
import { ReportContract } from "@Module/Reports/Models/ReportContract.model";
import { watch } from "fs";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

@Component
export default class ReportContractsExpand extends Vue {

  @Prop({ type: Object, required: true })
  protected reportContract: ReportContract;

  protected contractExtendedLink: string = "";

  protected get contractCode(): string {
    if (this.$mq.md) return Utils.smartTrim(this.reportContract.externalId, 14);
    else return Utils.smartTrim(this.reportContract.externalId, 16);
  }

  protected onViewContract(contractId: number) {
    this.$emit("select-contract", contractId);
  }
}