import { LoggerConfig, LogLevel } from './LoggerConfig';
import DeepMerge from "lodash.merge";
import { Env } from '../Utils/Env';

export class Logger {
  public static readonly default: Logger = Logger.create();
  public static sharedConfig: LoggerConfig = {} as LoggerConfig;
  private static defaultConfig: LoggerConfig = {
    logLevel: Env.isDevelopment || Env.isLocal
      ? LogLevel.Debug 
      : LogLevel.Error,
    prependLevel: true
  };

  private readonly config: LoggerConfig;

  private get mergedConfig(): LoggerConfig {
    return DeepMerge(Logger.defaultConfig, this.config, Logger.sharedConfig);
  }

  public get logLevel(): LogLevel {
    return this.mergedConfig.logLevel;
  }

  private constructor(config: LoggerConfig = Logger.defaultConfig) {
    this.config = config;
  }

  public static create(config?: LoggerConfig): Logger {
    return new Logger(config);
  }

  public trace(message?: any, ...params: any[]): void {
    this.log(LogLevel.Trace, message, ...params);
  }

  public debug(message?: any, ...params: any[]): void {
    this.log(LogLevel.Debug, message, ...params);
  }

  public info(message?: any, ...params: any[]): void {
    this.log(LogLevel.Info, message, ...params);
  }

  public warning(message?: any, ...params: any[]): void {
    this.log(LogLevel.Warn, message, ...params);
  }

  public error(message?: any, ...params: any[]): void {
    this.log(LogLevel.Error, message, ...params);
  }

  private log(level: LogLevel, message?: any, ...params: any[]): void {
    if (!console) return;
    const config = this.mergedConfig;
    if (level < config.logLevel) return;
    let logMessage = !!config.prependLevel ? `[${LogLevel[level]}] ${message}` : message;
    logMessage = !!config.prefix ? `${config.prefix} ${logMessage}` : logMessage;
    let logParams = params;
    if (level === LogLevel.Error) {
      console.error(logMessage, ...logParams);
    } else if (level === LogLevel.Warn) {
      console.warn(logMessage, ...logParams);
    } else {
      const logStyle = !!config.style ? `background:${config.style.background};color:${config.style.foreground};`  : undefined;
      logMessage = !!logStyle ? `%c${logMessage}` : logMessage;
      logParams = !!logStyle ? [logStyle, ...params] : params;
      if (level === LogLevel.Info) {
        console.info(logMessage, ...logParams);
      } else {
        console.debug(logMessage, ...logParams);
      }
    }
  }

}