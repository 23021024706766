import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { UIContentSizeMode } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { Notification } from "@Module/Notifications/Models/Notification.model";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapAction, MapGetter } from "types-vue";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import MenuStore from "@Core/Store/Menu/Menu.store";
import { Map } from "@Core/Models/Map";
import Vue from "vue";

@Component
export default class Header extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected docsUrl: string;

  @MapGetter(AppSettingsStore.Mapping)
  protected uiContentSizeMode: UIContentSizeMode;

  @MapAction(MenuStore.Mapping)
  protected toggleFloatingMenuVisibility: () => Promise<void>;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRole: OrganizationRole;

  @MapGetter(NotificationsInboxStore.Mapping)
  protected inbox: Notification[];

  @MapGetter(OrgsStore.Mapping)
  protected canSwitchOrg: boolean;

  protected orgSwitcherPopoverVisible: boolean = false;

  protected goToAppSettings() {
    this.$router.push("/settings/application").catch(e => e);
  }

  protected get restoringSession(): boolean {
    return this.authStatus === AuthStatus.RestoringSession;
  }

  protected get signingOut(): boolean {
    return this.authStatus === AuthStatus.SigningOut;
  }

  protected get signedOut(): boolean {
    return this.authStatus === AuthStatus.SignedOut;
  }

  protected get signingIn(): boolean {
    return this.authStatus === AuthStatus.SigningIn;
  }

  protected get signedIn(): boolean {
    return this.authStatus === AuthStatus.SignedIn;
  }

  protected get shouldDisableControls(): boolean {
    return this.signingIn || this.signingOut || this.restoringSession;
  }

  protected get documentationUrl(): string{
    return `${process.env.VUE_APP_SERVICE_URL_DOCS}`
  }

  protected get headerClass(): Map<boolean> {
    return {
      "is-plain": this.isPlainUi,
      "is-airy": this.uiContentSizeMode === "airy",
      "is-docs-embedded": !!this.docsUrl,
    }
  }
  
}