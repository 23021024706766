import { UIContentSizeMode } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import { Component, MapAction, MapGetter, Watch } from "types-vue";
import UsersStore from "@Module/Users/Store/Users.store";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { User } from "@Module/Users/Models/User.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import MenuStore from "@Core/Store/Menu/Menu.store";
import { ElMenu } from "element-ui/types/menu";
import { Map } from "@Core/Models/Map";
import { Console } from "vue-router";
import Vue from "vue";

@Component
export default class Menu extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(AppSettingsStore.Mapping)
  protected collapsedMenu: boolean;

  @MapGetter(AppSettingsStore.Mapping)
  protected expandSubmenus: boolean;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected docsUrl: string;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AppSettingsStore.Mapping)
  protected uiContentSizeMode: UIContentSizeMode;

  @MapAction(AppSettingsStore.Mapping)
  protected toggleCollapsedMenu: () => Promise<void>;

  @MapAction(AppSettingsStore.Mapping)
  protected setCollapsedMenu: (collapsed: boolean) => Promise<void>;

  @MapAction(MenuStore.Mapping)
  protected setFloatingMenuVisible: (visible: boolean) => Promise<void>;

  @MapAction(MenuStore.Mapping)
  protected toggleFloatingMenuVisibility: () => Promise<void>;

  @MapGetter(MenuStore.Mapping)
  protected isFloatingMenuVisible: boolean;

  protected openedSubmenus: string[] = [];
  protected menu: ElMenu = null;
 
  protected mounted() {
    this.menu = this.$refs.menu as ElMenu;
  }

  @Watch("expandSubmenus")
  @Watch("menu.items")
  protected onMenuReady() {
    if (!this.expandSubmenus) { return [] };
    const menuItems = Object.keys((this.$refs.menu as any)?.items || {});
    const submenus = Object.keys((this.$refs.menu as any)?.submenus || {});
    const menuItemsCount = menuItems.length ?? 0; 
    if (menuItemsCount < 10) {
      this.openedSubmenus = [...submenus, ...submenus.map(path => {
        const parent = this.serviceSections.find(r => r.path === path) as Console.ParentRouteDefinition;
        return parent.children.map(child => child.path);
      }).flat()];
    } else {
      this.openedSubmenus = [];
    }
  }

  protected get activeSectionPath(): string {
    return this.currentRoute?.meta.menu.selectionPath || this.currentRoute?.path || null;
  }

  protected get serviceSections(): Console.RouteDefinition[] {
    const sections = this.currentRoute?.getServiceSectionsForRole(this.accessRole) || [];
    return sections;
  }

  @Watch("$mq.lg", { immediate: true })
  protected onLargeScreenSize(isLarge: boolean, wasLarge: boolean) {
    this.setCollapsedMenu(!wasLarge && isLarge);
  }

  @Watch("$mq.md", { immediate: true })
  protected onMediumScreenSize(isMedium: boolean, wasMedium: boolean) {
    if (wasMedium && !isMedium) {
      this.setFloatingMenuVisible(false);
    }
  }

  protected onMenuItemSelected(path: string) {
      if (this.currentRoute.path === path) { return; }
      if (this.currentRoute.replaceNavigation) {
        this.$router.replace(path).catch(e => e);
      } else {
        this.$router.push(path).catch(e => e);
      }
  }

  protected onClickOutsideMenu() {
    if (this.isFloating && this.isFloatingMenuVisible) {
      this.toggleFloatingMenuVisibility();
    }
  }

  protected goToHome() {
    this.setFloatingMenuVisible(false);
    this.$router.replace('/').catch(e => e);
  }

  protected get isFloating(): boolean {
    return this.$mq.md;
  }

  protected get menuClass(): Map<boolean> {
    return { 
      "is-plain": this.isPlainUi, 
      "is-airy": this.uiContentSizeMode === "airy",
      "is-docs-embedded": !!this.docsUrl,
      "is-collapsed": this.collapsedMenu,
      "is-floating": this.isFloating,
      "is-floating-visible": this.isFloatingMenuVisible
    };
  }

  protected get userHasPreviewAccessToRoute(): boolean {
    return this.currentRoute.isAccessibleForUserWithPreviewAccess(this.sessionUser?.previewsAccess);
  }

}