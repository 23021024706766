var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"org-suggestion-list-item"},[_c('div',{staticClass:"flex items-center flex-wrap space-x-16"},[_c('core.details',{staticClass:"labels flex-1 text-ellipsis transition-all",style:({ 'opacity': _vm.loading ? '.4' : '1' }),attrs:{"label":_vm.organization.name,"spacing":"0px"}},[_c('div',{staticClass:"text-ellipsis text-uppercase"},[_c('small',[_vm._v(_vm._s(_vm.organization.uuid))])])]),_c('div',{staticClass:"flex-none space-x-6"},[(!!_vm.invitationNotif)?_c('el-dropdown',{attrs:{"placement":"bottom-end"},on:{"command":function (execution) { return execution.run(); }}},[_c('el-tag',{staticClass:"tag",attrs:{"size":"small","effect":_vm.loading ? 'dark' : 'light',"type":"info","disable-transitions":""}},[_c('core.label',{attrs:{"type":"inherit","icon-spacing":"4px","icon-position":"end"}},[_c('i',{staticClass:"text-semibold",class:_vm.loading ? 'el-icon-loading' : 'el-icon-arrow-down',attrs:{"slot":"icon"},slot:"icon"}),(_vm.declineInvitation.loading)?_c('span',[_vm._v("Declining")]):(_vm.acceptInvitation.loading)?_c('span',[_vm._v("Accepting")]):_c('span',[_vm._v("Invited as "+_vm._s(_vm.invitationNotif.role.name))])])],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',{staticClass:"m-x-16 mb-6 text-disabled"},[_c('small',[_c('core.date-label',{attrs:{"date":_vm.invitationNotif.creation,"label-type":"inherit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var date = ref.date;
return _c('span',{},[_vm._v(_vm._s(_vm._f("capitalize")(date.subtract(1, "second").fromNow())))])}},{key:"tooltip",fn:function(ref){
var formattedDate = ref.formattedDate;
return _c('span',{},[_vm._v(_vm._s(formattedDate))])}}],null,false,10337603)})],1)]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-check","command":_vm.acceptInvitation}},[_vm._v("Accept")]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-close","command":_vm.declineInvitation}},[_vm._v("Decline")])],1)],1):(!!_vm.accessRequestNotif)?_c('el-dropdown',{attrs:{"placement":"bottom-end"},on:{"command":function (execution) { return execution.run(); }}},[_c('el-tag',{staticClass:"tag",attrs:{"size":"small","effect":_vm.loading ? 'dark' : 'light',"type":_vm.loading ? 'info' : 'success',"disable-transitions":""}},[_c('core.label',{attrs:{"type":"inherit","icon-spacing":"4px","icon-position":"end"}},[_c('i',{staticClass:"text-semibold",class:_vm.loading ? 'el-icon-loading' : 'el-icon-arrow-down',attrs:{"slot":"icon"},slot:"icon"}),(_vm.cancelRequestAccess.loading)?_c('span',[_vm._v("Canceling")]):(_vm.requestAccess.loading)?_c('span',[_vm._v("Requesting")]):_c('span',[_vm._v(_vm._s(_vm.$mq.sm ? 'Requested' : 'Access requested'))])])],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',{staticClass:"m-x-16 mb-6 text-disabled"},[_c('small',[_c('core.date-label',{attrs:{"date":_vm.accessRequestNotif.creation,"label-type":"inherit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var date = ref.date;
return _c('span',{},[_vm._v(_vm._s(_vm._f("capitalize")(date.subtract(1, "second").fromNow())))])}},{key:"tooltip",fn:function(ref){
var formattedDate = ref.formattedDate;
return _c('span',{},[_vm._v(_vm._s(formattedDate))])}}])})],1)]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-close","command":_vm.cancelRequestAccess}},[_vm._v("Cancel")]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-top-right","command":_vm.requestAccess}},[_vm._v("Resend")])],1)],1):_c('el-button',{attrs:{"size":"mini","loading":_vm.requestAccess.loading},on:{"click":function($event){return _vm.requestAccess.run()}}},[(_vm.requestAccess.loading)?_c('span',[_vm._v("Requesting")]):_c('span',[_vm._v("Request access")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }