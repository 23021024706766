import { ContractPurchase } from "../Models/ContractPurchase.model";
import { AccessRole, Role } from "../Models/Roles/AccessRole.model";
import { SessionInitError } from "../Errors/SessionInitError";
import { SessionToken } from "../Models/SessionToken.model";

export interface AuthDialogConfig {
  context?: string;
  resolver: (success: boolean) => void;
}

export const enum AuthProcess {
  None = "none",
  AuthChallenge = "challenge",
  ForgotPwdRequest = "forgot.request",
  ForgotPwdChangePwd = "forgot.change-pwd"
}

export const enum AuthStatus {
  RestoringSession = "restoring",
  SigningOut = "signing-out",
  SignedOut = "signed-out",
  SigningIn = "signing-in",
  SignedIn = "signed-in",
  SigningUp = "signing-up"
}

export interface AuthStoreState {
  _authStatus: AuthStatus;
  _sessionToken: SessionToken;
  _sessionRestoreError: SessionInitError;

  _accessRole: AccessRole;
  _roles: Role[];

  _authDialogVisible: boolean;
  _authDialogConfig: AuthDialogConfig;
}