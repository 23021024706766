import { UIPlacement, UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapGetter, Prop } from "types-vue";
import { Console } from "vue-router";
import Vue from "vue";

@Component
export default class PurchasePopover extends Vue {

  @Prop({ type: String, default: "bottom" })
  protected placement: UIPlacement;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: String, default: "primary" })
  protected type: "" | "default" | "primary" | "info" | "danger" | "success" | "warning";

  @Prop({ type: String, default: "Purchase a contract" })
  protected label: string;
  
  @MapGetter(NavigationStore.Mapping)
  protected currentService: Console.ParentRouteDefinition;

  protected get purchaseMethods(): Console.PurchaseMethod[] {
    return this.currentService?.meta.appInfo?.purchaseMethods || [];
  }

  protected onPurchaseMethodSelected(method: Console.PurchaseMethod) {
    window.open(method.url);
  }
} 