import { License } from "@Module/Licensing/Models/License.model";
import { UIPlacement } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class LicenseUsageProgress extends Vue {
    
  @Prop({ type: Object, required: true })
  protected license: License;

  @Prop({ type: String, default: "line" })
  protected type: "line" | "circle" | "dashboard";

  @Prop({ type: Number, default: 500 })
  protected openDelay: number;

  @Prop({ type: String, default: "top" })
  protected placement: UIPlacement;

  @Prop({ type: Number, default: 100 })
  protected width: number;

  protected get isLine(): boolean {
    return this.type === "line";
  }

  protected get progressStyle() {
    return {
      "width": this.isLine ? "100%" : `${this.width}px`,
      "min-width": this.isLine ? "4rem" : "0"
    }
  }

    
}