import LicensingStore, { RegisterEncryptedHostPayload, RegisterUnencryptedHostPayload } from "@Module/Licensing/Store/Licensing.store";
import { ILicenseDto, License } from "@Module/Licensing/Models/License.model";
import FileDownloadMixin from "@Core/Mixins/FileDownload.mixin";
import { Component, MapAction, mixins, Prop } from "types-vue";
import { HttpRequestOptions } from "element-ui/types/upload";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { ServiceID } from "@Service/ServiceID";
import { Notify } from "@Core/Utils/Notify";

const enum RegistrationMode {
  ByFile = "by-file",
  ByKey = "by-key"
}

interface LicenseFile {
  name: string;
  content: string;
  downloaded: boolean;
}

@Component
export default class HostRegistrationDialog extends mixins(FileDownloadMixin) {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Boolean, default: false })
  protected encrypted: boolean;

  @MapAction(LicensingStore.Mapping)
  protected registerUnencryptedHost: (payload: RegisterUnencryptedHostPayload) => Promise<string>;

  @MapAction(LicensingStore.Mapping)
  protected registerEncryptedHost: (payload: RegisterEncryptedHostPayload) => Promise<string>;

  protected license: License = null;
  protected registrationKey: string = null;
  protected registrationMode: RegistrationMode = RegistrationMode.ByFile;
  protected licenseFile: LicenseFile = null;

  protected registerNewHost = Execution.create(
    (license: License, registration: string) => {
      if (this.encrypted) {
        return this.registerEncryptedHost({ 
          service: this.serviceId, 
          data: license.id,
          key: registration
        });
      } else {
        return this.registerUnencryptedHost({
          service: this.serviceId, 
          data: license.id,
          registration: JSON.parse(registration)
        })
      }
    }
  );

  public open(license: License): Promise<boolean> {
    this.license = license;
    const dialog = this.$refs.dialog as Dialog;
    return dialog.open();
  }

  protected onClosed() {
    this.registerNewHost.reset();
    this.registrationKey = null;
    this.licenseFile = null;
    this.registrationMode = RegistrationMode.ByFile;
  }

  protected async process(data: string | HttpRequestOptions) {
    const registrationKey = typeof data === "string" ? data : await this.readFile(data.file);
    const licenseContent = await this.registerNewHost.run(this.license, registrationKey);
    if (!this.registerNewHost.error) {
      this.licenseFile = { name: Date.now().toString(), downloaded: false, content: licenseContent };
      Notify.Success({
        title: "Host Registration Success",
        message: "Your new host has been successfully registered."
      });
    }
  }

  public get forgotDownload(): boolean {
    return !!this.licenseFile && !this.licenseFile.downloaded;
  }

  protected async downloadLicenseFile(): Promise<void> {
    const content = this.licenseFile.content;
    const fileName = this.licenseFile.name;
    this.downloadFile(fileName, content);
    this.licenseFile.downloaded = true;
  }

  protected readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('error', reject);
      reader.addEventListener('load', () => {
        resolve(reader.result as string)
      });
      reader.readAsText(file);
    });
  }

  protected async dialogCloseGuard(close: VoidFunction) {
    if (this.forgotDownload) {
      try {
        await this.$confirm(
          "The registration process is complete, however you have not downloaded the license file.",
          "License download warning",
          { confirmButtonText: "Close and skip download", cancelButtonText: "Cancel", type: "warning" });
      } catch { return; }
    }
    close()
  }

}