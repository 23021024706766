import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

@Component
export default class TrialDimensionSlot extends Vue {

  @Prop({ type: Number, required: true })
  protected index: number;

  @Prop({ type: Array, required: true })
  protected dimensions: Dimension[];

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  protected value: Dimension = null;
  protected popoverVisible: boolean = false;

  @Watch("dimensions", { immediate: true })
  protected onDimensionsChange(dimensions: Dimension[]) {
    if (dimensions.length === 1) {
      this.onDimensionSelected(dimensions[0]);
    }
  }

  protected get slotClass() {
    return {
      "is-disabled": this.disabled || this.loading,
      "is-fulfilled": !!this.value
    }
  }

  protected get hasDimensions(): boolean {
    return this.dimensions.length > 0;
  }

  protected get isSingleOption(): boolean {
    return this.dimensions.length === 1;
  }

  protected onDimensionSelected(dim: Dimension) {
    this.value = dim;
    this.$emit("select", this.index, dim);
    this.popoverVisible = false;
  }

}