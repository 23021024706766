import { Execution } from "@Core/Models/Execution";
import { ElNotificationOptions } from "element-ui/types/notification";
import { Component, MapAction, Prop, Vue } from "types-vue";
import { Notification } from "../Models/Notification.model";
import NotificationsInboxStore from "../Store/NotificationsInbox.store";
import NotificationsOutboxStore from "../Store/NotificationsOutbox.store";

export interface UiSuccessNotificationMap {
  Accept: ElNotificationOptions;
  Decline: ElNotificationOptions;
  Cancel?: ElNotificationOptions;
}

export interface INotificationContent {
  getSuccessNotifConfig(): UiSuccessNotificationMap;
}

@Component
export default class NotificationContentMixin extends Vue {

  @Prop({ type: Object, required: true })
  protected notification: Notification;

  @Prop({ type: Boolean, default: false })
  protected hideTypeLabel: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected isInbox: boolean;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptNotification: (notification: Notification) => Promise<Notification>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected declineNotification: (notification: Notification) => Promise<Notification>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected cancelNotification: (notification: Notification) => Promise<Notification>;

  protected cancelExecution = Execution.create(() => this.cancelNotification(this.notification)).throws();
  protected acceptExecution = Execution.create(() => this.acceptNotification(this.notification)).throws();
  protected declineExecution = Execution.create(() => this.declineNotification(this.notification)).throws();

  public get canCancel(): boolean { return true; }
  public get canResend(): boolean { return true; }
  public get canAccept(): boolean { return true; }
  public get canDecline(): boolean { return true; }
  public get isConfiguringResponse(): boolean { return false; }

  public async cancel(): Promise<boolean> {
    await this.cancelExecution.run();
    return true;
  }

  public async onCanceled(): Promise<void> {}

  public async accept(): Promise<boolean> {
    await this.acceptExecution.run();
    return true;
  }

  public async onAccepted(): Promise<void> {}

  public async decline(): Promise<boolean> { 
    await this.declineExecution.run();
    return true;
  }

  public async onDeclined(): Promise<void> {}

  public async resend(): Promise<void> {}

}