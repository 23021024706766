import { AbstractCookie, CookieOptions } from "./AbstractCookie.model";

export class BooleanCookie extends AbstractCookie<boolean> {

  public static create(name: string, options?: CookieOptions<boolean>) {
    return new BooleanCookie(name, options);
  }

  protected parseCookie(value: any): boolean {
    return value === "true";
  }
  
}