import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class Card extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @Prop({ type: Boolean, default: null })
  protected plain: Boolean;
  
  @Prop({ type: Boolean, default: false })
  protected bordered: boolean;

  @Prop({ type: String, default: "large" })
  protected borderRadius: UISize;

  @Prop({ type: Boolean, default: false })
  protected dashed: boolean;

  @Prop({ type: Boolean, default: false })
  protected noPadding: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: String, default: null })
  protected title: string;

  @Prop({ type: Boolean, default: false })
  protected selectable: boolean;

  @Prop({ type: Boolean, default: false })
  protected selected: boolean;

  protected get isPlain(): boolean {
    return this.plain as boolean ?? this.isPlainUi;
  }

  protected get cardClass() {
    return [{ 
        "is-plain": this.isPlain,
        "is-headerless": !this.$slots.header && !this.title,
        "is-bordered": this.bordered,
        "is-no-padding": this.noPadding,
        "is-compact": this.compact,
        "is-selectable": this.selectable,
        "is-selected": this.selected,
        "is-dashed": this.dashed
      }, 
      `is-border-radius-${this.borderRadius}`
    ]
  }

}