import NotificationContentMixin, { INotificationContent, UiSuccessNotificationMap } from "@Module/Notifications/Mixins/NotificationContentMixin";
import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { Component, MapAction, mixins, Prop } from "types-vue";
import { ServiceID } from "@Service/ServiceID";

@Component
export default class LicenseSharingNotifContent extends mixins(NotificationContentMixin) implements INotificationContent {

  @Prop({ type: Object, required: true })
  protected notification: LicenseSharingNotification;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptExchangeNotification: (notification: LicenseSharingNotification) => Promise<void>;

  public get canResend(): boolean {
    return false;
  }

  public getSuccessNotifConfig(): UiSuccessNotificationMap {
    return {
      Accept: {
        title: "License sharing accepted", 
        message: `License sharing request successfully accepted.`,
        duration: 6000
      },
      Decline: {
        title: "License sharing declined", 
        message: `License sharing request successfully declined.`,
        duration: 6000
      },
      Cancel: {
        title: "License sharing cancelled", 
        message: `License sharing request has been successfully cancelled.`
      }
    }
  }

  public get licenseService(): string {
    return ServiceID.nameOf(this.notification.service);
  }

  public async accept(): Promise<boolean> {
    await this.acceptExchangeNotification(this.notification);
    return true;
  }

}