import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { License } from "@Module/Licensing/Models/License.model";
import { Component, MapGetter, Prop } from "types-vue";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import Vue from "vue";

type ExchangeNotification = LicenseSharingNotification | LicenseReturnNotification;

@Component
export default class LicenseSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected license: License;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseSharingOutbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseReturnOutbox: LicenseReturnNotification[];

  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseSharingInbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseReturnInbox: LicenseReturnNotification[];

  protected get outboxNotif(): ExchangeNotification {
    return [...this.orgLicenseSharingOutbox, ...this.orgLicenseReturnOutbox]
      .find(notif => notif.isLicenseIncluded(this.license));
  }

  protected get inboxNotif(): ExchangeNotification {
    return [...this.licenseSharingInbox, ...this.licenseReturnInbox]
      .find(notif => notif.isLicenseIncluded(this.license));
  }

  protected get permissions() {
    return {
      CanListHosts: this.accessRole.can(this.license.serviceId, Permission.ListHosts)
    }
  }

  protected get isLicenseSharedToMe(): boolean {
    return this.license.isShared && this.license.isSharedToOrg(this.currentOrg);
  }

  protected get isLicenseSharedByMe(): boolean {
    return this.license.isShared && !this.license.isSharedToOrg(this.currentOrg)
  }

  protected get hasExchangeAlerts(): boolean {
    return this.license.isShared || !!this.outboxNotif || !!this.inboxNotif;
  }

}