export const enum Permission {
  AccessLegacyService = "AccessLegacyService",
  ActivateInstance = "ActivateInstance",
  AcceptOrgAccessRequest = "AcceptOrgAccessRequest",
  CreateAppUsers = "CreateAppUsers",
  CreateChildOrg = "CreateChildOrg",
  CreateContract = "CreateContract",
  CreateInstanceEndpoints = "CreateInstanceEndpoints",
  CreateInstanceWorkmodes = "CreateInstanceWorkmodes",
  CreateOrgUsers = "CreateOrgUsers",
  CancelOrgInvitation = "CancelOrgInvitation",
  DeactivateInstance = "DeactivateInstance",
  DeleteAppUsers = "DeleteAppUsers",
  DeleteInstanceEndpoints = "DeleteInstanceEndpoints",
  DeleteInstanceWorkmodes = "DeleteInstanceWorkmodes",
  DeleteOrgUsers = "DeleteOrgUsers",
  DownloadOtherResources = "DownloadOtherResources",
  DownloadPackages = "DownloadPackages",
  EditOrg = "EditOrg",
  EditAppUsers = "EditAppUsers",
  EditContract = "EditContract",
  EditContractNotifications = "EditContractNotifications",
  EditInstanceEndpoints = "EditInstanceEndpoints",
  EditInstanceIntegration = "EditInstanceIntegration",
  EditInstanceWorkmodes = "EditInstanceWorkmodes",
  EditOrgUsers = "EditOrgUsers",
  ExportBackups = "ExportBackups",
  ExportLicensingSummary = "ExportLicensingSummary",
  FullAccess = "FullAccess",
  InviteOrgMembers = "InviteOrgMembers",
  ListAppUsers = "ListAppUsers",
  ListBackups = "ListBackups",
  ListContracts = "ListContracts",
  ListHosts = "ListHosts",
  ListInstanceEndpoints = "ListInstanceEndpoints",
  ListInstanceWorkmodes = "ListInstanceWorkmodes",
  ListLicenses = "ListLicenses",
  ListLicensingSummary = "ListLicensingSummary",
  ListManagedPermissions = "ListManagedPermissions",
  ListOrgInvitations = "ListOrgInvitations",
  ListOrgUsers = "ListOrgUsers",
  ManageIssues = "ManageIssues",
  MarkOrgAsTest = "MarkOrgAsTest",
  PurchaseContract = "PurchaseContract",
  RecoverLicenseFile = "RecoverLicenseFile",
  RegisterHost = "RegisterHost",
  RequestTrial = "RequestTrial",
  ShareLicenses = "ShareLicenses",
  UnregisterHost = "UnregisterHost",
  ViewInstanceIntegration = "ViewInstanceIntegration",
  ListFinancialInfo = "ListFinancialInfo",
  AddFinancialInfo = "AddFinancialInfo",
  EditFinancialInfo = "EditFinancialInfo",
  CreateResources = "CreateResources",
}