import Vue from "vue";

export interface CookieOptions<T> {
  expiration?: string | number | Date;
  default?: T | null;
}

export abstract class AbstractCookie<T> {
  protected readonly name: string;
  protected readonly default: T | null;
  protected readonly expiration: string | number | Date;
  protected _value: T = null;

  protected constructor(name: string, options?: CookieOptions<T>) {
    this.name = name;
    this.expiration = options?.expiration ?? Infinity;
    this.default = options?.default ?? null;
    this.read();
  }

  private get options(): CookieOptions<T> {
    return {
      expiration: this.expiration,
      default: this.default
    }
  }

  public read(): T | null {
    const storedValue = Vue.$cookies.get(this.name);
    this._value = this.parseCookie(storedValue, this.options);
    return this._value;
  }

  public store(value: T) {
    Vue.$cookies.set(this.name, value, this.expiration);
    this._value = value;
  }

  public get value(): T {
    return this._value;
  }

  protected abstract parseCookie(value: any, options: CookieOptions<T>): T | null;
}