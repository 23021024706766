import { Dayjs } from "dayjs";
import { MapActionOptions } from "types-vue/lib/vuex/MapAction";
import { MapGetterOptions } from "types-vue/lib/vuex/MapGetter";
import { ActionContext } from "vuex";

export interface GroupByOptions {
  skipUndefined: boolean;
  skipNull: boolean;
  skipNoValue: boolean;
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> =
    Pick<T, Exclude<keyof T, Keys>> 
    & {
        [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
    }[Keys]

type PublicMapping = MapGetterOptions & MapActionOptions;
export class MappingOptions implements PublicMapping {
  public readonly namespace?: string;
  
  constructor(namespace?: string) {
    this.namespace = namespace;
  }

  public action(name: string): string {
    return !!this.namespace ? `${this.namespace}/${name}` : name;
  }

  public getter(name: string): string {
    return !!this.namespace ? `${this.namespace}/${name}` : name;
  }


  public rootAction(name: string): (ctx: ActionContext<any, any>, payload?: any) => Promise<any> {
    const prop = !!this.namespace ? `${this.namespace}/${name}` : name;
    return (ctx: ActionContext<any, any>, payload: any) => ctx.dispatch(prop, payload, { root: true });
  }

  public rootGetter(name: string): (ctx: ActionContext<any, any>) => any {
    const prop = !!this.namespace ? `${this.namespace}/${name}` : name;
    return (ctx: ActionContext<any, any>) => ctx.rootGetters[prop];
  }

}

export class Utils {

  public static smartTrim(text: string, maxLength: number, clamp: string = "...") {
    if (!text) return text;
    if (maxLength < 1) return text;
    if (text.length <= maxLength) return text;
    if (maxLength == 1) return text.substring(0,1) + clamp;

    var midpoint = Math.ceil(text.length / 2);
    var toremove = text.length - maxLength;
    var lstrip = Math.ceil(toremove/2);
    var rstrip = toremove - lstrip;
    return text.substring(0, midpoint-lstrip) + clamp + text.substring(midpoint+rstrip);
  }  

  public static sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public static passes(probability = 1.0): boolean {
    return Math.random() < probability;
  }

  public static throw(probability = 1.0): void {
    if (this.passes(probability)) {
      throw new Error("Mocked up error.");
    }
  }

  public static createVuexMappingOptions(namespace: string, ): MappingOptions {
    return new MappingOptions(namespace);
  }

  public static toSentenceCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  public static sanitizeUrl(url: string): string {
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  public static groupBy<TItem extends object, TKey extends keyof any>(
    items: TItem[], 
    group: string | ((item: TItem) => TKey),
    options: Partial<GroupByOptions> = {}
  ): Record<TKey, TItem[]> {
    options.skipUndefined ??= false;
    return items.reduce((prev, curr) => {
      let itemGroup = typeof group === "string" 
        ? this.getNestedProperty(curr, group) 
        : group(curr);
      if (options.skipNoValue && itemGroup == undefined) { return prev; } 
      if (options.skipUndefined && itemGroup === undefined) { return prev; } 
      if (options.skipNull && itemGroup === null) { return prev; } 
      (prev[itemGroup] = prev[itemGroup] || []).push(curr);
      return prev;
    }, {} as Record<TKey, TItem[]>);
  }

  public static getNestedProperty(item: object, path: string): any {
    return path
      .split(".")
      .reduce((prev, curr) => prev && prev[curr], item);
  }

  public static async poll<T>(
    fn: (...args: any[]) => Promise<T>,
    config: IntervalConfig<T>
  ): Promise<void> {
    let token: IntervalToken;

    if (!!config.interval.beforeExecute) {
      token = await config.interval.beforeExecute();
      console.log(`Checking leading. Sleeping ${token.delay} ms.`);
      if (token.shouldFinish) { return; }
    }

    const result = await fn();

    if (!!config.interval.afterExecute) {
      token = await config.interval.afterExecute(result);
      console.log(`Checking trailing. Sleeping ${token.delay} ms.`);
      if (token.shouldFinish) { return; }
    }
    
    await Utils.sleep(token.delay);
    Utils.poll(fn, config);
  }

  public static isString(target: any): target is string {
    return typeof target === "string";
  }

  public static isNumber(target: any): target is number {
    return typeof target === "number";
  }

  public static isObject(target: any): target is object {
    return typeof target === "object";
  }

  public static isBoolean(target: any): target is boolean {
    return typeof target === "boolean";
  }

  public static isFunction(target: any): target is Function {
    return typeof target === "function";
  }
  
  public static isArray(target: any): target is any[] {
    return Array.isArray(target);
  }

  public static get browserHasNotificationsSupport(): boolean {
    return window.Notification !== undefined;
  }

  public static get browserHasPersistentNotificationsSupport(): boolean {
    return window.Notification !== undefined 
      && window.ServiceWorkerRegistration !== undefined;
  }

  public static get hasNotificationPermission(): boolean {
    return Notification.permission === "granted";
  }

  public static compareDayJs(a: Dayjs, b: Dayjs): number {
    return a.diff(b);
  }

  public static checkParentsWithOverflow(querySelector: string) {
    let parent = document.querySelector(querySelector).parentElement;
    while (parent) {
      const hasOverflow = getComputedStyle(parent).overflow;
      if (hasOverflow !== 'visible') {
          console.log(hasOverflow, parent);
      }
      parent = parent.parentElement;
    }
  }
}

interface IntervalConfig<T> {
  interval: RequireAtLeastOne<{
    beforeExecute?: () => IntervalToken | Promise<IntervalToken>;
    afterExecute?: (result: T) => IntervalToken | Promise<IntervalToken>;
  }, "beforeExecute" | "afterExecute">
}

export class IntervalToken {
  public readonly delay: number;

  private constructor(delay: number) {
    this.delay = delay;
  }

  public get shouldFinish(): boolean {
    return this.delay === -1;
  }

  public static repeat(delay: number = 0): IntervalToken {
    return new IntervalToken(delay);
  }

  public static finish(): IntervalToken {
    return new IntervalToken(-1);
  }

}