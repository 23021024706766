import { AbapSuiteLicenseFilters } from "@Service/AbapSuite/Models/Filters/AbapSuiteLicenseFilters.model";
import LicensingStore, { FetchLicenseByIdPayload } from "@Module/Licensing/Store/Licensing.store";
import { AbapSuiteLicense } from "@Service/AbapSuite/Models/AbapSuiteLicense.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, MapAction, MapGetter } from "types-vue";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";

@Component
export default class AbapSuiteContractDetails extends Vue {
  protected serviceId: ServiceID = ServiceID.AbapSuite;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapAction(LicensingStore.Mapping)
  protected fetchLicenseById: (payload: FetchLicenseByIdPayload) => Promise<void>;
  
  protected filters = AbapSuiteLicenseFilters.create();

  protected viewDetails(license: AbapSuiteLicense) {
    this.$router.push({ 
      name: "abap-suite-license-details", 
      params: { id: license.id.toString() }
    });
  }

  protected onBackClick(prevRoute: ConsoleRoute) {
    if (!!prevRoute) {
      this.$router.back();
    } else {
      this.$router.replace("/abap-suite/contracts");
    }
  }

  protected get permissions() {
    return {
      CanRegisterHosts: this.accessRole.can(this.serviceId, Permission.RegisterHost)
    }
  }

}