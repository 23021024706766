import { IResourcePermissionsDto, ResourcePermissions } from "../Models/ResourcePermissions.model";
import { ServiceID, ServiceMap, ServicePayload } from "@Service/ServiceID";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import ResourcesApi from "../API/Resources.api";
import { ResourcesStoreState } from "./Resources.state";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";
import { ICreateReleaseDto, Release } from "../Models/Release.model";
import { ICreatePlatformDto, Platform } from "../Models/Platform.model";
import { Category } from "../Models/Categories.model";
import { ICreateResourceDto} from "../Models/Resource.model";
import { ICreateProductVersionDto, ProductVersion } from "../Models/ProductVersion.model";

export const enum ResourcesStoreAction {
  FetchResourcePermissions = "fetchResourcePermissions",
  FetchReleases = "fetchReleases",
  fetchProductVersions = "fetchProductVersions",
  fetchCategories = "fetchCategories",
  SetResourcePermissions = "setResourcePermissions",
  AddProductVersion = "addproductVersion"
}

const enum ResourcesStoreCommit {
  SetResourcePermissions = "_setResourcePermissions",
  SetResourcePermissionsMap = "_setResourcePermissionsMap",
  SetManageResourcesDialogVisible = "_setManageResourcesDialogVisible",
  SetReleases = "_setReleases",
  SetCategories = "_setCategories",
  SetAllPlatforms  = "_setAllPlatforms",
  SetAllVersions = "_setAllVersions"
}

export type SetResourcePermissionsPayload = ServicePayload<IResourcePermissionsDto>;

@Module({ namespaced: true })
export default class ResourcesStore extends VuexModule<ResourcesStoreState> implements ResourcesStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Resources");

  public _allPlatforms: Platform[] = [];
  public _releases: Release[] = [];
  private _categories: Category[] = [];
  public _allVersions: ProductVersion[] = [];
  public _resourcePermissionsMap: ServiceMap<ResourcePermissions> = {};
  public _manageResourcesDialogVisible: boolean = false;

  @Getter()
  public allPlatforms(): Platform[] {
    return this._allPlatforms;
  }

  @Getter()
  public releases(): Release[] {
    return this._releases;
  }

  @Getter()
  public categories(): Category[] {
    return this._categories;
  }

  @Getter()
  public allVersions(): ProductVersion[] {
    return this._allVersions;
  }


  @Mutation()
  protected _setReleases(releases: Release[]) {
    this._releases = releases;
  }

  @Mutation()
  protected _setAllPlatforms(platforms: Platform[]) {
    this._allPlatforms = platforms;
  }


  @Mutation()
  protected _setCategories(categories: Category[]) {
    this._categories = categories;
  }

  @Mutation()
  protected _setAllVersions(productVersions: ProductVersion[]) {
    this._allVersions = productVersions;
  }

  @Getter()
  public manageResourcesDialogVisible() { 
    return this._manageResourcesDialogVisible;
  }

  @Getter()
  public resourcePermissionsMap(): ServiceMap<ResourcePermissions> {
    return this._resourcePermissionsMap;
  }

  @Getter()
  public emoryResourcePermissions(): ResourcePermissions | null {
    return this._resourcePermissionsMap[ServiceID.Emory] || null;
  }

  @Getter()
  public connectorResourcePermissions(): ResourcePermissions | null {
    return this._resourcePermissionsMap[ServiceID.Connector] || null;
  }

  @Getter()
  public abapSuiteResourcePermissions(): ResourcePermissions | null {
    return this._resourcePermissionsMap[ServiceID.AbapSuite] || null;
  }

  @Mutation()
  protected _setManageResourcesDialogVisible(visible: boolean) { 
    this._manageResourcesDialogVisible = visible;
  }

  @Mutation()
  protected _setResourcePermissionsMap(permissionsMap: ServiceMap<ResourcePermissions>) {
    this._resourcePermissionsMap = permissionsMap;
  }

  @Mutation()
  protected _setResourcePermissions(permissions: ResourcePermissions) {
    Vue.set(this._resourcePermissionsMap, permissions.service, permissions);
  }

  @Action({ commit: ResourcesStoreCommit.SetAllPlatforms })
  public async fetchPlatforms(service: ServiceID): Promise<Platform[]> {
    const dtos = await ResourcesApi.getPlatforms(service);
    return dtos.map(Platform.fromDto);
  }

  @Action({ commit: ResourcesStoreCommit.SetCategories })
  public async fetchCategories(): Promise<Category[]> {
    const dtos = await ResourcesApi.getCategories();
    return dtos.map(Category.fromDto);
  }

  @Action({ commit: ResourcesStoreCommit.SetAllVersions })
  public async fetchProductVersions(service: ServiceID): Promise<ProductVersion[]> {
    const dtos = await ResourcesApi.getProductVersions(service);
    return dtos.map(ProductVersion.fromDto);
  }

  @Action({ commit: ResourcesStoreCommit.SetManageResourcesDialogVisible })
  public setManageResourcesDialogVisible(visible: boolean): boolean {
    return visible;
  }

  @Action({ commit: ResourcesStoreCommit.SetManageResourcesDialogVisible })
  public openManageResourcesDialog(): boolean {
    return true;
  }

  @Action()
  protected async createProductVersion(data: ICreateProductVersionDto): Promise<ProductVersion> {
    const dto = await ResourcesApi.createProductVersion(data);
    return ProductVersion.fromDto(dto);
  }

  @Action()
  protected async createPlatform(platform: ICreatePlatformDto): Promise<Platform> {
    const dto = await ResourcesApi.createPlatform(platform);
    return Platform.fromDto(dto);
  }

  @Action()
  protected async createRelease(data: ICreateReleaseDto): Promise<Release> {
    const dto = await ResourcesApi.createRelease(data);
    return Release.fromDto(dto);
  }

  @Action()
  protected async getUploadLink(data: ICreateResourceDto): Promise<string> {
    const url = await ResourcesApi.getUploadLink(data);
    return url;
  }

  @Action({ commit: ResourcesStoreCommit.SetManageResourcesDialogVisible })
  public closeManageResourcesDialog(): boolean {
    return false;
  }

  @Action({ commit: ResourcesStoreCommit.SetResourcePermissionsMap })
  public async clearResourcesStore(): Promise<ServiceMap<ResourcePermissions>> {
    return {};
  }

  @Action({ commit: ResourcesStoreCommit.SetResourcePermissions })
  public async fetchResourcePermissions(serviceId: ServiceID): Promise<ResourcePermissions> {
    const dto = await ResourcesApi.getPermissions(serviceId);
    return ResourcePermissions.fromDto(dto, serviceId);
  }

  @Action({ commit: ResourcesStoreCommit.SetResourcePermissions })
  public async setResourcePermissions(payload: SetResourcePermissionsPayload): Promise<ResourcePermissions> {
    return ResourcePermissions.fromDto(payload.data, payload.service);
  }


}