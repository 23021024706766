import { AbapSuiteHostFilters } from "@Service/AbapSuite/Models/Filters/AbapSuiteHostFilters.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { AbapSuiteHost } from "@Service/AbapSuite/Models/AbapSuiteHost.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class AbapSuiteHostFiltersPanelExtension extends Vue {

  @Prop({ type: Object, required: true })
  protected filters: AbapSuiteHostFilters;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Array, default: null })
  protected data: AbapSuiteHost[];

}