import { Organization } from "@Module/Orgs/Models/Organization.model";
import { ITrialInfoDto } from "@Module/Trial/Models/TrialInfo.model";
import { TrialStatusApiError } from "../Errors/TrialStatusApiError";
import AxiosAPI from "@Core/API/AxiosAPI";
import { GetTrialDimensionsApiError } from "../Errors/GetTrialDimensionsApiError";
import { StartTrialApiError } from "../Errors/StartTrialApiError";
import { IDimensionDto, IDimensionUnitsDto } from "@Module/Licensing/Models/Dimension.model";
import { IContractDto } from "@Module/Licensing/Models/Contract.model";
import { ServiceID } from "@Service/ServiceID";

export interface IStartTrialResponseDto {
  trial: ITrialInfoDto;
  contract: IContractDto;
}

class TrialAPI extends AxiosAPI {
  private static instance: TrialAPI;

  public static get Instance(): TrialAPI {
    return this.instance || (this.instance = new this());
  }

  protected url(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }
  
  public async getTrialInfo(service: ServiceID): Promise<ITrialInfoDto> {
    try {
      const url = this.url("contracts/trial");
      const response = await this.axios.get(url, { 
        params: { product: service }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving your trial information.`;
      throw new TrialStatusApiError(message, { 
        cause: error, 
        meta: { service }  
      });
    }
  }

  public async requestTrialStart(
    service: ServiceID, 
    org: Organization, 
    dimensions: IDimensionUnitsDto[]
  ): Promise<IStartTrialResponseDto> {
    try {
      const url = this.url("contracts/trial");
      const response = await this.axios.post(url, {
        product: service, 
        orgname: org.name, 
        orguuid: org.uuid, 
        dimensions
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred requesting your trial start. Please try again later.`;
      throw new StartTrialApiError(message, {
        cause: error,
        meta: { service, org, dimensions }
      });
    }
  }

  public async getTrialDimensions(service: ServiceID): Promise<IDimensionDto[]> {
    try {
      const url = this.url("dimensions");
      const response = await this.axios.get(url, {
        params: { trial: true, product: service }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving the available trial dimensions.`;
      throw new GetTrialDimensionsApiError(message, { 
        cause: error, 
        meta: { service }  
      });
    }
  }
}

export default TrialAPI.Instance;