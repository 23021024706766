import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class OriginSelector extends Vue {

  @Prop({ type: [String, Array], required: true })
  protected value: ContractOrigin | ContractOrigin[];

  @Prop({ type: String, default: "Filter by origin" })
  protected placeholder: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected filterable: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Array, default: () => ContractOrigin.All })
  protected options: ContractOrigin[];

  protected get sortedOptions(): ContractOrigin[] {
    return this.options.sort((a, b) => this.originName(a).localeCompare(this.originName(b)));
  }

  protected onOriginSelected(origin: ContractOrigin | ContractOrigin[]) {
    this.$emit("input", origin);
  }

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }

}