import { EmoryHostFilters } from "@Service/Emory/Models/Filters/EmoryHostFilters.model";
import { EmoryLicense } from "@Service/Emory/Models/EmoryLicense.model";
import { EmoryHost } from "@Service/Emory/Models/EmoryHost.model";
import { ServiceID } from "@Service/ServiceID";
import { Utils } from "@Core/Utils/Utils";
import { Component } from "types-vue";
import Vue from "vue";

@Component
export default class EmoryLicenseDetails extends Vue {
  protected serviceId = ServiceID.Emory;
  protected filters = EmoryHostFilters.create();

  protected clusterId(host: EmoryHost): string {
    if (this.$mq.md) return Utils.smartTrim(host.clusterId, 8);
    else return Utils.smartTrim(host.clusterId, 12);
  }

  protected clientVersion(host: EmoryHost): string {
    return Utils.smartTrim(host.clientVersion || "", 20);
  }
  
  protected viewContract(license: EmoryLicense) {
    this.$router.push({
      name: "backup-contract-details",
      params: { id: license.contractId.toString() }
    });
  }

}