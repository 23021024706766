import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class Alert  extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;
  
  @Prop({ type: String, default: null })
  protected title: string;
  
  @Prop({ type: String, default: null })
  protected description: string;

  @Prop({ type: Boolean, default: true })
  protected closable: boolean;

  @Prop({ type: String, default: "" })
  protected closeText: string;

  @Prop({ type: Boolean, default: true })
  protected showIcon: boolean;

  @Prop({ type: Boolean, default: false })
  protected subtle: boolean;

  @Prop({ type: Boolean, default: null })
  protected plain: Boolean;
  
  @Prop({ type: Boolean, default: false })
  protected floating: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: String, default: "info" })
  protected type: "info" | "success" | "error" | "warning";

  @Prop({ type: Boolean, default: false })
  protected overBackground: boolean;

  @Prop({ type: String, default: "50%" })
  protected top: string;

  @Prop({ type: String, default: "0" })
  protected offset: string;

  protected visible: boolean = true;

  protected get isPlain(): boolean {
    return this.plain as boolean ?? this.isPlainUi;
  }

  public close() {
    this.visible = false;
    this.$emit("close");
  }

  protected get alertStyle() {
    return {
      "margin-top": this.offset,
      "top": this.top
    }
  }

  protected get alertClass() {
    return {
      "is-plain": this.isPlain,
      "is-floating": this.floating,
      "is-subtle": this.subtle,
      "is-compact": this.compact,
      "is-over-background": this.overBackground,
      "is-no-title": !this.title && !this.$slots.title,
      "is-no-icon": !this.showIcon
    }
  }

}