import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Host } from "@Module/Licensing/Models/Host.model";
import { HostFilters } from "@Module/Licensing/Models/HostFilters.model";
import { License } from "@Module/Licensing/Models/License.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class HostFiltersPanel extends Vue {

  @Prop({ type: Object, required: true })
  protected value: HostFilters;

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Array, default: null })
  protected data: Host[];

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;
  
  @Prop({ type: String, default: null })
  protected size: UISize;

  @MapGetter(LicensingStore.Mapping)
  protected licenses: ServiceMap<License[]>;

  protected get serviceLicenses(): License[] {
    return this.licenses[this.serviceId] || [];
  }

  protected get serviceLicenseHosts(): Host[] {
    return this.data || this.serviceLicenses.map(license => license.hosts).flat();
  }

  protected get slotScope() {
    return {
      filters: this.value,
      serviceId: this.serviceId,
      data: this.serviceLicenseHosts,
      disabled: this.disabled,
      size: this.size
    }
  }

}