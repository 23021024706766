import { INotificationDto, Notification, NotificationRef, NotificationResponseStatus, NotificationStatus, NotificationType } from "../Models/Notification.model";
import { RespondOrgAccessRequestNotificationApiError } from "../Errors/RespondOrgAccessRequestNotificationApiError";
import { RespondContractPurchaseNotificationApiError } from "../Errors/RespondContractPurchaseNotificationApiError";
import { GetOrgOutboxNotificationsApiError } from "../Errors/GetOrgOutboxNotificationsApiError";
import { GetMyOutboxNotificationsApiError } from "../Errors/GetMyOutboxNotificationsApiError";
import { RespondOrgInvitationLinkApiError } from "../Errors/RespondOrgInvitationLinkApiError";
import { OrgInvitationNotificationLink } from "../Models/OrgInvitationNotification.model";
import { GetNotificationsInboxApiError } from "../Errors/GetNotificationsInboxApiError";
import { SendOrgAccessRequestApiError } from "../Errors/SendOrgAccessRequestApiError";
import { SendContractPurchaseApiError } from "../Errors/SendContractPurchaseApiError";
import { RespondNotificationApiError } from "../Errors/RespondNotificationApiError";
import { SendLicenseExchangeApiError } from "../Errors/SendLicenseExchangeApiError";
import { SendOrgInvitationApiError } from "../Errors/SendOrgInvitationApiError";
import { ContractPurchase } from "@Module/Auth/Models/ContractPurchase.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { License } from "@Module/Licensing/Models/License.model";
import { ServiceID } from "@Service/ServiceID";
import AxiosAPI from "@Core/API/AxiosAPI";

export interface LicenseExchangeOptions {
  owner: Organization;
  tenant: Organization;
  service: ServiceID;
  toShare: License[];
  toReturn: License[];
  returnImmediately: boolean;
  voluntaryReturn: boolean;
}

class NotificationsAPI extends AxiosAPI {
  private static instance: NotificationsAPI;
  
  public static get Instance(): NotificationsAPI {
    return this.instance || (this.instance = new this());
  }
  
  protected url(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/notifications`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }
  
  public async getNotificationsInbox(): Promise<INotificationDto[]> {
    try {
      const url = this.url("inbox");
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred retrieving your notifications inbox. Please try again later.`;
      throw new GetNotificationsInboxApiError(message, { cause: error });
    }
  }

  public async cancelNotification(notification: Notification): Promise<INotificationDto> {
    return this.respondNotification(notification, NotificationStatus.Cancelled);
  }

  public async acceptNotification(notification: Notification): Promise<INotificationDto> {
    return this.respondNotification(notification, NotificationStatus.Accepted);
  }

  public async acceptOrgRequestNotification(notification: Notification, role: Role): Promise<INotificationDto> {
    try {
      return await this.respondNotification(notification, NotificationStatus.Accepted, { role_id: role.id });
    } catch (error) {
      const responseError: RespondNotificationApiError = error;
      const message = `An error occurred responding the organization access request notification. Please try again later.`;
      throw new RespondOrgAccessRequestNotificationApiError(message, {
        cause: responseError,
        meta: { ...responseError.meta, role }
      });
    }
  }

  public async acceptContractPurchaseNotification(notification: Notification, organization: Organization): Promise<INotificationDto> {
    try {
      return await this.respondNotification(notification, NotificationStatus.Accepted, { org_id: organization.id });
    } catch (error) {
      const responseError: RespondNotificationApiError = error;
      const message = `An error occurred responding the contract purchase notification. Please try again later.`;
      throw new RespondContractPurchaseNotificationApiError(message, {
        cause: responseError,
        meta: { ...responseError.meta, organization }
      });
    }
  }

  public async acceptOrgInvitationLink(link: OrgInvitationNotificationLink): Promise<INotificationDto> {
    return this.respondOrgInvitationLink(link, NotificationStatus.Accepted);
  }

  public async declineNotification(notification: Notification): Promise<INotificationDto> {
    return this.respondNotification(notification, NotificationStatus.Rejected);
  }

  public async declineOrgInvitationLink(link: OrgInvitationNotificationLink): Promise<INotificationDto> {
    return this.respondOrgInvitationLink(link, NotificationStatus.Rejected);
  }

  protected async respondOrgInvitationLink(
    link: OrgInvitationNotificationLink, 
    status: NotificationResponseStatus
  ): Promise<INotificationDto> {
    try {
      return this.respondNotification(link.notificationRef, status);
    } catch (error) {
      const responseError: RespondNotificationApiError = error;
      const message = `An error occurred responding the organization invitation. Please try again later.`;
      throw new RespondOrgInvitationLinkApiError(message, {
        cause: responseError,
        meta: { ...responseError.meta, link }
      });
    }
  }

  protected async respondNotification(
    notification: Notification | NotificationRef, 
    status: NotificationResponseStatus, 
    requestBodyExtension: object = {}
  ): Promise<INotificationDto> {
    try {
      const url = this.url(notification.id.toString());
      const response = await this.axios.put(url, { status, ...requestBodyExtension });
      return response.data;
    } catch (error) {
      const message = `An error occurred responding the notification. Please try again later.`;
      throw new RespondNotificationApiError(message, { 
        cause: error,
        meta: { notification, status }
      });
    }
  }

  public async sendOrgAccessRequest(organization: Organization): Promise<INotificationDto> {
    try {
      const url = this.url(NotificationType.OrgAccessRequest);
      const response = await this.axios.post(url, { org_id: organization.id });
      return response.data;
    } catch (error) {
      const message = `An error occurred sending the organization access request. Please try again later.`;
      throw new SendOrgAccessRequestApiError(message, {
        cause: error,
        meta: { organization }
      })
    }
  }

  public async sendOrgInvitation(username: string, organization: Organization, role: Role): Promise<INotificationDto> {
    try {
      const url = this.url(NotificationType.OrgInvitation);
      const response = await this.axios.post(url, {
        org_id: organization.id,
        user_id: username,
        role_id: role.id
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred sending the invitation. Please try again later.`;
      throw new SendOrgInvitationApiError(message, { 
        cause: error,
        meta: { organization, username, role }
      });
    }
  }

  public async sendContractPurchase(contractPurchase: ContractPurchase): Promise<INotificationDto> {
    try {
      const url = this.url(NotificationType.ContractPurchase);
      const response = await this.axios.post(url, {
        contract_id: contractPurchase.id
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred registering yout contract purchase. Please try again later.`;
      throw new SendContractPurchaseApiError(message, { 
        cause: error,
        meta: { contractPurchase }
      });
    }
  }

  public async getOrgOutboxNotifications(type: NotificationType): Promise<INotificationDto[]> {
    try {
      const url = this.url(type);
      const response = await this.axios.get(url, {
        params: { sentByMe: false }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred retrieving ${Notification.labelForType(type)}s sent from your organization. Please try again later.`;
      throw new GetOrgOutboxNotificationsApiError(message, { 
        cause: error,
        meta: { type }
      });
    }
  }

  public async getMyOutboxNotifications(type: NotificationType, status?: NotificationStatus): Promise<INotificationDto[]> {
    try {
      const url = this.url(type);
      const response = await this.axios.get(url, {
        params: { sentByMe: true, status }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred retrieving ${Notification.labelForType(type)}s sent by you. Please try again later.`;
      throw new GetMyOutboxNotificationsApiError(message, { 
        cause: error,
        meta: { type }
      });
    }
  }

  public async sendLicenseExchange(options: LicenseExchangeOptions): Promise<void> {
    try {
      const url = this.url(NotificationType.LicenseSharing);
      await this.axios.post(url, {
        owner: options.owner.id,
        tenant: options.tenant.id,
        product: options.service,
        to_give: options.toShare.map(license => license.id), 
        to_return: options.toReturn.map(license => license.id),
        return_immediately: options.returnImmediately,
        voluntary_return: options.voluntaryReturn
      });
    } catch (error) {
      const message = `An error occurred exchanging licenses. Please try again later.`;
      throw new SendLicenseExchangeApiError(message, {
        cause: error,
        meta: { 
          owner: options.owner, 
          tenant: options.tenant, 
          service: options.service, 
          toShare: options.toShare, 
          toReturn: options.toReturn, 
          returnImmediately: options.returnImmediately 
        }
      });
    }
  }
}

export default NotificationsAPI.Instance;