import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class Empty extends Vue {
  
  @Prop({ type: String, default: null })
  protected description: string;

  @Prop({ type: Boolean, default: false })
  protected noIcon: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  protected get emptyClass() {
    return {
      "p-y-24 space-y-6": !this.compact,
      "p-y-16 space-y-4": this.compact,
    }
  }
  
}