import { OrgsLicensingSummaryFilters } from "@Module/Orgs/Models/OrgsLicensingSummaryFilters.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class OrgsLicensingSummaryFiltersPanel extends Vue {

  @Prop({ type: Object, required: true })
  protected value: OrgsLicensingSummaryFilters;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

}