import TrialDialog from "@Module/Trial/Components/TrialDialog/TrialDialog";
import { Duration as DayJsDuration } from "dayjs/plugin/duration";
import { ServiceID } from "@Service/ServiceID";
import { Component } from "types-vue";
import DayJS from "dayjs";
import Vue from "vue";

@Component
export default class EmoryTrialDialog extends Vue {

  protected serviceId: ServiceID = ServiceID.Emory;
  protected duration: DayJsDuration = DayJS.duration(15, "days");
  protected slots: number = 3;
  
  public open(): Promise<boolean> {
    return (this.$refs.dialog as TrialDialog).open();
  }

}