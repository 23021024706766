import { Component, MapAction, MapGetter, mixins, Prop, Watch } from "types-vue";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model"
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import AppRestartMixin from "@Core/Mixins/AppRestart.mixin";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import { ElForm } from "element-ui/types/form";
import { ServiceID } from "@Service/ServiceID";
import { Notify } from "@Core/Utils/Notify";
import { Utils } from "@Core/Utils/Utils";

@Component
export default class OrgEditorForm extends mixins(AppRestartMixin) {

  @Prop({ type: Object, default: () => Organization.create() })
  protected value: Organization;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(OrgsStore.Mapping)
  protected orgs: Organization[];

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapAction(OrgsStore.Mapping)
  protected createOrg: (org: Organization) => Promise<Organization>;

  @MapAction(OrgsStore.Mapping)
  protected updateOrg: (org: Organization) => Promise<Organization>;

  @MapAction(OrgsStore.Mapping)
  protected fetchOrgRoles: () => Promise<void>;

  protected internalValue: Organization = null;

  protected get isUpdateMode(): boolean {
    return this.value.isRemote;
  }

  protected createOrUpdate = Execution.create(async () => {
    if (this.isUpdateMode) {
      await this.updateExistingOrg();
    } else {
      await this.createNewOrg();
    }
  });

  private async createNewOrg() {
    await this.createOrg(this.value);
    if (this.noOrganization) {
      this.promptAppRestart();
      await Utils.sleep(100);
    } else {
      await this.fetchOrgRoles();
    }
    Notify.Success({
      title: "Organization created",
      message: `Organization ${this.value.name} has been successfully created.`,
      duration: 6000
    });
    this.$emit("success", this.value);
  }

  private async updateExistingOrg() {
    await this.updateOrg(this.value);
    Notify.Success({
      title: "Organization updated",
      message: `Organization ${this.value.name} has been successfully updated.`,
      duration: 6000
    });
    this.$emit("success", this.value);
  }

  protected get parentOrg(): Organization {
    return this.orgs.find(org => org.id === this.value.parentId);
  }
  
  protected domainRegexp = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;
  protected validationRules = {
    name: [{ required: true, message: "Please enter an organization name.", trigger: ["change", "blur"] }],
    domain: [{ validator: this.validateOrgDomain, trigger: ["change", "blur"] }]
  };

  protected validateOrgDomain(rule: any, value: string, callback: (error?: Error) => void): void {
    const validDomain = !value || this.domainRegexp.test(value);
    return validDomain 
      ? callback() 
      : callback(new Error("Please enter a valid organization domain (i.e. my-org.com)."));
  }

  @Watch("internalValue", { deep: true })
  protected onInternalValueChange(newValue: Organization) {
    this.$emit("input", newValue);
    this.$emit("update:value", newValue);
  }

  @Watch("value", { immediate: true })
  protected onValueChange(newValue: Organization) {
    this.internalValue = newValue;
  }

  @Watch("createOrUpdate.loading")
  protected onLoading(loading: boolean) {
    this.$emit("loading", loading);
  }

  public reset() {
    (this.$refs.form as ElForm)?.clearValidate();
  }

  protected get emailDomain(): string {
    return this.sessionUser.username.split("@").pop();
  }

  protected get parentDomain(): string | undefined {
    return this.parentOrg?.domain;
  }

  protected get formWithErrors(): boolean {
    return (this.$refs.form as any)?.fields?.some((item: any) => item.validateState === "error") || false;
  }

  protected get validOrgModel(): boolean {
    return !!this.internalValue.name;
  }

  protected get hasUncommittedChanges(): boolean {
    return this.internalValue.hasUncommittedChanges;
  }

  protected get canAssignParentDomain(): boolean {
    return !!this.parentDomain && this.internalValue.domain !== this.parentDomain;
  }

  protected get canAssignUserDomain(): boolean {
    return this.internalValue.domain !== this.emailDomain 
          && this.parentDomain !== this.emailDomain;
  }

  protected get permissions() {
    return {
      CanMarkOrgAsTest: this.accessRole.can(ServiceID.Console, Permission.MarkOrgAsTest)
    }
  }

}