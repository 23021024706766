
export enum LicensingStatusPhase {
  Active = "Active",
  Cancelled = "Cancelled",
  Expired = "Expired",
  InGrace = "InGracePeriod",
  OrgPending = "Org_Pending"
}

export namespace LicensingStatusPhase {

  export const Basic: LicensingStatusPhase[] = [
    LicensingStatusPhase.Active,
    LicensingStatusPhase.Expired,
    LicensingStatusPhase.InGrace
  ];

  export const All: LicensingStatusPhase[] = [
    LicensingStatusPhase.Active,
    LicensingStatusPhase.Cancelled,
    LicensingStatusPhase.Expired,
    LicensingStatusPhase.InGrace,
    LicensingStatusPhase.OrgPending
  ];

  export function nameOf(phase: LicensingStatusPhase): string {
    switch (phase) {
      case LicensingStatusPhase.Active: return "Active";
      case LicensingStatusPhase.InGrace: return "In Grace";
      case LicensingStatusPhase.Expired: return "Expired";
      case LicensingStatusPhase.Cancelled: return "Cancelled";
      case LicensingStatusPhase.OrgPending: return "Pending";
      default: return "Unknown";
    }
  }

}

export class LicensingStatus {
  public readonly phase: LicensingStatusPhase;
  
  private constructor(phase: string) {
    this.phase = phase as LicensingStatusPhase;
  }

  public static create(phase: string): LicensingStatus {
    return new LicensingStatus(phase);
  }

  public get isCancelled(): boolean { 
    return this.phase === LicensingStatusPhase.Cancelled; 
  }

  public get isInactive(): boolean { 
    return this.phase === LicensingStatusPhase.OrgPending; 
  }

  public get isActive(): boolean { 
    return this.phase === LicensingStatusPhase.Active; 
  }

  public get isExpired(): boolean { 
    return this.phase === LicensingStatusPhase.Expired; 
  }

  public get isInGrace(): boolean { 
    return this.phase === LicensingStatusPhase.InGrace; 
  }

  public get isFinished(): boolean {
    return this.isExpired || this.isCancelled;
  }

  public get isActiveOrInGrace(): boolean {
    return this.isActive || this.isInGrace;
  }

  public get label(): string {
    return LicensingStatusPhase.nameOf(this.phase);
  }

}
