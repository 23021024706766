import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { OrgRoleTreeData, OrgRoleTreeNode } from "../OrgRoleTree/OrgRoleTree";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, MapGetter, Prop } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import Vue from "vue";

@Component
export default class OrgRoleTreeItem extends Vue {

  @Prop({ type: Object, required: true })
  protected node: OrgRoleTreeNode;

  @Prop({ type: Boolean, default: true })
  protected showRole: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected defaultOrg: Organization;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  protected get nodeData(): OrgRoleTreeData {
    return this.node.data;
  }

  protected get orgRole(): OrganizationRole {
    return this.nodeData.orgRole;
  }

  protected get disabled(): boolean {
    return this.nodeData.disabled;
  }

  protected get isSelected(): boolean {
    return this.node.checked;
  }

  protected get classBinding() {
    return {
      "is-disabled": this.disabled,
      "is-compact": this.compact,
      "is-selected": this.isSelected
    }
  }

}