import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { AccessRole, Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, MapGetter, Prop, Watch } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { ElSelect } from "element-ui/types/select";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class RoleSelector extends Vue {

  @Prop({ type: [Object, Array], default: null })
  protected value: Role | Role[];

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected defaultFirstOption: boolean;

  @Prop({ type: String, default: "Access role" })
  protected placeholder: string;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AuthStore.Mapping)
  protected roles: Role[];

  protected searchText: string = null;
  protected visibleDropdown: boolean = false;
  protected internalValue: Role | Role[] = null;

  protected mounted() {
    if (!this.value && this.defaultFirstOption && this.roles.length > 0) {
      this.internalValue ??= this.roles[0];
    }
  }

  @Watch("value", { immediate: true, deep: true })
  protected onValueChange(newValue: Role | Role[]) {
    this.internalValue = newValue;
    if (!newValue && this.defaultFirstOption && !this.clearable && this.roles.length > 0) {
      this.internalValue ??= this.roles[0];
    }
  }

  @Watch("internalValue", { immediate: true })
  protected onRoleSelected(newValue: Role | Role[]) {
    if (!newValue) {
      newValue = this.multiple ? [] : null;
    }
    this.$emit("input", newValue);
    this.$emit("update:value", newValue);
    this.$emit("select", newValue);
    this.$emit("change", newValue);
    this.searchText = null;
  }

  protected get filteredRoles(): Role[] {
    const filteredRoles = this.accessRole.isManaged
      ? this.roles
      : this.roles.filter(role => !role.isManaged);
    return filteredRoles.filter(role => role.meetsFilter(this.searchText))
  }

  protected onSelectFilterChange(text: string): void {
    this.searchText = text || null;
  }

  protected async onVisibleChange(visible: boolean) {
    this.visibleDropdown = visible;
    if (visible) { return; }
    await Utils.sleep(200);
    this.searchText = null;
  }

  public focus() {
    (this.$refs.select as ElSelect)?.focus();
  }

  public blur() {
    (this.$refs.select as ElSelect).blur();
  }

  protected get hasNoValue(): boolean {
    if (!this.value) { 
      return true; 
    } else if (Array.isArray(this.value)) { 
      return this.value.length === 0;
    } else {
      return false;
    }
  }
  
}