import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class MenuItem extends Vue {

  @Prop({ type: String, required: true })
  protected label: string;

  @Prop({ type: String, default: null })
  protected tooltip: string;

  @Prop({ type: Boolean, default: true })
  protected showTooltip: boolean;

  @Prop({ type: String, required: true })
  protected index: string;

  @Prop({ type: Boolean, default: false })
  protected collapse: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected activeIndex: string;

  protected get menuItemClass() {
    return  {
      "is-collapse": this.collapse
    }
  }

  protected get tooltipContent(): string {
    return this.tooltip || this.label;
  }

  protected get isActive(): boolean {
    return this.index === this.activeIndex;
  }

}