import { BackupCloudProvider, CloudProviderType } from "./CloudProvider/BackupCloudProvider.model";
import { BackupObject, IBackupObjectDto } from "./BackupObject.model";
import { BackupLifecycle } from "./BackupLifecycle.model";
import { FileSize } from "@Core/Utils/FileSize";
import DayJS, { Dayjs } from "dayjs";

export interface Tag {
  name: string;
  value: string;
}

export interface IBackupDto {
  id: number;
  uuid: string;
  status: string;
  start_date: string;
  end_date?: string;
  level?: string;

  source_database?: string;
  lifecycle?: string;
  expiration_date?: string;
  num_objects?: number;
  total_size?: number;
  error_code?: number;
  error_message?: string;
  license_id: number;
  license_uuid: string;

  host_id: number;
  host_external_id: string;
  host_sid?: string;
  host_hostname?: string;
  host_platform?: string;
  host_client_version?: string;

  type: string;
  encryption_type?: string;
  encryption_key?: string;
  location_name?: string;
  location_path?: string;
  region?: string;
  tags?: { name: string, value: string }[];
  objects?: IBackupObjectDto[];
}

export enum BackupStatus {
  Started = "started",
  Completed = "completed",
  Failed = "failed"
}

export namespace BackupStatus {
  export const All: BackupStatus[] = [
    BackupStatus.Completed, BackupStatus.Failed, BackupStatus.Started
  ]
}

export interface BackupHost {
  readonly id: number;
  readonly uuid: string;
  readonly sid?: string;
  readonly hostname?: string;
  readonly platform?: string;
  readonly clientVersion?: string;
}

export interface BackupFailure {
  readonly reason: string;
  readonly code: number;
}

export class Backup {
  public readonly id: number;
  public readonly uuid: string;
  public readonly type: CloudProviderType;
  public readonly status: BackupStatus;
  public readonly level: string;
  public readonly startDate: Dayjs;
  public readonly endDate?: Dayjs;
  public readonly duration?: string;
  public readonly sourceDatabase?: string;
  public readonly licenseId: number;
  public readonly licenseUuid: string;
  public readonly host: BackupHost;
  public readonly size?: FileSize;
  public readonly failure?: BackupFailure;
  public readonly lifecycle?: BackupLifecycle;
  public readonly cloud: BackupCloudProvider;
  public readonly objects: BackupObject[];
  public readonly tags: Tag[];
  
  public static fromDto(dto: IBackupDto) {
    return new Backup(dto);
  }

  private constructor(dto: IBackupDto) {
    this.id = dto.id;
    this.uuid = dto.uuid;
    this.type = dto.type as CloudProviderType;
    this.status = dto.status as BackupStatus;
    this.level = dto.level;
    this.startDate = DayJS(dto.start_date);
    this.endDate = !!dto.end_date ? DayJS(dto.end_date) : null;
    this.sourceDatabase = dto.source_database;
    if (!!this.endDate) {
      this.duration = this.startDate.to(this.endDate, true);
    }
    this.licenseId = dto.license_id;
    this.licenseUuid = dto.license_uuid;
    this.host = {
      id: dto.host_id,
      uuid: dto.host_external_id,
      sid: dto.host_sid,
      hostname: dto.host_hostname,
      platform: dto.host_platform,
      clientVersion: dto.host_client_version
    };
    if (!!dto.error_message) {
      this.failure = {
        code: dto.error_code,
        reason: dto.error_message
      };
    } 
    if (!!dto.lifecycle && dto.lifecycle !== "N/A" && !!dto.expiration_date) {
      this.lifecycle = BackupLifecycle.fromDto(dto);
    }
    if (!!dto.total_size) {
      this.size = FileSize.fromBytes(dto.total_size);
    }
    this.tags = dto.tags || [];
    this.cloud = BackupCloudProvider.Factory.fromDto(dto);
    this.objects = dto.objects?.map(BackupObject.fromDto) || []; 
  }

  public get hasObjects(): boolean {
    return this.objects.length > 0;
  }

  public get hasFailed(): boolean {
    return this.status === BackupStatus.Failed;
  }

  public get statusLabel(): string {
    switch (this.status) {
      case BackupStatus.Started: return "Started";
      case BackupStatus.Completed: return "Completed";
      case BackupStatus.Failed: return "Failed";
      default: return "N/A";
    }
  }

  public get numberOfObjects(): number {
    return this.objects?.length ?? 0;
  }

  public get isAWS(): boolean { 
    return this.type === CloudProviderType.AWS;
  }

  public get isAzure(): boolean { 
    return this.type === CloudProviderType.Azure;
  }

  public get isGCloud(): boolean { 
    return this.type === CloudProviderType.GCloud;
  }

}
