import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { AbapSuiteHostFilters } from "./AbapSuiteHostFilters.model";

export class AbapSuiteLicenseFilters extends LicenseFilters {

  public hosts: AbapSuiteHostFilters;

  protected initialize() {
    super.initialize();
    this.hosts = AbapSuiteHostFilters.create();
  }

  public static create(): AbapSuiteLicenseFilters {
    return new AbapSuiteLicenseFilters();
  }

}