import { SignInChallenge } from "./SignInChallenge.model";

export interface INewPwdChallengeDto {
  username: string;
  challenge_name: string;
  session: string;
  password: string;
}

export class NewPwdChallenge {
  username: string;
  challengeName: string;
  session: string;
  keepSession: boolean;
  password: string;
  passwordRetype: string;

  private constructor(challenge: SignInChallenge) {
    this.username = challenge.username;
    this.session = challenge.session;
    this.keepSession = challenge.keepSession;
    this.challengeName = challenge.challengeName;
    this.password = null;
    this.passwordRetype = null;
  }

  public static create(challenge: SignInChallenge): NewPwdChallenge {
    return new NewPwdChallenge(challenge);
  }

  public toDto(): INewPwdChallengeDto {
    return {
      username: this.username,
      challenge_name: this.challengeName,
      session: this.session,
      password: this.password
    };
  }

}