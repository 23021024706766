import { License } from "@Module/Licensing/Models/License.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class LicenseUsageSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected license: License;

  @Prop({ type: Boolean, required: false })
  protected expand: boolean;

}