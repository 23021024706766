import Vue from "vue";
import { Component, Prop, Watch } from "types-vue";

const enum DialogResult {
  Cancel = "cancel",
  Success = "success"
}

@Component
export default class Dialog extends Vue{

  @Prop({ type: Boolean, default: false })
  protected visible: boolean;

  @Prop({ type: String, default: null })
  protected title: string;

  @Prop({ type: String, default: null })
  protected width: string;

  @Prop({ type: String, default: "15vh" })
  protected top: string;

  @Prop({ type: String, default: null })
  protected customClass: string;

  @Prop({ type: Boolean, default: false })
  protected fullscreen: boolean;
  
  @Prop({ type: Boolean, default: true })
  protected modal: boolean;
  
  @Prop({ type: Boolean, default: true })
  protected modalAppendToBody: boolean;
  
  @Prop({ type: Boolean, default: false })
  protected appendToBody: boolean;
  
  @Prop({ type: Boolean, default: true })
  protected lockScroll: boolean;
  
  @Prop({ type: Boolean, default: true })
  protected closeOnClickModal: boolean;
  
  @Prop({ type: Boolean, default: true })
  protected closeOnPressEscape: boolean;

  @Prop({ type: Boolean, default: true })
  protected showClose: boolean;

  @Prop({ type: Function, default: (close: Function) => close() })
  protected beforeClose: (close: () => void) => void;
  
  @Prop({ type: Boolean, default: false })
  protected center: boolean;
  
  @Prop({ type: Boolean, default: false })
  protected destroyOnClose: boolean;

  protected result: DialogResult = null;
  protected isVisible: boolean = false;

  protected opened: boolean = false;

  private resolve: (success: boolean) => void = () => {};

  @Watch("visible", { immediate: true })
  protected onVisiblePropChange(visible: boolean) {
    this.isVisible = visible;
  }

  @Watch("isVisible")
  protected onVisibilityChange(visible: boolean) {
    this.$emit("update:visible", visible);
    if (visible) {
      this.result = null;
    }
  }

  public async open(): Promise<boolean> {
    this.isVisible = true;
    return new Promise(resolve => {
      this.resolve = resolve;
    });
  }

  public markAsCancel() {
    this.result = DialogResult.Cancel;
  }

  public cancel(force: boolean = false) {
    this.markAsCancel();
    if (force) {
      this.resolvePromise();
      this.isVisible = false;
    } else {
      this.closeGuard(() => {
        this.isVisible = false;
      });
    }
  }

  public markAsSuccess() {
    this.result = DialogResult.Success;
  }
  
  public success(force: boolean = false) {
    this.markAsSuccess();
    if (force) {
      this.resolvePromise();
      this.isVisible = false;
    } else {
      this.closeGuard(() => {
        this.isVisible = false;
      });
    }
  }

  protected closeGuard(close: () => void) {
    this.beforeClose(() => {
      this.resolvePromise();
      this.isVisible = false;
      close();
    });
  }

  protected resolvePromise() {
    const isSuccess = this.result === DialogResult.Success;
    this.resolve(isSuccess);
    this.$emit("resolve", isSuccess);
  }

  protected onOpen() {
    this.opened = true;
    this.$emit("open");
  }

  protected onClosed() {
    this.opened = false;
    this.$emit("closed");
  }

  protected get shouldRender(): boolean {
    return !this.destroyOnClose ? true : this.opened;
  }

  public get isOpened(): boolean {
    return this.opened;
  }

  public get customDialogClass(): string {
    const cls = [this.customClass || ""];
    cls.push(!this.showClose ? "is-close-hidden" : "");
    return cls.join(" ");
  }
}