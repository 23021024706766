import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapGetter, Prop } from "types-vue";
import Clone from "lodash.clonedeep";
import Vue from "vue";

@Component
export default class ForbiddenView extends Vue {

  @Prop({ type: Number, default: 5 })
  protected seconds: number;

  @Prop({ type: String, default: "/" })
  protected redirectTarget: string;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  protected redirectOverwrite: string = null;

  protected created() {
    this.redirectOverwrite = this.$route.query.redirect as string;
    if (!!this.redirectOverwrite) {
      const query = Clone(this.$route.query);
      delete query.redirect;
      this.$router.replace({ query }).catch(e => e);
    }
  }

  protected get redirectPath(): string {
    return this.redirectOverwrite || this.previousRoute?.path || this.redirectTarget || this.currentRoute.redirectPath;
  }

}