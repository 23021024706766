import DayJS, { Dayjs } from "dayjs";

export interface IPaymentDto {
  id: number,
  billingPeriodId: number,
  number: number,
  amount: number,
  date: Date;
}

export class Payment {
  id: number;
  billingPeriodId: number;
  number: number;
  amount: number;
  date: Date;

 private constructor(dto: IPaymentDto) {
   this.id = dto.id;
   this.billingPeriodId = dto.billingPeriodId;
   this.number = dto.number;
   this.amount = dto.amount;
   this.date = dto.date;
 }

 public static create(dto?: Partial<IPaymentDto>): Payment {
   return new Payment({
     id: dto?.id,
     billingPeriodId: dto?.billingPeriodId,
     number: dto?.number,
     amount: dto?.amount,
     date: dto?.date,
   });
 }

 public static fromDto(dto: IPaymentDto): Payment {
   return new Payment(dto);
 }
}