import { Utils } from "@Core/Utils/Utils";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { ReportsStoreState } from "./Reports.state";
import { IReportContractDto, IReportFiltersDto, ReportContract } from "../Models/ReportContract.model";
import ReportsApi from "../API/Reports.api";
import { ReportContractsFilters } from "../Models/ReportContractFilters";
import { FInfo, IfInfoDto } from "../Models/FinancialInfo.model";
import { ILeadGeneratorDto, LeadGenerator } from "../Models/LeadGenerator.model";
import { BillingPeriod, IBillingPeriodDto } from "../Models/BillingPeriod.model";
import Vue from "vue";


export const enum ReportsStoreAction {
  fetchReportContracts = "fetchReportContracts",
}

const enum ReportsStoreCommit {
  SetReportsDialogVisible = "_setReportsDialogVisible",
  SetFInfoDialogVisible = "_setFInfoDialogVisible",
  SetBillingPeriodDialogVisible = "_setBillingPeriodDialogVisible",
  SetReportContracts = "_setReportContracts",
  AddReportContracts = "_addReportContract",
  AddBillingPeriod = "_addBillingPeriod",
  AddFInfo = "_addFInfo",
  SetLeadGenerators = "_setLeadGenerators",
  SetFInfo = "_setFInfo",
  CurrentYear = "_currentYear",
  SetCurrentYear = "_setCurrentYear",

}


@Module({ namespaced: true })
export default class ReportsStore extends VuexModule<ReportsStoreState> implements ReportsStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Reports");
  
  public _reportsDialogVisible: boolean = false;
  public _billingPeriodDialogVisible: boolean = false;
  public _fInfoDialogVisible: boolean = false;
  public _reportContracts: ReportContract[] = [];
  public _fInfo: FInfo[] = [];
  public _leadGenerators: LeadGenerator[] = [];
  public _currentYear: number = new Date().getFullYear();

  @Getter()
  public reportsDialogVisible() { 
    return this._reportsDialogVisible;
  }

  @Getter()
  public fInfoDialogVisible() { 
    return this._fInfoDialogVisible;
  }

  @Getter()
  public billingPeriodDialogVisible() { 
    return this._billingPeriodDialogVisible;
  }

  @Getter()
  public currentYear() { 
    return this._currentYear;
  }

  @Getter()
  public reportContracts(): ReportContract[] {
    return this._reportContracts;
  }

  @Getter()
  public fInfo(): FInfo[] {
    return this._fInfo;
  }

  @Getter()
  public leadGenerators(): LeadGenerator[] {
    return this._leadGenerators;
  }

  @Mutation()
  protected _setCurrentYear(year: number) { 
    this._currentYear = year;
  }

  @Mutation()
  protected _setReportsDialogVisible(visible: boolean) { 
    this._reportsDialogVisible = visible;
  }

  @Mutation()
  protected _setFInfoDialogVisible(visible: boolean) { 
    this._fInfoDialogVisible = visible;
  }

  @Mutation()
  protected _setBillingPeriodDialogVisible(visible: boolean) { 
    this._billingPeriodDialogVisible = visible;
  }

  @Action({ commit: ReportsStoreCommit.SetReportsDialogVisible })
  public setReportsDialogVisible(visible: boolean): boolean {
    return visible;
  }

  @Action({ commit: ReportsStoreCommit.SetFInfoDialogVisible })
  public setFInfoDialogVisible(visible: boolean): boolean {
    return visible;
  }

  @Action({ commit: ReportsStoreCommit.SetBillingPeriodDialogVisible })
  public setBillingPeriodDialogVisible(visible: boolean): boolean {
    return visible;
  }

  @Action({ commit: ReportsStoreCommit.SetReportsDialogVisible })
  public openReportsDialog(): boolean {
    return true;
  }

  @Action({ commit: ReportsStoreCommit.SetFInfoDialogVisible })
  public openFInfoDialog(): boolean {
    return true;
  }

  @Action({ commit: ReportsStoreCommit.SetReportsDialogVisible })
  public closeReportsDialog(): boolean {
    return false;
  }

  @Action({ commit: ReportsStoreCommit.SetFInfoDialogVisible })
  public closeFInfoDialog(): boolean {
    return false;
  }

  @Action({ commit: ReportsStoreCommit.SetBillingPeriodDialogVisible })
  public closeBillingPeriodDialog(): boolean {
    return false;
  }

  @Mutation()
  protected _addReportContract(reportContracts: ReportContract[]) {
    for (const editedContract of reportContracts) {
      const contract = this._reportContracts.find(c => c.id === editedContract.id);
      if (!!contract) {
        Object.assign(contract, editedContract);
      } else {
        this._reportContracts.push(editedContract);
      }
    } 
  }

  @Mutation()
  protected _addFInfo(newInfo: FInfo) {
    const currentInfo = this._fInfo.find(a => a.id === newInfo.id);
    if (!!currentInfo) {
      this._fInfo = this._fInfo.map(a => (a.id == newInfo.id) ? newInfo : a);
    } else {
      if (newInfo.date.get("year") === this._currentYear){
        this._fInfo.push(newInfo);
      }  
    }
  }

  @Mutation()
  protected _addBillingPeriod(billingPeriod: BillingPeriod) {
    
    for (let [index, contract] of this._reportContracts.entries()) {
      if (billingPeriod.contractID == contract.id) {
      let billingIndex= contract.billingPeriods.findIndex(period => period.id == billingPeriod.id)
      if (billingIndex !== -1){
        let billingPeriods = [...contract.billingPeriods]
        billingPeriods[billingIndex] = billingPeriod.toDto()
        Vue.set(contract, "billingPeriods", billingPeriods)
        this._reportContracts[index].billingPeriods = billingPeriods
      } else {
        contract.billingPeriods.push(billingPeriod.toDto())
      }
    }
  } 
  }

  @Mutation()
  protected _setReportContracts(reportContracts: ReportContract[]) {
    this._reportContracts = reportContracts;
  }

  @Mutation()
  protected _addLeadGenerators(leadGenerators: LeadGenerator[]) {
    this._leadGenerators = leadGenerators;
  }

  @Mutation()
  protected _setFInfo(fInfo: FInfo[]) {
    this._fInfo = fInfo;
  }

  @Action({ commit: ReportsStoreCommit.SetReportContracts })
  public async fetchReportContracts(filters: ReportContractsFilters): Promise<ReportContract[]> {
    const dtos = await ReportsApi.getReportContracts(filters.toDto());
    return dtos.map(ReportContract.fromDto);
  }

  @Action({ commit: ReportsStoreCommit.SetLeadGenerators})
  public async fetchLeadGenerators(): Promise<LeadGenerator[]> {
    const dtos = await ReportsApi.getLeadGenerators();
    return dtos.map(LeadGenerator.fromDto);
  }

  @Action()
  public async fetchReportContract(contractId: number): Promise<ReportContract> {
    const dto = await ReportsApi.getReportContract(contractId);
    return ReportContract.fromDto(dto);
  }

  @Action({ commit: ReportsStoreCommit.SetFInfo })
  public async fetchFInfo(year: number): Promise<FInfo[]> {
    const dtos = await ReportsApi.getFInfo(year);
    return dtos.map(FInfo.fromDto);
  }

  @Action({commit: ReportsStoreCommit.AddReportContracts})
  protected async updateReportContract(contract: IReportContractDto): Promise<ReportContract[]> {
    const dto = await ReportsApi.updateReportContract(contract);
    return [ReportContract.fromDto(dto)];
  }

  @Action({commit: ReportsStoreCommit.AddBillingPeriod})
  protected async updateBillingPeriod(billingPeriod: IBillingPeriodDto): Promise<BillingPeriod> {
    const dto = await ReportsApi.updateBillingPeriod(billingPeriod);
    return BillingPeriod.fromDto(dto);
  }

  @Action({commit: ReportsStoreCommit.AddBillingPeriod})
  protected async addBillingPeriod(billingPeriod: IBillingPeriodDto): Promise<BillingPeriod> {
    const dto = await ReportsApi.addBillingPeriod(billingPeriod);
    return BillingPeriod.fromDto(dto);
  }

  @Action({commit: ReportsStoreCommit.AddFInfo})
  protected async addFInfo(fInfo: IfInfoDto): Promise<FInfo> {
    const dto = await ReportsApi.addFInfo(fInfo);
    return FInfo.fromDto(dto);
  }

  @Action({commit: ReportsStoreCommit.AddFInfo})
  protected async updateFInfo(fInfo: IfInfoDto): Promise<FInfo> {
    const dto = await ReportsApi.updateFInfo(fInfo);
    return FInfo.fromDto(dto);
  }

}