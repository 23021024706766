import { Duration as DayJsDuration } from "dayjs/plugin/duration";
import { Prop } from "vue-property-decorator";
import { ServiceID } from "@Service/ServiceID";
import { Component, MapAction } from "types-vue";
import Vue from "vue";
import { Dimension, IDimensionUnits } from "@Module/Licensing/Models/Dimension.model";
import { TrialInfo } from "@Module/Trial/Models/TrialInfo.model";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import TrialApi from "@Module/Trial/API/Trial.api";
import TrialStore, { TrialRequestPayload } from "@Module/Trial/Store/Trial.store";

const enum TrialStep {
  Configuration = "configuration",
  NextSteps = "next-steps"
}

@Component
export default class TrialDialog extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Object, required: true })
  protected duration: DayJsDuration;

  @Prop({ type: Number, required: true })
  protected slots: number;

  @Prop({ type: Boolean, default: false })
  protected skipNextSteps: boolean;

  @MapAction(TrialStore.Mapping)
  private requestTrialStart: (payload: TrialRequestPayload) => Promise<TrialInfo>;

  protected step: TrialStep = TrialStep.Configuration;
  protected trialInfo: TrialInfo = null;
  protected availableDimensions: Dimension[] = [];
  protected dimensionUnits: IDimensionUnits[] = [];

  protected getTrialDimensions = Execution.create(
    () => TrialApi.getTrialDimensions(this.serviceId)
  );

  protected requestTrial = Execution.create(
    (units: IDimensionUnits[]) => this.requestTrialStart({
      service: this.serviceId,
      data: units
    })
  );

  public open(): Promise<boolean> {
    return (this.$refs.dialog as Dialog).open();
  }

  public onOpen() {
    this.fetchTrialDimensions();
  }

  public onClosed() {
    this.step = TrialStep.Configuration;
    this.availableDimensions = [];
    this.dimensionUnits = [];
    this.requestTrial.reset();
    this.getTrialDimensions.reset();
  }

  protected async fetchTrialDimensions() {
    const dtos = await this.getTrialDimensions.run();
    if (!this.getTrialDimensions.error) {
      this.availableDimensions = dtos.map(Dimension.fromDto);
    }
  }

  protected get isInConfigurationStep(): boolean {
    return this.step === TrialStep.Configuration;
  }

  protected onDimensionUnitsChange(unitsMap: Record<string, number>) {
    this.dimensionUnits = Object.keys(unitsMap)
      .reduce((arr, dimName) => {
        const units = unitsMap[dimName];
        arr.push({ dimension: dimName, units });
        return arr;
      }, [] as IDimensionUnits[]);
  }

  protected get isSelectionFulfilled(): boolean {
    const currDimUnits = this.dimensionUnits
      .reduce((total, dimUnit) => total + dimUnit.units, 0);
    return this.slots === currDimUnits;
  }
  
  protected async startTrial() {
    this.requestTrial.reset();
    this.trialInfo = await this.requestTrial.run(this.dimensionUnits);
    if (!this.requestTrial.error && this.trialInfo?.isActive) {
      if (this.skipNextSteps) {
        const dialog = this.$refs.dialog as Dialog;
        dialog.success();
      } else {
        this.step = TrialStep.NextSteps;
      }
    }
  }

  protected async closeGuard(close: VoidFunction) {
    if (this.requestTrial.loading) { return; }
    const dialog = this.$refs.dialog as Dialog;
    if (this.isInConfigurationStep) {
      dialog.markAsCancel();
    } else {
      dialog.markAsSuccess();
    }
    close();
  }

}