import { Component, Prop, Watch } from "types-vue";
import Crypto from "crypto";
import Vue from "vue";

@Component
export default class Drawer extends Vue {

  @Prop({ type: Boolean, default: false })
  protected appendToBody: boolean;

  @Prop({ type: Function, default: (close: VoidFunction) => close() })
  protected beforeClose: (close: VoidFunction) => void;

  @Prop({ type: Boolean, default: true })
  protected closeOnPressEscape: boolean;

  @Prop({ type: String, default: null })
  protected customClass: string;

  @Prop({ type: Boolean, default: false })
  protected destroyOnClose: boolean;

  @Prop({ type: Boolean, default: true })
  protected modal: boolean;

  @Prop({ type: Boolean, default: true })
  protected modalAppendToBody: boolean;

  @Prop({ type: String, default: "rtl" })
  protected direction: "rtl" | "ltr" | "ttb" | "btt";

  @Prop({ type: Boolean, default: true })
  protected showClose: boolean;

  @Prop({ default: 320 })
  protected size: number | string;

  @Prop({ type: String, default: null })
  protected title: string;

  @Prop({ type: Boolean, default: false })
  protected visible: boolean;

  @Prop({ type: Boolean, default: true })
  protected wrapperClosable: boolean;

  @Prop({ type: Boolean, default: true })
  protected withHeader: boolean;

  @Prop({ type: Boolean, default: true })
  protected offset: boolean;
  
  private uid: string = Crypto.randomBytes(16).toString("hex");
  protected static drawers: string[] = [];
  protected drawerVisible: boolean = false;
  protected marginY: string = "0";

  @Watch("visible", { immediate: true })
  protected onVisibilityChange(visible: boolean) {
    // this.updateDrawersOffset(visible);
    this.drawerVisible = visible;
  }

  protected open() {
    this.drawerVisible = true;
  }

  protected close() {
    this.drawerVisible = false;
  }

  protected toggle() {
    this.drawerVisible = !this.drawerVisible;
  }

  private updateDrawersOffset(visible: boolean) {
    if (visible) { 
      this.marginY = `-${Drawer.drawers.length * 6}px`;
      Drawer.drawers.push(this.uid); 
    } else { 
      const index = Drawer.drawers.indexOf(this.uid);
      if (index !== -1) {
        Drawer.drawers.splice(index, 1); 
      } 
    }
  }

  @Watch("drawerVisible")
  protected onInnerVisibilityChange(visible: boolean) {
    this.$emit("update:visible", visible);
  }

  protected get drawerClass() {
    return {
      "is-offseaat": this.offset
    }
  }

  protected get drawerStyle() {
    return {
      "margin-top": this.marginY,
      "margin-bottom": this.marginY
    }
  }

}