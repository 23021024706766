import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class FiltersCollapse extends Vue {

  @Prop({ type: String, default: "Filters" })
  protected title: string;

  @Prop({ type: Boolean, default: false })
  protected defaultCollapsed: boolean;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected filtering: boolean

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected plain: boolean;
  
  @Prop({ type: Boolean, default: false })
  protected bordered: boolean;

  @Prop({ type: Boolean })
  protected separated: boolean;

}