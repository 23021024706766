import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { LicensingStatusPhase } from "@Module/Licensing/Models/LicensingStatus.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { ServiceID } from "@Service/ServiceID";
import { ReportContract } from "./ReportContract.model";
import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import { LeadGeneratorContract } from "./LeadGeneratorContract.model";

export interface IReportContractFiltersDto {
  status: string;
  product: string;
  withHosts: boolean;
  origin: string;
  onlyPocs: boolean;
  orgFilters?: string;
  dimensions?: string;

}

export class ReportContractsFilters {
  services: ServiceID[];
  public onlyWithHosts: boolean;
  orgs: Organization[];
  dimensions: Dimension[];
  public status: LicensingStatusPhase[];
  public origins: ContractOrigin[];
  public onlyPocs: boolean;

  private constructor() {
    this.status = [];
    this.origins = [];
    this.services = [];
    this.dimensions = [];
    this.onlyWithHosts = false;
    this.orgs = [];
    this.onlyPocs = false;
  }

  public static create(): ReportContractsFilters {
    return new ReportContractsFilters();
  }

  protected get dto(): IReportContractFiltersDto {
    return this.toDto();
  }

  public get isFiltering(): boolean {
    return this.services.length > 0
        || this.onlyWithHosts
        || this.status.length > 0 
        || this.dimensions.length > 0
        || this.origins.length > 0
        || this.orgs.length > 0;
  }

  public toDto(): IReportContractFiltersDto {
    
    return {
      withHosts: this.onlyWithHosts,
      product: this.services.length > 0 ? this.services.join(",") : undefined,
      status: this.status.length > 0 ? this.status.join(",") : undefined,
      origin: this.origins.length > 0 ? this.origins.join(",") : undefined,
      orgFilters: this.orgs.length > 0 ? this.orgs.map(o => o.id).join(",") : undefined,
      onlyPocs: this.onlyPocs,
      dimensions: this.dimensions.length > 0 ? this.dimensions.map(d => d.name).join(",") : undefined
    }
  }
}