import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

@Component
export default class OrgRoleEditor extends Vue {

  @Prop({ type: Object, default: () => OrganizationRole.create() })
  protected value: OrganizationRole;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @Prop({ type: Boolean })
  protected defaultFirstRole: boolean;

  @Prop({ type: Array, default: null })
  protected roles: Role[] | null;

  @Prop({ type: Array, default: () => [] })
  protected disabledOrgs: Organization[];

  protected internalValue: OrganizationRole = null;

  @Watch("internalValue", { deep: true })
  protected onInternalValueChange(newValue: OrganizationRole) {
    this.$emit("input", newValue);
    this.$emit("update:value", newValue);
    this.$emit("select", newValue);
    this.$emit("change", newValue);
  }

  @Watch("value", { immediate: true, deep: true })
  protected onValueChange(newValue: OrganizationRole) {
    this.internalValue = newValue;
  }

}