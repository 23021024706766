import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { Component, MapGetter } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import { Loading } from "element-ui";
import Vue from "vue";

@Component
export default class AppRestartMixin extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  protected async promptAppRestart(message?: string): Promise<void> {
    try {
      await this.$alert(
        "The application needs to be refreshed in order to finish setting up your organizations.", 
        "Refresh Required", {
          type: "info",
          confirmButtonText: 'Refresh now',
          showClose: false,
          callback: async () => {
            Loading.service({ fullscreen: true, lock: true, text: "Refreshing..." });
            await Utils.sleep(1000);
            this.$router.replace(
              this.currentRoute.redirectPath, 
              () => window.location.reload(), 
              () => window.location.reload()
            );
          }
      });
    } catch {}
  }

}