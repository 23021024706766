import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class OrgLabel extends Vue {

  @Prop({ type: Object, required: true })
  protected org: Organization;

  @Prop({ type: String, default: "inherit" })
  protected type: "primary" | "secondary" | "inherit" | "danger" | "success" | "border";

  @Prop({ type: String, default: "inherit" })
  protected iconSize: string | "inherit";

  @Prop({ type: String })
  protected iconYOffset: string;

  @Prop({ type: String, default: "3px" })
  protected iconSpacing: string;
  
}