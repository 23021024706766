import { OrganizationRole } from "../Models/Roles/OrganizationRole.model";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapAction, MapGetter } from "types-vue";
import { Organization } from "../Models/Organization.model";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import OrgsStore from "../Store/Orgs.store";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class OrgSwitchMixin extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRole: OrganizationRole;

  @MapGetter(OrgsStore.Mapping)
  protected orgRoles: OrganizationRole[];

  @MapAction(OrgsStore.Mapping)
  protected switchToOrgRole: (org: OrganizationRole) => Promise<void>;

  protected canSwitchToOrg(org: Organization): boolean {
    if (this.currentOrgRole.organization.id === org.id) { return false; }
    return this.orgRoles.some(orgRole => orgRole.organization.id === org.id && !orgRole.role.isNoAccess)
  }

  protected canSwitchToOrgRole(orgRole: OrganizationRole): boolean {
    return this.currentOrgRole.organization.id !== orgRole.organization.id 
        && !orgRole.role.isNoAccess;
  }

  protected async switchToOrg(org: Organization) {
    if (!this.canSwitchToOrg(org)) { return; }
    const orgRole = this.orgRoles.find(orgRole => orgRole.organization.id === org.id);
    if (!!orgRole) {
      return this.switchOrgRole(orgRole);
    }
  }

  protected async switchOrgRole(orgRole: OrganizationRole) { 
    if (!this.canSwitchToOrgRole(orgRole)) { return; }
    const text = `Switching ${!orgRole.isMain ? 'to' : 'back to'} ${orgRole.organization.name} organization as ${orgRole.role.name}...`;
    this.$loading({ lock: true, text });
    await this.switchToOrgRole(orgRole);
    await Utils.sleep(1000);
    this.$router.replace(
      this.currentRoute.redirectPath, 
      () => window.location.reload(), 
      () => window.location.reload()
    );
  }

}