import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { MenuStoreState } from "./Menu.state";
import { Utils } from "@Core/Utils/Utils";
import { ActionContext } from "vuex";

const enum MenuStoreCommit {
  SetFloatingMenuVisible = "_setFloatingMenuVisible"
}

@Module({ namespaced: true })
export default class MenuStore extends VuexModule<MenuStoreState> implements MenuStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Menu");

  public _isFloatingMenuVisible: boolean = false;

  @Getter()
  public isFloatingMenuVisible(): boolean {
    return this._isFloatingMenuVisible;
  }

  @Mutation()
  public _setFloatingMenuVisible(visible: boolean): void {
    this._isFloatingMenuVisible = visible;
  }

  @Action({ commit: MenuStoreCommit.SetFloatingMenuVisible })
  public async setFloatingMenuVisible(visible: boolean): Promise<boolean> {
    return visible;
  }

  @Action({ useContext: true, commit: MenuStoreCommit.SetFloatingMenuVisible })
  public async toggleFloatingMenuVisibility(ctx: ActionContext<MenuStoreState, any>): Promise<boolean> {
    return !ctx.state._isFloatingMenuVisible;
  }

}