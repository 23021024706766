import { render, staticRenderFns } from "./AdminEditContractDialog.vue?vue&type=template&id=1d3135fa&scoped=true&"
import script from "./AdminEditContractDialog.ts?vue&type=script&lang=ts&"
export * from "./AdminEditContractDialog.ts?vue&type=script&lang=ts&"
import style0 from "./AdminEditContractDialog.vue?vue&type=style&index=0&id=1d3135fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d3135fa",
  null
  
)

export default component.exports