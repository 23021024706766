import { Utils } from "@Core/Utils/Utils";
import { Component } from "types-vue";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component
export default class Pulse extends Vue {
  private static readonly INITIAL_X_OFFSET = "6px";

  @Prop({ type: String, default: "" })
  protected type: "info" | "success" | "danger" | "warning" | "";

  @Prop({ type: String, default: "right" })
  protected position: "left" | "right";

  @Prop({ type: String, default: "0px" })
  protected xOffset: string;

  @Prop({ type: String, default: "0px" })
  protected yOffset: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected get pulseStyle() {
    return {
      top: this.yOffset,
      left: this.position === "left" ? `calc(${this.xOffset} + ${Pulse.INITIAL_X_OFFSET})` : "unset",
      right: this.position === "right" ? `calc(${this.xOffset} - ${Pulse.INITIAL_X_OFFSET})` : "unset"
    }
  }

  protected get pulseClass() {
    return {
      "is-info": this.type === "info",
      "is-success": this.type === "success",
      "is-danger": this.type === "danger",
      "is-warning": this.type === "warning"
    }
  }

}