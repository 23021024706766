import { GetOrgLicensingSummaryApiError } from "../Errors/GetOrgLicensingSummaryApiError";
import { IOrgLicensingSummaryDto } from "../Models/OrgLicensingSummary.model";
import { IOrganizationDto, Organization } from "../Models/Organization.model";
import { AxiosRequestConfig } from "axios";
import AxiosAPI from "@Core/API/AxiosAPI";
import { UpdateOrgApiError } from "../Errors/UpdateOrgApiError";
import { IOrgsLicensingSummaryFiltersDto } from "../Models/OrgsLicensingSummaryFilters.model";
import { IOrganizationRoleDto } from "../Models/Roles/OrganizationRole.model";
import { GetOrgRolesApiError } from "../Errors/GetOrgRolesApiError";
import { GetOrgSuggestionsApiError } from "../Errors/GetOrgSuggestionsApiError";
import { CreateOrgApiError } from "../Errors/CreateOrgApiError";
import { SetDefaultOrganizationApiError } from "../Errors/SetDefaultOrganizationApiError";
import { GetOrgChildrenApiError } from "../Errors/GetOrgChildrenApiError";
import { Utils } from "@Core/Utils/Utils";

class OrgsAPI extends AxiosAPI {
  private static instance: OrgsAPI;

  public static get Instance(): OrgsAPI {
    return this.instance || (this.instance = new this());
  }

  protected url(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/organizations`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  public async fetchOrgAPIKey(organization: Organization): Promise<string> {
    try {
      const url = this.url(`apikey`)+`?orgUUID=${organization.uuid}`;
      const response = await this.axios.get(url);

      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving organization API key.`;
      throw new Error(message);
    }
  }


  public async getOrgRoles(filter?: string): Promise<IOrganizationRoleDto[]> {
    try {
      const url = this.url("roles");
      const config: AxiosRequestConfig = { params: { filter } }; 
      const response = await this.axios.get(url, config);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving user organizations role.`;
      throw new GetOrgRolesApiError(message, { 
        cause: error, 
        meta: { filter }
      });
    }
  }

  public async getOrgSuggestions(): Promise<IOrganizationDto[]> {
    try {
      const url = this.url("suggestions");
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred retrieving organization suggestions. Please try again.`;
      throw new GetOrgSuggestionsApiError(message, {
        cause: error
      });
    }
  }

  public async createOrganization(organization: Organization): Promise<IOrganizationDto> {
    try {
      const url = this.url("new");
      const response = await this.axios.post(url, organization.toDto());
      return response.data;
    } catch (error) {
      const message = `An error occurred creating organization. Please try again.`;
      throw new CreateOrgApiError(message, {
        cause: error,
        meta: { organization }
      })
    }
  }

  public async setDefaultOrganization(organization: Organization): Promise<void> {
    try {
      const url = this.url(`${organization.id.toString()}/default`);
      await this.axios.put(url, {});
    } catch (error) {
      const message = `An error occurred configuring your default organization. Please try again.`;
      throw new SetDefaultOrganizationApiError(message, {
        cause: error,
        meta: { organization }
      });
    }
  }

  public async getOrgLicensingSummary(filters: IOrgsLicensingSummaryFiltersDto): Promise<IOrgLicensingSummaryDto[]> {
    try {
      const url = this.url("all");
      const response = await this.axios.get(url, { params: filters });
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving organization licensing summary. Please try again later.";
      throw new GetOrgLicensingSummaryApiError(message, {
        cause: error,
        meta: { filters }
      });
    }
  }

  public async getOrgChildren(organization: Organization): Promise<IOrganizationDto[]> {
    try {
      const url = this.url(`${organization.id.toString()}/children`);
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving organization children. Please try again later.";
      throw new GetOrgChildrenApiError(message, {
        cause: error,
        meta: { organization }
      })
    }
  }

  public async updateOrg(org: Organization): Promise<IOrganizationDto> {
    try {
      const updateDto = org.toUpdateDto();
      const url = this.url(org.id.toString());
      const response = await this.axios.put(url, updateDto);
      return response.data;
    } catch (error) {
      const message = "An error occurred while updating organization. Please try again later.";
      throw new UpdateOrgApiError(message, {
        cause: error,
        meta: { uuid: org.uuid, dto: org.toUpdateDto() }
      });
    }
  }
  
}

export default OrgsAPI.Instance;