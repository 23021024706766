import LayoutMixin from "@Core/Mixins/PageLayout.mixin";
import { Component, mixins } from "types-vue";
import { Prop } from "vue-property-decorator";

@Component
export default class Layout extends mixins(LayoutMixin) {

  @Prop({ type: Boolean, default: false })
  protected hideBreadcrumb: boolean;

}