import { ServiceID } from "@Service/ServiceID";
import { Console } from "vue-router";

import ServiceViewport from "@Core/Components/ServiceViewport/ServiceViewport.vue";

import AmisHub from './Views/Hub/AmisHub.vue';
import AmisCatalog from './Views/Catalog/AmisCatalog.vue';
import PreinstalledDocumentation from "./Views/Documentation/PreinstalledDocumentation.vue";
import Viewport from "@Core/Components/Viewport/Viewport.vue";

const routes: Console.RouteConfig[] = [
  {
    path: "/preinstalled-sap",
    name: "amis",
    component: ServiceViewport,
    redirect: { name: "amis-home" },
    meta: {
      authRequired: true,
      appInfo: {
        version: "1.1.0",
        serviceId: ServiceID.Amis,
        summary: "Amazon Machine Images (AMI's) packed with all necessary software to deploy your chosen SAP Systems within minutes."
      },
      menu: {
        label: ServiceID.nameOf(ServiceID.Amis),
        groupId: "service",
        icon: {
          type: "custom",
          name: "icons/amis.svg"
        }
      }
    },
    children: [
      {
        path: "home",
        name: "amis-home",
        component: AmisHub,
        meta: {
          authRequired: true,
          menu: {
            label: "AMIS",
            icon: {
              type: "ion-icon",
              name: "bookmark"
            }
          }
        }
      },
      {
        path: "catalog",
        name: "amis-catalog",
        component: AmisCatalog,
        meta: {
          authRequired: true,
          menu: {
            label: "Image Catalog",
            icon: {
              type: "ion-icon",
              name: "apps"
            }
          }
        }
      },
    ]
  }
];

export default routes
