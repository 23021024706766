import { ElForm } from "element-ui/types/form";
import { Component, Prop } from "types-vue";
import Vue from "vue";

export interface NewPasswordModel {
  newPassword: string;
  repeatPassword: string;
}

@Component
export default class NewPwdForm extends Vue {
  
  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected validationAttempt: boolean = false;
  protected wasValid: boolean = false;

  protected model: NewPasswordModel = {
    newPassword: null,
    repeatPassword: null
  };

  protected validationRules: object = {
    newPassword: [
      { required: true, message: "Enter your new password.", trigger: "blur" },
      { required: true, message: "Enter your new password.", trigger: "change" }
    ],
    repeatPassword: [
      { required: true, message: "Repeat your new password.", trigger: "blur" },
      { required: true, message: "Repeat your new password.", trigger: "change" }
    ],
  }

  protected readonly Regex = {
    SpecialChar: /[!@#$%^&*(),.?":{}|<>]/,
    Uppercase: /[A-Z]/,
    Lowercase: /[a-z]/,
    Whitespace: /\s/,
    Digit: /[\d]/,
    Length: /\w{1,8}/
  }

  protected get hasValue(): boolean { return !!this.model.newPassword }
  protected get hasSpecialChar(): boolean { return this.hasValue && this.Regex.SpecialChar.test(this.model.newPassword); }
  protected get hasUppercase(): boolean { return this.hasValue && this.Regex.Uppercase.test(this.model.newPassword); }
  protected get hasLowercase(): boolean { return this.hasValue && this.Regex.Lowercase.test(this.model.newPassword); }
  protected get noWhitespace(): boolean { return this.hasValue && !this.Regex.Whitespace.test(this.model.newPassword); }
  protected get hasDigit(): boolean { return this.hasValue && this.Regex.Digit.test(this.model.newPassword); }
  protected get hasMinLength(): boolean { return this.hasValue && this.Regex.Length.test(this.model.newPassword); }
  protected get bothPasswordsMatch(): boolean {
    const bothFieldsWithValue = !!this.model.newPassword && !!this.model.repeatPassword;
    if (!bothFieldsWithValue) { return false; }
    return this.model.newPassword === this.model.repeatPassword;
  }

  public get isValid(): boolean {
    return this.hasValue
        && this.bothPasswordsMatch
        && this.hasSpecialChar
        && this.hasUppercase
        && this.hasLowercase
        && this.noWhitespace
        && this.hasDigit
        && this.hasMinLength;
  }

  public validate(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        this.validationAttempt = true;
        await (this.$refs.newPasswordForm as ElForm).validate();
        if (!this.isValid) { reject(); }
        else { resolve(this.model.newPassword); }
      } catch {
        reject();
      }
    });
  }

  protected onInputChange(value: string) {
    const isValid = this.isValid;
    this.$emit("input", value, isValid);
    if (isValid !== this.wasValid) {
      this.$emit("change", value, isValid);
    }
    this.wasValid = this.isValid;
  }

  public reset() {
    this.model = { newPassword: null, repeatPassword: null };
    (this.$refs.newPasswordForm as ElForm)?.clearValidate();
  }

}