import { render, staticRenderFns } from "./SuperadminButton.vue?vue&type=template&id=d512c2da&scoped=true&"
import script from "./SuperadminButton.ts?vue&type=script&lang=ts&"
export * from "./SuperadminButton.ts?vue&type=script&lang=ts&"
import style0 from "./SuperadminButton.vue?vue&type=style&index=0&id=d512c2da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d512c2da",
  null
  
)

export default component.exports