import { ServiceID } from "@Service/ServiceID";

export interface IResourcePermissionsDto {
  canDownload: boolean;
}

export class ResourcePermissions {
  public readonly canDownload: boolean;
  public readonly service: ServiceID;

  private constructor(dto: IResourcePermissionsDto, service: ServiceID) {
    this.canDownload = dto.canDownload;
    this.service = service;
  }

  public static fromDto(dto: IResourcePermissionsDto, service): ResourcePermissions {
    return new ResourcePermissions(dto, service);
  }
}