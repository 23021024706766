import { NewPwdChallenge } from "@Module/Auth/Models/SignIn/NewPwdChallenge.model";
import { SignInResponse } from "@Module/Auth/Models/SignIn/SignInResponse.model";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import AuthStore from "@Module/Auth/Store/Auth.store";
import NewPwdForm from "../NewPwdForm/NewPwdForm";
import Vue from "vue";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";

@Component
export default class NewPwdChallengePanel extends Vue {

  @Prop({ required: true })
  protected challenge: NewPwdChallenge;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapAction(AuthStore.Mapping)
  private respondToNewPwdChallenge: (challenge: NewPwdChallenge) => Promise<SignInResponse>;

  protected changingPwd: boolean = false;
  protected newPwdError: Error = null;

  protected async submit() {
    try {
      await (this.$refs.form as NewPwdForm).validate();
      this.performSignInChallenge();
    } catch {}
  }

  protected setChangingPwd(changingPwd: boolean) {
    this.changingPwd = changingPwd;
    this.$emit("update:disabled", changingPwd);
  }

  protected async performSignInChallenge() {
    try {
      this.setChangingPwd(true);
      await this.respondToNewPwdChallenge(this.challenge);
      this.$emit("success");
    } catch (error) {
      this.newPwdError = error;
    } finally {
      this.setChangingPwd(false);
    }
  }

  protected onNewPasswordChange(value: string, valid: boolean) {
    this.challenge.password = valid ? value : null;
  }

  public async closeGuard(close: VoidFunction) {
    if (this.authStatus === AuthStatus.SignedIn) {
      return close(); 
    }

    try {
      const message = "<p>Do you really want to finish the current password change process?</p>"
        + "<p><strong>This is a required process</strong> and will start again the next time you sign in.</p>";
      await this.$confirm(message, "Finish password change", {
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Finish",
        dangerouslyUseHTMLString: true
      });
      return close();
    } catch {}
  }
}