import NavigationStore, { InitUserNavigationPayload, NavigationStoreAction } from "@Core/Store/Navigation/Navigation.store";
import AuthStore, { AuthStoreAction } from "@Module/Auth/Store/Auth.store";
import { OrganizationRole } from "../Models/Roles/OrganizationRole.model";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import { ComputedGetters, OrgsStoreState } from "./Orgs.state";
import { Organization } from "../Models/Organization.model";
import { User } from "@Module/Users/Models/User.model";
import AuthApi from "@Module/Auth/API/Auth.api";
import { Notify } from "@Core/Utils/Notify";
import { Utils } from "@Core/Utils/Utils";
import AxiosAPI from "@Core/API/AxiosAPI";
import DeepClone from "lodash.clonedeep";
import OrgsApi from "../API/Orgs.api";
import { ActionContext } from "vuex";
import OrgApi from "../API/Orgs.api";
import Vue from "vue";
import { ServiceID } from "@Service/ServiceID";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";

export type InitOrgsPayload = {
  user: User;
  userDidChange: boolean;
}

export const enum OrgsStoreCookies {
  OrgOverwrite = "orgs.overwrite",
  Switching = "orgs.switching",
  OrgsHistory = "orgs.history"
}

const enum OrgsStoreCommit {
  SetComputedGetters = "_setComputedGetters",
  SetOrgSuggestions = "_setOrgSuggestions",
  SetCurrentOrgRole = "_setCurrentOrgRole",
  SetOrgRoles = "_setOrgRoles",
  SetOrgRolesHistory = "_setOrgRolesHistory",
  UpdateOrg = "_updateOrg"
}

export const enum OrgsStoreAction {
  InitOrgs = "initOrgs",
  DeinitOrgs = "deinitOrgs",
  ClearOrgRolesHistory = "clearOrgRolesHistory",
  FetchOrgRoles = "fetchOrgRoles",
  FetchCurrentOrgChildren = "fetchCurrentOrgChildren"
}

export const enum OrgsStoreGetter {
  CurrentOrg = "currentOrg",
  CurrentOrgRole = "currentOrgRole",
  NoOrganization = "noOrganization",
}

export const MAX_ORGS_HISTORY_SIZE: number = 4;
const MAX_ORGS_HISTORY_SEPARATOR: string = "-";

declare type OrgsStoreContext = ActionContext<OrgsStoreState, any>;

@Module({ namespaced: true })
export default class OrgsStore extends VuexModule<OrgsStoreState> implements OrgsStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Orgs");

  public _computed?: ComputedGetters = null;

  public _currentOrgId: number = null;

  public _orgRoles: OrganizationRole[] = [];
  public _orgRolesHistory: OrganizationRole[] = [];
  public _suggestions: Organization[] = [];


  @Getter()
  public orgArrayParamSerializer(): Serializer<Organization[]> {
    return {
      serialize: (value: Organization[]): string[] => {
        return value.map(org => org.id.toString());
      },
      deserialize: (param: string | string[]): Organization[] => {
        const ids = Array.isArray(param) ? param : [param];
        return this._orgRoles
          .filter(orgRole => ids.some(p => Number(p) === orgRole.organization.id))
          .map(orgRole => orgRole.organization);
      }
    }
  }

  @Getter()
  public orgParamSerializer(): Serializer<Organization> {
    return {
      serialize: (value: Organization): string => {
        return value.id.toString();
      },
      deserialize: (param: string): Organization => {
        return this._orgRoles
          .find(orgRole => Number(param) === orgRole.organization.id)?.organization || null;
      }
    }
  }

  @Getter()
  public currentOrgHasChildren(): boolean {
    return this._computed?.currentOrgRoleHierarchy.children.length > 0 ?? false;
  }

  @Getter()
  public orgSuggestions(): Organization[] {
    return this._suggestions;
  }

  @Getter()
  public orgRolesHistory(): OrganizationRole[] {
    return this._orgRolesHistory;
  }

  @Getter()
  public defaultOrg(): Organization {
    return this._computed?.defaultOrgRole?.organization || null;
  }

  @Getter()
  public defaultOrgRole(): OrganizationRole {
    return this._orgRoles.find(orgRole => orgRole.isMain) || null;
  }

  @Getter()
  public currentOrg(): Organization {
    return this._computed?.currentOrgRole?.organization || null;
  }

  @Getter()
  public currentOrgRole(): OrganizationRole {
    return this._orgRoles.find(orgRole => orgRole.organization.id === this._currentOrgId) || null;
  }

  @Getter()
  public noOrganization(): boolean {
    return this._orgRoles.length === 0;
  }

  @Getter()
  public isInMainOrg(): boolean {
    return !!this._currentOrgId && this._computed?.defaultOrg?.id === this._currentOrgId;
  }

  @Getter()
  public orgs(): Organization[] {
    return this._orgRoles.map(orgRole => orgRole.organization);
  }

  @Getter()
  public orgRoles(): OrganizationRole[] {
    return this._orgRoles;
  }

  @Getter()
  public currentOrgRoleHierarchy(): OrganizationRole {
    return this._computed?.findOrgRoleHierarchy(this._currentOrgId) ?? null;
  }

  @Getter()
  public currentOrgChildren(): Organization[] {
    const currentOrg = this._computed?.currentOrg;
    return !currentOrg ? [] 
      : this._computed?.orgs.filter(o => o.parentId === currentOrg.id) || [];
  }

  @Getter()
  public currentOrgRoleParent(): OrganizationRole {
    const currentOrg = this._computed?.currentOrg;
    return !currentOrg || !currentOrg.parentId
      ? null
      : this._orgRoles.find(orgRole => currentOrg.parentId === orgRole.organization.id) || null;
  }

  @Getter()
  public currentOrgParent(): Organization {
    return this._computed?.currentOrgRoleParent?.organization || null;
  }

  @Getter()
  public findOrgRoleHierarchy(): (org: number | Organization) => OrganizationRole {
    return (org: number | Organization) => {
      const id = typeof org === "object" ? org.id : org;
      const findOrgRole = (orgRoles: OrganizationRole[]): OrganizationRole => {
        for (const orgRole of orgRoles) {
          if (orgRole.organization.id === id) { return orgRole; }
          const found = findOrgRole(orgRole.children); 
          if (!!found) { return found; }
        }
      }
      return findOrgRole(this._computed.orgRolesHierarchy);
    }
  }

  @Getter()
  public orgRolesHierarchy(): OrganizationRole[] {
    const orgRoles = DeepClone(this._orgRoles);
    const hierarchy = orgRoles.filter(orgRole => !orgRole.organization.parentId);
    const childOrgRolesMap = Utils.groupBy(orgRoles, orgRole => orgRole.organization.parentId, { skipNoValue: true });
    const assignChildrenRoleOrgs = (orgRoles: OrganizationRole[]) => {
      orgRoles.forEach(orgRole => {
        const orgId = orgRole.organization.id;
        orgRole.children = childOrgRolesMap[orgId] || [];
        delete childOrgRolesMap[orgId];
        assignChildrenRoleOrgs(orgRole.children);
      })
    }
    assignChildrenRoleOrgs(hierarchy);
    const orphanOrgRoles = Object.values(childOrgRolesMap).flat();
    hierarchy.push(...orphanOrgRoles);
    hierarchy.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
    return hierarchy;
  }

  @Getter()
  public canSwitchOrg(): boolean {
    return this._orgRoles.filter(orgRole => !orgRole.role.isNoAccess).length > 1;
  }

  @Mutation()
  protected _setCurrentOrgRole(orgRole: OrganizationRole | null) {
    this._currentOrgId = orgRole?.organization.id;
    AxiosAPI.setOrganization(orgRole?.organization);
  }

  @Mutation()
  protected _setOrgSuggestions(suggestions: Organization[]) {
    this._suggestions = suggestions
      .filter(org => !this._orgRoles.some(orgRole => orgRole.organization.id === org.id));
  }

  @Mutation()
  protected _setOrgRolesHistory(orgRoles?: OrganizationRole[]) {
    this._orgRolesHistory = orgRoles || [];
    if (!orgRoles) {
      Vue.$cookies.remove(OrgsStoreCookies.OrgsHistory);
    } else {
      this._orgRolesHistory = orgRoles;
      const orgIdsHistory = this._orgRolesHistory.map(orgRole => orgRole.organization.id).join(MAX_ORGS_HISTORY_SEPARATOR);
      Vue.$cookies.set(OrgsStoreCookies.OrgsHistory, orgIdsHistory);
    }
  }

  @Mutation()
  protected _setOrgRoles(orgRoles: OrganizationRole[]) {
    this._orgRoles = orgRoles.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
    if (!this._currentOrgId) {
      let currOrgRole = this._orgRoles.find(orgRole => orgRole.isMain);
      if (!currOrgRole && this._orgRoles.length > 0) {
        currOrgRole = this._orgRoles[0];
      }
      this._currentOrgId = currOrgRole?.organization.id;
      AxiosAPI.setOrganization(currOrgRole?.organization);
    }
  }

  @Mutation()
  protected _updateOrg(org: Organization) {
    const existingOrgRole = this._orgRoles.find(orgRole => orgRole.organization.id === org.id);
    if (!!existingOrgRole) {
      Vue.set(existingOrgRole, "organization", org);
    }
  }

  @Mutation()
  protected _setComputedGetters(computed: ComputedGetters) {
    this._computed = computed;
  }

  @Action({ useContext: true })
  public async setDefaultOrganization(ctx: OrgsStoreContext, organization: Organization): Promise<void> {
    await OrgsApi.setDefaultOrganization(organization);
    await ctx.dispatch(OrgsStoreAction.FetchOrgRoles);
  }

  @Action({ commit: OrgsStoreCommit.SetOrgSuggestions })
  public async fetchOrgSuggestions(): Promise<Organization[]> {
    const dtos = await OrgApi.getOrgSuggestions();
    return dtos.map(Organization.fromDto);
  }

  @Action()
  protected async createOrg(org: Organization): Promise<Organization> {
    const dto = await OrgsApi.createOrganization(org);
    return Organization.fromDto(dto);
  }

  @Action({ commit: OrgsStoreCommit.UpdateOrg })
  protected async updateOrg(org: Organization): Promise<Organization> {
    const dto = await OrgsApi.updateOrg(org);
    return Organization.fromDto(dto);
  }

  @Action({ useContext: true })
  public async switchToOrgRole(ctx: OrgsStoreContext, orgRole: OrganizationRole): Promise<void> {
    Vue.$cookies.set(OrgsStoreCookies.Switching, true);

    const history = ctx.state._orgRolesHistory;
    const currentHistoryIndex = history.findIndex(o => o.organization.id === orgRole.organization.id);
    if (currentHistoryIndex !== -1) {
      history.splice(currentHistoryIndex, 1);
    } else if (history.length === MAX_ORGS_HISTORY_SIZE) {
      history.splice(history.length - 1, 1);
    }
    const orgRolesHistory = [orgRole, ...history];
    ctx.commit(OrgsStoreCommit.SetOrgRolesHistory, orgRolesHistory);

    if (orgRole.isMain) {
      Vue.$cookies.remove(OrgsStoreCookies.OrgOverwrite);
    } else {
      Vue.$cookies.set(OrgsStoreCookies.OrgOverwrite, orgRole.organization.id);
    }
  }

  @Action({ commit: OrgsStoreCommit.SetOrgRolesHistory })
  public async clearOrgRolesHistory(): Promise<OrganizationRole[]> {
    return null;
  }

  @Action({ useContext: true, commit: OrgsStoreCommit.SetOrgRoles })
  public async fetchCurrentOrgChildren(ctx: OrgsStoreContext): Promise<OrganizationRole[]> {
    const currentOrg = ctx.state._computed?.currentOrg;
    if (!currentOrg) { return ctx.state._orgRoles; }

    const fetchOrgChildren = async (org: Organization): Promise<Organization[]> => {
      const dtos = await OrgApi.getOrgChildren(org);
      const children = dtos.map(Organization.fromDto);
      const childrenOfChildren = children.map(fetchOrgChildren);
      const allChildren = await Promise.all(childrenOfChildren);
      return [...children, ...allChildren].flat();
    }

    const childOrgs = await fetchOrgChildren(currentOrg);
    return childOrgs.reduce((orgRoles, org) => {
      const hasAccess = orgRoles.some(orgRole => orgRole.organization.id === org.id);
      if (!hasAccess) {
        orgRoles.push(OrganizationRole.noAccess(org));
      }
      return orgRoles;
    }, ctx.state._orgRoles);
  }

  @Action({ commit: OrgsStoreCommit.SetOrgRoles })
  public async fetchOrgRoles(): Promise<OrganizationRole[]> {
    const dtos = await OrgApi.getOrgRoles();
    return dtos.map(OrganizationRole.fromDto);
  }

  @Action({ useContext: true })
  public async initOrgs(ctx: OrgsStoreContext, payload: InitOrgsPayload): Promise<void> {
    
    ctx.commit(OrgsStoreCommit.SetComputedGetters, ctx.getters);

    if (payload.userDidChange) {
      await ctx.dispatch(OrgsStoreAction.ClearOrgRolesHistory);
    }
    
    let allowedOrgRoles: OrganizationRole[];
    if (payload.user.universalOrgAccess) {
      allowedOrgRoles = await ctx.dispatch(OrgsStoreAction.FetchOrgRoles);
    } else {
      allowedOrgRoles = payload.user.allOrgRoles;
      ctx.commit(OrgsStoreCommit.SetOrgRoles, allowedOrgRoles);
    }

    const overwriteId = Vue.$cookies.get(OrgsStoreCookies.OrgOverwrite);
    let switchOrgRole: OrganizationRole;
    if (overwriteId != undefined) {
      switchOrgRole = allowedOrgRoles.find(orgRole => orgRole.organization.id === Number(overwriteId));
    }

    let defaultOrgRole = payload.user.mainOrgRole; 
    if (!defaultOrgRole) {
      defaultOrgRole = payload.user.allowedOrgRoles.length > 0 ? payload.user.allowedOrgRoles[0] : null;
    }
    
    const currentOrgRole = switchOrgRole || defaultOrgRole;
    ctx.commit(OrgsStoreCommit.SetCurrentOrgRole, currentOrgRole);

    // Init access role for current organization
    let accessRole: AccessRole;
    if (!!currentOrgRole) {
      const roleDto = await AuthApi.getRolePermissions(currentOrgRole.role.id);
      accessRole = AccessRole.fromDto(roleDto, payload.user);
    } else {
      accessRole = AccessRole.guestRole(payload.user);
    }
    const setAccessRole = AuthStore.Mapping.rootAction(AuthStoreAction.SetAccessRole);
    await setAccessRole(ctx, accessRole);

    // Get current organization children if needed
    const shouldFetchChildren = accessRole.can(ServiceID.Console, Permission.CreateChildOrg) 
                            || accessRole.canForSomeService(Permission.ShareLicenses);
    if (!!currentOrgRole?.organization && shouldFetchChildren) {
      await ctx.dispatch(OrgsStoreAction.FetchCurrentOrgChildren);
    }
    
    // Manage user's switch org
    const wasSwitchingOrg = Vue.$cookies.get(OrgsStoreCookies.Switching) === "true";
    if (wasSwitchingOrg && !!currentOrgRole) {
      Notify.Info({ 
        title: "Organization Switch", 
        message: `Switched ${currentOrgRole.isMain ? 'back to' : 'to'} ${currentOrgRole.organization.name} organization with ${currentOrgRole.role.name} access role.`, 
        duration: 1500 
      });
    }
    Vue.$cookies.remove(OrgsStoreCookies.Switching);

    // Manage user's org history
    const historyCookie = Vue.$cookies.get(OrgsStoreCookies.OrgsHistory) || null;
    const orgIdsHistory: string[] = historyCookie?.split(MAX_ORGS_HISTORY_SEPARATOR) as string[] || [];
    if (orgIdsHistory.length > 0) {
      const orgRolesHistory = orgIdsHistory.reduce((history, orgId) => {
        const orgRole = allowedOrgRoles.find(orgRole => orgRole.organization.id === Number(orgId));
        if (!!orgRole) { history.push(orgRole); }
        return history;
      }, [] as OrganizationRole[]);
      ctx.commit(OrgsStoreCommit.SetOrgRolesHistory, orgRolesHistory);
    }

    const initUserNavigation = NavigationStore.Mapping.rootAction(NavigationStoreAction.InitUserNavigation);
    await initUserNavigation(ctx, { user: payload.user, accessRole } as InitUserNavigationPayload);
  }

  @Action({ useContext: true })
  public async deinitOrgs(ctx: OrgsStoreContext, expired: boolean): Promise<void> {
    ctx.commit(OrgsStoreCommit.SetOrgRoles, []);
    ctx.commit(OrgsStoreCommit.SetOrgSuggestions, []);
    ctx.commit(OrgsStoreCommit.SetCurrentOrgRole, null);
    ctx.commit(OrgsStoreCommit.SetComputedGetters, null);
    AxiosAPI.setOrganization(null);

    if (!expired) {
      Vue.$cookies.remove(OrgsStoreCookies.OrgOverwrite);
      Vue.$cookies.remove(OrgsStoreCookies.Switching);
    }

    const deinitUserNavigation = NavigationStore.Mapping.rootAction(NavigationStoreAction.DeinitUserNavigation);
    await deinitUserNavigation(ctx, expired);
  }

}