import { ServiceID } from "@Service/ServiceID";
import DayJS, { Dayjs } from "dayjs";
import { ProductVersion } from "./ProductVersion.model";
import { Platform } from "./Platform.model";
import { Release } from "./Release.model";


export interface ICreateResourceDto {
  product: ServiceID,
  resource: IResourceDto,
}

export interface ResourceFilters {
  productVersionId?: number,
  platformId?: number
  filteredPlatforms: Platform[]
  releaseId?: number
  filteredReleases: Release[]
}


export interface IResourceDto {
  id: string;
  file: string;
  description: string;
  category?: string;
  subcategory: string;
  created_at?: string;
  release_note_id?: number;
  release_id?: number;
}

export type IResource = IResourceDto;

export const enum ResourceType {
  Install = "Install",
  Upgrade = "Upgrade",
  Maintenance = "Maintenance",
  ReleaseNote = "Release Note"
}

export class Resource {
  id: string;
  fileName: string;
  category?: string;
  subcategory: string;
  description: string;
  created_at?: Dayjs;
  type: ResourceType;
  notesId?: number;
  releaseId?: number;

  public static create(dto?: Partial<IResourceDto>): Resource {
    return new Resource({
      id: dto?.id,
      category: dto?.category,  
      description: dto?.description,
      subcategory: dto?.subcategory,
      release_note_id: dto?.release_note_id,
      file: dto?.file,
      release_id: dto?.release_id
    });
  }

  private constructor(dto: IResourceDto) {
    this.id = dto.id;
    this.fileName = dto.file;
    this.description = dto.description;
    this.category = !dto.category ? null : dto.category; 
    this.subcategory = dto?.subcategory;
    this.type = dto.subcategory as ResourceType;
    this.releaseId = !dto.release_id ? null : dto.release_id
    this.notesId = !dto.release_note_id ? null : dto.release_note_id;
  }

  public static fromDto(dto: IResourceDto): Resource {
    return new Resource(dto);
  }

  
  public toDto(): IResourceDto {
    return  {
      id: this.id,
      file: this.fileName,
      description: this.description,
      category: this.category? this.category : null,
      subcategory: this.subcategory,
      release_id: this.releaseId,
      release_note_id: this.notesId
    };
  }

}