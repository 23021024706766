import { OrgAccessRequestNotification } from "@Module/Notifications/Models/OrgAccessRequestNotification.model";
import { OrgInvitationNotification } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { NotificationType } from "@Module/Notifications/Models/Notification.model";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, MapAction, MapGetter } from "types-vue";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import Vue from "vue";

@Component
export default class OrganizationSetup extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapGetter(OrgsStore.Mapping)
  protected orgSuggestions: Organization[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected myOrgRequestsOutbox: OrgAccessRequestNotification[];

  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgInvitationsInbox: OrgInvitationNotification[];

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<Notification[]>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected fetchMyOutbox: (type: NotificationType) => Promise<Notification[]>;

  @MapAction(OrgsStore.Mapping)
  protected fetchOrgSuggestions: () => Promise<Organization[]>;

  protected getInbox = Execution.create(() => this.fetchInbox());
  protected getOrgSuggestions = Execution.create(
    () => Promise.all([
      this.fetchOrgSuggestions(),
      this.fetchMyOutbox(NotificationType.OrgAccessRequest)
    ])
  );

  protected mounted() {
    this.getInbox.run();
    this.getOrgSuggestions.run();
  }

  protected get inboxFirstLoad(): boolean {
    return this.orgInvitationsInbox.length === 0 && this.getInbox.loading;
  }

}