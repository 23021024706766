import { Map } from "@Core/Models/Map";
import { Utils } from "@Core/Utils/Utils";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class ListItem extends Vue {

  @Prop({ required: true })
  protected item: object;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: true })
  protected selectable: boolean;

  @Prop({ type: Boolean, default: false })
  protected separated: boolean;

  @Prop({ type: String, default: "label" })
  protected labelKeyPath: string;

  @Prop({ type: String, default: "description" })
  protected descriptionKeyPath: string;

  @Prop({ type: [String, Number], default: "6px" })
  protected spacing: string | number;

  protected get listItemClass(): Map<boolean> {
    return {
      "is-loading": this.loading,
      "is-disabled": this.disabled,
      "is-selectable": this.selectable,
      "is-compact": this.compact
    }
  }

  protected get listItemStyle() {
    const vSpacing = Utils.isNumber(this.spacing) ? `${this.spacing}px` : this.spacing;
    return {
      "padding-top": `${vSpacing}`,
      "padding-bottom": `${vSpacing}`
    }
  }

  protected get titleLabel(): string {
    return Utils.getNestedProperty(this.item, this.labelKeyPath);
  }

  protected get descriptionLabel(): string | null {
    return Utils.getNestedProperty(this.item, this.descriptionKeyPath) || null;
  }

  protected onClick() {
    if (this.disabled || !this.selectable || this.loading) { return; }
    this.$emit("select", this.item);
  }

}