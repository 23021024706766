import { render, staticRenderFns } from "./EmoryContracts.vue?vue&type=template&id=cdffd42e&"
import script from "./EmoryContracts.ts?vue&type=script&lang=ts&"
export * from "./EmoryContracts.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports