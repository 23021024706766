export interface AwsRegion {
  name: string;
  code: string;
}

export namespace AwsRegion {
  export const AllByRegion = {
    "United States": [
      { name: 'N. Virginia',    code: 'us-east-1'      },
      { name: 'Ohio',           code: 'us-east-2'      },
      { name: 'N. California',  code: 'us-west-1'      },
      { name: 'Oregon',         code: 'us-west-2'      }
    ],
    "Africa": [
      { name: 'Cape Town',      code: 'af-south-1'     }
    ],
    "Asia Pacific": [
      { name: 'Hong Kong',      code: 'ap-east-1'      },
      { name: 'Mumbai',         code: 'ap-south-1'     },
      { name: 'Seoul',          code: 'ap-northeast-2' },
      { name: 'Singapore',      code: 'ap-southeast-1' },
      { name: 'Sydney',         code: 'ap-southeast-2' },
      { name: 'Tokyo',          code: 'ap-northeast-1' }
    ],
    "Canada": [
      { name: 'Central',        code: 'ca-central-1'   }
    ],
    "Europe": [
      { name: 'Frankfurt',      code: 'eu-central-1'   },
      { name: 'Ireland',        code: 'eu-west-1'      },
      { name: 'London',         code: 'eu-west-2'      },
      { name: 'Milan',          code: 'eu-south-1'     },
      { name: 'Paris',          code: 'eu-west-3'      },
      { name: 'Stockholm',      code: 'eu-north-1'     }
    ],
    "Middle East": [
      { name: 'Bahrain',        code: 'me-south-1'     }
    ],
    "South America": [
      { name: 'São Paulo',      code: 'sa-east-1'      }
    ]
  };
  
  export const All: AwsRegion[] = [
    { name: 'N. Virginia',    code: 'us-east-1'      },
    { name: 'Ohio',           code: 'us-east-2'      },
    { name: 'N. California',  code: 'us-west-1'      },
    { name: 'Oregon',         code: 'us-west-2'      },
    { name: 'Cape Town',      code: 'af-south-1'     },
    { name: 'Hong Kong',      code: 'ap-east-1'      },
    { name: 'Mumbai',         code: 'ap-south-1'     },
    { name: 'Seoul',          code: 'ap-northeast-2' },
    { name: 'Singapore',      code: 'ap-southeast-1' },
    { name: 'Sydney',         code: 'ap-southeast-2' },
    { name: 'Tokyo',          code: 'ap-northeast-1' },
    { name: 'Central',        code: 'ca-central-1'   },
    { name: 'Frankfurt',      code: 'eu-central-1'   },
    { name: 'Ireland',        code: 'eu-west-1'      },
    { name: 'London',         code: 'eu-west-2'      },
    { name: 'Milan',          code: 'eu-south-1'     },
    { name: 'Paris',          code: 'eu-west-3'      },
    { name: 'Stockholm',      code: 'eu-north-1'     },
    { name: 'Bahrain',        code: 'me-south-1'     },
    { name: 'São Paulo',      code: 'sa-east-1'      }
  ].sort((a, b) => a.name.localeCompare(b.name));

  export function findByCode(code: string): AwsRegion {
    return AwsRegion.All.find(region => code === region.code);
  }
}