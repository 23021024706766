import { Organization } from "@Module/Orgs/Models/Organization.model";
import { License } from "@Module/Licensing/Models/License.model";
import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ServiceID } from "@Service/ServiceID";

interface SendLicenseExchangeApiErrorMeta {
  owner: Organization; 
  tenant: Organization; 
  service: ServiceID; 
  toShare: License[]; 
  toReturn: License[];
  returnImmediately: boolean;
}

export class SendLicenseExchangeApiError extends ConsoleError<SendLicenseExchangeApiErrorMeta> {}