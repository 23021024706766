import { HostFilters } from "@Module/Licensing/Models/HostFilters.model";

export class EmoryHostFilters extends HostFilters {

  public versions: string[];
  public ips: string[];
  public sIds: string[];

  protected initialize() {
    super.initialize();
    this.versions = [];
    this.sIds = [];
    this.ips = [];
  }

  public static create(): EmoryHostFilters {
    return new EmoryHostFilters();
  }

  public get isFiltering(): boolean {
    return super.isFiltering 
      || this.versions.length > 0
      || this.sIds.length > 0
      || this.ips.length > 0;
  }

}