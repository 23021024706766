import { IOrganizationDto, Organization } from "@Module/Orgs/Models/Organization.model";
import { ExpirationFilterable } from "./ExpirationFilterable.model";
import { LicensingStatus } from "./LicensingStatus.model";
import { LicenseFilters } from "./LicenseFilters.model";
import { ServiceID } from "@Service/ServiceID";
import { Host, IHostDto } from "./Host.model";
import DayJS, { Dayjs } from "dayjs";

export interface ILicenseRecoveryDto  {
  hostContent: string;
}

export interface ILicenseDto {
  id: number;
  uuid: string;
  status: string;
  contractId: number;
  contractExternalId: string;
  dimension: string;
  platform: string;
  creation: string;
  expiration: string;
  maxHosts: number;
  hosts?: IHostDto[];
  owner: IOrganizationDto;
  shared_with?: IOrganizationDto;
}

export abstract class License<THost extends Host = any> implements ExpirationFilterable {
  public readonly id: number;
  public readonly externalId: string;
  public readonly status: LicensingStatus;
  public readonly contractId: number;
  public readonly contractExternalId: string;
  public readonly dimension: string;
  public readonly platform?: string;
  public readonly creation: Dayjs;
  public readonly expiration: Dayjs;
  public readonly maxHosts: number;
  public readonly hosts: THost[];
  public readonly owner: Organization;
  public readonly tenant?: Organization;
  
  public readonly serviceId: ServiceID;

  protected constructor(dto: ILicenseDto, serviceId: ServiceID) {
    this.id = dto.id;
    this.externalId = dto.uuid;
    this.status = LicensingStatus.create(dto.status);
    this.contractId = dto.contractId;
    this.contractExternalId = dto.contractExternalId;
    this.dimension = dto.dimension;
    this.platform = dto.platform || undefined;
    this.creation = DayJS(dto.creation);
    this.expiration = DayJS(dto.expiration);
    this.maxHosts = dto.maxHosts;
    this.hosts = !!dto.hosts ? this.parseHosts(dto.hosts) : [];
    this.serviceId = serviceId;
    this.owner = Organization.fromDto(dto.owner);
    if (!!dto.shared_with) {
      this.tenant = Organization.fromDto(dto.shared_with);
    }
  }

  protected abstract parseHosts(dtos: IHostDto[]): THost[];

  public get hostCount(): number {
    return this.hosts.length;
  }

  public get hasAvailability(): boolean {
    return this.status.isActive && this.hosts.length < this.maxHosts;
  }

  public get isMultiHost(): boolean {
    return this.maxHosts > 1;
  }

  public get isShared(): boolean {
    return !!this.tenant;
  }

  public isSharedToOrg(org?: Organization) {
    return this.isShared && this.tenant.id === org?.id;
  }

  public isOwnedByOrg(org?: Organization) {
    return this.owner.id === org?.id;
  }

  public isEligibleToShare(owner?: Organization, tenant?: Organization): boolean {
    return this.status.isActiveOrInGrace && !this.isShared && this.isOwnedByOrg(owner);
  }

  public isEligibleToReturn(owner?: Organization, tenant?: Organization): boolean {
    return this.isOwnedByOrg(owner) && this.isSharedToOrg(tenant);
  }

  public get usagePercentage(): number {
    const usage = this.hosts.length / this.maxHosts;
    return Math.round((usage * 100) * 10) / 10;
  }

  public meetFilters(filters: LicenseFilters): boolean {

    if (filters.onlyWithHosts && this.hostCount === 0) {
      return false;
    }

    const meetsOwner = filters.owners.length > 0
      ? filters.owners.some(owner => owner.id === this.owner?.id) : true;
    if (!meetsOwner) { return false; }

    const meetsTenant = filters.tenants.length > 0
      ? filters.tenants.some(tenant => tenant.id === this.tenant?.id) : true;
    if (!meetsTenant) { return false; }
    
    const meetsStatus = filters.status.length > 0 
      ? filters.status.includes(this.status.phase) : true;
    if (!meetsStatus) { return false; }
    
    const meetsDimensions = filters.dimensions.length > 0 
      ? filters.dimensions.some(d => d.name === this.dimension) : true;
    if (!meetsDimensions) { return false; }
    
    if (!this.hasAvailability && filters.withAvailability) {
      return false;
    }

    const meetsHostFilters = filters.hosts.isFiltering 
      ? this.hosts.some(host => host.meetFilters(filters.hosts)) : true;
    if (!meetsHostFilters) { return false; }
    
    const text = filters.search?.toLowerCase() || "";
    return this.meetsSearch(text);
  }

  public meetsSearch(query: string): boolean {
    const text = query.toLowerCase();
    if (!text) { return true; }
    const state = this.status.label.toLowerCase();
    const code = this.externalId.toLowerCase();
    const dimension = this.dimension.toLowerCase();
    const contractExternalId = this.contractExternalId;
    const platform = this.platform?.toLowerCase() || "";
    const ownerSearch = this.owner.meetsSearch(text) ?? false;
    const tenantSearch = this.tenant?.meetsSearch(text) ?? false;
    return state.includes(text) 
      || code.includes(text) 
      || dimension.includes(text) 
      || contractExternalId.includes(text)
      || platform.includes(text)
      || ownerSearch
      || tenantSearch;
  }

  public meetsExpirationThreshold(threshold: Dayjs): boolean {
    return !this.status.isExpired && this.expiration.isBefore(threshold);
  }

}