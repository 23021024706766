import { YesNoOption } from "@Core/Components/YesNoCheckboxGroup/YesNoCheckboxGroup";
import { HostFilters } from "@Module/Licensing/Models/HostFilters.model";

export class AbapSuiteHostFilters extends HostFilters {

  public production: YesNoOption[];
  public sIds: string[];

  protected initialize() {
    super.initialize();
    this.production = [];
    this.sIds = [];
  }

  public static create(): AbapSuiteHostFilters {
    return new AbapSuiteHostFilters();
  }

  public get isFiltering(): boolean {
    return super.isFiltering 
      || this.sIds.length > 0
      || this.production.length > 0;
  }

}