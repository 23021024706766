import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { TreeNode, TreeData, TreeProps, ElTree } from "element-ui/types/tree";
import { Component, Prop, Watch } from "types-vue";
import IsEqual from "lodash.isequal";
import Vue from "vue";

export interface OrgRoleTreeData extends TreeData {
  orgRole: OrganizationRole;
  children: OrgRoleTreeData[];
}

export type OrgRoleTreeNode = TreeNode<number, OrgRoleTreeData>;

interface TreeCheckState {
  checkedNodes: OrgRoleTreeData[];
  halfCheckedNodes: OrgRoleTreeData[];
  checkedKeys: number[];
  halfCheckedKeys: number[];
}

@Component
export default class OrgRoleTree extends Vue {

  @Prop({ default: null })
  protected value: OrganizationRole | OrganizationRole[];

  @Prop({ type: Array, required: true })
  protected data: OrganizationRole[];

  @Prop({ type: Boolean, default: false })
  protected includeNoAccessRoles: boolean;

  @Prop({ type: String, default: "There are no organizations." })
  protected noDataText: string;

  @Prop({ type: String, default: "No organization meets your filtering criteria." })
  protected noMatchText: string;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected showCheckbox: boolean;

  @Prop({ type: Boolean, default: false })
  protected plain: boolean;

  @Prop({ type: Boolean, default: false })
  protected accordion: boolean;

  @Prop({ type: Boolean, default: false })
  protected childInset: boolean;

  @Prop({ type: Boolean, default: false })
  protected groupKeyline: boolean;

  @Prop({ type: Boolean, default: false })
  protected defaultExpandAll: boolean;

  @Prop({ type: Array, default: () => {} })
  protected defaultExpand: OrganizationRole[];

  @Prop({ type: Object, default: null })
  protected defaultHighlight: OrganizationRole;

  @Prop({ type: Boolean, default: true })
  protected selectable: boolean;

  @Prop({ type: Boolean, default: false })
  protected noExpand: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: Boolean, default: true })
  protected showRole: boolean;

  @Prop({ type: Number, default: Number.MAX_SAFE_INTEGER })
  protected levelLimit: number;

  @Prop({ type: Array, default: () => [] })
  protected disabledItems: OrganizationRole[];

  protected searchText: string = null;
  protected isEmpty: boolean = false;

  @Watch("value", { immediate: true })
  public onValuePropChange(newValue: OrganizationRole | OrganizationRole[]) {
    if (!newValue) { 
      this.clearSelection();
    } else if (!Array.isArray(newValue)) {
      const orgId = newValue.organization.id;
      this.setHighlight(orgId);
      this.setSelection(orgId);
    } else {
      const orgIds = newValue.map(orgRole => orgRole.organization.id);
      this.setSelection(...orgIds);
      if (orgIds.length > 0 && !this.getCurrentTreeItem()) {
        this.setHighlight(orgIds[0]);
      }
    }
  }

  protected mounted() {
    this.onValuePropChange(this.value);
    this.$watch(
      () => (this.$refs.tree as any)?.isEmpty || false, 
      empty => this.isEmpty = empty, 
      { immediate: true }
    );
    if (!!this.defaultHighlight) {
      this.setHighlight(this.defaultHighlight.organization.id);
    }
  }
  
  protected getTree(): ElTree<number, OrgRoleTreeData> | undefined {
    return this.$refs.tree as ElTree<number, OrgRoleTreeData>;
  }

  public getSelectedTreeItem(): HTMLElement | undefined {
    if (!this.value) {
      return;
    } else if (!Array.isArray(this.value)) {
      return this.getTreeItem(this.value.organization.id);
    } else if (this.value.length > 0) {
      const firstId = this.value[0].organization.id;
      return this.getTreeItem(firstId);
    }
  }

  public getTreeItem(id: number): HTMLElement {
    const tree = this.getTree();
    const childId = `#org-role-tree-item-${id}`;
    const treeItem: HTMLElement = tree.$el.querySelector(childId);
    return treeItem;
  }

  public highlightFirstTreeItem() {
    const firstTreeItem = this.getFirstTreeItem();
    if (!firstTreeItem) { return; }
    const firstEl: HTMLElement = firstTreeItem.firstChild.lastChild as HTMLElement;
    const firstId: number = parseInt(firstEl.dataset.orgId);
    this.setHighlight(firstId);
  }

  public highlightSelectedTreeItem() {
    const selectedTreeItem = this.getSelectedTreeItem();
    if (!selectedTreeItem) { return; }
    this.expandSelection();
    const firstSelectedId: number = parseInt(selectedTreeItem.dataset.orgId);
    this.setHighlight(firstSelectedId);
  }

  public getNavigableTreeItems(): HTMLElement[] {
    const treeItems: HTMLElement[] = Array.from((this.getTree() as any)?.treeItems ?? []);
    return treeItems.filter(el => el.classList.contains("is-focusable") && !el.classList.contains("is-hidden"));
  }

  public getFirstTreeItem(): HTMLElement | undefined {
    const navigableItems = this.getNavigableTreeItems();
    if (navigableItems.length === 0) { return; }
    return navigableItems[0];
  }

  public getCurrentTreeItem(): HTMLElement | undefined {
    const currId = this.getTree()?.getCurrentKey();
    if (!currId) { return; }
    return this.getTreeItem(currId);
  }

  public navigateTreeItems(command: "prev" | "next") {
    const navigableItems = this.getNavigableTreeItems();
    if (navigableItems.length === 0) { return; }
    const currIndex = navigableItems.findIndex(el => el.classList.contains("is-current"));
    let nextIndex = currIndex;
    if (command === "next") {
      nextIndex = currIndex + 1 > navigableItems.length - 1 ? 0 : currIndex + 1;
      for (let i = nextIndex; i <= navigableItems.length - 1; i++) {
        const target = navigableItems[i].firstChild.lastChild as HTMLElement;
        if (target.clientHeight > 0) {
          this.setHighlight(parseInt(target.dataset.orgId))
          return;
        }
      }
    } else {
      nextIndex = currIndex - 1 < 0 ? navigableItems.length - 1 : currIndex - 1;
      for (let i = nextIndex; i >= 0; i--) {
        const target = navigableItems[i].firstChild.lastChild as HTMLElement;
        if (target.clientHeight > 0) {
          this.setHighlight(parseInt(target.dataset.orgId))
          return;
        }
      }
    }
  }

  public selectCurrentTreeItem() {
    const node = this.getTree().getCurrentNode();
    this.onOrgRoleSelected(node);
  }

  public async setCurrentTreeItemExpand(expanded: boolean) {
    const tree = this.getTree();
    const currId = tree.getCurrentKey();
    const node = tree.getNode(currId);
    if (node.childNodes.length > 0) {
      node.expanded = expanded;
      await this.$nextTick();
      tree.$forceUpdate();
    }
  }

  protected get defaultExpandedKeys(): number[] {
    const shouldExpand = (data: OrgRoleTreeData) => {
      return data.orgRole.children.length > 0 && !data.isLeaf;
    };
    if (!!this.defaultExpandAll) {
      return this.treeData.map(data => {
        const expand = shouldExpand(data);
        const expandChildren = data.children.filter(shouldExpand).map(data => data.orgRole);
        return expand ? [data.orgRole, ...expandChildren] : expandChildren;
      }).flat().map(orgRole => orgRole.organization.id);
    } else if (!!this.defaultExpand) {
      return this.defaultExpand.reduce((expanded, orgRole) => {
        const data = this.treeData.find(data => data.orgRole.organization.id === orgRole.organization.id);
        if (!!data && shouldExpand(data)) { expanded.push(data.orgRole.organization.id); }
        return expanded;
      }, [] as number[]);
    } else {
      const selection = Array.isArray(this.value) ? this.value : !!this.value ? [this.value] : [];
      return selection
        .filter(orgRole => !!orgRole.organization.parentId)
        .map(i => i.organization.parentId);
    }
  }

  protected get treeData(): OrgRoleTreeData[] {
    return this.filterNoAccessRoles(this.data)
      .map(this.buildTreeNode);
  }

  protected get treeProps(): TreeProps {
    return {
      label: "label",
      children: "children",
      disabled: "disabled",
      isLeaf: "isLeaf"
    }
  }

  private filterNoAccessRoles(orgRoles: OrganizationRole[]): OrganizationRole[] {
    return !this.includeNoAccessRoles 
      ? orgRoles.filter(orgRole => !orgRole.role.isNoAccess)
      : orgRoles;
  }

  protected buildTreeNode(orgRole: OrganizationRole, level: number = 0): OrgRoleTreeData {
    const children = level < this.levelLimit 
      ? this.filterNoAccessRoles(orgRole.children)
        .map(orgRole => this.buildTreeNode(orgRole, level + 1))
      : [];
    const disabled = this.disabledItems.some(item => item.organization.id === orgRole.organization.id);
    return { 
      id: orgRole.organization.id,
      label: orgRole.organization.name,
      isLeaf: children.length === 0,
      disabled,
      children,
      orgRole
    }
  }

  public expandSelection() {
    const tree = this.$refs.tree as ElTree<number, OrgRoleTreeData>;
    const selectedParentNodes = tree.getCheckedNodes()
      .filter(node => !!node.orgRole.organization.parentId)
      .reduce((nodes, data) => {
        const node = tree.getNode(data.orgRole.organization.parentId);
        if (!!node) { nodes.push(node); }
        return nodes;
      }, [] as TreeNode<number, OrgRoleTreeData>[]);
    selectedParentNodes.forEach(node => node.expanded = true);
  }

  public async filterTree(query?: string, search: boolean = true) {
    this.searchText = query || null;
    const tree = this.$refs.tree as ElTree<number, OrgRoleTreeData>;
    tree.filter(query);
    if (search) {
      await this.$nextTick();
      this.highlightFirstTreeItem();
    }
  }

  public clearSelection() {
    const tree = this.$refs.tree as ElTree<number, OrgRoleTreeData>;
    tree?.setCheckedKeys([]);
  }

  public setSelection(...ids: number[]) {
    const tree = this.$refs.tree as ElTree<number, OrgRoleTreeData>;
    tree?.setCheckedKeys(ids);
  }

  public setHighlight(id: number) {
    const tree = this.$refs.tree as ElTree<number, OrgRoleTreeData>;
    tree?.setCurrentKey(id);
  }

  protected filterNode(value: string, data: OrgRoleTreeData): boolean {
    if (!value) { return true; }
    return data.orgRole.organization.meetsSearch(value);
  }

  protected onOrgRoleChecked(_node: TreeNode<number, OrgRoleTreeData>, state: TreeCheckState): void {
    this.onValueChange(state.checkedNodes.map(node => node.orgRole));
  }

  protected onOrgRoleSelected(data: OrgRoleTreeData): void {
    if (data.disabled || !this.selectable) { return; }
    if (Array.isArray(this.value)) {
      const currSelection = Array.from(this.value);
      const currIndex = currSelection.findIndex(v => v.organization.id === data.orgRole.organization.id);
      if (currIndex !== -1) {
        currSelection.splice(currIndex, 1);
      } else {
        currSelection.push(data.orgRole);
      }
      this.onValueChange(currSelection);
    } else {
      this.onValueChange(data.orgRole);
    }
  }
  
  protected onValueChange(value: OrganizationRole | OrganizationRole[]) {
    const selectedOrgRole = value || (this.multiple ? [] : null);
    
    if (!Array.isArray(this.value) 
    && !Array.isArray(selectedOrgRole)
    && this.value?.organization.id === selectedOrgRole?.organization.id) {
      return;
    } else if (IsEqual(this.value, selectedOrgRole)) { 
      return; 
    }
    
    this.$emit("input", selectedOrgRole);
    this.$emit("select", selectedOrgRole);
    this.$emit("change", selectedOrgRole);

    this.searchText = null;
    this.filterTree(undefined, false);
  }

  protected get treeClass() {
    return {
      "is-filtering": !!this.searchText,
      "is-no-child-inset": !this.childInset,
      "is-no-selectable": !this.selectable,
      "is-group-keyline": this.groupKeyline,
      "is-checkbox": this.showCheckbox,
      "is-no-expand": this.noExpand,
      "is-plain": this.plain,
      "is-compact": this.compact,
      "is-one-item": this.treeData.length === 1 && this.treeData[0].isLeaf
    }
  }
  
}