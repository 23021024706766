import DayJS, { Dayjs } from "dayjs";
import { ILeadGeneratorDto, LeadGenerator } from "./LeadGenerator.model";

export interface ILeadGeneratorContractDto {
  id: Number;
  contractID: Number;
  leadGeneratorID: Number;
  observations?: string;
  leadGenerator?: ILeadGeneratorDto;
}

export class LeadGeneratorContract {
  id: Number;
  contractID: Number;
  leadGeneratorID: Number;
  LeadGenerator?: ILeadGeneratorDto;
  observations?: string;

 private constructor(dto: ILeadGeneratorContractDto ) {
   this.id = dto.id;
   this.contractID = dto.contractID;
   this.leadGeneratorID = dto.leadGeneratorID;
   this.LeadGenerator = dto.leadGenerator;
   this.observations = dto.observations;
 }

 public static create(dto?: Partial<ILeadGeneratorContractDto >): LeadGeneratorContract {
   return new LeadGeneratorContract({
      id: dto?.id,
      contractID: dto?.contractID,
      leadGenerator: dto?.leadGenerator,
      leadGeneratorID: dto?.leadGeneratorID,
      observations: dto?.observations,
   });
 }

 public toDto(): ILeadGeneratorContractDto {
    return {
      id: this.id,
      contractID: this.contractID,
      leadGenerator: this.LeadGenerator,
      leadGeneratorID: this.leadGeneratorID,
      observations: this.observations,
    };
 }

 public static fromDto(dto: ILeadGeneratorContractDto ): LeadGeneratorContract {
   return new LeadGeneratorContract(dto);
 }
}