import { Duration as DayJsDuration } from "dayjs/plugin/duration";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class TrialSummary extends Vue {

  @Prop({ type: Number, required: true })
  protected slots: number;

  @Prop({ type: Object, required: true })
  protected duration: DayJsDuration;

}