import { Payment } from "@Module/Reports/Models/Payment.model";
import { IReportContractDto, ReportContract } from "@Module/Reports/Models/ReportContract.model";
import ReportsStore from "@Module/Reports/Store/Reports.store";
import { DatePickerOptions } from "element-ui/types/date-picker";
import DayJS from "dayjs";
import { Component, MapAction, MapGetter, Prop, Watch} from "types-vue";
import Vue from "vue";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { cloneDeep } from "lodash";
import { BillingPeriod, IBillingPeriodDto } from "@Module/Reports/Models/BillingPeriod.model";
import dayjs from "dayjs";

@Component
export default class BillingPeriodDialog extends Vue {
  @MapGetter(ReportsStore.Mapping)
  protected billingPeriodDialogVisible: boolean;

  @MapAction(ReportsStore.Mapping)
  protected updateBillingPeriod: (contract: IBillingPeriodDto) => Promise<IBillingPeriodDto>;

  @MapAction(ReportsStore.Mapping)
  protected addBillingPeriod: (contract: IBillingPeriodDto) => Promise<IBillingPeriodDto>;

  @Prop({ type: Number, default: null })
  protected contractId: number;

  @MapAction(ReportsStore.Mapping)
  protected setBillingPeriodDialogVisible: (visible: boolean) => void
  
  protected billingPeriod: BillingPeriod = BillingPeriod.create({
    contractID: null,
    agreement: "",
    currency: "",
    observations: "",
    commercialProduct: "",
    payments: []
  })
  protected currencyOptions: string[] = ["USD", "EUR"]
  protected formType: string = "create"
  protected update = Execution.create((billingPeriod: IBillingPeriodDto) => this.updateBillingPeriod(billingPeriod));
  protected create = Execution.create((billingPeriod: IBillingPeriodDto) => this.addBillingPeriod(billingPeriod));

  async open(billingPeriod: BillingPeriod) {
    if (billingPeriod !== null){
      this.formType = "update"
      if (!!billingPeriod.payments){
        this.billingPeriod.payments = billingPeriod.payments.map(payment => { 
          if (payment.date != null){
            payment.date = DayJS(payment.date).locale("es").toDate(); return payment }
          })    
      } 
      this.billingPeriod.agreement = billingPeriod.agreement
      this.billingPeriod.awsFee = billingPeriod.awsFee
      this.billingPeriod.commercialProduct = billingPeriod.commercialProduct
      this.billingPeriod.paymentsNumber = billingPeriod.paymentsNumber
      this.billingPeriod.contractID = billingPeriod.contractID
      this.billingPeriod.currency = billingPeriod.currency
      this.billingPeriod.endDate = billingPeriod.endDate
      this.billingPeriod.id = billingPeriod.id
      this.billingPeriod.observations = billingPeriod.observations
      this.billingPeriod.revenue = billingPeriod.revenue
      this.billingPeriod.startDate = billingPeriod.startDate
      this.billingPeriod.totalAmount = billingPeriod.totalAmount
    } else {
      this.billingPeriod.contractID = this.contractId;
    }
    this.setBillingPeriodDialogVisible(true)
  }

  protected onClosed() {
    this.billingPeriod = BillingPeriod.create({
      contractID: null,
      agreement: "",
      currency: "",
      observations: "",
      commercialProduct: "",
      payments: []
    })
    this.formType = "create"
    this.update.reset();
    this.setBillingPeriodDialogVisible(false)
  }

  protected addPaymentForm() {
      this.billingPeriod.payments.push(Payment.create({ number: this.billingPeriod.payments.length + 1, billingPeriodId: this.billingPeriod.id}))
  }

  protected removePaymentForm() {
    this.billingPeriod.payments.pop()
  }

  async onOpen() {
    this.setBillingPeriodDialogVisible(true)
   /*this.billingPeriod.payments.map(payment => { 
      if (payment.date != null){
        payment.date = DayJS(payment.date).locale("es").toDate(); return payment }
      })    */
  }

  private async updateElement() {
    await this.update.run(this.billingPeriod.toDto());
      if (!this.update.error) {
        Notify.Success({
          title: "Billing Period updated",
          message: "Billing Period successfully updated.",
          duration: 3000
        });
        (this.$refs.dialog as Dialog).success(true);
      }
}


private async createElement() {
  await this.create.run(this.billingPeriod.toDto());
    if (!this.create.error) {
      Notify.Success({
        title: "Billing Period updated",
        message: "Billing Period successfully updated.",
        duration: 3000
      });
      (this.$refs.dialog as Dialog).success(true);
    }
}

  protected get datePickerOptions(): DatePickerOptions {
    return {
      shortcuts: [{
        text: 'In 1 Week',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "weeks").toDate())
      }, {
        text: 'In 15 Days',
        onClick: (picker) => picker.$emit("pick", DayJS().add(15, "days").toDate())
      }, {
        text: 'In 1 Month',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "month").toDate())
      }, {
        text: 'In 1 Year',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "year").toDate())
      }]
    }
  }

}