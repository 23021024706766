import { CustomHubResource } from "@Core/Models/CustomHubResource.model";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import { Component, MapAction, MapGetter } from "types-vue";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";

const enum ResourceTypeTab {
  Packages = "packages",
  Documentation = "docs",
  Other = "other"
}

const enum CustomResourcesType {
  OnlineDocs = "docs.online"
}

@Component
export default class AmisHub extends Vue {

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;
  
  @MapAction(AuthStore.Mapping)
  protected openAuthDialog: (context?: string) => Promise<boolean>;

  protected serviceId: ServiceID = ServiceID.Amis;
  protected activeTab: ResourceTypeTab = ResourceTypeTab.Packages;

  protected get docResources(): CustomHubResource[] {
    return [{ 
      id: CustomResourcesType.OnlineDocs, 
      label: "Service Documentation (Online)",
      meta: { url: `${process.env.VUE_APP_SERVICE_URL_DOCS}/amis` }
    }]
  }

  protected get otherResources(): CustomHubResource[] { 
    return [] 
  }

  protected customResourceSelected(item: CustomHubResource) {
    if (!!item.meta.url) {
      window.open(item.meta.url);
    }
  }

  protected signInForDownloads() {
    const ctx = "Sign into your account to download resources from this service.";
    this.openAuthDialog(ctx);
  }

  protected goToCatalog() {
    this.$router.push("/preinstalled-sap/catalog");
  }

  protected openIssueManager() {
    window.open(process.env.VUE_APP_SERVICE_URL_ISSUES);
  }

  protected get contactMail(): string {
    return process.env.VUE_APP_CONTACT_EMAIL;
  }

}