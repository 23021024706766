import { LicensingStatusPhase } from "@Module/Licensing/Models/LicensingStatus.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

@Component
export default class StatusSelector extends Vue {

  @Prop({ type: [String, Array], required: true })
  protected value: LicensingStatusPhase | LicensingStatusPhase[];

  @Prop({ type: String, default: "checkbox" })
  protected type: "checkbox" | "select" | "checkbox-button";

  @Prop({ type: String, default: "Filter by status" })
  protected placeholder: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected filterable: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Array, default: () => LicensingStatusPhase.Basic })
  protected options: LicensingStatusPhase[];

  protected status: LicensingStatusPhase | LicensingStatusPhase[] = null;

  @Watch("value", { immediate: true })
  protected onValueChange(status: LicensingStatusPhase | LicensingStatusPhase[]) {
    this.status = status;
  }

  protected onStatusSelectionChange(status: LicensingStatusPhase | LicensingStatusPhase[]) {
    this.$emit("input", status);
  }

  protected statusName(phase: LicensingStatusPhase): string {
    return LicensingStatusPhase.nameOf(phase);
  }

}