import Dialog from "@Core/Components/Dialog/Dialog";
import { ServiceID } from "@Service/ServiceID";
import { Component, MapAction, MapGetter, Watch } from "types-vue";
import { Prop } from "vue-property-decorator";
import Vue from "vue";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { ICreateReleaseDto, Release } from "@Module/Resources/Models/Release.model";
import ResourcesStore from "@Module/Resources/Store/Resources.store";
import { ICreatePlatformDto, Platform } from "@Module/Resources/Models/Platform.model";
import ResourcesApi from "@Module/Resources/API/Resources.api";
import { ICreateResourceDto, IResourceDto, Resource, ResourceFilters } from "@Module/Resources/Models/Resource.model";
import { Category } from "@Module/Resources/Models/Categories.model";
import { HttpRequestOptions } from "element-ui/types/upload";
import { ICreateProductVersionDto, ProductVersion } from "@Module/Resources/Models/ProductVersion.model";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";

@Component
export default class ManageDialog extends Vue {
  @MapGetter(ResourcesStore.Mapping)
  protected manageResourcesDialogVisible: boolean;

  @MapGetter(ResourcesStore.Mapping)
  protected allVersions: ProductVersion[];

  @MapGetter(ResourcesStore.Mapping)
  protected allPlatforms: Platform[];

  @MapAction(ResourcesStore.Mapping)
  protected setManageResourcesDialogVisible: (visible: boolean) => void

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @MapAction(ResourcesStore.Mapping)
  protected createPlatform: (platform: ICreatePlatformDto) => Promise<Platform>;

  @MapAction(ResourcesStore.Mapping)
  protected createProductVersion: (productVersion: ICreateProductVersionDto) => Promise<ProductVersion>;

  @MapAction(ResourcesStore.Mapping)
  protected createRelease: (data: ICreateReleaseDto) => Promise<Release>;

  @MapAction(ResourcesStore.Mapping)
  protected getUploadLink: (data: ICreateResourceDto) => Promise<string>;

  @MapAction(ResourcesStore.Mapping)
  protected fetchPlatforms: (serviceId: ServiceID) => Promise<Platform[]>;

  @MapAction(ResourcesStore.Mapping)
  protected fetchProductVersions: (serviceId: ServiceID) => Promise<ProductVersion[]>;

  protected getCategories = Execution.create(ResourcesApi.getCategories, ResourcesApi);
  protected uploadResource = Execution.create(ResourcesApi.uploadResource, ResourcesApi);
  protected deleteResource = Execution.create(ResourcesApi.deleteResource, ResourcesApi);
  protected abapSuite = ServiceID.AbapSuite
  protected categories: Category[] = []
  protected platform: Platform = Platform.create()
  protected productVersion: ProductVersion = ProductVersion.create()
  protected release: Release = Release.create()
  protected filteredPlatformsForRelease: Platform[] = []
  protected platformFilterForRelease: number = null
  protected resource: Resource = Resource.create()
  protected resourceFilters: ResourceFilters = {
    productVersionId: null,
    platformId: null,
    releaseId: null,
    filteredPlatforms: [],
    filteredReleases: [],
  }
  protected releaseNotes: IResourceDto[] = []
  protected formValue: string = 'version'
  protected selectedCategory: Category = null
  protected subcategories: string[] = []
  protected searchText: string = null
  protected fileList: File[] = []
  protected file: File = null

  @Watch("sessionToken", { immediate: true })
  protected async onSessionChange(token: SessionToken) {
    if (!!token && !token.hasExpired) {
      this.getCategories.run();
    } else {
      this.getCategories.reset();
    }
  }

  protected mounted() {
    this.fetchCategories();
    if (this.serviceId == this.abapSuite) {
      this.formValue = 'version'
    } else {
      this.formValue = 'platform'
    }
  }

  @Watch("allPlatforms", { immediate: true, deep: true })
  protected onAllPlatformsChange() {
    this.filteredPlatformsForRelease = this.allPlatforms
    this.resourceFilters.filteredPlatforms = this.allPlatforms
  }

  @Watch("platformFilterForRelease", { immediate: true, deep: true })
  protected onPlatformChange() {
    this.release.platform_id = null
    if (this.serviceId === this.abapSuite) {
      var selectedVersion = this.allVersions.find(version => version.id == this.platformFilterForRelease)
      if (selectedVersion != null) {
        this.filteredPlatformsForRelease = selectedVersion?.platforms.map(platform => Platform.fromDto(platform))
      }
    }
  }

  @Watch("resourceFilters.productVersionId", { immediate: true, deep: true })
  protected onResourceChange() {
    this.resourceFilters.platformId= null 
    this.resourceFilters.releaseId= null
    this.resource.releaseId = null
    this.resource.notesId = null
    this.resourceFilters.filteredPlatforms = []
    this.resourceFilters.filteredReleases = []
    this.releaseNotes = []
  
    if (this.serviceId === this.abapSuite) {
      var selectedVersion = this.allVersions.find(version => version.id == this.resourceFilters.productVersionId)
      if (selectedVersion != null) {
        this.resourceFilters.filteredPlatforms = selectedVersion?.platforms.map(platform => Platform.fromDto(platform))
      }
    }
  }

  @Watch("resourceFilters.platformId", { immediate: true, deep: true })
  protected onResourcePlatformChange() {
    this.resourceFilters.releaseId= null
    this.resource.releaseId = null
    this.resource.notesId = null
    this.resourceFilters.filteredReleases = []
    this.releaseNotes = []
  
    var selectedPlatform = this.resourceFilters.filteredPlatforms.find(platform => platform.id == this.resourceFilters.platformId)
    if (selectedPlatform != null) {
      this.resourceFilters.filteredReleases = selectedPlatform?.releases
    }
  }

  @Watch("resource.releaseId", { immediate: true, deep: true })
  protected onResourceReleaseChange() {
    var release: Release = this.resourceFilters.filteredReleases.find(release => release.id === this.resource.releaseId)
    if (release != null) {
      this.releaseNotes = release.resources.filter(resource => resource.subcategory === 'Release Note')
    }

  }

  protected async fetchCategories() {
    const dtos = await this.getCategories.run();
    this.categories = dtos.map(Category.fromDto);
  }

  private async createNewPlatform() {
    var platformData: ICreatePlatformDto = {
      product: this.serviceId,
      name: this.platform.name,
      description: this.platform.description,
    }
    if (this.serviceId == this.abapSuite) {
      platformData['product_version_id'] = this.platform.productVersionId
    }
    await this.createPlatform(platformData);
    Notify.Success({
      title: "platform created",
      message: `platform ${this.platform.name} has been successfully created.`,
      duration: 6000
    });
    this.$emit("success");
  }

  private refreshFetch() {
    if (this.serviceId == this.abapSuite) {
      this.fetchProductVersions(this.serviceId);
    } else {
      this.fetchPlatforms(this.serviceId);
    } 
  }

  private async createNewProductVersion() {
    var pVersionData: ICreateProductVersionDto = {
      product: this.serviceId,
      version: this.productVersion.version,
      description: this.productVersion.description,
    }
    await this.createProductVersion(pVersionData);
    Notify.Success({
      title: "release created",
      message: `release ${this.productVersion.version} has been successfully created.`,
      duration: 6000
    });
    this.$emit("success");
  }

  private async createElement() {
    switch (this.formValue) {
      case 'resource':
        this.createNewResource();
        this.refreshFetch();
        break;
      case 'release':
        await this.createNewRelease();
        this.refreshFetch();
        break;
      case 'version':
        await this.createNewProductVersion();
        this.refreshFetch();
        break;
      case 'platform':
        await this.createNewPlatform();
        this.refreshFetch();
        break;
    }
    this.refreshFetch();
    (this.$refs.dialog as Dialog).success();
  }

  protected get canCreate(): boolean {
    var canCreate: boolean
    switch (this.formValue) {
      case 'version':
        canCreate = !!this.productVersion.description
          && !!this.productVersion.version
        break;
      case 'release':
        canCreate = !!this.release.description
          && !!this.release.name && !!this.release.platform_id
        break;
      case 'platform':
        canCreate = !!this.platform.description
          && !!this.platform.name

        if (this.serviceId == this.abapSuite && canCreate == true){
          canCreate = !!this.platform.productVersionId
        }
        break;
      case 'resource':
        canCreate = !!this.resource.category
          && !!this.resource.subcategory
          && !!this.resource.description
          && !!this.resource.fileName
          && !!this.resource.releaseId
        break;
    }
    return canCreate
  }

  private async createNewRelease() {
    var releaseData: ICreateReleaseDto = {
      name: this.release.name,
      platform_id: this.release.platform_id,
      description: this.release.description,
    }
    await this.createRelease(releaseData);
    Notify.Success({
      title: "release created",
      message: `release ${this.release.name} has been successfully created.`,
      duration: 6000
    });
    this.$emit("success");
  }

  private async createNewResource() {
    var resourceData: ICreateResourceDto = {
      resource: this.resource.toDto(),
      product: this.serviceId
    }
    let link = await this.getUploadLink(resourceData);

    let data: FormData = new FormData()
    data.append("File", this.file)
    let result = await this.uploadResource.run(link, data);
    if (this.uploadResource.error) {
      Notify.Error({
        title: "resource upload failed",
        message: `Resource could not be uploaded.`,
        duration: 6000
      });
      this.$emit("error");
    } else{
      Notify.Success({
        title: "resource created",
        message: `Resource has been successfully created.`,
        duration: 6000
      });
      this.$emit("success");
    }
    
  }

  protected onCategorySelected(category: Category): void {
    this.resource.category = category.name
    this.resource.subcategory = null
    this.subcategories = category.subcategories
    this.searchText = null;
  }

  protected async process(data: HttpRequestOptions) {
    this.resource.fileName = data.file.name
    this.file = data.file
  }

  protected onClosed() {
    this.resource = Resource.create();
    this.platform = Platform.create();
    this.release = Release.create();
    this.platformFilterForRelease = null;
    this.file = null;
    this.releaseNotes = []
    this.fileList = []
    this.resourceFilters = {
      productVersionId: null,
      platformId: null,
      releaseId: null,
      filteredPlatforms: [],
      filteredReleases: [],
    }

    if (this.serviceId == this.abapSuite) {
      this.filteredPlatformsForRelease = [];
    }

    if (this.serviceId !== this.abapSuite) {
      this.resourceFilters.filteredPlatforms = this.allPlatforms;
    }
  }

  protected handleRemove() {
    this.resource.fileName = null
    this.file = null
  }

  protected handleExceed() {
    this.$message.warning(`You can only upload one resource at a time`);
  }
}
