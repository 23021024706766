import { IReportContractDto, ReportContract } from "../Models/ReportContract.model";
import AxiosAPI from "@Core/API/AxiosAPI";
import { GetContractReportApiError } from "../Errors/GetContractReportApiError";
import { IReportContractFiltersDto } from "../Models/ReportContractFilters";
import { GetContractsReportApiError } from "../Errors/GetContractsReportApiError";
import { IfInfoDto, fInfoTypes } from "../Models/FinancialInfo.model";
import { ILeadGeneratorDto, LeadGenerator } from "../Models/LeadGenerator.model";
import { IBillingPeriodDto } from "../Models/BillingPeriod.model";


 class ReportsApi extends AxiosAPI {
  private static instance: ReportsApi;

  public static get Instance(): ReportsApi {
    return this.instance || (this.instance = new this());
  }

  protected url(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/reports`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  public async getReportContracts(params: IReportContractFiltersDto): Promise<IReportContractDto[]> {
    try {
      const url = this.url("contracts-report");
      const response = await this.axios.get(url, {
        params: { ...params }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving service relases. Please try again.`;
      throw new GetContractsReportApiError(message, {
        cause: error,
        meta: { filters: params }
      });
    }
  }


  public async uploadReports(): Promise<string> {
    try {
      const url = this.url("financial-upload");
      const response = await this.axios.put(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while updating financial data. Please try again.`;
      throw new GetContractsReportApiError(message, {
        cause: error,
      });
    }
  }

//MIRAR
  public async getReportContract(contractId: number): Promise<IReportContractDto> {
    try {
      const url = this.url("report" + "/" + contractId.toString());
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving service relases. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
        meta: { contractId: contractId }
      });
    }
  }

  public async getLeadGenReport(): Promise<string> {
    try {
      const url = this.url("lead-generator-report");
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving service relases. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }

  public async getFInfo(year: number): Promise<IfInfoDto[]> {
    try {
      const url = this.url("financial-info");
      const response = await this.axios.get(url,
        {
          params: { year: year }
        }
      );
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving service relases. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }

  public async getFinancialTypes(): Promise<fInfoTypes[]> {
    try {
      const url = this.url("financial-types");
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving service relases. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }


  public async getLeadGenerators(): Promise<ILeadGeneratorDto[]> {
    try {
      const url = this.url("lead-generators");
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving lead generators. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }
  

  public async getExcel (params: IReportContractFiltersDto): Promise<string> {
    try {
      const url = this.url("export");
      const response = await this.axios.get(url, {
        params: { ...params }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving datasource excel. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }

  public async getMRR (): Promise<string> {
    try {
      const url = this.url("mrr-report");
      const response = await this.axios.get(url, {
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving MRR info. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }


  public async getTCV (): Promise<string> {
    try {
      const url = this.url("tcv");
      const response = await this.axios.get(url, {
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving TCV info. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }


  public async updateReportContract(contractInfo: IReportContractDto): Promise<IReportContractDto> {
    try {
      const url = this.url("financial-contract");
      const response = await this.axios.post(url, {...contractInfo});
      return response.data;
    } catch (error) {
      const message = `An error occurred while updating report contract. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
  
      });
    }
  }
  

  public async updateBillingPeriod(billingPeriod: IBillingPeriodDto): Promise<IBillingPeriodDto> {
    try {
      const url = this.url("billing-period");
      const response = await this.axios.put(url, {...billingPeriod});
      return response.data;
    } catch (error) {
      const message = `An error occurred while updating billing period. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }

  public async addBillingPeriod(billingPeriod: IBillingPeriodDto): Promise<IBillingPeriodDto> {
    try {
      const url = this.url("billing-period");
      const response = await this.axios.post(url, {...billingPeriod});
      return response.data;
    } catch (error) {
      const message = `An error occurred while adding billing period. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }


  public async addFInfo(fInfo: IfInfoDto): Promise<IfInfoDto> {
    try {
      const url = this.url("financial-info");
      const response = await this.axios.post(url, {...fInfo});
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving financial info. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }

  public async updateFInfo(fInfo: IfInfoDto): Promise<IfInfoDto> {
    try {
      const url = this.url("financial-info");
      const response = await this.axios.put(url, {...fInfo});
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving service relases. Please try again.`;
      throw new GetContractReportApiError(message, {
        cause: error,
      });
    }
  }
}





export default ReportsApi.Instance;