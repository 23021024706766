import { BackupFilters } from "@Service/Emory/Models/Backups/BackupFilters.model";
import EmoryBackupsApi from "@Service/Emory/API/EmoryBackups.api";
import FileDownloadMixin from "@Core/Mixins/FileDownload.mixin";
import { ExportFormat } from "@Core/Models/ExportFormat.model";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { Prop } from "vue-property-decorator";
import { Component, mixins } from "types-vue";

interface FileRequestResult {
  name: string;
  content: string;
  base64: boolean;
  downloaded: boolean;
}

const enum ExportStep { 
  Configuration, 
  Download 
}

@Component
export default class EmoryBackupExportDialog extends mixins(FileDownloadMixin) {

  @Prop({ type: Object, required: true })
  protected filters: BackupFilters;

  @Prop({ type: Number, required: true })
  protected recordCount: number;

  protected exportResult: FileRequestResult = null;
  protected exportFormat = ExportFormat.CSV;
  protected exportBackups = Execution.create(EmoryBackupsApi.getBackupsExport, EmoryBackupsApi);

  public open() {
    return (this.$refs.dialog as Dialog).open();
  }

  protected onClosed() {
    this.exportResult = null;
    this.exportBackups.reset();
    this.exportFormat = ExportFormat.CSV
  }

  protected get currentExportStep(): ExportStep {
    return !this.exportResult ? ExportStep.Configuration : ExportStep.Download;
  }

  protected async startExport() {
    this.exportBackups.reset();
    const content = await this.exportBackups.run(this.filters.toDto(), this.exportFormat);
    const name = `Export-${new Date().getTime()}.zip`;
    if (!this.exportBackups.error) {
      this.exportResult = { 
        name, content, 
        base64: true, 
        downloaded: false 
      };
    }
  }

  protected async downloadBackupsHistoryFile(): Promise<void> {
    const content = this.exportResult.content;
    const fileName = this.exportResult.name;
    const base64 = this.exportResult.base64;
    this.downloadFile(fileName, content, { base64 });
    this.exportResult.downloaded = true;
  }

  protected get exportComplete(): boolean {
    return this.currentExportStep === ExportStep.Download;
  }

  protected get forgotDownload(): boolean {
    return !!this.exportResult && !this.exportResult.downloaded;
  }

  protected get hasRecords(): boolean {
    return this.recordCount > 0;
  }

  protected async dialogCloseGuard(close: VoidFunction) {
    if (this.exportBackups.loading) {
      try {
        await this.$confirm(
          "Your backups are still being exported, if you close the dialog you will not be able to download the resulting file.",
          "Export warning",
          { confirmButtonText: "Close and skip download", cancelButtonText: "Cancel", type: "warning" });
      } catch { return; }
    }
    if (this.forgotDownload) {
      try {
        await this.$confirm(
          "The export process is complete, however you have not downloaded the resulting file.",
          "Export download warning",
          { confirmButtonText: "Close and skip download", cancelButtonText: "Cancel", type: "warning" });
      } catch { return; }
    }
    close()
  }

}