import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class Breadcrumb extends Vue {

  @Prop({ required: true })
  protected route: ConsoleRoute;

  
  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  protected get shouldAppendCurrentRoute(): boolean {
    return !this.route.meta.breadcrumb?.hidden;
  }

  protected shouldDisable(item): boolean {
    return item.redirect?.name === this.currentRoute.name ;
  }

}