import ReportsStore from "@Module/Reports/Store/Reports.store";
import { Component, MapAction, MapGetter, Watch, mixins} from "types-vue";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { ServiceID } from "@Service/ServiceID";
import DayJS from "dayjs";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import FileDownloadMixin from "@Core/Mixins/FileDownload.mixin";
import { FInfo, FormattedfInfo, IfInfoDto } from "@Module/Reports/Models/FinancialInfo.model";
import AmisDialog from "@Module/Reports/Components/AmisDialog/AmisDialog.vue";
import FinancialInfoDialog from "../FinancialInfoDialog/FinancialInfoDialog.vue";

@Component
export default class FinancialInfoReport extends mixins(FileDownloadMixin) {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(ReportsStore.Mapping)
  protected fInfo: FInfo[];

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;


  @MapGetter(ReportsStore.Mapping)
  protected currentYear: number;
  
  @MapAction(ReportsStore.Mapping)
  protected fetchFInfo: (year: number) => Promise<FInfo[]>;

  @MapAction(ReportsStore.Mapping)
  protected setCurrentYear: (year: number) => void;

  protected serviceId: ServiceID = ServiceID.Console;
  protected year: number = new Date().getFullYear();
  protected formattedFInfo: FormattedfInfo[] = [];


  protected mounted() {
    if (this.permissions.CanListFinancialInfo) {
      this.fetchFInfo(this.year);
      this.formattedFInfo = this.transformDates(this.fInfo);
    }   
  }

  @Watch("fInfo")
  protected onFInfoChange() {
    this.formattedFInfo = this.transformDates(this.fInfo);
  }

  protected transformDates(fInfo: FInfo[]): FormattedfInfo[] {
    return fInfo.map((info) => {
      return {
        id: info.id,
        month: DayJS(info.date).format("MMMM"),
        fInfo: info,
        amount: info.amount,
        type: info.type,
        project: info?.project
      };
    });
    }

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }

  protected previousYear() {
    if (this.year > 2021) {
      this.year--;
      this.currentYear = this.year;
      this.fetchFInfo(this.year);
    }
  }

  protected nextYear() {
    if (this.year < new Date().getFullYear()) {
      this.year++;
      this.currentYear = this.year;
    this.fetchFInfo(this.year);

    } 
  }

  protected openFInfoEditor(ami?: FInfo) {
    const isEdit = ami? true : false;
    const targetAmi = ami || FInfo.create();
    (this.$refs.financialInfoDialog as FinancialInfoDialog).open(targetAmi, isEdit);
  }

  protected get permissions() {
    return {
      CanAddFinancialInfo: this.accessRole.can(this.serviceId, Permission.AddFinancialInfo),
      CanListFinancialInfo: this.accessRole.can(this.serviceId, Permission.ListFinancialInfo),
      CanEditFinancialInfo: this.accessRole.can(this.serviceId, Permission.EditFinancialInfo)
    }
  }
}