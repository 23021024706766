import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import { Map } from "@Core/Models/Map";
import Vue from "vue";

@Component
export default class Details extends Vue {

  @Prop({ type: String, default: null })
  protected label: string;

  @Prop({ type: String, default: null })
  protected value: string;

  @Prop({ type: String, default: "N/A" })
  protected placeholder: string;

  @Prop({ type: Boolean, default: false })
  protected indent: boolean;

  @Prop({ type: [String, Number], default: "4px" })
  protected spacing: string | number;

  @Prop({ type: String, default: "start" })
  protected align: "start" | "center" | "end";

  protected get labelStyle() {
    return {
      "margin-bottom": Utils.isNumber(this.spacing) ? `${this.spacing}px` : this.spacing
    }
  }

  protected get detailsClass(): Map<boolean> {
    return {
      "is-indent": this.indent === true,
      "is-align-start": this.align === "start",
      "is-align-center": this.align === "center",
      "is-align-end": this.align === "end"
    }
  }

}