import { Component } from "types-vue";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class Collapse extends Vue {

  @Prop({ type: [String, Array], default: null })
  protected value: string | string[];

  @Prop({ type: Boolean, default: false })
  protected accordion: boolean;

  @Prop({ type: Boolean, default: true })
  protected bordered: boolean;

  @Prop({ type: Boolean, default: false })
  protected noArrow: boolean;

  protected internalValue: string | string[] = null;

  @Watch("internalValue")
  protected onInternalValueChange(newValue: string | string[]) {
    this.$emit("input", newValue);
    this.$emit("update:value", newValue);
  }

  @Watch("value", { immediate: true })
  protected onValueChange(newValue: string | string[]) {
    this.internalValue = newValue;
  }

  protected get collapseClass() {
    return {
      "is-bordered": this.bordered,
      "is-no-arrow": this.noArrow
    }
  }

}