import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapGetter, mixins, Watch } from "types-vue";
import { SessionToken } from "../Models/SessionToken.model";
import { Console, NavigationGuardNext } from "vue-router";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import AuthStore from "@Module/Auth/Store/Auth.store";
import AxiosAPI from "../../../Core/API/AxiosAPI";
import { Notify } from "@Core/Utils/Notify";
import SignOutMixin from "./SignOut.mixin";
import { AxiosError } from "axios";

interface NavigationGuardParams {
  to: Console.Route;
  from: Console.Route;
  next: NavigationGuardNext;
}

@Component
export default class SessionInterceptorMixin extends mixins(SignOutMixin) {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(NavigationStore.Mapping)
  protected getRoute: (path: string) => ConsoleRoute;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  protected reqInterceptorId: number = null; 
  protected resInterceptorId: number = null; 

  @Watch("sessionToken")
  protected onSessionActiveChange(token: SessionToken) {
    if (!!token) {
      this.addApiInterceptors();
    } else {
      this.ejectApiInterceptors();
    }
  }

  protected created() {
    this.addRouteInterceptors();
  }

  protected beforeDestroy() {
    this.ejectApiInterceptors();
  }

  private addRouteInterceptors() {
    this.$router.beforeEach(async (to: Console.Route, from: Console.Route, next) => {
      if (!!this.sessionToken && this.sessionToken.hasExpired) {
        await this.signOutDueToSessionExpired({ to, from, next });
      }
      if (to.path !== from?.path) {
        this.$Progress.start();
      }
      next();
    });
    this.$router.afterEach((_to, _from) => {
      this.$Progress.finish();
    });
  }

  private addApiInterceptors() {
    if (this.reqInterceptorId !== null) { return; }
    this.reqInterceptorId = AxiosAPI.requestInterceptor.use(async config => {
      if (!config.unauthenticated && !!this.sessionToken && this.sessionToken.hasExpired) {
        await this.signOutDueToSessionExpired();
        return new Promise(() => {});
        
      }
      return config;
    });

    if (this.resInterceptorId !== null) { return; }
    this.resInterceptorId = AxiosAPI.responseInterceptor.use(undefined, 
      async (error: AxiosError) => {
        if (error.response?.status === 401 && !!this.sessionToken) {
          await this.signOutDueToSessionExpired();
        }
        return Promise.reject(error);
    });
  }

  private ejectApiInterceptors() {
    if (this.reqInterceptorId !== null) {
      AxiosAPI.requestInterceptor.eject(this.reqInterceptorId);
      this.reqInterceptorId = null
    }
    if (this.resInterceptorId !== null) {
      AxiosAPI.responseInterceptor.eject(this.resInterceptorId);
      this.resInterceptorId = null
    }
  }

  private async signOutDueToSessionExpired(nav?: NavigationGuardParams) {
    Notify.Info({ 
      title: "Session expired", 
      message: "You have been disconnected because your session has expired. Please sign in again.", 
      duration: 6000 
    });
    await this.performSignOut(true);
    this.$router.replace(ConsoleRoute.AuthPath).catch(e => e);

    /*if (!!nav) {
      const targetRoute = this.getRoute(nav.to.path);
      const legacyTarget = targetRoute?.isServiceLegacy === true;
      if (!legacyTarget) {
        return nav.next({ 
          path: nav.to.path, 
          query: nav.to.query,
          params: nav.to.params,  
          replace: true 
        });
      }
    }
    
    return nav.next({ 
      path: this.currentRoute.redirectPath, 
      replace: true 
    });*/
  }

}