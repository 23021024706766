import { Notification } from "@Module/Notifications/Models/Notification.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class NotificationsList extends Vue {

  @Prop({ type: Array, required: true })
  protected notifications: Notification[];

  @Prop({ type: Boolean, default: true })
  protected isInbox: boolean;

  @Prop({ type: Boolean, default: false })
  protected hideTypeLabel: boolean;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Number })
  protected loadingThrottle: number;

}