import { OrgContractSummary, OrgLicensingSummary, OrgServiceSummary } from "../../Models/OrgLicensingSummary.model";
import { OrgsLicensingSummaryFilters } from "@Module/Orgs/Models/OrgsLicensingSummaryFilters.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import { cellCallbackParams as TreeEvent } from "element-ui/types/table";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import { Component, MapGetter } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Debounce from "lodash.debounce";
import OrgsApi from "../../API/Orgs.api";
import Vue from "vue";

@Component
export default class OrgsLicensingSummaryView extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;
  
  @MapGetter(OrgsStore.Mapping)
  protected orgArrayParamSerializer: Serializer<Organization[]>;

  protected orgsSummaries: OrgLicensingSummary[] = [];
  protected getSummary = Execution.create(
    (filters: OrgsLicensingSummaryFilters) => OrgsApi.getOrgLicensingSummary(filters.toDto()), OrgsApi);

  protected filtersDrawerVisible: boolean = false;

  protected expandedIds: string[] = [];
  protected expandedOrgMaxLevel: Record<string, number> = {};
  protected allServices: ServiceID[] = ServiceID.WithLicensing;

  protected filters: OrgsLicensingSummaryFilters = OrgsLicensingSummaryFilters.create();
  protected disableFiltersWatcher: VoidFunction = null;

  protected filtersBackup = {
    filters: OrgsLicensingSummaryFilters.create()
  }

  protected mounted() {
    this.fetchSummary();
    this.disableFiltersWatcher = this.$watch("filters.dto", this.debouncedFetchSummary, { deep: true });
  }

  protected async resetFilters() {
    try {
      const msg = `Are you sure you want to clear all current filter criteria? This action cannot be undone.`;
      await this.$confirm(msg, "Clear confirmation", {
        type: "warning",
        confirmButtonText: "Clear filters",
        cancelButtonText: "Cancel" 
      });
      this.filters = OrgsLicensingSummaryFilters.create();
    } catch {}
  }

  protected get firstLoad(): boolean {
    return this.orgsSummaries.length === 0 && this.getSummary.loading;
  }

  protected debouncedFetchSummary = Debounce(this.fetchSummary, 500);
  protected async fetchSummary() {
    const dtos = await this.getSummary.run(this.filters);
    if (!this.getSummary.error) {
      this.orgsSummaries = dtos.map(OrgLicensingSummary.fromDto);
      this.orgsSummaries.sort((a, b) => a.org.name.localeCompare(b.org.name));
    }
  }

  protected get hasSummaries(): boolean {
    return this.orgsSummaries.length > 0;
  }
  
  protected get summaries(): OrgLicensingSummary[] {
    return this.orgsSummaries
      .filter(s => {
        if (this.filters.hideWithoutContracts && s.children.length === 0) { return false; }
        else if (this.filters.orgs.length === 0) { return true; }
        return this.filters.orgs.some(org => org.uuid === s.org.uuid);
      });
  }

  protected onExpandChange(row: any, expanded: boolean) {
    const item = row as OrgLicensingSummary | OrgServiceSummary | OrgContractSummary;
    const level = this.getLevel(item);
    const orgUUID = item.org.uuid;

    if (expanded) {
      this.expandedIds.push(item.treeData.id);
      this.expandedOrgMaxLevel[orgUUID] = Math.max(level + 1, this.expandedOrgMaxLevel[orgUUID] ?? 0);
    } else {
      const index = this.expandedIds.indexOf(item.treeData.id);
      if (index !== -1) {
        this.expandedIds.splice(index, 1);
      }
      this.expandedOrgMaxLevel[orgUUID] = Math.min(level, this.expandedOrgMaxLevel[orgUUID] ?? 0);
    }
  }

  protected getClassForCell(event: TreeEvent): string {
    const item = event.row as OrgLicensingSummary | OrgServiceSummary | OrgContractSummary;
    const level = this.getLevel(item);
    const orgUUID = item.org.uuid;

    let cls: string[] = [];

    const orgExpandedMaxLevel = this.expandedOrgMaxLevel[orgUUID] || 0;
    if (orgExpandedMaxLevel > 0 && level === 0) {
      cls.push("expanded-org-cell");
    }

    if (event.columnIndex === 0 && level === 0 && item.children.length === 0) {
      cls.push("no-expand-cell");
    }
    if (level > 0) {
      cls.push("inner-cell");
    }
    if (event.columnIndex <= level) {
      cls.push("path-cell");
    }
    if (event.columnIndex < level) {
      cls.push("idem-cell");
    }
    return cls.join(" ");
  }
  
  protected getLevel(item: OrgLicensingSummary | OrgServiceSummary | OrgContractSummary): number {
    if (item instanceof OrgLicensingSummary) {
      return 0;
    } else if (item instanceof OrgServiceSummary) {
      return 1;
    } else if (item instanceof OrgContractSummary) {
      return 2;
    } else {
      return 3;
    }
  }

  protected serviceLabel(service: ServiceID): string {
    return ServiceID.nameOf(service);
  }

  protected contractsStatus(service?: ServiceID): LicensingStatus[] {
    const contracts = this.summaries.map(summary => summary.contracts).flat();
    return contracts.filter(c => !service || c.service === service).map(c => c.status);
  }

  public async onNotVisible() {
    this.disableFiltersWatcher();
    this.filtersBackup = {
      filters: this.filters
    };
    this.filters = OrgsLicensingSummaryFilters.create();
  }

  public async onVisible() {
    this.filters = this.filtersBackup.filters;
    await this.debouncedFetchSummary();
    this.disableFiltersWatcher = this.$watch("filters.dto", this.debouncedFetchSummary, { deep: true });
  }

}