import { Utils } from "@Core/Utils/Utils";

declare type Func = (...args: any[]) => Promise<any>;

export class Execution<T extends Func> {

  public readonly run: T;
  protected mockBlock?: () => Promise<void>;
  public loading: boolean = false;
  public expceptionThrowing: boolean = false;
  public error: Error = null;

  private constructor(body: T) {
    this.run = (async (...args: any[]) => {
      try {
        this.loading = true;
        this.error = null;
        await this.mockBlock?.();
        const result = await body(...args);
        return result;
      } catch (error) {
        this.error = error;
        if (this.expceptionThrowing) {
          throw error;
        } else {
          console.error(error);
        }
      } finally {
        this.loading = false;
      }
    }) as any;
  }

  public static create<T extends Func>(body: T, thisArg?: any): Execution<T> {
    if (!!thisArg) {
      return new Execution(body.bind(thisArg));
    } else {
      return new Execution(body);
    }
  }

  public static dummy(sleep: number = 1000) {
    return this.create(async () => Utils.sleep(sleep));
  }

  public throws(): Execution<T> {
    this.expceptionThrowing = true;
    return this;
  }

  public mock(sleep: number, failProbability: number = .5): Execution<T> {
    this.mockBlock = async () => {
      await Utils.sleep(sleep);
      Utils.throw(failProbability);
    }
    return this;
  }

  public reset() {
    this.loading = false;
    this.error = null;
  }

}