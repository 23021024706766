import { OrgAccessRequestNotification } from '@Module/Notifications/Models/OrgAccessRequestNotification.model';
import { OrgInvitationNotification } from '@Module/Notifications/Models/OrgInvitationNotification.model';
import NotificationsOutboxStore from '@Module/Notifications/Store/NotificationsOutbox.store';
import NotificationsInboxStore from '@Module/Notifications/Store/NotificationsInbox.store';
import { OrganizationRole } from '@Module/Orgs/Models/Roles/OrganizationRole.model';
import { Notification, NotificationType } from '@Module/Notifications/Models/Notification.model';
import OrgEditorDialog from '@Module/Orgs/Components/OrgEditorDialog/OrgEditorDialog';
import { AccessRole, Role } from '@Module/Auth/Models/Roles/AccessRole.model';
import { Component, MapAction, MapGetter, mixins, Watch } from 'types-vue';
import OrgSelector from '@Module/Orgs/Components/OrgSelector/OrgSelector';
import { Permission } from '@Module/Auth/Models/Roles/Permissions.model';
import AppSettingsStore from '../../Store/AppSettings/AppSettings.store';
import { Organization } from '@Module/Orgs/Models/Organization.model';
import { SessionToken } from '@Module/Auth/Models/SessionToken.model';
import NavigationStore from '@Core/Store/Navigation/Navigation.store';
import OrgSwitchMixin from '@Module/Orgs/Mixins/OrgSwitch.mixin';
import AppRestartMixin from '@Core/Mixins/AppRestart.mixin';
import { ConsoleRoute } from '@Core/Models/ConsoleRoute';
import UsersStore from '@Module/Users/Store/Users.store';
import { User } from '@Module/Users/Models/User.model';
import OrgsStore from '@Module/Orgs/Store/Orgs.store';
import AuthStore from '@Module/Auth/Store/Auth.store';
import UsersApi from '@Module/Users/API/Users.api';
import { Execution } from '@Core/Models/Execution';
import { ServiceID } from '@Service/ServiceID';
import { Notify } from '@Core/Utils/Notify';
import { Utils } from '@Core/Utils/Utils';
import { Map } from '@Core/Models/Map';
import OrgsApi from '@Module/Orgs/API/Orgs.api';

@Component
export default class AccountSettings extends mixins(OrgSwitchMixin, AppRestartMixin) {

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;
  
  @MapGetter(UsersStore.Mapping)
  protected orgUsers: User[];

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected defaultOrgRole: OrganizationRole;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRole: OrganizationRole;

  @MapGetter(OrgsStore.Mapping)
  protected canSwitchOrg: boolean;
  
  @MapGetter(AuthStore.Mapping)
  protected roles: Role[];

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRoleHierarchy: OrganizationRole[];

  @MapGetter(OrgsStore.Mapping)
  protected orgSuggestions: Organization[];

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected myOrgRequestsOutbox: OrgAccessRequestNotification[];

  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgAccessRequestInbox: OrgAccessRequestNotification[];
  
  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgInvitationsInbox: OrgInvitationNotification[];

  @MapAction(OrgsStore.Mapping)
  protected setDefaultOrganization: (org: Organization) => Promise<void>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected fetchMyOutbox: (type: NotificationType) => Promise<Notification[]>;

  @MapAction(OrgsStore.Mapping)
  protected fetchOrgSuggestions: () => Promise<Organization[]>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<Notification[]>;

  @MapAction(UsersStore.Mapping)
  protected fetchOrgUsers: () => Promise<User[]>;

  @MapAction(UsersStore.Mapping)
  protected removeUserFromCurrentOrg: (user: User) => Promise<void>;

  protected remove = Execution.create((user: User) => this.removeUserFromCurrentOrg(user))
  protected currentOrgApiKey = "";
  protected leaveCurrentOrg = Execution.create(async (user: User, org: Organization) => UsersApi.removeUserFromOrg(user, org));
  protected setAsDefaultOrg = Execution.create((org: Organization) => this.setDefaultOrganization(org));
  protected getOrgUsers = Execution.create(async () => this.fetchOrgUsers());
  protected getOrgAPIKey = Execution.create(async (org: Organization) => OrgsApi.fetchOrgAPIKey(org));
  protected getInbox = Execution.create(async () => {
    try { return this.fetchInbox(); } catch {}
  });
  protected getOrgSuggestions = Execution.create(async () => {
    try {
      return Promise.all([
        this.fetchOrgSuggestions(),
        this.fetchMyOutbox(NotificationType.OrgAccessRequest)
      ]);
    } catch {}
  });

  @Watch("permissions.CanListOrgUsers", { immediate: true })
  protected async onCanListOrgUsers() {
    if (!this.permissions.CanListOrgUsers || !this.currentOrgRole) { return }
    this.getOrgUsers.run();
  }

  @Watch("currentOrgRole", { immediate: true })
  protected async onCurrentOrgRole() {
    if (!this.currentOrgRole) { return }
    this.getApiKey();
  }

  protected async mounted() {
    await this.getInbox.run();
    await this.getOrgSuggestions.run();
    this.getApiKey();
  }



  protected async getApiKey() {
    const orgAPIKey = await this.getOrgAPIKey.run(this.currentOrgRole.organization);
    if (!!orgAPIKey) {
      this.currentOrgApiKey = orgAPIKey;
    } else {
      Notify.Error({
        title: "API key error",
        message: `An error occurred while retrieving organization API key.`
      });
    }
  }

  protected get showListAccessRequestInbox(): boolean {
    return this.permissions.CanAcceptOrgAccessRequest && this.orgAccessRequestInbox.length > 0;
  }

  protected get showInvitationsInbox(): boolean {
    return this.orgInvitationsInbox.length > 0;
  }

  protected get firstOrgUsersLoad(): boolean {
    return this.orgUsers.length === 0 
        && this.getOrgUsers.loading;
  }

  protected get organizationUsers(): User[] {
    return this.orgUsers
      .filter(user => user.username !== this.sessionUser.username);
  }

  protected openOrgEditor(org?: Organization, parent?: Organization) {
    const targetOrg = org || Organization.create({ parent_id: parent?.id });
    (this.$refs.orgEditorDialog as OrgEditorDialog).open(targetOrg);
  }

  protected get orgAccessCount(): number {
    return this.orgRoles.filter(orgRole => !orgRole.role.isNoAccess).length;
  }

  protected async onDefaultOrgChange(org: Organization) {
    try {
      const title = `Default organization confirmation`;
      const message = `Are you sure you want to set ${org.name} as your default organization?`;
      await this.$confirm(message, title, {
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Set as default"
      });
      (this.$refs.defaultOrgSelector as OrgSelector)?.blur();
      await this.setAsDefaultOrg.run(org);
      if (!!this.setAsDefaultOrg.error) {
        Notify.Error({
          title: "Default organization error",
          message: `An error occurred setting ${org.name} as your default organization.`
        });
      } else {
        if (this.currentOrgRole.organization.id !== this.defaultOrgRole.organization.id) {
          this.switchToDefaultOrg();
        }
        await Utils.sleep(100);
        Notify.Success({
          title: `Organization set as default`,
          message: `The organization ${org.name} was successfully set as default.`
        });
      }
    } catch {}
  }

  protected async switchToDefaultOrg() {
    try {
      const title = `Switch to default organization`;
      const message = `Do you want to switch to your new default organization ${this.defaultOrgRole.organization.name}?`;
      await this.$confirm(message, title, {
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Switch to default"
      });
      this.switchOrgRole(this.defaultOrgRole);
    } catch {}
  }

  protected async leaveCurrentOrganization() {
    try {
      const org = this.currentOrgRole.organization;
      const title = `Leave organization`;
      const message = `Are you sure you want to leave <span class='text-semibold'>${org.name}</span> organization?`;
      await this.$prompt(message, title, {
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: `Leave ${org.name}`,
        confirmButtonClass: 'el-button--danger',
        dangerouslyUseHTMLString: true,
        inputErrorMessage: `Type '${org.name}' to confirm`,
        inputValidator: value => value === org.name,
        inputPlaceholder: `Type '${org.name}' to confirm`
      });
      await this.leaveCurrentOrg.run(this.sessionUser, this.currentOrgRole.organization);
      if (!this.leaveCurrentOrg.error) {
        this.promptAppRestart();
        await this.$nextTick();
        Notify.Success({
          title: `You have left ${org.name}`,
          message: `You have successfully left ${org.name} organization.`
        });
      } else {
        Notify.Error({
          title: `Error leaving ${org.name}`,
          message: `An error occurred while leaving ${org.name} organization. Please try again later.`
        });
      }
    } catch {}
  }

  protected get pageClass(): Map<boolean> {
    return { 
      "is-plain": this.isPlainUi
    }
  }

  protected get permissions() {
    return {
      CanEditOrg: this.accessRole.can(ServiceID.Console, Permission.EditOrg),
      CanInviteOrgMembers: this.accessRole.can(ServiceID.Console, Permission.InviteOrgMembers),
      CanListOrgUsers: this.accessRole.can(ServiceID.Console, Permission.ListOrgUsers),
      CanListOrgInvitations: this.accessRole.can(ServiceID.Console, Permission.ListOrgInvitations),
      CanAcceptOrgAccessRequest: this.accessRole.can(ServiceID.Console, Permission.AcceptOrgAccessRequest),
      CanCreateChildOrg: this.accessRole.can(ServiceID.Console, Permission.CreateChildOrg)
    }
  }

  protected getServiceName(service: ServiceID): string {
    return ServiceID.nameOf(service);
  }

}