import { IBackupDto } from "./Backup.model";
import DayJS, { Dayjs } from "dayjs";

export const enum LifecycleType {
  Daily = "D",
  Weekly = "W",
  Monthly = "M",
  Yearly = "Y"
}

export class BackupLifecycle {
  public readonly type: LifecycleType;
  public readonly expiration: Dayjs;

  public static fromDto(dto: IBackupDto): BackupLifecycle {
    return new BackupLifecycle(dto);
  }

  private constructor(dto: IBackupDto) {
    this.type = dto.lifecycle as LifecycleType;
    this.expiration = DayJS(dto.expiration_date);
  }

  public get hasExpired(): boolean {
    return this.expiration.isBefore(DayJS());
  }

  public get label(): string {
    switch (this.type) {
      case LifecycleType.Daily: return "Daily";
      case LifecycleType.Weekly: return "Weekly";
      case LifecycleType.Monthly: return "Monthly";
      case LifecycleType.Yearly: return "Yearly";
      default: return this.type;
    }
  }
}