export interface ISignInResponseDto {
  username: string;
  idtoken: string;
  accesstoken: string;
  refreshtoken: string;
  orgname: string;
  orguuid: string;
  role: string;
}

export class SignInResponse {
  public readonly idToken: string;
  public readonly accessToken: string;

  private constructor(dto: ISignInResponseDto) {
    this.idToken = dto.idtoken;
    this.accessToken = dto.accesstoken;
  }

  public static fromDto(dto: ISignInResponseDto): SignInResponse {
    return new SignInResponse(dto);
  }

}