import AxiosAPI from "@Core/API/AxiosAPI";

export interface ConsoleErrorOptions<TMeta = any> {
  cause?: Error;
  meta?: TMeta;
  fatal?: boolean;
}

export class ConsoleError<TMeta = any> extends Error {
  public readonly orguuid: string;
  public readonly id: string;
  public readonly cause?: Error;
  public readonly meta?: TMeta;
  public readonly isFatal: boolean;

  public constructor(message: string, options?: ConsoleErrorOptions<TMeta>) {
    super(message);
    this.cause = options?.cause;
    this.meta = options?.meta;
    this.isFatal = options?.fatal ?? false;
    this.orguuid = AxiosAPI.getOrgUUID();
    this.orguuid = AxiosAPI.getOrgID();
    Object.setPrototypeOf(this, new.target.prototype);
  }

}