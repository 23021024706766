import { ServiceID } from "@Service/ServiceID";
import { IPaymentDto } from "./Payment.model";
import DayJS, { Dayjs } from "dayjs";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import { Dimension, IDimensionUnitsDto } from "@Module/Licensing/Models/Dimension.model";

export interface IBillingPeriodDto {
  id: number;
  contractID: number;
  startDate: string;
  endDate?: string;
  agreement?: string;
  totalAmount?: number;
  revenue?:  number;
  paymentsNumber?: number;
  commercialProduct: string;
  awsFee?: number;
  currency?: string;
  payments: IPaymentDto[];
  observations?: string;
}

export class BillingPeriod {
  id: number;
  contractID: number;
  startDate: Dayjs;
  endDate?: Dayjs;
  agreement?: string;
  totalAmount?: number;
  revenue?:  number;
  paymentsNumber?: number;
  commercialProduct: string;
  awsFee?: number;
  currency?: string;
  payments: IPaymentDto[];
  observations?: string;



 private constructor(dto: IBillingPeriodDto) {
   this.id = dto.id;
   this.contractID = dto.contractID;
   this.agreement = dto?.agreement;
   this.currency = dto?.currency;
   this.observations = dto?.observations;
   this.totalAmount = dto?.totalAmount;
   this.paymentsNumber = dto?.paymentsNumber;
   this.awsFee = dto?.awsFee;
   this.revenue = dto?.revenue;
   this.commercialProduct = dto.commercialProduct;
   this.startDate = DayJS(dto.startDate);
   this.endDate = DayJS(dto?.endDate);
   this.payments = !dto.payments ? [] : dto.payments;
 }

 public static create(dto?: Partial<IBillingPeriodDto>): BillingPeriod {
   return new BillingPeriod({
     id: dto?.id,
     contractID: dto.contractID,
     agreement: dto?.agreement,
     currency: dto?.currency,
     observations: dto?.observations,
     totalAmount: dto?.totalAmount,
     paymentsNumber: dto?.paymentsNumber,
     awsFee: dto?.awsFee,
     revenue: dto?.revenue,
     payments: dto?.payments || [],
     commercialProduct: dto?.commercialProduct,
     startDate: dto?.startDate,
     endDate: dto?.endDate,
   });
 }

 public static fromDto(dto: IBillingPeriodDto): BillingPeriod {
   return new BillingPeriod(dto);
 }

 public toDto(): IBillingPeriodDto {
  return  {
    id: this.id,
    contractID: this.contractID,
    observations: this.observations ? this.observations : null, 
    agreement: this.agreement ? this.agreement : null,
    currency: this.currency,
    totalAmount: this.totalAmount,
    paymentsNumber: this.paymentsNumber,
    awsFee: this.awsFee,
    revenue: this.revenue,
    payments: this.payments,
    commercialProduct: this.commercialProduct,
    startDate: DayJS(this.startDate).format(),
    endDate: this.endDate ? DayJS(this.endDate).format() : null,
  }
}

}
