import { Contract } from "@Module/Licensing/Models/Contract.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class ContractValiditySummary extends Vue {

  @Prop({ type: Object, required: true })
  protected contract: Contract;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;
  
}