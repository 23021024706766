import { AbapSuiteHostFilters } from "@Service/AbapSuite/Models/Filters/AbapSuiteHostFilters.model";
import { AbapSuiteLicense } from "@Service/AbapSuite/Models/AbapSuiteLicense.model";
import { ServiceID } from "@Service/ServiceID";
import { Component } from "types-vue";
import Vue from "vue";

@Component
export default class AbapSuiteLicenseDetails extends Vue {

  protected serviceId = ServiceID.AbapSuite;
  protected filters = AbapSuiteHostFilters.create();

  protected viewContract(license: AbapSuiteLicense) {
    this.$router.push({
      name: "abap-suite-contract-details",
      params: { id: license.contractId.toString() }
    });
  }

}