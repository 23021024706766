import { GetResPermissionsApiError } from "../Errors/GetPermissionsApiError";
import { GetReleasesApiError } from "../Errors/GetReleasesApiError";
import { GetResourceLinkApiError } from "../Errors/GetResourceLinkApiError";
import { IResourcePermissionsDto } from "../Models/ResourcePermissions.model";
import { ICreateResourceDto} from "../Models/Resource.model";
import { ICreateReleaseDto, IReleaseDto } from "../Models/Release.model";
import { ServiceID } from "@Service/ServiceID";
import AxiosAPI from "@Core/API/AxiosAPI";
import { CreateReleaseApiError } from "../Errors/CreateReleaseApiError";
import { GetWizardReleasesApiError } from "../Errors/GetWizardReleasesApiError";
import { CreateProductVersionApiError } from "../Errors/CreateProductVersionApiError";
import { CreatePlatformApiError } from "../Errors/CreatePlatformApiError";
import { ICategoryDto } from "../Models/Categories.model";
import { AxiosRequestConfig } from "axios";
import { ICreatePlatformDto, IPlatformDto, Platform } from "../Models/Platform.model";
import { ICreateProductVersionDto, IProductVersionDto } from "../Models/ProductVersion.model";
import { UploadResourceApiError } from "../Errors/UploadResourceApiError";

class ResourcesAPI extends AxiosAPI {
  private static instance: ResourcesAPI;

  public static get Instance(): ResourcesAPI {
    return this.instance || (this.instance = new this());
  }

  protected url(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/resources`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  public async getPermissions(service: ServiceID): Promise<IResourcePermissionsDto> {
    try {
      const url = this.url("can_download");
      const response = await this.axios.get(url, {
        params: { product: service }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred determining service resource access permissions. Please try again.`;
      throw new GetResPermissionsApiError(message, {
        cause: error,
        meta: { service }
      });
    }
  }

  public async getReleases(service: ServiceID): Promise<IReleaseDto[]> {
    try {
      const url = this.url("product-versions");
      const response = await this.axios.get(url, {
        params: { product: service }
      });
      const releases = response.data as IReleaseDto[];
      return releases;
    } catch (error) {
      const message = `An error occurred while retrieving service relases. Please try again.`;
      throw new GetReleasesApiError(message, {
        cause: error,
        meta: { service }
      });
    }
  }

  public async createPlatform(platform: ICreatePlatformDto): Promise<IPlatformDto> {
    try {
      const url = this.url("platforms");
      const response = await this.axios.post(url, { ...platform });
      return response.data;
    } catch (error) {
      const message = `An error occurred creating release. Please try again.`;
      throw new CreatePlatformApiError(message, {
        cause: error,
        meta: { platform }
      })
    }
  }

  public async uploadResource(awsUrl: string, data: FormData): Promise<string> {
    try {
      var newAxios = AxiosAPI.awsAxios
      const response = await newAxios.put(awsUrl, data.get('File'));
      return response.data;
    } catch (error) {
      const message = `An error occurred creating release. Please try again.`;
      throw new UploadResourceApiError(message, {
        cause: error,
        meta: { data }
      })
    }
  }

  public async getUploadLink(data: ICreateResourceDto): Promise<string> {
    const url = this.url("upload");
    const config: AxiosRequestConfig = { params: { product: data.product } };
    const response = await this.axios.post(url, { ...data.resource }, config);
    return response.data;
  }

  public async deleteResource(resourceId: number): Promise<void> {
    try {
      const url = this.url("resources/" + resourceId);
      const response = await this.axios.delete(url);
    } catch (error) {
      const message = `An error occurred deleting release.`;
      throw new UploadResourceApiError(message, {
        cause: error,
      })
    }
  }


  public async createRelease(data: ICreateReleaseDto): Promise<IReleaseDto> {
    try {
      const url = this.url("releases");
      const response = await this.axios.post(url, { ...data });
      return response.data;
    } catch (error) {
      const message = `An error occurred creating release. Please try again.`;
      throw new CreateReleaseApiError(message, {
        cause: error,
        meta: { data }
      })
    }
  }

  public async createProductVersion(data: ICreateProductVersionDto): Promise<IProductVersionDto> {
    try {
      const url = this.url("product-versions");
      const response = await this.axios.post(url, { ...data });
      return response.data;
    } catch (error) {
      const message = `An error occurred creating ProductVersion. Please try again.`;
      throw new CreateProductVersionApiError(message, {
        cause: error,
        meta: { data }
      })
    }
  }


  public async getResourceLink(resId: number, service: ServiceID): Promise<string> {
    try {
      const url = this.url("download");
      const response = await this.axios.get(url, {
        params: { product: service, file: resId }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred obtaining a resource link. Please try again.`;
      throw new GetResourceLinkApiError(message, {
        cause: error,
        meta: { resId, service }
      });
    }
  }

  public async getProductVersions(product: ServiceID): Promise<IProductVersionDto[]> {
    try {
      const url = this.url("all-versions");
      const response = await this.axios.get(url, {
        params: { product: product }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred obtaining download resources. Please try again.`;
      throw new GetWizardReleasesApiError(message, {
        cause: error,
      });
    }
  }


  public async getPlatforms(product: ServiceID): Promise<IPlatformDto[]> {
    try {
      const url = this.url("all-platforms");
      const response = await this.axios.get(url, {
        params: { product: product }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred obtaining download resources. Please try again.`;
      throw new GetWizardReleasesApiError(message, {
        cause: error,
      });
    }
  }

  public async getCategories(): Promise<ICategoryDto[]> {
    try {
      const url = this.url("categories");
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred obtaining categories. Please try again.`;
      throw new GetWizardReleasesApiError(message, {
        cause: error,
      });
    }
  }

}



export default ResourcesAPI.Instance;
