import { BackupCloudProvider, CloudProviderType } from "./BackupCloudProvider.model";
import { BackupObject } from "../BackupObject.model";
import { IBackupDto } from "../Backup.model";
import { Utils } from "@Core/Utils/Utils";

export class BackupAwsCloudProvider implements BackupCloudProvider {
  private static BaseUrl: string = "https://s3.console.aws.amazon.com";

  public readonly location: string;
  public readonly path: string;
  public readonly encryption?: string;
  public readonly encryptionKeyName?: string;

  public readonly region: string;

  public static fromDto(dto: IBackupDto): BackupAwsCloudProvider {
    return new BackupAwsCloudProvider(dto);
  }

  protected constructor(dto: IBackupDto) {
    this.location = dto.location_name;
    this.path = dto.location_path;
    this.encryption = dto.encryption_type;
    this.encryptionKeyName = dto.encryption_key;
    this.region = dto.region;
  }

  public get providerName(): string {
    return CloudProviderType.nameOf(CloudProviderType.AWS);
  }

  public buildLocationUrl(object?: BackupObject): string {
    let url = BackupAwsCloudProvider.BaseUrl;
    if (!!this.location) { url += `/s3/${!!object ? 'object' : 'buckets'}/${this.location}`; }
    const path = object?.remotePath || this.path;
    if (!!path) { url += `/${path}${!!object ? '' : '/'}`; }
    if (!!this.region) { url += `?region=${this.region}`; }
    return Utils.sanitizeUrl(url);
  }
}