import { User } from "@Module/Users/Models/User.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class UserSummaryBanner extends Vue {

  @Prop({ type: Object, required: true })
  protected user: User;

}