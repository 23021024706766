import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";
import { ProductVersion } from "@Module/Resources/Models/ProductVersion.model";

@Component
export default class ProductVersionSelector extends Vue {

  @Prop({ type: String, default: "Pick a ProductVersion" })
  protected placeholder: string;

  @Prop({ type: Number, default: null })
  protected value: Number;

  @Prop({ type: [Object, Array], default: null })
  protected productVersions: ProductVersion[];

  @Prop({ type: String, default: null })
  protected serviceId: ServiceID;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected allowBrowsing: boolean;
  
  protected searchText: string = null;

  protected onVersionSelected(version: Number): void {
    const selectedVersion = version || null;
    this.$emit("input", selectedVersion);
    this.$emit("update:value", selectedVersion);
    this.$emit("select", selectedVersion);
    this.$emit("change", selectedVersion);
    this.searchText = null;
  } 
}