import { Organization } from "@Module/Orgs/Models/Organization.model";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Component } from "types-vue";
import Clone from "lodash.clonedeep";
import Vue from "vue";

@Component
export default class OrgEditorDialog extends Vue {

  protected newOrg: Organization = Organization.create();

  protected isLoading: boolean = false;

  public open(org: Organization = Organization.create()) {
    this.newOrg = Clone(org);
    const dialog = this.$refs.dialog as Dialog;
    return dialog.open();
  }

  protected get isUpdateMode(): boolean {
    return this.newOrg.isRemote;
  }

  protected onEditorSuccess(org: Organization) {
    (this.$refs.dialog as Dialog).success();
  }

}