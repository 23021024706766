import { CloudProviderType } from "@Service/Emory/Models/Backups/CloudProvider/BackupCloudProvider.model";
import { Backup } from "@Service/Emory/Models/Backups/Backup.model";
import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class EmoryBackupSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected backup: Backup;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;

  protected get backupUuid(): string {
    if (this.$mq.md) return Utils.smartTrim(this.backup.uuid, 18);
    else if (this.$mq.lg) return Utils.smartTrim(this.backup.uuid, 22);
    else return Utils.smartTrim(this.backup.uuid, 26);
  }

  protected get backupHostname(): string {
    if (this.$mq.md) return Utils.smartTrim(this.backup.host.hostname, 12);
    else if (this.$mq.lg) return Utils.smartTrim(this.backup.host.hostname, 16);
    else return Utils.smartTrim(this.backup.host.hostname, 20);
  }

  protected get providerName() {
    return CloudProviderType.nameOf(this.backup.type);
  }

}