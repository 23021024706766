import { Organization } from "@Module/Orgs/Models/Organization.model"
import { IDimensionUnitsDto } from "../Models/Dimension.model"
import { ConsoleError } from "@Core/Errors/ConsoleError"
import { ServiceID } from "@Service/ServiceID"

interface CreateContractApiErrorMeta {
  org: Organization;
  service: ServiceID;
  expiration: Date;
  dimensions: IDimensionUnitsDto[];
}

export class CreateContractApiError extends ConsoleError<CreateContractApiErrorMeta> {}