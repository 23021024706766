export const enum UserStatusPhase {
  Unconfirmed = "UNCONFIRMED",
  Confirmed = "CONFIRMED",
  Archived = "ARCHIVED",
  Compromised = "COMPROMISED",
  Unknown = "UNKNOWN",
  ResetRequired = "RESET_REQUIRED",
  ForcePasswordChange = "FORCE_CHANGE_PASSWORD",
}

export class UserStatus {
  public readonly phase: UserStatusPhase;

  private constructor(phase: string) {
    this.phase = phase as UserStatusPhase;
  }

  public static create(phase: string): UserStatus {
    return new UserStatus(phase);
  }

  public get label(): string {
    switch (this.phase) {
      case UserStatusPhase.Unconfirmed: return "Unconfirmed";
      case UserStatusPhase.Confirmed: return "Confirmed";
      case UserStatusPhase.Archived: return "Archived";
      case UserStatusPhase.Compromised: return "Compromised";
      case UserStatusPhase.Unknown: return "Unknown";
      case UserStatusPhase.ResetRequired: return "Reset required";
      case UserStatusPhase.ForcePasswordChange: return "Password change";
    }
  }

  public get description(): string {
    switch (this.phase) {
      case UserStatusPhase.Unconfirmed: return "User has been created but not confirmed.";
      case UserStatusPhase.Confirmed: return "User has been confirmed.";
      case UserStatusPhase.Archived: return "User is no longer active.";
      case UserStatusPhase.Compromised: return "User is disabled due to a potential security threat.";
      case UserStatusPhase.Unknown: return "User status is not known.";
      case UserStatusPhase.ResetRequired: return "User is confirmed, but the user must request a code and reset his or her password before he or she can sign in.";
      case UserStatusPhase.ForcePasswordChange: return "The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change his or her password to a new value before doing anything else.";
    }
  }
}