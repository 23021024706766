export enum FileSizeUnit {
  B = "B",
  KB = "KB",
  MB = "MB",
  GB = "GB",
  TB = "TB"
}

export namespace FileSizeUnit {

  export const All: FileSizeUnit[] = [
    FileSizeUnit.B, FileSizeUnit.KB, FileSizeUnit.MB, FileSizeUnit.GB, FileSizeUnit.TB
  ];

  export function getUnitFullName(unit: FileSizeUnit): string {
    switch (unit) {
      case FileSizeUnit.B: return "Bytes";
      case FileSizeUnit.KB: return "Kilobytes";
      case FileSizeUnit.MB: return "Megabytes";
      case FileSizeUnit.GB: return "Gigabytes";
      case FileSizeUnit.TB: return "Terabytes";
      default: throw new Error(`[describre] Unknown size unit '${unit}'`);
    }
  }

  export function toBytes(unit: FileSizeUnit): number {
    switch (unit) {
      case FileSizeUnit.B: return 1;
      case FileSizeUnit.KB: return 1024;
      case FileSizeUnit.MB: return 1048576;
      case FileSizeUnit.GB: return 1073741824;
      case FileSizeUnit.TB: return 1099511627776;
      default: throw new Error(`[toBytes] Unknown size unit '${unit}'`);
    }
  }

}

export class FileSize {
  public readonly value: number; 
  public readonly unit: FileSizeUnit;

  private constructor(bytes: number, decimals: number = 2) {
    const kiloBytes = FileSizeUnit.toBytes(FileSizeUnit.KB);
    const megaBytes = FileSizeUnit.toBytes(FileSizeUnit.MB);
    const gigaBytes = FileSizeUnit.toBytes(FileSizeUnit.GB);
    const teraBytes = FileSizeUnit.toBytes(FileSizeUnit.TB);

    if (bytes < kiloBytes) { 
      // return bytes if less than a KB
      this.value = bytes;
      this.unit = FileSizeUnit.B;
    } else if (bytes < megaBytes) {
      // return KB if less than a MB
      this.value = parseFloat((bytes / kiloBytes).toFixed(decimals));
      this.unit = FileSizeUnit.KB;
    } else if (bytes < gigaBytes) {
      // return MB if less than a GB
      this.value = parseFloat((bytes / megaBytes).toFixed(decimals));
      this.unit = FileSizeUnit.MB;
    } else if (bytes < teraBytes) {
      // return GB if less than a TB
      this.value = parseFloat((bytes / gigaBytes).toFixed(decimals));
      this.unit = FileSizeUnit.GB;
    } else {
      // return TB for higher sizes
      this.value = parseFloat((bytes / teraBytes).toFixed(decimals));
      this.unit = FileSizeUnit.TB;
    }
  }

  public static fromBytes(bytes: number, decimals = 2): FileSize {
    return new FileSize(bytes, decimals);
  }

  public get unitFullName(): string {
    return FileSizeUnit.getUnitFullName(this.unit);
  }

  public toString(): string {
    return `${this.value} ${this.unit}`;
  }

  public get bytes(): number {
    return this.value * FileSizeUnit.toBytes(this.unit);
  }

}