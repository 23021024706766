import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, MapGetter, Prop, Watch } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import OrgRoleTree from "../OrgRoleTree/OrgRoleTree";
import { ElSelect } from "element-ui/types/select";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class OrgRoleSelector extends Vue {

  @Prop({ type: [Object, Array], default: null })
  protected value: OrganizationRole | OrganizationRole[];

  @Prop({ type: Array })
  protected data: OrganizationRole[];

  @Prop({ type: Boolean, default: false })
  protected childInset: boolean;

  @Prop({ type: Boolean })
  protected includeNoAccessRoles: boolean;

  @Prop({ type: String })
  protected noDataText: string;

  @Prop({ type: String })
  protected noMatchText: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @Prop({ type: Boolean, default: false })
  protected collapseTags: boolean;

  @Prop({ type: Boolean, default: false })
  protected compact: boolean;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: true })
  protected showRole: boolean;

  @Prop({ type: Boolean, default: false })
  protected defaultExpandAll: boolean;

  @Prop({ type: Boolean, default: false })
  protected noExpand: boolean;

  @Prop({ type: String, default: "Select an organization" })
  protected placeholder: string;

  @Prop({ type: Array, default: () => [] })
  protected disabledItems: OrganizationRole[];

  @MapGetter(OrgsStore.Mapping)
  protected orgRoles: OrganizationRole[];

  @MapGetter(OrgsStore.Mapping)
  protected orgRolesHierarchy: OrganizationRole[];

  protected searchText: string = null;
  protected visibleDropdown: boolean = false;
  
  protected selection: OrganizationRole | OrganizationRole[] = null;

  @Watch("value", { immediate: true })
  protected onValuePropChange(newValue: OrganizationRole | OrganizationRole[]) {
    this.selection = newValue;
  }

  protected async onSelectionChange(selection: OrganizationRole | OrganizationRole[]) {
    const newValue = selection || (this.multiple ? [] : null);
    
    this.$emit("input", newValue);
    this.$emit("select", newValue);
    this.$emit("change", newValue);

    this.searchText = null;
    if (!this.multiple) {
      await Utils.sleep(0);
      await this.$nextTick();
      this.blur();
    }
  }

  protected get treeData(): OrganizationRole[] {
    return this.data || this.orgRolesHierarchy;
  }
  
  protected getOrgRoleTree(): OrgRoleTree | undefined {
    return this.$refs.tree as OrgRoleTree;
  }

  protected async onSelectFilterChange(text: string) {
    const tree = this.getOrgRoleTree();
    this.searchText = text || null;
    tree.filterTree(text);
    if (!this.searchText) {
      const element = tree.getSelectedTreeItem();
      if (!element) { return; }
      this.scrollToTreeItem(element);
      const orgId = parseInt(element.dataset.orgId);
      await this.$nextTick();
      tree.setHighlight(orgId);
    }
  }

  protected get hasNoValue(): boolean {
    if (!this.value) { 
      return true; 
    } else if (Array.isArray(this.value)) { 
      return this.value.length === 0;
    } else {
      return false;
    }
  }
  
  protected async onVisibleChange(visible: boolean) {
    this.visibleDropdown = visible;
    if (visible) {
      const tree = this.getOrgRoleTree();
      const treeItem = tree.getSelectedTreeItem();
      if (!!treeItem) {
        tree.highlightSelectedTreeItem();
        this.scrollToTreeItem(treeItem);
      } else {
        tree.highlightFirstTreeItem();
        const firstTreeItem = tree.getFirstTreeItem();
        this.scrollToTreeItem(firstTreeItem);
      }
    } else {
      await Utils.sleep(200);
      this.searchText = null;
      this.getOrgRoleTree()?.filterTree();
      if (!this.value) {
        this.selection = null;
      }
    }
  }

  public focus() {
    (this.$refs.select as ElSelect).focus();
  }

  public blur() {
    (this.$refs.select as ElSelect).blur();
  }

  protected async scrollToTreeItem(treeItem: HTMLElement, options: ScrollToOptions = {}) {
    options.behavior ??= "auto";
    await this.$nextTick();
    const scroll: Element = (this.$refs.select as any).$refs.popper.$el.querySelector('.el-select-dropdown__wrap');
    if (!!scroll && !!treeItem) {
      const scrollOffset = (treeItem.offsetTop + treeItem.clientHeight) - (scroll.clientHeight / 2);
      scroll.scroll({ top: scrollOffset, ...options });
    }
  }

  protected async onKeyDown(event: KeyboardEvent) {
    const tree = this.getOrgRoleTree();

    if (event.key === "Enter") {
      tree.selectCurrentTreeItem();
      return;
    } else if (event.key === "ArrowRight") {
      tree.setCurrentTreeItemExpand(true);
      return;
    } else if (event.key === "ArrowLeft") {
      tree.setCurrentTreeItemExpand(false);
      return;
    }

    if (event.key === "ArrowUp") {
      tree.navigateTreeItems("prev");
    } else if (event.key === "ArrowDown") {
      tree.navigateTreeItems("next");
    }
    await this.$nextTick();
    const treeItem = tree.getCurrentTreeItem();
    this.scrollToTreeItem(treeItem, { behavior: "smooth" });
  }

  protected onSelectChange(selection: OrganizationRole | OrganizationRole[]) {
    this.selection = selection;
    this.onSelectionChange(selection);
  }

  protected onTreeOrgRolesChange(selection: OrganizationRole | OrganizationRole[]) {
    this.onSelectionChange(selection);
  }

  protected onSelectClear() {
    this.onSelectionChange(null);
  }

  protected get isMini() {
    return this.size === "mini"
  }

}