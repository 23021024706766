import { Organization } from "@Module/Orgs/Models/Organization.model";
import { INotificationDto, Notification } from "./Notification.model";

interface IOrgAccessRequestDetailsDto {}

type IOrgAccessRequestNotificationDto = INotificationDto<IOrgAccessRequestDetailsDto>;

export class OrgAccessRequestNotification extends Notification {

  public readonly organization: Organization;

  private constructor(dto: IOrgAccessRequestNotificationDto) {
    super(dto);
  }

  public static fromDto(dto: IOrgAccessRequestNotificationDto): OrgAccessRequestNotification {
    return new this(dto);
  }
}