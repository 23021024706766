import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class OrgDetails extends Vue {

  @Prop({ type: Object, required: true })
  protected org: Organization;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;

  protected get orgName(): string {
    if (this.$mq.md) return Utils.smartTrim(this.org.name, this.expand ? 8 : 14);
    else if (this.$mq.lg) return Utils.smartTrim(this.org.name, this.expand ? 16 : 18);
    else return Utils.smartTrim(this.org.name, this.expand ? 20 : 26);
  }

  protected get orgCode(): string {
    if (this.$mq.md) return Utils.smartTrim(this.org.uuid, this.expand ? 6 : 8);
    else if (this.$mq.lg) return Utils.smartTrim(this.org.uuid, this.expand ? 8 : 10);
    else return Utils.smartTrim(this.org.uuid, this.expand ? 10 : 14);
  }

}