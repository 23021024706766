import OrgsStore, { InitOrgsPayload, OrgsStoreAction, OrgsStoreGetter } from "@Module/Orgs/Store/Orgs.store";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { UsersStoreState } from "./Users.state";
import { User } from "../Models/User.model";
import { Utils } from "@Core/Utils/Utils";
import UsersApi from "../API/Users.api";
import { ActionContext } from "vuex";
import Vue from "vue";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";

const enum UsersStoreCookies {
  LastUser = "users.last-user"
}

const enum UsersStoreCommit {
  SetSessionUser = "_setSessionUser",
  SerOrgUsers = "_setOrgUsers",
  SetUsers = "_setUsers",
  AddUsers = "_addUsers",
  AddOrgUsers = "_addOrgUsers",
  RemoveAppUser = "_removeAppUser",
  RemoveOrgUser = "_removeOrgUser"
}

export const enum UsersStoreAction {
  InitSessionUser = "initSessionUser",
  DeinitSessionUser = "deinitSessionUser",
  FetchOrgUsers = "fetchOrgUsers"
}

export const enum UsersStoreGetter {
  sessionUser = "sessionUser"
}

declare type UsersStoreContext = ActionContext<UsersStoreState, any>;

@Module({ namespaced: true })
export default class UsersStore extends VuexModule<UsersStoreState> implements UsersStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Users");

  public _sessionUser: User = null;
  public _users: User[] = [];
  public _orgUsers: User[] = [];
  
  @Getter()
  public sessionUser(): User {
    return this._sessionUser;
  }

  @Getter()
  public users(): User[] {
    return this._users;
  }

  @Getter()
  public orgUsers(): User[] {
    return this._orgUsers;
  }

  @Mutation()
  protected _setSessionUser(user: User) {
    this._sessionUser = user;
    if (!!user) {
      Vue.$cookies.set(UsersStoreCookies.LastUser, user.username);
    }
  }

  @Mutation()
  protected _setUsers(users: User[]) {
    this._users = users;
  }

  @Mutation()
  protected _setOrgUsers(users: User[]) {
    this._orgUsers = users;
  }

  @Mutation()
  protected _removeOrgUser(user: User) {
    const index = this._orgUsers.findIndex(u => u.username === user.username);
    if (index !== -1) {
      this._orgUsers.splice(index, 1);
    }
  }

  @Mutation()
  protected _removeAppUser(user: User) {
    const index = this._users.findIndex(u => u.username === user.username);
    if (index !== -1) {
      this._users.splice(index, 1);
    }
  }

  @Mutation()
  protected _addOrgUsers(users: User[]) {
    for (const newUser of users) {
      const currentUser = this._orgUsers.find(u => u.username === newUser.username);
      if (!!currentUser) {
        Object.assign(currentUser, newUser);
      } else {
        this._orgUsers.push(newUser);
      }
    }
  }

  @Mutation()
  protected _addUsers(users: User[]) {
    for (const newUser of users) {
      const currentUser = this._users.find(u => u.username === newUser.username);
      if (!!currentUser) {
        Object.assign(currentUser, newUser);
      } else {
        this._users.push(newUser);
      }
    }
  }

  @Action({ useContext: true, commit: UsersStoreCommit.SerOrgUsers })
  public async fetchOrgUsers(ctx: UsersStoreContext): Promise<User[]> {
    const currentOrg: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    const dtos = await UsersApi.getUsers(currentOrg);
    return dtos.map(User.fromDto);
  }

  @Action({ commit: UsersStoreCommit.SetUsers })
  public async fetchUsers(): Promise<User[]> {
    const dtos = await UsersApi.getUsers();
    return dtos.map(User.fromDto);
  }

  @Action({ commit: UsersStoreCommit.AddUsers })
  public async fetchUser(username: string): Promise<User[]> {
    const dto = await UsersApi.getUser(username);
    return [User.fromDto(dto)];
  }

  @Action({ useContext: true, commit: UsersStoreCommit.RemoveAppUser })
  public async deleteUser(ctx: UsersStoreContext, user: User): Promise<User> {
    await UsersApi.deleteUser(user.username);
    return user;
  }

  @Action({ commit: UsersStoreCommit.AddUsers })
  public async createUser(user: User): Promise<User[]> {
    const userDto = await UsersApi.createUser(user.toDto());
    return [User.fromDto(userDto)];
  }

  @Action({ commit: UsersStoreCommit.AddUsers })
  public async updateUser(user: User): Promise<User[]> {
    const userDto = await UsersApi.updateUser(user.toDto());
    return [User.fromDto(userDto)];
  }

  @Action({ useContext: true, commit: UsersStoreCommit.AddOrgUsers })
  public async updateOrgUserRole(ctx: UsersStoreContext, user: User): Promise<User[]> {
    const currentOrg: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    const userDto = await UsersApi.updateOrgUserRole(user, currentOrg);
    return [User.fromDto(userDto)];
  }

  @Action({ useContext: true, commit: UsersStoreCommit.RemoveOrgUser })
  public async removeUserFromCurrentOrg(ctx: UsersStoreContext, user: User): Promise<User> {
    const currentOrg: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    await UsersApi.removeUserFromOrg(user, currentOrg);
    return user;
  }

  @Action({ useContext: true })
  public async clearUsersStore(ctx: UsersStoreContext): Promise<void> {
    ctx.commit(UsersStoreCommit.SerOrgUsers, []);
    ctx.commit(UsersStoreCommit.SetUsers, []);
  }

  @Action({ useContext: true, commit: UsersStoreCommit.SetSessionUser })
  public async initSessionUser(ctx: UsersStoreContext, username: string): Promise<User> {
    const userDto = await UsersApi.getUser(username);
    const user = User.fromDto(userDto);
    
    const previousUsername = Vue.$cookies.get(UsersStoreCookies.LastUser);

    // Init user orgs
    const initUserOrgs = OrgsStore.Mapping.rootAction(OrgsStoreAction.InitOrgs);
    const initUserOrgsPayload: InitOrgsPayload = { user, userDidChange: previousUsername !== user.username };
    await initUserOrgs(ctx, initUserOrgsPayload);

    return user;
  }

  @Action({ useContext: true, commit: UsersStoreCommit.SetSessionUser })
  public async deinitSessionUser(ctx: UsersStoreContext, expired: boolean): Promise<User> {
    // Deinit user orgs
    const deinitUserOrgs = OrgsStore.Mapping.rootAction(OrgsStoreAction.DeinitOrgs);
    await deinitUserOrgs(ctx, expired);

    return null;
  }

}