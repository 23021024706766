import { Host } from "@Module/Licensing/Models/Host.model";
import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class HostSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected host: Host;

  protected get hostCode(): string {
    if (this.$mq.md) return Utils.smartTrim(this.host.externalId, 12);
    else return Utils.smartTrim(this.host.externalId, 16);
  }

}