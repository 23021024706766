import LegalStore from "@Module/Legal/Store/Legal.store";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class DataProtectionDialog extends Vue {

  @MapGetter(LegalStore.Mapping)
  protected dataProtectionDialogVisible: boolean;
  
  @MapAction(LegalStore.Mapping)
  protected setDataProtectionDialogVisible: (visible: boolean) => void;

}