import { IBackupHistoryPageDto } from "../Models/Backups/BackupHistoryPage.model";
import { GetBackupsExportApiError } from "../Errors/GetBackupsExportApiError";
import { GetBackupLevelsApiError } from "../Errors/GetBackupLevelsApiError";
import { IBackupFiltersDto } from "../Models/Backups/BackupFilters.model";
import { IBackupLevelDto } from "../Models/Backups/BackupLevel.model";
import { GetBackupsApiError } from "../Errors/GetBackupsApiError";
import { ExportFormat } from "@Core/Models/ExportFormat.model";
import { IBackupDto } from "../Models/Backups/Backup.model";
import { ServiceID } from "@Service/ServiceID";
import AxiosAPI from "@Core/API/AxiosAPI";

class EmoryBackupsAPI extends AxiosAPI {
  private static instance: EmoryBackupsAPI;

  public static get Instance(): EmoryBackupsAPI {
    return this.instance || (this.instance = new this());
  }

  protected url(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/backups`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  public async getBackupLevels(): Promise<IBackupLevelDto[]> {
    try {
      const url = this.url("levels");
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving the supported backup levels. Try again later.`;
      throw new GetBackupLevelsApiError(message, { cause: error });
    }
  }

  public async getBackups(filters: IBackupFiltersDto): Promise<IBackupHistoryPageDto> {
    try {
      const url = this.url();
      const response = await this.axios.get(url, {
        params: { product: ServiceID.Emory, ...filters }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while obtaining your backup history. Please try again later.`;
      throw new GetBackupsApiError(message, {
        cause: error,
        meta: { filters }
      });
    }
  }

  public async getBackup(backupId: number): Promise<IBackupDto> {
    try {
      const url = this.url(backupId.toString());
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while obtaining the requested backup record. Please try again later.`;
      throw new GetBackupsApiError(message, {
        cause: error, 
        meta: { backupId }
      });
    }
  }

  public async getBackupsExport(filters: IBackupFiltersDto, format: ExportFormat): Promise<string> {
    try {
      const url = this.url("export");
      const response = await this.axios.get(url, {
        params: { 
          product: ServiceID.Emory,
          exp_type: format,
          ...filters 
        }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while exporting your backup history query. Please try again later.`;
      throw new GetBackupsExportApiError(message, {
        cause: error,
        meta: { filters, format }
      });
    }
  }
}

export default EmoryBackupsAPI.Instance;