import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { License } from "@Module/Licensing/Models/License.model";
import { Component, MapGetter, Prop, Watch } from "types-vue";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { Host } from "@Module/Licensing/Models/Host.model";
import Vue from "vue";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";

const enum LicenseFiltersSection {
  LicenseHosts = "license-hosts"
}

@Component
export default class LicenseFiltersPanel extends Vue {

  @Prop({ type: Object, required: true })
  protected value: LicenseFilters;

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Array, default: null })
  protected data: License[];

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @MapGetter(LicensingStore.Mapping)
  protected licenses: ServiceMap<License[]>;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgHasChildren: boolean;

  protected visibleSections: LicenseFiltersSection[] = [];
  protected test = [];

  protected get serviceLicenses(): License[] {
    return this.data || this.licenses[this.serviceId] || [];
  }

  protected get owners(): Organization[] {
    return this.serviceLicenses.reduce((owners, license) => {
      if (!owners.some(owner => owner.id === license.owner.id)
        && license.owner.id !== this.currentOrg.id) {
        owners.push(license.owner);
      }
      return owners;
    }, [] as Organization[]);
  }

  protected get tenants(): Organization[] {
    return this.serviceLicenses.reduce((tenants, license) => {
      if (!!license.tenant 
        && !tenants.some(tenant => tenant.id === license.tenant.id) 
        && license.tenant.id !== this.currentOrg.id) {
        tenants.push(license.tenant);
      }
      return tenants;
    }, [] as Organization[]);
  }

  @Watch("value.hosts", { immediate: true, deep: true })
  protected async onFiltersChange() {
    if (this.value.hosts.isFiltering) {
      await this.$nextTick();
      this.visibleSections = [LicenseFiltersSection.LicenseHosts];
    }
  }

  protected get serviceLicenseHosts(): Host[] {
    return this.serviceLicenses.map(license => license.hosts).flat();
  }

  protected get hasHosts(): boolean {
    return this.serviceLicenseHosts.length > 0;
  }

  protected get shouldDisplayTenantFilter() { 
    return this.tenants.length > 0 
        && (this.currentOrgHasChildren || this.serviceLicenses.some(license => license.isShared && !license.isSharedToOrg(this.currentOrg)));
  }

  protected get shouldDisplayOwnerFilter() { 
    return this.owners.length > 0 
      && (!!this.currentOrg.parentId || this.serviceLicenses.some(license => !license.isOwnedByOrg(this.currentOrg)));
  }

}