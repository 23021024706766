import { render, staticRenderFns } from "./ContractDetailsView.vue?vue&type=template&id=715cfcc5&scoped=true&"
import script from "./ContractDetailsView.ts?vue&type=script&lang=ts&"
export * from "./ContractDetailsView.ts?vue&type=script&lang=ts&"
import style0 from "./ContractDetailsView.vue?vue&type=style&index=0&id=715cfcc5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "715cfcc5",
  null
  
)

export default component.exports