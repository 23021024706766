import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

export const enum YesNoOption {
  Yes = "Yes",
  No = "No"
}

@Component
export default class YesNoCheckboxGroup extends Vue {

  @Prop({ type: Array, default: () => [] })
  protected value: YesNoOption[];

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected internalValue: YesNoOption[] = [];

  @Watch("value", { immediate: true })
  protected onValueChange(value: YesNoOption[]) {
    this.internalValue = value;
  }

  @Watch("internalValue")
  protected onInternalValueChange(value: YesNoOption[]) {
    this.$emit("input", value);
  }

}