import { Contract } from "@Module/Licensing/Models/Contract.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { Payment } from "@Module/Reports/Models/Payment.model";
import { IReportContractDto, ReportContract } from "@Module/Reports/Models/ReportContract.model";
import ReportsStore from "@Module/Reports/Store/Reports.store";
import { DatePickerOptions } from "element-ui/types/date-picker";
import DayJS from "dayjs";
import { Component, MapAction, MapGetter, Prop, Watch} from "types-vue";
import Vue from "vue";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { cloneDeep } from "lodash";
import { FInfo, IfInfoDto, fInfoTypes } from "@Module/Reports/Models/FinancialInfo.model";
import ReportsApi from "@Module/Reports/API/Reports.api";

@Component
export default class FinancialInfoDialog extends Vue {
  @MapGetter(ReportsStore.Mapping)
  protected fInfoDialogVisible: boolean;

  @MapAction(ReportsStore.Mapping)
  protected addFInfo: (fInfo: IfInfoDto) => Promise<IfInfoDto>;

  @MapAction(ReportsStore.Mapping)
  protected setFInfoDialogVisible: (visible: boolean) => void

  @MapAction(ReportsStore.Mapping)
  protected updateFInfo: (fInfo: IfInfoDto) => Promise<IfInfoDto>;

  protected fInfo = FInfo.create();
  protected add = Execution.create((fInfo: IfInfoDto) => this.addFInfo(fInfo));
  protected update = Execution.create((fInfo: IfInfoDto) => this.updateFInfo(fInfo));
  protected getFinancialTypes = Execution.create(() => ReportsApi.getFinancialTypes());
  protected isEdit = false;
  protected typeOptions: fInfoTypes[] = [];

  public open(editFInfo: FInfo = FInfo.create(), isEdit: boolean) {
    this.fInfo = cloneDeep(editFInfo);
    if (isEdit) {
      this.isEdit = true;
    }
    const dialog = this.$refs.dialog as Dialog;
    return dialog.open();
  }

  protected async fetchFinancialTypes() {
    const dtos = await this.getFinancialTypes.run();
    this.typeOptions = dtos.map((dto) => ({ id: dto.id, type: dto.type }));
  }


  protected onClosed() {
    this.fInfo = FInfo.create();
    (this.$refs.dialog as Dialog).cancel();
    this.isEdit = false;
    this.add.reset();
    this.update.reset();
    this.setFInfoDialogVisible(false);
  }

  protected mounted() {
    this.fetchFinancialTypes();
  }

  onOpen() {
    this.setFInfoDialogVisible(true)  
  }

  @Watch("fInfo", { deep: true })
  protected onTypeChanged() {
    if (this.fInfo.type !== 'Professional Services'){
      this.fInfo.project = null;
    }
  }

  private async createElement() {
    let date = this.fInfo.date ? DayJS(this.fInfo.date).locale('es').format("YYYY-MM-DD") : null;
    let newFInfo = { amount: this.fInfo.amount, date: date, type: this.fInfo.type, project: this.fInfo?.project };
    await this.add.run(newFInfo);
      if (!this.add.error) {
        Notify.Success({
          title: "Financial info added",
          message: "Financial info successfully added.",
          duration: 3000
        });
        (this.$refs.dialog as Dialog).success(true);
      } 
}

  private async updateElement() {
    let date = this.fInfo.date ? DayJS(this.fInfo.date).locale('es').format("YYYY-MM-DD") : null;
    let newFInfo = { amount: this.fInfo.amount, date: date, type: this.fInfo.type, project: this.fInfo?.project, id: this.fInfo.id};
    await this.update.run(newFInfo);
      if (!this.update.error) {
        Notify.Success({
          title: "Financial info updated",
          message: "Financial info successfully updated.",
          duration: 3000
        });
        (this.$refs.dialog as Dialog).success(true);
      } 
  }
}