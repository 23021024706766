import { IBillingPeriodDto } from "@Module/Reports/Models/BillingPeriod.model";
import { ReportContract } from "@Module/Reports/Models/ReportContract.model";
import dayjs, { Dayjs } from "dayjs";
import { Component, Prop, Watch } from "types-vue";
import Vue from "vue";

@Component
export default class BillingPeriod extends Vue {

  @Prop({ type: Object, required: true })
  protected reportContract: ReportContract;

  @Prop({ type: Number, default: null })
  protected contractId: number;

  protected get billingPeriods(): IBillingPeriodDto[] {
    return this.reportContract.billingPeriods;
  }
  protected displayPeriods(billingPeriod: IBillingPeriodDto): boolean {
    return billingPeriod.payments !== undefined || !!billingPeriod.revenue || billingPeriod.revenue === 0
    || !!billingPeriod.totalAmount || !!billingPeriod.paymentsNumber || !!billingPeriod.currency
    || !!billingPeriod.agreement || !!billingPeriod.awsFee;
  }

  protected getTimePeriod(startDate: Dayjs, endDate: Dayjs): string {
    if (!endDate) {
      return `${this.getFormattedDate(startDate)} - Present (Auto Renewal)`;
    } else {
      return `${this.getFormattedDate(startDate)} - ${this.getFormattedDate(endDate)}`;
    }
  }

  protected getFormattedDate(time: Dayjs): string {
    return dayjs(time).format("DD/MM/YYYY");
  }

  protected getCurrencySign(currency: string): string {
      switch(currency) {
        case "USD":
          return "$";
        case "EUR":
          return "€";
        default:
          return "";
      }
  }
}

