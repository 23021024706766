import { Host, IHostDto } from "@Module/Licensing/Models/Host.model";
import { EmoryHostFilters } from "./Filters/EmoryHostFilters.model";

export class EmoryHost extends Host {
  public readonly ip: string;
  public readonly sid: string;
  public readonly memory: number;
  public readonly clusterId: string;
  public readonly clientVersion: string;

  protected constructor(dto: IHostDto) {
    super(dto);
    this.ip = dto.ip;
    this.sid = dto.sid;
    this.memory = dto.memory;
    this.clusterId = dto.clusterId;
    this.clientVersion = dto.clientVersion;
  }

  public static fromDto(dto: IHostDto): EmoryHost {
    return new EmoryHost(dto);
  }

  public meetFilters(filters: EmoryHostFilters): boolean {
    const meetsBaseFilters = super.meetFilters(filters);
    if (!meetsBaseFilters) { return false; }

    const meetsHostSid = filters.sIds.length > 0 
      ? filters.sIds.some(sid => this.sid === sid) : true;
    if (!meetsHostSid) { return false; }

    const meetVersions = filters.versions.length > 0 
      ? filters.versions.some(v => this.clientVersion === v) : true;
    if (!meetVersions) { return false; }

    const meetIps = filters.ips.length > 0 
      ? filters.ips.some(ip => this.ip === ip) : true;
    if (!meetIps) { return false; }

    return true;
  }
}
