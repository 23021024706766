import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapGetter } from "types-vue";
import Vue from "vue";

@Component
export default class AuthPage extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  
}