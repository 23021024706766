import { Payment } from "@Module/Reports/Models/Payment.model";
import { HttpRequestOptions } from "element-ui/types/upload";
import { Component, Prop} from "types-vue";
import Vue from "vue";

@Component
export default class PaymentForm extends Vue {

  @Prop({ type: Object, required: true })
  protected payment: Payment;
 
  @Prop({ type: Number, required: true })
  protected paymentNumber: number;

  protected mounted() {
    this.payment.number = this.paymentNumber + 1
  }
}