import { ILicenseDto, License } from "@Module/Licensing/Models/License.model";
import { IHostDto } from "@Module/Licensing/Models/Host.model";
import { ServiceID } from "@Service/ServiceID";
import { EmoryHost } from "./EmoryHost.model";
import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { EmoryLicenseFilters } from "./Filters/EmoryLicenseFilters.model";

export class EmoryLicense extends License<EmoryHost> {

  protected constructor(dto: ILicenseDto) {
    super(dto, ServiceID.Emory);
  }

  protected parseHosts(dtos: IHostDto[]): EmoryHost[] {
    return dtos.map(EmoryHost.fromDto);
  }

  public static fromDto(dto: ILicenseDto): EmoryLicense {
    return new EmoryLicense(dto);
  }

  public meetFilters(filters: EmoryLicenseFilters): boolean {
    const meetsBaseFilters = super.meetFilters(filters);
    if (!meetsBaseFilters) { return false; }

    return true;
  }

}