import DayJS, { Dayjs } from "dayjs";
import { IResourceDto } from "./Resource.model";
import { ServiceID } from "@Service/ServiceID";

export interface ICreateReleaseDto {
  name: string,
  description: string,
  platform_id: number,
}


export interface IReleaseDto {
  id: number;
  name: string;
  description: string;
  platform_id?: number;
  created_at?: string;
  resources: IResourceDto[]
}

export class Release {
   id: number;
   name: string;
   description: string;
   platform_id?: number;
   created_at: Dayjs;
   resources: IResourceDto[];

  private constructor(dto: IReleaseDto) {
    this.id = dto.id;
    this.name = dto?.name;
    this.description = dto.description;
    this.resources = dto.resources;
    this.platform_id = dto?.platform_id;
    this.created_at = DayJS(dto.created_at);
  }

  public static create(dto?: Partial<IReleaseDto>): Release {
    return new Release({
      id: dto?.id,
      name: dto?.name,  
      description: dto?.description,
      platform_id: dto?.platform_id,
      resources: dto?.resources,
      created_at: dto?.created_at,
    });
  }

  public static fromDto(dto: IReleaseDto): Release {
    return new Release(dto);
  }
}