import DayJS, { Dayjs } from "dayjs";

export interface IfInfoDto {
  id?: string;
  amount: number;
  date: string;
  type: string;
  project?: string;
}

export interface FormattedfInfo {
  id: string;
  month: string;
  fInfo: FInfo;
  amount: number;
  type: string;
  project?: string;
}

export interface fInfoTypes {
  id: string;
  type: string;
}

export class FInfo {
  id?: string;
  amount: number;
  date: Dayjs;
  type: string;
  project?: string;

 private constructor(dto: IfInfoDto) {
   this.id = dto.id;
   this.amount = dto.amount;
   this.date = DayJS(dto.date);
   this.type = dto.type;
   this.project = dto?.project;
 }

 public static create(dto?: Partial<IfInfoDto>): FInfo {
   return new FInfo({
     id: dto?.id,
     amount: dto?.amount,
      date: dto?.date,
      type: dto?.type,
      project: dto?.project,
   });
 }

 public toDto(): IfInfoDto {
    return {
      id: this.id,
      amount: this.amount,
      date: this.date.toString(),
      type: this.type,
      project: this.project,
    };
 }

 public static fromDto(dto: IfInfoDto): FInfo {
   return new FInfo(dto);
 }


}