var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('licensing.contract-details-view',{attrs:{"filters":_vm.filters,"service-id":_vm.serviceId},on:{"select":_vm.viewDetails,"back":_vm.onBackClick},scopedSlots:_vm._u([{key:"host-filters",fn:function(ref){
var data = ref.data;
var size = ref.size;
var disabled = ref.disabled;
return _c('emory.host-filters-panel-extension',{attrs:{"filters":_vm.filters.hosts,"data":data,"size":size,"disabled":disabled}})}},{key:"expand",fn:function(ref){
var license = ref.license;
var exchangeStatus = ref.exchangeStatus;
return _c('licensing.license-summary',{attrs:{"license":license,"expand":""}},[_c('div',{staticClass:"flex content-space-between"},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-more"},on:{"click":function($event){return _vm.viewDetails(license)}}},[_c('span',[_vm._v("View license details")])]),(_vm.permissions.CanRegisterHosts && (!exchangeStatus.isShared || exchangeStatus.isSharedToCurrentOrg))?_c('el-button',{attrs:{"size":"small","disabled":!license.hasAvailability,"type":"default"},on:{"click":function($event){return _vm.$refs.hostRegistrationDialog.open(license)}}},[_c('span',{staticClass:"space-x-6 flex items-center"},[_c('ion-icon',{attrs:{"name":"link-outline"}}),_c('span',[_vm._v("Register a host")])],1)]):_vm._e()],1)])}}])},[_c('core.query-param',{attrs:{"name":"ip","type":"string-array"},model:{value:(_vm.filters.hosts.ips),callback:function ($$v) {_vm.$set(_vm.filters.hosts, "ips", $$v)},expression:"filters.hosts.ips"}}),_c('core.query-param',{attrs:{"name":"version","type":"string-array"},model:{value:(_vm.filters.hosts.versions),callback:function ($$v) {_vm.$set(_vm.filters.hosts, "versions", $$v)},expression:"filters.hosts.versions"}}),(_vm.permissions.CanRegisterHosts)?_c('licensing.host-registration-dialog',{ref:"hostRegistrationDialog",attrs:{"service-id":_vm.serviceId,"encrypted":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }