import { LicensingSummary } from "@Module/Licensing/Models/LicensingSummary.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class LicensingSummaryExpand extends Vue {

  @Prop({ type: Object, required: true })
  protected summary: LicensingSummary;

}