import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class StatCard extends Vue {

  @Prop({ type: [String, Number], required: true })
  protected value: string | number;

  @Prop({ type: String, required: true })
  protected title: string;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected plain: boolean;

  @Prop({ type: Boolean, default: false })
  protected bordered: boolean;

}