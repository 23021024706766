import { ExpirationThreshold, ExpirationThresholdCookie } from "@Module/Licensing/Models/ExpirationThreshold.model";
import { ExpirationFilterable } from "@Module/Licensing/Models/ExpirationFilterable.model";
import { Component, Prop } from "types-vue";
import DayJS, { Dayjs } from "dayjs";
import Vue from "vue";

@Component
export default class NextExpirationsCollapse extends Vue {

  @Prop({ type: Array, required: true })
  protected data: ExpirationFilterable[];
  
  @Prop({ type: Object, required: true })
  protected cookie: ExpirationThresholdCookie;

  @Prop({ type: String, default: "Expirations" })
  protected title: string;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected plain: boolean;

  @Prop({ type: Boolean, default: false })
  protected defaultCollapsed: boolean;

  @Prop({ type: Boolean, default: false })
  protected bordered: boolean;

  @Prop({ type: Boolean })
  protected separated: boolean;

  @Prop({ type: Array, default: () => [
    { amount: 5, unit: "days" },
    { amount: 15, unit: "days" },
    { amount: 1, unit: "month" },
    { amount: 6, unit: "months" },
    { amount: 1, unit: "year" }
  ] as ExpirationThreshold[]})
  protected options: ExpirationThreshold[];

  protected get expirationThreshold(): Dayjs {
    return DayJS().add(this.cookie.value.amount, this.cookie.value.unit);
  }

  protected get expirationThresholdLabel(): string {
    return `${this.cookie.value.amount} ${this.cookie.value.unit}`;
  }

  protected get expiredBeforeThreshold(): ExpirationFilterable[] {
    return this.data.filter(item => 
      item.meetsExpirationThreshold(this.expirationThreshold)
    );
  }

  protected storeValue(option: ExpirationThreshold) {
    this.cookie.store(option);
  }

}