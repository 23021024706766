import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class FiltersDrawer extends Vue {

  @Prop({ type: String, default: "Filters" })
  protected title: string;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected clearDisabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;
  
}