import { EmoryHost } from "@Service/Emory/Models/EmoryHost.model";
import { EmoryHostFilters } from "@Service/Emory/Models/Filters/EmoryHostFilters.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class EmoryHostFiltersPanelExtension extends Vue {

  @Prop({ type: Object, required: true })
  protected filters: EmoryHostFilters;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Array, default: null })
  protected data: EmoryHost[];

}