import { Utils } from "@Core/Utils/Utils";

export interface IBackupLevelDto {
  id: number;
  name: string;
  platform: string;
  isLog: boolean;
}

export class BackupLevel {
  public id: number;
  public name: string;
  public platform: string;
  public isLog: boolean;

  public static fromDto(dto: IBackupLevelDto): BackupLevel {
    return new BackupLevel(dto);
  }

  private constructor(dto: IBackupLevelDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.platform = dto.platform;
    this.isLog = dto.isLog;
  }

  public get label(): string {
    return Utils.toSentenceCase(this.name.replaceAll(/[-_]/g, " "));
  }
}