import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Contract } from "@Module/Licensing/Models/Contract.model";
import { TrialInfo } from "@Module/Trial/Models/TrialInfo.model";
import TrialStore from "@Module/Trial/Store/Trial.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { Component, MapGetter } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";

@Component
export default class EmoryContracts extends Vue {
  protected serviceId: ServiceID = ServiceID.Emory;

  @MapGetter(TrialStore.Mapping)
  protected emoryTrialInfo: TrialInfo;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  protected viewDetails(contract: Contract) {
    this.$router.push({ 
      name: "backup-contract-details", 
      params: { id: contract.id.toString() }
    });
  }

  protected get permissions() {
    return {
      CanPurchaseContracts: this.accessRole.can(this.serviceId, Permission.PurchaseContract),
      CanRequestTrial: this.accessRole.can(this.serviceId, Permission.RequestTrial)
    }
  }
  
}