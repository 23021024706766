import { NavigationStoreState, SetRoutePayload } from "./Navigation.state";
import OrgsStore, { OrgsStoreGetter } from "@Module/Orgs/Store/Orgs.store";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import LicensingApi from "@Module/Licensing/API/Licensing.api";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { User } from "@Module/Users/Models/User.model";
import { Utils } from "@Core/Utils/Utils";
import { Console } from "vue-router";
import { ActionContext } from "vuex";
import Vue from "vue";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { ILicensingCheckDto } from "@Module/Licensing/Models/LicensingCheck.model";

interface ServiceLegacyAccessPayload {
  service: ServiceID;
  hasAccess: boolean;
}

enum NavigationStoreGetter {
  GetRoute = "getRoute",
  AllServiceRoutes = "allServiceRoutes"
}

enum NavigationStoreCommit {
  SetRoutes = "_setRoutes",
  SetPreviousRoute = "_setPreviousRoute",
  SetCurrentRoute = "_setCurrentRoute",
  SetServiceLegacyAccess = "_setServiceLegacyAccess",
  SetDocsUrl = "_setDocsUrl"
}

export enum NavigationStoreAction {
  InitUserNavigation = "initUserNavigation",
  DeinitUserNavigation = "deinitUserNavigation"
}

export type InitUserNavigationPayload = {
  user: User;
  accessRole: AccessRole;
}

declare type NavigationStoreContext = ActionContext<NavigationStoreState, any>

@Module({ namespaced: true })
export default class NavigationStore extends VuexModule<NavigationStoreState> implements NavigationStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Navigation");

  public _legacyAccess: ServiceMap<boolean> = {};

  public _routes: ConsoleRoute[] = [];

  public _previousRoute: ConsoleRoute = null;
  public _currentRoute: ConsoleRoute = null;

  public _docsUrl: string = null;

  @Getter()
  public routes(): ConsoleRoute[] {
    return this._routes;
  }

  @Getter()
  public navigationInitialized(): boolean {
    return this._routes.length > 0;
  }

  @Getter()
  public getRoute(): (path: string) => ConsoleRoute {
    return (path: string) => this._routes.find(r => r.path === path);
  }

  @Getter()
  public abapSuiteServiceRoute(): ConsoleRoute[] {
    return this._routes.filter(route => !route.isRoot && !route.isChild && route.path === "/abap-suite");
  }
  
  @Getter()
  public availableServiceRoutes(): ConsoleRoute[] {
    return this._routes.filter(route => {
      if (route.isRoot || route.isChild || route.isHidden) {
        return false;
      } else if (route.isServiceLegacy) {
        return route.hasLegacyAccess;
      } else {
        return true;
      }
    });
  }

  @Getter()
  public allServiceRoutes(): ConsoleRoute[] {
    return this._routes.filter(route => !route.isRoot && !route.isChild);
  }

  @Getter()
  public currentService(): Console.ParentRouteDefinition {
    return this._currentRoute?.service || null;
  }

  @Getter()
  public currentServiceId(): ServiceID {
    return this._currentRoute?.serviceId || null;
  }

  @Getter() 
  public docsUrl(): string { 
    return this._docsUrl; 
  }

  @Getter() 
  public docsPanelVisible(): boolean { 
    return !!this._docsUrl; 
  }

  @Getter() 
  public previousRoute(): ConsoleRoute { 
    return this._previousRoute; 
  }

  @Getter() 
  public currentRoute(): ConsoleRoute { 
    return this._currentRoute; 
  }

  @Mutation() 
  protected _setDocsUrl(url: string | null) { 
    this._docsUrl = url;
  }

  @Mutation() 
  protected _setRoutes(routes: ConsoleRoute[]) { 
    this._routes = routes;
  }

  @Mutation() 
  protected _setPreviousRoute(route: ConsoleRoute) { 
    this._previousRoute = route;
  }

  @Mutation() 
  protected _setCurrentRoute(route: ConsoleRoute) { 
    this._currentRoute = route;
  }

  @Mutation() 
  protected _setServiceLegacyAccess(payload: ServiceLegacyAccessPayload) { 
    this._routes
      .filter(route => route.serviceId === payload.service)
      .forEach(route => route.hasLegacyAccess = payload.hasAccess);
  }

  @Action({ commit: NavigationStoreCommit.SetDocsUrl })
  protected async openDocsPanel(url: string): Promise<string> {
    return url;
  }

  @Action({ commit: NavigationStoreCommit.SetDocsUrl })
  protected async closeDocsPanel(): Promise<string> {
    return null;
  }

  @Action({ commit: NavigationStoreCommit.SetRoutes })
  public async initNavigation(): Promise<ConsoleRoute[]> {
    const routes = Vue.$router.getRoutes() as Console.RouteRecord[];
    return routes.reduce((consoleRoutes, route) => {
      if (!route.path) { return consoleRoutes; }
      const pathParts = route.path.substring(1).split("/");
      const servicePath = `/${pathParts[0]}`;
      const service = routes.find(r => r.path === servicePath);
      const consoleRoute = ConsoleRoute.create(service, route);
      consoleRoutes.push(consoleRoute);
      return consoleRoutes;
    }, [] as ConsoleRoute[]);
  }

  @Action({ useContext: true })
  public async initUserNavigation(ctx: NavigationStoreContext, payload: InitUserNavigationPayload): Promise<void> {
    
    const noOrg: boolean = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.NoOrganization)(ctx);
    if (noOrg) { return; }

    const allServiceRoutes: ConsoleRoute[] = ctx.getters[NavigationStoreGetter.AllServiceRoutes];
    const promises = allServiceRoutes.reduce((promises, route) => {
      if (route.isServiceLegacy) {
        const queryPromise = payload.accessRole.can(ServiceID.Console, Permission.AccessLegacyService) 
          ? Promise.resolve({ has_contract: true, has_shared_licenses: true } as ILicensingCheckDto)
          : LicensingApi.licensingCheck(route.serviceId);
        queryPromise.then((check: ILicensingCheckDto) => {
          const hasAccess = check.has_contract || check.has_shared_licenses;
          const payload: ServiceLegacyAccessPayload = { service: route.serviceId, hasAccess };
          ctx.commit(NavigationStoreCommit.SetServiceLegacyAccess, payload);
          return hasAccess;
        });
        promises.push(queryPromise);
      }
      return promises;
    }, [] as Promise<ILicensingCheckDto>[]);
    await Promise.all(promises);
  }
  
  @Action({ useContext: true })
  public async deinitUserNavigation(ctx: NavigationStoreContext, expired: boolean): Promise<void> {
    const allServiceRoutes: ConsoleRoute[] = ctx.getters[NavigationStoreGetter.AllServiceRoutes];
    allServiceRoutes.forEach(route => {
      if (route.isServiceLegacy) {
        const payload: ServiceLegacyAccessPayload = { service: route.serviceId, hasAccess: false };
        ctx.commit(NavigationStoreCommit.SetServiceLegacyAccess, payload);
      }
    });
  }

  @Action({ useContext: true })
  public async setRoute(ctx: NavigationStoreContext, payload: SetRoutePayload): Promise<ConsoleRoute> {
    const to = payload.current.matched[payload.current.matched.length - 1];
    const from = !!payload.previous ? payload.previous.matched[payload.previous.matched.length - 1] : undefined;
    const getRoute: (path: string) => ConsoleRoute = ctx.getters[NavigationStoreGetter.GetRoute];

    const previousRoute = !!from ? getRoute(from.path) : undefined;
    const currentRoute = getRoute(to.path);

    if (currentRoute?.service.path !== previousRoute?.service.path) {
      ctx.commit(NavigationStoreCommit.SetDocsUrl, null);
    }

    ctx.commit(NavigationStoreCommit.SetCurrentRoute, currentRoute);
    if (!!previousRoute && !previousRoute.replaceNavigation) {
      ctx.commit(NavigationStoreCommit.SetPreviousRoute, previousRoute);
    }
    
    Vue.$route = payload.current;
    return currentRoute;
  }
}