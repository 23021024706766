export interface IForgotPwdDto {
  username: string;
  newpassword: string;
  confirmationcode: string;
}

export class ForgotPwd {
  public email: string;
  public newPassword: string;
  public code: string;

  private constructor() {
    this.email = null;
    this.newPassword = null;
    this.code = null;
  }
  
  public static create(): ForgotPwd {
    return new ForgotPwd();
  }

  public toDto(): IForgotPwdDto {
    return {
      username: this.email,
      newpassword: this.newPassword || undefined,
      confirmationcode: this.code || undefined
    }
  }
}