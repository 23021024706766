import NotificationContentMixin, { INotificationContent, UiSuccessNotificationMap } from "@Module/Notifications/Mixins/NotificationContentMixin";
import ContractPurchaseOrgSelectionDialog from "../../ContractPurchaseOrgSelectionDialog/ContractPurchaseOrgSelectionDialog";
import NotificationsInboxStore, { AcceptContractPurchasePayload } from "@Module/Notifications/Store/NotificationsInbox.store";
import { ContractPurchaseNotification } from "@Module/Notifications/Models/ContractPurchaseNotification.model";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapAction, MapGetter, mixins, Prop } from "types-vue";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { ServiceID } from "@Service/ServiceID";

type ConfigDialog = ContractPurchaseOrgSelectionDialog;

@Component
export default class ContractPurchaseNotifContent extends mixins(NotificationContentMixin) implements INotificationContent {

  @Prop({ type: Object, required: true })
  protected notification: ContractPurchaseNotification;

  @Prop({ type: Boolean, default: true })
  protected isInbox: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptContractPurchaseNotification: (payload: AcceptContractPurchasePayload) => Promise<void>;

  protected contractOrg: Organization = null;
  protected configuring: boolean = false;

  protected mounted() {
    this.$watch(
      () => (this.$refs.dialog as ConfigDialog)?.isOpened ?? false,
      isOpened => this.configuring = isOpened,
      { immediate: true }
    );
  }

  public get canAccept(): boolean {
    return !this.noOrganization;
  }

  public get canResend(): boolean {
    return false;
  }

  public get canCancel(): boolean {
    return false;
  }

  public get isConfiguringResponse() {
    return this.configuring;
  }

  public getSuccessNotifConfig(): UiSuccessNotificationMap {
    return {
      Accept: {
        title: "Contract purchase accepted", 
        message: `Your ${this.contractService} contract purchase from ${this.contractOrigin} has been successfully accepted and assigned.`,
        duration: 6000
      },
      Decline: {
        title: "Contract purchase declined", 
        message: `Your ${this.contractService} contract purchase from ${this.contractOrigin} has been successfully declined.`,
        duration: 6000
      }
    }
  }

  protected get contractOrigin(): string {
    return ContractOrigin.nameOf(this.notification.contractOrigin);
  }

  protected get contractService(): string {
    return ServiceID.nameOf(this.notification.service)
  }

  public async accept(): Promise<boolean> {
    this.contractOrg = this.currentOrg;
    const orgSelectionDialog = this.$refs.dialog as ConfigDialog;
    const shouldProceed = await orgSelectionDialog.open();
    if (!shouldProceed) { return false; }
    await this.acceptContractPurchaseNotification({
      notification: this.notification,
      organization: this.contractOrg
    });
    return true;
  }

}