import { RemoveUserFromCurrentOrgApiError } from "../Errors/RemoveUserFromCurrentOrgApiError";
import { UpdateOrgUserRoleApiError } from "../Errors/UpdateOrgUserRoleApiError";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { DeleteUserApiError } from "../Errors/DeleteUserApiError";
import { UpdateUserApiError } from "../Errors/UpdateUserApiError";
import { CreateUserApiError } from "../Errors/CreateUserApiError";
import { GetUsersApiError } from "../Errors/GetUsersApiError";
import { IUserDto, User } from "../Models/User.model";
import AxiosAPI from "@Core/API/AxiosAPI";

class UsersAPI extends AxiosAPI {
  private static instance: UsersAPI;

  public static get Instance(): UsersAPI {
    return this.instance || (this.instance = new this());
  }

  protected url(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/users`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  public async getUsers(org?: Organization): Promise<IUserDto[]> {
    try {
      const url = this.url();
      const response = await this.axios.get(url, {
        params: { byOrg: !!org }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving users. Please try again later.`;
      throw new GetUsersApiError(message, { 
        cause: error
      });
    }
  }

  public async getUser(username: string): Promise<IUserDto> {
    try {
      const url = this.url(username);
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving user. Please try again later.`;
      throw new GetUsersApiError(message, { 
        cause: error, 
        meta: { username }  
      });
    }
  }

  public async createUser(dto: IUserDto): Promise<IUserDto> {
    try {
      const url = this.url();
      const response = await this.axios.post(url, dto);
      return response.data;
    } catch (error) {
      const message = `An error occurred while creating user. Please try again later.`;
      throw new CreateUserApiError(message, { 
        cause: error, 
        meta: { dto }  
      });
    }
  }

  public async updateUser(dto: IUserDto): Promise<IUserDto> {
    try {
      const url = this.url();
      const response = await this.axios.put(url, dto);
      return response.data;
    } catch (error) {
      const message = `An error occurred while updating user. Please try again later.`;
      throw new UpdateUserApiError(message, { 
        cause: error, 
        meta: { dto }  
      });
    }
  }

  public async deleteUser(username: string): Promise<void> {
    try {
      const url = this.url(username);
      await this.axios.delete(url);
    } catch (error) {
      const message = `An error occurred while deleting user. Please try again later.`;
      throw new DeleteUserApiError(message, { 
        cause: error, 
        meta: { username }  
      });
    }
  }

  public async updateOrgUserRole(user: User, org: Organization): Promise<IUserDto> {
    try {
      const url = this.url(`from-org/${user.username}`);
      const response = await this.axios.put(url, {
        role_id: user.getOrgRoleForOrg(org).role.id
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while updating organization user role. Please try again later.`;
      throw new UpdateOrgUserRoleApiError(message, { 
        cause: error, 
        meta: { user, org }  
      });
    }
  }

  public async removeUserFromOrg(user: User, org: Organization): Promise<void> {
    try {
      const url = this.url(`from-org/${user.username}`);
      await this.axios.delete(url);
    } catch (error) {
      const message = `An error occurred while removing user from ${org.name} organization. Please try again.`;
      throw new RemoveUserFromCurrentOrgApiError(message, { 
        cause: error, 
        meta: { user, org }  
      });
    }
  }

}

export default UsersAPI.Instance;