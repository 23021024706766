import { NumberCookie } from "@Core/Models/Cookies/NumberCookie.model";
import { Execution } from "@Core/Models/Execution";
import { Contract } from "@Module/Licensing/Models/Contract.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { ReportContract } from "@Module/Reports/Models/ReportContract.model";
import ReportsStore from "@Module/Reports/Store/Reports.store";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { Console } from "console";
import DayJS from "dayjs";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class ContractSelector extends Vue {
   
  @MapAction(LicensingStore.Mapping)
  protected fetchAllContracts: (service: ServiceID) => Promise<Contract[]>;

  @Prop({ type: Number, default: null })
  protected value: Number;

  @MapGetter(ReportsStore.Mapping)
  protected reportContracts: ReportContract[];

  @Prop({ type: Object, default: null })
  protected contract: ReportContract;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected filteredContracts: ReportContract[] = [];
  protected data = [];
  //protected getContracts = Execution.create(() => this.fetchAllContracts(this.serviceId));

  async mounted() {
    this.filteredContracts = this.reportContracts.filter(c => c.orgName === this.contract.orgName && c.productName === this.contract.productName);
    this.filteredContracts.forEach(contract => {
      var contractExpiration = DayJS(contract.expiration).locale("es").format("DD/MM/YYYY");
      var contractCreation = DayJS(contract.creation).locale("es").format("DD/MM/YYYY");
      this.data.push({ label:  "#" + contract.id, dates: contractCreation + "-" + contractExpiration, id: contract.id})
    })
  }

  protected onContractSelected(id: Contract): void {
    const extendedContractId = id || null;
    this.$emit("input", extendedContractId);
    this.$emit("update:value", extendedContractId);
    this.$emit("select", extendedContractId);
    this.$emit("change", extendedContractId);
  }
}


