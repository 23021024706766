import NavigationStore from '@Core/Store/Navigation/Navigation.store';
import AppSettingsStore from '@Service/Settings/Store/AppSettings/AppSettings.store';
import { Component, MapGetter } from 'types-vue';
import Vue from "vue";
import { Console } from 'vue-router';

@Component
export default class ConsoleHub extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected availableServiceRoutes: Console.RouteRecord[];

  protected get serviceShortcuts(): Console.RouteRecord[] {
    return this.availableServiceRoutes
      .filter(route => route.meta.menu?.groupId !== "internal");
  }

  protected availableOtherRoutes: Console.RouteRecord[];

  protected get otherShortcuts(): Console.RouteRecord[] {
    return this.availableOtherRoutes
      .filter(route => route.meta.menu?.groupId !== "internal");
  }


}