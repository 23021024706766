import { LicensingStatus, LicensingStatusPhase } from "@Module/Licensing/Models/LicensingStatus.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class LicensingStatusTag extends Vue {

  @Prop({ type: Object, required: true })
  protected status: LicensingStatus;

  @Prop({ type: String, default: "mini" })
  protected size: UISize & "tiny";
  
  protected get type(): "info" | "success" | "danger" | "warning" | "" {
    switch (this.status.phase) {
      case LicensingStatusPhase.Active: return "success";
      case LicensingStatusPhase.InGrace: return "warning";
      case LicensingStatusPhase.Expired: return "danger";
      default: return "info";
    }
  }

}