import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Component, MapAction, MapGetter } from "types-vue";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { ElForm } from "element-ui/types/form";
import { Notify } from "@Core/Utils/Notify";
import Vue from "vue";

@Component
export default class UserEditorDialog extends Vue {
  
  @MapGetter(AuthStore.Mapping)
  protected roles: Role[];

  @MapGetter(OrgsStore.Mapping)
  protected defaultOrg: Organization;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapAction(UsersStore.Mapping)
  protected updateUser: (user: User) => Promise<void>;

  @MapAction(UsersStore.Mapping)
  protected createUser: (user: User) => Promise<void>;

  protected user: User = User.create();
  
  protected validationRules = {
    username: [
      { required: true, message: "Please enter an email.", trigger: ["change", "blur"] },
      { type: "email", message: "Please enter a valid email address.", trigger: "blur" }
    ],
    firstName: [{ required: true, message: "Please enter a first name.", trigger: ["change", "blur"] }], 
    lastName: [{ required: true, message: "Please enter a last name.", trigger: ["change", "blur"] }], 
    role: [{ required: true, message: "Please select a role.", trigger: ["change", "blur"] }]
  };
  
  protected update = Execution.create((user: User) => this.updateUser(user));
  protected create = Execution.create((user: User) => this.createUser(user));

  protected async open(user: User = User.create()): Promise<boolean> {
    if (user.isRemote) {
      this.user = user.clone();
    } else {
      this.user = user;
    }
    if (!this.user.mainOrgRole) {
      this.user.mainOrgRole =  OrganizationRole.create({ isMain: true });
    }
    return (this.$refs.dialog as Dialog).open();
  }

  protected async onOpen() {
    (this.$refs.form as ElForm)?.clearValidate();
  }

  protected async onClosed() {
    this.user = User.create();
    await this.$nextTick();
    (this.$refs.form as ElForm).clearValidate();
    this.update.reset();
    this.create.reset();
  }

  protected get isUpdateMode(): boolean {
    return this.user.isRemote;
  }

  protected get loading(): boolean {
    return this.create.loading || this.update.loading;
  }

  protected get canCreateOrUpdate(): boolean {
    return !!this.user.username
        && !!this.user.firstName
        && !!this.user.lastName;
  }

  protected filterMainOrgOptions(org: Organization): boolean {
    return !this.user.allowedOrgRoles.some(orgRole => orgRole.organization.id === org.id);
  }

  protected filterAllowedOrgsOptions(org: Organization): boolean {
    let selectedOrgs = this.user.allowedOrgRoles.map(orgRole => orgRole.organization);
    if (!!this.user.mainOrgRole?.organization) {
      selectedOrgs = [this.user.mainOrgRole.organization, ...selectedOrgs];
    }
    return !selectedOrgs.some(o => o.id === org.id);
  }

  protected addAllowedOrgRole(orgs: Organization[]) {
    const orgRoles = orgs.map(org => {
      return this.user.allowedOrgRoles.find(orgRole => orgRole.organization.id === org.id) 
        ?? OrganizationRole.create({ role: this.roles[0], isMain: false, org });
    });
    this.user.allowedOrgRoles = orgRoles;
  }

  protected removeAllowedOrgRole(orgRole: OrganizationRole) {
    const index = this.user.allowedOrgRoles.findIndex(or => or.organization.id === orgRole.organization.id);
    if (index !== -1) {
      this.user.allowedOrgRoles.splice(index, 1);
    }
  }

  protected async discardChanges() {
    try {
      const message = "Do you really want to discard the changes?";
      await this.$confirm(message, "Discard confirmation", { 
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Discard changes"
      });
      this.user.discardUncommittedChanges();
    } catch {}
  }

  protected async createOrUpdateUser() {
    try {
      await (this.$refs.form as ElForm).validate();
    } catch { return; }
    if (this.user.isRemote) {
      this.updateCurrentUser();
    } else {
      this.createCurrentUser();
    }
  }

  protected async closeGuard(done: Function): Promise<void> {
    if (this.loading) { return; }
    else if (!this.user.isRemote || !this.user.hasUncommittedChanges) { 
      return done(); 
    }
    try {
      await this.$confirm(
        "This user has uncommitted changes. Are you sure you want to discard these changes?",
        "Discard confirmation",
        { confirmButtonText: "Discard changes", cancelButtonText: "Cancel", type: "warning" }
      );
      return done();
    } catch (e) { return; }
  }

  protected async updateCurrentUser() {
    try {
      const message = "Do you really want to save the user changes?";
      const title = "Update confirmation";
      await this.$confirm(message, title, { 
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Update user"
      });
      await this.update.run(this.user);
      if (!this.update.error) {
        Notify.Success({
          title: "User updated",
          message: "User successfully updated.",
          duration: 3000
        });
        (this.$refs.dialog as Dialog).success(true);
      }
    } catch {}
  }

  protected async createCurrentUser() {
    try {
      const message = "Do you really want to create the user?";
      const title = "Create confirmation";
      await this.$confirm(message, title, { 
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Create user"
      });
      await this.create.run(this.user);
      if (!this.create.error) {
        Notify.Success({
          title: "User created",
          message: "User successfully created.",
          duration: 3000
        });
        (this.$refs.dialog as Dialog).success(true);
      }
    } catch {}
  }

}