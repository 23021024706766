import { ContractsSummaryFilters } from "@Module/Licensing/Models/ContractsSummaryFilters.model";
import { LicensingStatusPhase } from "@Module/Licensing/Models/LicensingStatus.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import { Component, MapGetter, Prop, Watch } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";
import { ReportContractsFilters } from "@Module/Reports/Models/ReportContractFilters";

@Component
export default class ReportContractsFilterPanel extends Vue {

  @Prop({ type: Object, required: true })
  protected value: ReportContractsFilters;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @MapGetter(LicensingStore.Mapping)
  protected dimensionArrayParamSerializer: Serializer<Dimension[]>;

  protected servicesForReports: ServiceID[] = ServiceID.ForReports;
  protected contractOrigins: ContractOrigin[] = ContractOrigin.All;
  protected allLicensingStatus: LicensingStatusPhase[] = LicensingStatusPhase.All;

  protected serviceName(service: ServiceID): string {
    return ServiceID.nameOf(service);
  }

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }


  @Watch("value.services", { immediate: true })
  protected onServiceFilterChange(services: ServiceID[]) {
    if (services.length === 0) {
      this.value.dimensions = [];
    }
  }



}