import DayJS, { Dayjs } from "dayjs";
import { HostFilters } from "./HostFilters.model";

export interface IHostDto {
  [serviceAttrs: string]: any;
  id: number;
  externalId: string;
  licenseId: number;
  hostname: string;
  creation: string;
  expiration: string;
  clientVersion: string;
}

export enum HostAvailability {
  Available = "Available",
  Registered = "Registered",
  Expired = "Expired"
}

export class Host {
  public readonly id: number;
  public readonly externalId: string;
  public readonly licenseId: number;
  public readonly hostname: string;
  public readonly creation: Dayjs;
  public readonly expiration: Dayjs;
  public readonly clientVersion: string;

  protected constructor(dto: IHostDto) {
    this.id = dto.id;
    this.externalId = dto.externalId;
    this.licenseId = dto.licenseId;
    this.hostname = dto.hostname;
    this.creation = DayJS(dto.creation);
    this.expiration = DayJS(dto.expiration);
    this.clientVersion = dto.clientVersion;
  }

  public static fromDto(dto: IHostDto): Host {
    return new Host(dto);
  }

  public get isRegistered(): boolean {
    return !!this.hostname;
  }

  public get hasExpired(): boolean {
    return this.expiration.isBefore(DayJS());
  }

  public get availability(): HostAvailability {
    if (this.hasExpired) {
      return HostAvailability.Expired;
    } else {
      return this.isRegistered 
        ? HostAvailability.Registered 
        : HostAvailability.Available;
    }
  }

  public meetFilters(filters: HostFilters): boolean {
    const meetsHostHostnames = filters.hostnames.length > 0 
      ? filters.hostnames.some(hname => this.hostname === hname) : true;
    if (!meetsHostHostnames) { return false; }

    const meetsExternalIds = filters.externalIds.length > 0 
      ? filters.externalIds.some(extId => this.externalId === extId) : true;
    if (!meetsExternalIds) { return false; }

    const text = filters.search?.toLowerCase() || "";
    if (!text) { return true; }
    const searchCode = this.externalId.toLowerCase().includes(text);
    const searchHostname = this.hostname.toLowerCase().includes(text);
    return searchCode || searchHostname;
  }

}