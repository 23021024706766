import { ConsoleError } from "@Core/Errors/ConsoleError";
import { Component, Prop } from "types-vue";
import Vue from "vue";

const enum ExpandableSection {
  Details = "details"
}

@Component
export default class ErrorAlert<T = unknown> extends Vue {

  @Prop({ type: Error, required: true })
  protected error: ConsoleError<T>;
  
  @Prop({ type: String })
  protected title: string;

  @Prop({ type: Boolean })
  protected closable: boolean;

  @Prop({ type: Boolean })
  protected showIcon: boolean;

  @Prop({ type: Boolean, default: false })
  protected disableExpanse: boolean;

  protected expandedSections: ExpandableSection[] = [];

}