import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import { Organization } from "./Organization.model";
import { ServiceID } from "@Service/ServiceID";
import DayJS, { Dayjs } from "dayjs";

interface TreeData {
  id: string;
  orgName: string;
  service: string;
  contract: string;
  licenseDimension: string;
  licenseUnits: string;
}

export interface IOrgContractLicenseSummary {
  DimensionName: string;
  NumberLicenses: number;
}

export interface IOrgContractSummaryDto {
  ID: number;
  ExternalID: string;
  CreationDate: string;
  ExpirationDate: string;
  Status: string;
  LicensesList: IOrgContractLicenseSummary[];
}

export interface IOrgServiceSummaryDto {
  ProductName: string;
  Contracts: IOrgContractSummaryDto[];
}

export interface IOrgLicensingSummaryDto {
  OrgName: string;
  OrgUUID: string;
  Products: IOrgServiceSummaryDto[];
}

export class OrgContractLicenseSummary {
  service: ServiceID;
  org: Organization;
  contractId: number;
  contractExternalId: string;
  dimension: string;
  units: number;

  private constructor(
    org: Organization,
    service: ServiceID,
    contractId: number,
    contractExternalId: string,
    dto: IOrgContractLicenseSummary
  ) {
    this.org = org;
    this.contractId = contractId;
    this.contractExternalId = contractExternalId;
    this.service = service;
    this.dimension = dto.DimensionName;
    this.units = dto.NumberLicenses;
  }

  public static fromDto(
    org: Organization,
    service: ServiceID,
    contractId: number,
    contractExternalId: string,
    dto: IOrgContractLicenseSummary
  ): OrgContractLicenseSummary {
    return new OrgContractLicenseSummary(org, service, contractId, contractExternalId, dto);
  }
  
  public get children(): any[] {
    return [];
  }

  public get treeData(): TreeData {
    return {
      id: `${this.org.uuid}-${this.contractId}-${this.dimension}`,
      orgName: this.org.name,
      service: ServiceID.nameOf(this.service),
      contract: this.contractExternalId,
      licenseDimension: this.dimension,
      licenseUnits: this.units.toString()
    }
  }
}

export class OrgContractSummary {
  id: number;
  externalId: string;
  service: ServiceID;
  org: Organization;
  creation: Dayjs;
  expiration: Dayjs;
  status: LicensingStatus;
  licensesSummary: OrgContractLicenseSummary[];

  private constructor(
    service: ServiceID, 
    org: Organization,
    dto: IOrgContractSummaryDto
  ) {
    this.id = dto.ID;
    this.externalId = dto.ExternalID;
    this.service = service
    this.org = org;
    this.creation = DayJS(dto.CreationDate);
    this.expiration = DayJS(dto.ExpirationDate);
    this.status = LicensingStatus.create(dto.Status);
    this.licensesSummary = dto.LicensesList
      .map(o => OrgContractLicenseSummary.fromDto(this.org, this.service, this.id, this.externalId, o));
    }

  public static fromDto(
    service: ServiceID, 
    org: Organization,
    dto: IOrgContractSummaryDto): OrgContractSummary {
    return new OrgContractSummary(service, org, dto);
  }

  public get licenseUnits(): number {
    return this.licensesSummary.reduce((acc, curr) => {
      acc += curr.units;
      return acc++;
    }, 0);
  }

  public get children(): OrgContractLicenseSummary[] {
    return this.licensesSummary;
  }

  public get treeData(): TreeData {
    return {
      id: this.id.toString(),
      service: ServiceID.nameOf(this.service),
      orgName: this.org.name,
      contract: this.externalId,
      licenseDimension: "All",
      licenseUnits: this.licenseUnits.toString()
    }
  }
}

export class OrgServiceSummary {
  org: Organization;
  service: ServiceID;
  contracts: OrgContractSummary[];

  private constructor(org: Organization, dto: IOrgServiceSummaryDto) {
    this.org = org;
    this.service = dto.ProductName as ServiceID;
    this.contracts = dto.Contracts
      .map(dto => OrgContractSummary.fromDto(this.service, this.org, dto));
    this.contracts.sort((a, b) => a.expiration.diff(b.expiration)).reverse();
  }

  public static fromDto(org: Organization, dto: IOrgServiceSummaryDto): OrgServiceSummary {
    return new OrgServiceSummary(org, dto);
  }

  public get licenseUnits(): number {
    return this.contracts.reduce((acc, curr) => {
      acc += curr.licenseUnits;
      return acc;
    }, 0);
  } 

  public get children(): OrgContractSummary[] {
    return this.contracts;
  }

  public get treeData(): TreeData {
    return {
      id: `${this.org.uuid}-${this.service}`,
      service: ServiceID.nameOf(this.service),
      orgName: this.org.name,
      contract: "All",
      licenseDimension: "All",
      licenseUnits: this.licenseUnits.toString()
    }
  }
}

export class OrgLicensingSummary {
  org: Organization;
  serviceSummaries: OrgServiceSummary[];

  private constructor(dto: IOrgLicensingSummaryDto) {
    this.org = Organization.create({ uuid: dto.OrgUUID, name: dto.OrgName });
    this.serviceSummaries = dto.Products
      .map(dto => OrgServiceSummary.fromDto(this.org, dto));
    this.serviceSummaries
      .sort((a, b) => a.treeData.service.localeCompare(b.treeData.service));
  }

  public static fromDto(dto: IOrgLicensingSummaryDto): OrgLicensingSummary {
    return new OrgLicensingSummary(dto);
  }

  public get licenseUnits(): number {
    return this.serviceSummaries.reduce((acc, curr) => {
      acc += curr.licenseUnits;
      return acc;
    }, 0);
  }
  
  public get children(): OrgServiceSummary[] {
    return this.serviceSummaries;
  }

  public get contracts(): OrgContractSummary[] {
    return this.serviceSummaries.map(s => s.contracts).flat();
  }

  public get treeData(): TreeData {
    return {
      id: this.org.uuid,
      orgName: this.org.name,
      service: "All",
      contract: "All",
      licenseDimension: "All",
      licenseUnits: this.licenseUnits.toString()
    }
  }
}