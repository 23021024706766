import { License } from "@Module/Licensing/Models/License.model";
import { Prop } from "vue-property-decorator";
import { Utils } from "@Core/Utils/Utils";
import { Component } from "types-vue";
import Vue from "vue";

@Component
export default class LicenseGeneralSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected license: License;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;

  protected get licenseCode(): string {
    if (this.$mq.md) return Utils.smartTrim(this.license.externalId, 8);
    else return Utils.smartTrim(this.license.externalId, 10);
  }

}