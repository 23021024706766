import { EmoryLicenseFilters } from "@Service/Emory/Models/Filters/EmoryLicenseFilters.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { EmoryLicense } from "@Service/Emory/Models/EmoryLicense.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { TrialInfo } from "@Module/Trial/Models/TrialInfo.model";
import TrialStore from "@Module/Trial/Store/Trial.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Component, MapGetter } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";

@Component
export default class EmoryLicenses extends Vue {
  protected serviceId: ServiceID = ServiceID.Emory;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(TrialStore.Mapping)
  protected emoryTrialInfo: TrialInfo;
  
  protected filters = EmoryLicenseFilters.create();

  protected viewDetails(license: EmoryLicense) {
    this.$router.push({ 
      name: "backup-license-details", 
      params: { id: license.id.toString() }
    });
  }

  protected viewContract(license: EmoryLicense) {
    this.$router.push({
      name: "backup-contract-details",
      params: { id: license.contractId.toString() }
    });
  }

  protected get permissions() {
    return {
      CanListContracts: this.accessRole.can(this.serviceId, Permission.ListContracts),
      CanRegisterHosts: this.accessRole.can(this.serviceId, Permission.RegisterHost),
      CanPurchaseContracts: this.accessRole.can(this.serviceId, Permission.PurchaseContract),
      CanRequestTrial: this.accessRole.can(this.serviceId, Permission.RequestTrial)
    }
  }

}