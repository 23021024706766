import ResourcesStore, { ResourcesStoreAction, SetResourcePermissionsPayload } from "@Module/Resources/Store/Resources.store";
import LicensingStore, { ContractsDtoPayload, LicensingStoreAction } from "@Module/Licensing/Store/Licensing.store";
import { FetchLicenseByIdPayload } from "@Module/Licensing/Store/Licensing.store";
import { ServiceID, ServiceMap, ServicePayload } from "@Service/ServiceID";
import OrgsStore, { OrgsStoreGetter } from "@Module/Orgs/Store/Orgs.store";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { IDimensionUnits } from "../../Licensing/Models/Dimension.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { TrialInfo } from "../Models/TrialInfo.model";
import { TrialsStoreState } from "./Trial.state";
import { Utils } from "@Core/Utils/Utils";
import TrialApi from "../API/Trial.api";
import { ActionContext } from "vuex";
import Vue from "vue";

const enum TrialsStoreCommit {
  SetTrialInfo = "_setTrialInfo",
  SetTrialsInfoMap = "_setTrialsInfoMap"
}

export type TrialRequestPayload = ServicePayload<IDimensionUnits[]>;

declare type TrialStoreContext = ActionContext<TrialsStoreState, any>;

@Module({ namespaced: true })
export default class TrialStore extends VuexModule<TrialsStoreState> implements TrialsStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Trial");
  
  public _trialsInfoMap: ServiceMap<TrialInfo> = {};

  @Getter()
  public emoryTrialInfo(): TrialInfo | null {
    return this._trialsInfoMap[ServiceID.Emory] || null;
  }

  @Getter()
  public connectorTrialInfo(): TrialInfo | null {
    return this._trialsInfoMap[ServiceID.Connector] || null;
  }
  
  @Getter()
  public trialInfo(): ServiceMap<TrialInfo> {
    return this._trialsInfoMap;
  }

  @Mutation()
  protected _setTrialsInfoMap(trialsInfo: ServiceMap<TrialInfo>) {
    this._trialsInfoMap = trialsInfo;
  }

  @Mutation()
  protected _setTrialInfo(trialInfo: TrialInfo) {
    Vue.set(this._trialsInfoMap, trialInfo.service, trialInfo);
  }

  @Action({ commit: TrialsStoreCommit.SetTrialsInfoMap })
  public async clearTrialStore(): Promise<ServiceMap<TrialInfo>> {
    return {};
  }

  @Action({ commit: TrialsStoreCommit.SetTrialInfo })
  public async fetchTrialInfo(serviceId: ServiceID): Promise<TrialInfo> {
    const trialDto = await TrialApi.getTrialInfo(serviceId);
    return TrialInfo.fromDto(trialDto, serviceId);
  }

  @Action({ useContext: true })
  public async requestTrialStart(ctx: TrialStoreContext, payload: TrialRequestPayload): Promise<TrialInfo> {
    const org: Organization = OrgsStore.Mapping.rootGetter(OrgsStoreGetter.CurrentOrg)(ctx);
    const responseDto = await TrialApi.requestTrialStart(payload.service, org, payload.data);
    
    const trialInfo = TrialInfo.fromDto(responseDto.trial, payload.service);
    ctx.commit(TrialsStoreCommit.SetTrialInfo, trialInfo);

    const addContracts = LicensingStore.Mapping.rootAction(LicensingStoreAction.AddContracts);
    await addContracts(ctx, { service: payload.service, data: [responseDto.contract] } as ContractsDtoPayload);

    const setResPermissions = ResourcesStore.Mapping.rootAction(ResourcesStoreAction.SetResourcePermissions);
    await setResPermissions(ctx, { service: payload.service, data: { canDownload: true } } as SetResourcePermissionsPayload);

    const fetchContractLicenses = LicensingStore.Mapping.rootAction(LicensingStoreAction.FetchLicensesByContractId);
    await fetchContractLicenses(ctx, { service: payload.service, data: responseDto.contract.id } as FetchLicenseByIdPayload);

    return trialInfo;
  }

}