import { YesNoOption } from "@Core/Components/YesNoCheckboxGroup/YesNoCheckboxGroup";
import { AbapSuiteHostFilters } from "./Filters/AbapSuiteHostFilters.model";
import { Host, IHostDto } from "@Module/Licensing/Models/Host.model";

export class AbapSuiteHost extends Host {
  public readonly isProduction: boolean;
  public readonly sid: string;

  protected constructor(dto: IHostDto) {
    super(dto);
    this.sid = dto.sid;
    this.isProduction = dto.isProduction;
  }

  public static fromDto(dto: IHostDto): AbapSuiteHost {
    return new AbapSuiteHost(dto);
  }

  public meetFilters(filters: AbapSuiteHostFilters): boolean {
    const meetBaseFilters = super.meetFilters(filters);
    if (!meetBaseFilters) { return false; }

    const meetsHostSid = filters.sIds.length > 0 
      ? filters.sIds.some(sid => this.sid === sid) : true;
    if (!meetsHostSid) { return false; }

    const meetsProdEnv = filters.production.length > 0 
      ? filters.production.some(notifStatus => {
        return this.isProduction && notifStatus === YesNoOption.Yes
            || !this.isProduction && notifStatus === YesNoOption.No
        }) : true;
    if (!meetsProdEnv) { return false; }

    return true;
  }
}