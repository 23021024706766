var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('core.drawer',{attrs:{"custom-class":"filters-drawer"},scopedSlots:_vm._u([{key:"reference",fn:function(ref){
var open = ref.open;
var close = ref.close;
var toggle = ref.toggle;
return _vm._t("reference",[_c('el-button',{attrs:{"type":"text","disabled":_vm.disabled,"size":_vm.size},on:{"click":toggle}},[_c('ion-icon',{staticClass:"mr-6",attrs:{"name":"funnel"}}),_c('span',[_vm._v("Filters")])],1)],{"open":open,"close":close,"toggle":toggle})}},{key:"default",fn:function(ref){
var open = ref.open;
var close = ref.close;
var toggle = ref.toggle;
return _vm._t("default",null,{"open":open,"close":close,"toggle":toggle})}}],null,true)},[_c('div',{staticClass:"filters-header flex content-space-between items-center space-x-16",attrs:{"slot":"title"},slot:"title"},[_c('core.label',{attrs:{"icon-size":"16px","icon-spacing":"6px"}},[_c('ion-icon',{attrs:{"slot":"icon","name":"funnel"},slot:"icon"}),_c('H1',[_vm._v(_vm._s(_vm.title))])],1),_c('el-button',{attrs:{"size":"mini","type":"text","disabled":_vm.clearDisabled},on:{"click":function($event){return _vm.$emit('clear')}}},[_c('span',[_vm._v("Clear")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }