import { UIContentSizeMode } from '@Service/Settings/Store/AppSettings/AppSettings.state';
import AppSettingsStore from '@Service/Settings/Store/AppSettings/AppSettings.store';
import { Permission } from '@Module/Auth/Models/Roles/Permissions.model';
import { Component, MapAction, MapGetter, Vue, Watch } from 'types-vue';
import { AccessRole } from '@Module/Auth/Models/Roles/AccessRole.model';
import NavigationStore from '@Core/Store/Navigation/Navigation.store';
import { SessionToken } from '@Module/Auth/Models/SessionToken.model';
import { TrialInfo } from '@Module/Trial/Models/TrialInfo.model';
import { AuthStatus } from '@Module/Auth/Store/Auth.state';
import UsersStore from '@Module/Users/Store/Users.store';
import { ConsoleRoute } from '@Core/Models/ConsoleRoute';
import LegalStore from '@Module/Legal/Store/Legal.store';
import TrialStore from '@Module/Trial/Store/Trial.store';
import { User } from '@Module/Users/Models/User.model';
import AuthStore from '@Module/Auth/Store/Auth.store';
import OrgsStore from '@Module/Orgs/Store/Orgs.store';
import { ServiceID } from '@Service/ServiceID';
import { Map } from '@Core/Models/Map';
import { Console } from 'vue-router';

@Component
export default class ServiceViewport extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(AppSettingsStore.Mapping)
  protected hiddenLoadingIndicator: boolean;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AppSettingsStore.Mapping)
  protected uiContentSizeMode: UIContentSizeMode;

  @MapGetter(NavigationStore.Mapping)
  protected docsUrl: string;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapAction(TrialStore.Mapping)
  protected fetchTrialInfo: (service: ServiceID) => Promise<TrialInfo>;

  @MapAction(LegalStore.Mapping)
  protected closeDataProtectionDialog: () => Promise<void>;

  @MapAction(AuthStore.Mapping)
  protected closeAuthDialog: () => Promise<void>;

  @MapAction(NavigationStore.Mapping)
  protected closeDocsPanel: () => Promise<void>;

  protected docsPageLoading: boolean = false;

  @Watch("sessionToken", { immediate: true })
  @Watch("currentRoute.serviceId", { immediate: true })
  protected onServiceOrSessionChange() {
    if (!!this.sessionToken 
      && !!this.currentRoute.serviceId 
      && this.permissions.CanRequestTrial) {
      this.fetchTrialInfo(this.currentRoute.serviceId);
    }
  }

  @Watch("$route")
  protected onRouteChange(to: Console.Route, from: Console.Route) {
    if (to.path === from?.path) { return; }
    const scroller = this.$refs.viewportContent as Vue;
    scroller?.$el.scrollTo({ top: 0, behavior: 'auto' });
  }

  protected get authRequired(): boolean {
    return this.currentRoute.meta.authRequired && (!this.sessionUser || !this.sessionToken);
  }

  protected get restoringSession(): boolean {
    return this.authStatus === AuthStatus.RestoringSession;
  }

  protected get signingOut(): boolean {
    return this.authStatus === AuthStatus.SigningOut;
  }

  protected get signedIn(): boolean {
    return this.authStatus === AuthStatus.SignedIn;
  }

  protected get serviceViewportClass(): Map<boolean> {
    return { 
      "is-plain": this.isPlainUi,
      "is-airy": this.uiContentSizeMode === "airy",
      "is-docs-embedded": !!this.docsUrl
    };
  }

  protected get userHasPreviewAccessToRoute(): boolean {
    return this.currentRoute.isAccessibleForUserWithPreviewAccess(this.sessionUser?.previewsAccess);
  }

  protected get userHasAccessToRoute(): boolean {
    return this.accessRole.grantsAccessToRoute(this.currentRoute);
  }

  protected get permissions() {
    return {
      CanRequestTrial: this.accessRole.can(this.currentRoute.serviceId, Permission.RequestTrial)
    }
  }

  protected openCurrentDocsUrlInNewTab() {
    let url = this.docsUrl;
    try {
      const iFrame = this.$refs.iFrame as HTMLIFrameElement;
      url = iFrame.contentWindow.location.href;
    } catch (error) {}
    window.open(url, "_blank");
  }

  protected beforeDestroy() {
    this.closeAuthDialog();
    this.closeDataProtectionDialog();
  }

  @Watch("docsUrl")
  protected onDocsUrlChange() {

    this.docsPageLoading = true;
  }

  protected onDocsPageLoad() {
    this.docsPageLoading = false;
  }

  @Watch("signedIn")
  protected onSignedIn(isSignedIn: boolean) {
    if (isSignedIn && this.noOrganization && !this.currentRoute.isOrgConfig) {
      this.$router.replace(ConsoleRoute.OrgConfigPath);
    }
  }
  
}