import { Vue } from 'types-vue'
import { Logger } from '../Log/Logger';

const requireModule = require.context('../../', true,  /\.vue$/);

const kebabize = (str: string) => {
  return str.split('').map((letter, idx) => {
    return letter.toUpperCase() === letter
      ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
      : letter;
  }).join('');
}

requireModule.keys()
  .filter(fileName => fileName.includes('/Components/'))
  .map(filename => {
    const separateFileName = filename.split('/').filter(part => part !== "Components");
    const componentname = separateFileName.pop().split('.')[0];
    const modulename = (['Modules', 'Services'].includes(separateFileName[1]))
      ? separateFileName[2] // es un modulo/servicio
      : separateFileName[1]; // es el core
      return { filename, modulename, componentname }
    })
    .forEach(({ filename, modulename, componentname }) => {
    const component = requireModule(filename).default || requireModule(filename);
    const modName = kebabize(modulename).toLowerCase();
    const cmpName = kebabize(componentname).toLowerCase();
    // const cmpTag = !cmpName.startsWith(modName) ? `${modName}-${cmpName}` : cmpName;
    const cmpTag = `${modName}.${cmpName}`;
    Logger.default.debug(`[√] Loaded component '${filename}' as '${cmpTag}'`);
    Vue.component(cmpTag, component);
  });