import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class CopyLabel extends Vue {

  @Prop({ type: String, required: true })
  protected value: string;

  @Prop({ type: String, default: "end" })
  protected iconPosition: "start" | "end";

  @Prop({ type: String, default: "inherit" })
  protected iconSize: string;

  @Prop({ type: String, default: "4px" })
  protected iconSpacing: string;

  protected copying: boolean = false;
  protected showSuccess: boolean = false;

  public async copy() {
    try {
      this.copying = true;
      this.copyToClipboard(this.value);
      await Utils.sleep(250);
      // this.$message({ 
      //   message: "Copied to clipboard.",
      //   offset: 60,
      //   type: "success",
      //   showClose: true,
      //   duration: 1000,
      //   center: true
      // });
      this.showSuccess = true;
      await Utils.sleep(2000);
      this.showSuccess = false;
    } catch (error) { 
      console.error("Error copying content.") 
    } finally {
      this.copying = false;
    }
  }

  private copyToClipboard(text: string) {
    const input = document.createElement("input");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("Copy");
    input.remove();
  }

}