import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import { Utils } from "@Core/Utils/Utils";
import { User } from "@Module/Users/Models/User.model";
import UsersStore from "@Module/Users/Store/Users.store";
import { Component, MapGetter, Prop, Vue, Watch } from "types-vue";
import NotificationContentMixin, { INotificationContent } from "../../Mixins/NotificationContentMixin";
import { Notification } from "../../Models/Notification.model";
import DeepClone from "lodash.clonedeep";

interface NotificationEvent {
  notification: Notification;
}

export interface NotifLoadingEvent extends NotificationEvent {
  loading: boolean;
}

export interface NotifConfiguringResponseEvent extends NotificationEvent {
  configuring: boolean;
}

@Component
export default class NotificationListItem extends Vue {

  @Prop({ type: Object, required: true })
  protected notification: Notification;

  @Prop({ type: Boolean, default: true })
  protected isInbox: boolean;

  @Prop({ type: Boolean, default: true })
  protected allowResend: boolean;

  @Prop({ type: Boolean, default: true })
  protected allowCancel: boolean;

  @Prop({ type: Boolean, default: false })
  protected hideTypeLabel: boolean;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  protected canCancel: boolean = false;
  protected canResend: boolean = false;
  protected canAccept: boolean = false;
  protected canDecline: boolean = false;
  protected isConfiguringResponse: boolean = false;

  protected cancel = Execution.create(this.doCancel);
  protected accept = Execution.create(this.doAccept);
  protected decline = Execution.create(this.doDecline);
  protected resend = Execution.create(this.doResend);

  @Watch("isLoading")
  protected onLoadingChange(loading: boolean) {
    const loadingEvent: NotifLoadingEvent = { notification: this.notification, loading };
    this.$emit("loading", loadingEvent);
  }

  @Watch("isConfiguringResponse")
  protected onConfiguringResponse(configuring: boolean) {
    const configEvent: NotifConfiguringResponseEvent = { notification: this.notification, configuring };
    this.$emit("configuring", configEvent);
  }

  protected mounted() {
    this.$watch(
      () => this.getContent()?.isConfiguringResponse ?? false,
      isConfiguringResponse => this.isConfiguringResponse = isConfiguringResponse,
      { immediate: true }
    );
    this.$watch(
      () => this.getContent()?.canAccept ?? false, 
      canAccept => this.canAccept = canAccept, 
      { immediate: true }
    );
    this.$watch(
      () => this.getContent()?.canDecline ?? false, 
      canDecline => this.canDecline = canDecline, 
      { immediate: true }
    );
    this.$watch(
      () => this.getContent()?.canResend ?? false, 
      canResend => this.canResend = canResend, 
      { immediate: true }
    );
    this.$watch(
      () => this.getContent()?.canCancel ?? false, 
      canCancel => this.canCancel = canCancel, 
      { immediate: true }
    );
  }

  protected beforeDestroy() {
    this.onLoadingChange(false);
    this.onConfiguringResponse(false);
  }

  protected getContent(): NotificationContentMixin & INotificationContent {
    return this.$refs.content as NotificationContentMixin & INotificationContent;
  }

  protected get sender(): string {
    return Utils.smartTrim(this.notification.sender, 20);
  }

  private async doDecline() {
    try {
      const notification = DeepClone(this.notification);
      const content = this.getContent();
      const shouldProceed = await content.decline();
      if (!shouldProceed) { return; }
      this.$emit("decline", notification);
      await content.onDeclined();
      await this.$nextTick();
      Notify.Success(content.getSuccessNotifConfig().Decline);
    } catch (error) {
      Notify.Error({
        title: "Notification response error", 
        message: error.message
      });
    }
  }

  private async doCancel() {
    try {
      const notification = DeepClone(this.notification);
      const content = this.getContent();
      const shouldProceed = await content.cancel();
      if (!shouldProceed) { return; }
      this.$emit("cancel", notification);
      await content.onCanceled();
      await this.$nextTick();
      Notify.Success(content.getSuccessNotifConfig().Cancel);
    } catch (error) {
      Notify.Error({
        title: "Notification cancel error", 
        message: error.message
      });
    }
  }

  private async doAccept() {
    try {
      const notification = DeepClone(this.notification);
      const content = this.getContent();
      const shouldProceed = await content.accept();
      if (!shouldProceed) { return; }
      this.$emit("accept", notification);
      await content.onAccepted();
      await this.$nextTick();
      Notify.Success(content.getSuccessNotifConfig().Accept);
    } catch (error) {
      Notify.Error({
        title: "Notification response error", 
        message: error.message
      });
    }
  }

  private async doResend() {
    await this.getContent().resend(); 
    this.$emit("resend", this.notification);
  }

  protected get isLoading(): boolean {
    return this.cancel.loading 
        || this.accept.loading 
        || this.decline.loading 
        || this.resend.loading;
  }

  protected get wasSentByMe(): boolean {
    return this.notification.sender === this.sessionUser.username;
  }

}