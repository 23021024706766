
import { Component, Prop } from "types-vue";
import { Map } from "@Core/Models/Map";
import Vue from "vue";

@Component
export default class Label extends Vue {
  
  @Prop({ type: String, default: null })
  protected value: string;

  @Prop({ type: String, default: "primary" })
  protected type: "primary" | "secondary" | "inherit" | "danger" | "success" | "border";
  
  @Prop({ type: String, default: "start" })
  protected iconPosition: "start" | "end";

  @Prop({ type: String, default: "0px" })
  protected iconYOffset: string;

  @Prop({ type: String, default: "inherit" })
  protected iconSize: string;

  @Prop({ type: String, default: "6px" })
  protected iconSpacing: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  protected get labelClass() {
    return {
      "is-icon-start": this.iconPosition === "start",
      "is-icon-end": this.iconPosition === "end",
      "is-primary": this.type === "primary",
      "is-disabled": this.disabled,
      "is-secondary": this.type === "secondary",
      "is-inherit": this.type === "inherit",
      "is-danger": this.type === "danger",
      "is-success": this.type === "success",
      "is-border": this.type === "border"
    }
  }
  
  protected get iconStyle(): Map<string> {
    return {
      "font-size": this.iconSize,
      "margin": this.iconPosition === "end" 
            ? `${this.iconYOffset} 0 0 ${this.iconSpacing}` 
            : `${this.iconYOffset} ${this.iconSpacing} 0 0`
    }
  }

}