import { AbstractCookie, CookieOptions } from "@Core/Models/Cookies/AbstractCookie.model";
import { OpUnitType as DayJsUnit } from "dayjs";

export interface ExpirationThreshold {
  amount: number;
  unit: DayJsUnit;
}

export class ExpirationThresholdCookie extends AbstractCookie<ExpirationThreshold> {

  public static create(name: string, options?: CookieOptions<ExpirationThreshold>) {
    return new ExpirationThresholdCookie(name, options);
  }

  protected parseCookie(value: any, options: CookieOptions<ExpirationThreshold>): ExpirationThreshold | null {
    return !!value ? value : options.default;
  }

}