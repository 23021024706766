import { Organization } from "./Organization.model";

export interface IOrgsLicensingSummaryFiltersDto {
  withTests: boolean;
  onlyActive: boolean;
}

export class OrgsLicensingSummaryFilters {
  withTests: boolean;
  onlyActive: boolean;
  hideWithoutContracts: boolean;
  orgs: Organization[];

  public static create(): OrgsLicensingSummaryFilters {
    return new OrgsLicensingSummaryFilters();
  }
  private constructor() {
    this.withTests = false;
    this.onlyActive = false;
    this.hideWithoutContracts = false;
    this.orgs = [];
  }

  protected get dto(): IOrgsLicensingSummaryFiltersDto {
    return this.toDto();
  }

  public get isFiltering(): boolean {
    return this.withTests
      || this.onlyActive;
  }

  public toDto(): IOrgsLicensingSummaryFiltersDto {
    return {
      withTests: this.withTests,
      onlyActive: this.onlyActive
    }
  }

}