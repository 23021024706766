import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { SignInChallenge } from "@Module/Auth/Models/SignIn/SignInChallenge.model";
import { SignInResponse } from "@Module/Auth/Models/SignIn/SignInResponse.model";
import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import { SignIn } from "@Module/Auth/Models/SignIn/SignIn.model";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { ElForm } from "element-ui/types/form";
import Vue from "vue";

@Component
export default class SignInPanel extends Vue {

  @Prop({ type: String, default: null })
  protected context: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected skippable: boolean;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgInvitationLink: OrgInvitationNotificationLink;

  @MapAction(AuthStore.Mapping)
  protected signIn: (model: SignIn) => Promise<SignInResponse | SignInChallenge>;

  protected signingIn: boolean = false;
  protected signInError: Error = null;
  protected signInModel: SignIn = SignIn.create();

  @Watch("orgInvitationLink", { immediate: true })
  protected onInvitationChange(link: OrgInvitationNotificationLink) {
    this.signInModel.email = link?.email ?? null;
  }

  protected get canSignIn(): boolean {
    return !!this.signInModel.email 
        && !!this.signInModel.password;
  }

  protected get isDisabled(): boolean {
    return this.disabled || this.signingIn;
  }

  protected setSigningIn(signingIn: boolean) {
    this.signingIn = signingIn;
    this.$emit("update:disabled", signingIn);
  }

  protected async submit() {
    try {
      await (this.$refs.form as ElForm).validate()
      await this.performSignIn();
    } catch (error) {}
  }

  protected async performSignIn() {    
    let result: SignInResponse | SignInChallenge;
    try {
      this.signInError = null;
      this.setSigningIn(true);
      result = await this.signIn(this.signInModel);
    } catch (error) {
      if (error instanceof SignInChallenge) {
        this.$emit("challenge", result);
      } else {
        this.signInError = error;
      }
    } finally {
      this.setSigningIn(false);
      if (!!result) {
        this.$emit("success", result);
      }
    }
  }
  
  protected validationRules = {
    email: [{ type: "email", message: "Please enter a valid email address.", trigger: ["change", "blur"] }]
  }

  protected get formWithErrors(): boolean {
    return (this.$refs.form as any).fields?.some((item: any) => item.validateState === "error") || false;
  }

  public closeGuard(close: VoidFunction) {
    if (this.authStatus === AuthStatus.SigningIn) return;
    else return close();
  }

}
