import { ConsoleError } from "@Core/Errors/ConsoleError";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { Execution } from "@Core/Models/Execution";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Notify } from "@Core/Utils/Notify";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { User } from "@Module/Users/Models/User.model";
import UsersStore from "@Module/Users/Store/Users.store";
import { ServiceID } from "@Service/ServiceID";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapAction, MapGetter, Watch } from "types-vue";
import AuthStore from "@Module/Auth/Store/Auth.store";
import Vue from "vue";

const enum Section {
  General = "general",
  MainOrg = "org",
  AllowedOrgs = "allowed-orgs"
}

@Component
export default class UserDetails extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(UsersStore.Mapping)
  protected users: User[];

  @MapGetter(OrgsStore.Mapping)
  protected defaultOrg: Organization;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapAction(UsersStore.Mapping)
  protected fetchUser: (username: string) => Promise<void>;

  @MapAction(UsersStore.Mapping)
  protected deleteUser: (user: User) => Promise<void>;

  protected error: ConsoleError = null;

  protected allSections: Section[] = [ Section.General, Section.MainOrg, Section.AllowedOrgs];
  protected expandedSections: Section[] = this.allSections;

  protected getUser = Execution.create((username: string) => this.fetchUser(username));
  protected removeUser = Execution.create((user: User) => this.deleteUser(user));

  @Watch("$route.params.username", { immediate: true })
  protected async onUsernameParamChange() {
    this.error = null;
    this.getUser.reset();
    await this.$nextTick();

    const username = this.$route.params.username;
    const fetchedUser = this.users.some(user => user.username === username);
    if (!fetchedUser) {
      await this.getUser.run(username);
      if (!!this.getUser.error) {
        const errorMsg = `User with username ${this.$route.params.id} was not found.`;
        this.error = new ConsoleError(errorMsg, { fatal: true, meta: { username } });
        return;
      }
    }
  }

  protected get user(): User {
    const username = this.$route.params.username;
    return this.users.find(u => u.username === username);
  }

  protected get allSectionsCollapsed(): boolean {
    return this.expandedSections.length === 0;
  }

  protected async onDeleteClick(user: User) {
    try {
      await this.$confirm(
        "Are you sure you want to delete this user? This action can not be undone.", 
        "Delete confirmation", {
          confirmButtonText: "Delete user",
          cancelButtonText: "Cancel",
          type: "warning"
      });
      await this.removeUser.run(user);
      if (!!this.removeUser.error) {
        Notify.Error({
          title: "User deletion error",
          message: this.removeUser.error.message,
          duration: 3000
        });
      } else {
        Notify.Success({
          title: "User deleted",
          message: "The user was successfully deleted.",
          duration: 3000
        });
      }
    } catch {}
  }

  protected toggleExpandAll() {
    this.expandedSections = this.allSectionsCollapsed ? this.allSections : [];
  }

  protected onBackClick() {
    if (!!this.previousRoute) {
      this.$router.back();
    } else {
      this.$router.replace("/cxlink/users");
    }
  }
  
  protected getServiceName(service: ServiceID): string {
    return ServiceID.nameOf(service);
  }

  protected get permissions() {
    return {
      CanCreateAppUsers: this.accessRole.can(ServiceID.Console, Permission.CreateAppUsers),
      CanEditAppUsers: this.accessRole.can(ServiceID.Console, Permission.EditAppUsers),
      CanDeleteAppUsers: this.accessRole.can(ServiceID.Console, Permission.DeleteAppUsers)
    }
  }

}