export interface IDimensionDto {
  name: string;
  description: string;
}

export interface IDimensionUnitsDto {
  dimension: string;
  units: number;
}

export type IDimensionUnits = IDimensionUnitsDto;

export class Dimension {
  public readonly name: string;
  public readonly description?: string;

  private constructor(dto: IDimensionDto) {
    this.name = dto.name;
    this.description = dto.description;
  }

  public static fromDto(dto: IDimensionDto): Dimension {
    return new Dimension(dto);
  }

  public static create(name: string): Dimension {
    return new Dimension({ name, description: null });
  }

  public meetsFilter(search: string): boolean {
    const text = search ? search.toLowerCase() : "";
    return this.name.toLowerCase().includes(text) || this.description.toLowerCase().includes(text);
  }
  
}