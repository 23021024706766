import { Utils } from "@Core/Utils/Utils";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class PropValueSelector extends Vue {

  @Prop({ required: true })
  protected value: any | any[];

  @Prop({ type: Array, required: true })
  protected data: any[];

  @Prop({ type: String, required: true })
  protected prop: string;

  @Prop({ type: String, default: "Filter by origin" })
  protected placeholder: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected multiple: boolean;

  @Prop({ type: Boolean, default: false })
  protected filterable: boolean;

  @Prop({ type: Boolean, default: false })
  protected clearable: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  protected get values(): string[] {
    const data: Set<string> = this.data.reduce(
      (set: Set<string>, item: any) => {
        const propValue = Utils.getNestedProperty(item, this.prop);
        if (propValue === undefined || propValue === null) return set;
        set.add(String(propValue));
        return set;
      }, new Set<string>()
    );
    return Array.from(data);
  }

  protected get hasValues(): boolean {
    return this.values.length > 0;
  }

  protected onValueSelected(value: any | any[]) {
    this.$emit("input", value);
  }

}