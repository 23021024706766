import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class Icon extends Vue {

  @Prop({ type: String, required: true })
  protected name: string;

  @Prop({ type: String, default: "element" })
  protected type: "element" | "ion-icon" | "custom";
  
}