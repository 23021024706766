export interface ICategoryDto {
  name: string;
  subcategories: string[];
}

export class Category {
  public readonly name: string;
  public readonly subcategories: string[];


  private constructor(dto: ICategoryDto) {
    this.name = dto.name;
    this.subcategories = dto.subcategories;
  }

  public static fromDto(dto: ICategoryDto): Category{
    return new Category(dto);
  }

  public toDto(): ICategoryDto {
    return  {
      name: this.name,
      subcategories: this.subcategories
    };
  }
}