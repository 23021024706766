import Axios, { AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Organization } from '@Module/Orgs/Models/Organization.model';
import _Vue from 'vue'

export default class AxiosAPI {
  public static readonly axios: AxiosInstance = Axios.create({ unauthenticated: false });
  public static readonly awsAxios: AxiosInstance = Axios.create({ unauthenticated: false });

  protected get axios(): AxiosInstance {
    return AxiosAPI.axios;
  }

  protected get awsAxios(): AxiosInstance {
    return AxiosAPI.awsAxios;
  } 

  public static getOrgUUID(): string {
    return this.axios.defaults?.params?.orguuid;
  }

  public static getOrgID(): string {
    return this.axios.defaults?.params?.orgId;
  }

  public static setOrganization(org?: Organization) {
    this.axios.defaults.params ??= {};
    this.axios.defaults.params.orguuid = org?.uuid;
    this.axios.defaults.params.orgId = org?.id;
  }

  public static setToken(token?: string) {
    const authToken = !!token ? `Bearer ${token}` : undefined;
    this.axios.defaults.headers.common["Authorization"] = authToken;
  }

  public static get requestInterceptor(): AxiosInterceptorManager<AxiosRequestConfig>  {
    return AxiosAPI.axios.interceptors.request;
  }

  public static get responseInterceptor(): AxiosInterceptorManager<AxiosResponse>  {
    return AxiosAPI.axios.interceptors.response;
  }
}