import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { User } from "@Module/Users/Models/User.model";
import { Permission } from "./Permissions.model";
import { ServiceID } from "@Service/ServiceID";
import { Console } from "vue-router";

export interface IRoleDto {
  id: number;
  name: string;
  is_managed: boolean;
}

export class Role {
  public readonly id: number;
  public readonly name: string;
  public readonly isManaged: boolean;

  private constructor(dto: IRoleDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.isManaged = dto.is_managed;
  }

  public static fromDto(dto: IRoleDto): Role {
    return new Role(dto);
  }

  public meetsFilter(search: string): boolean {
    const text = search ? search.toLowerCase() : "";
    return this.name.toLowerCase().includes(text);
  }

  public static get Guest(): Role {
    return new Role({ 
      id: -1, 
      name: "Guest", 
      is_managed: false 
    });
  }

  public static get NoAccess(): Role {
    return new Role({ 
      id: -2, 
      name: "No Access", 
      is_managed: false 
    });
  }

  public get isGuest(): boolean {
    return this.id === -1;
  }

  public get isNoAccess(): boolean {
    return this.id === -2;
  }

  public toDto(): IRoleDto {
    return {
      id: this.id,
      name: this.name,
      is_managed: this.isManaged
    }
  }

}

export interface IRolePermissionsDto {
  Role: IRoleDto;
  Permissions: string[];
}

export class AccessRole {
  public readonly id: number;
  public readonly name: string;
  public readonly permissions: string[];
  public readonly isManaged: boolean;
  public readonly user?: User;
  
  private constructor(dto: IRolePermissionsDto, user?: User) {
    this.id = dto.Role.id;
    this.name = dto.Role.name;
    this.permissions = dto.Permissions;
    this.isManaged = dto.Role.is_managed;
    this.user = user || null;
  }

  public static guestRole(user?: User): AccessRole {
    return new AccessRole({ 
      Role: Role.Guest.toDto(),
      Permissions: []
    }, user);
  }

  public static fromDto(dto: IRolePermissionsDto, user: User): AccessRole {
    return new AccessRole(dto, user);
  }

  public get isGuestRole(): boolean {
    return this.id === null;
  }

  public canAll(service: ServiceID, ...permissions: Permission[]): boolean {
    return permissions.every(permission => this.can(service, permission));
  }

  public canSome(service: ServiceID, ...permissions: Permission[]): boolean {
    return permissions.some(permission => this.can(service, permission));
  }

  public canForSomeService(permission: Permission): boolean {
    return this.permissions.some(p => p.endsWith(`:${permission}`));
  }

  public can(service: ServiceID, permission: Permission): boolean {
    return this.permissions.includes(`${service}:${permission}`)
        || this.permissions.includes(`${service}:*`);
  }

  public grantsAccessToRoute(route: ConsoleRoute | Console.RouteDefinition): boolean {
    const canAccessUser = route.meta.canAccessUser?.(this.user) ?? true;
    const routePermissions = route.meta.permissions || [];
    const reqPermissions = Array.isArray(routePermissions) ? routePermissions : [routePermissions];
    return canAccessUser && this.hasPermissions(...reqPermissions as string[]);
  }

  public hasPermissions(...permissions: string[]): boolean {
    return permissions.every(permission => { 
      const service = permission.split(":")[0];
      return this.permissions.includes(permission)
          || this.permissions.includes(`${service}:*`);
    });
  }
}