import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { Contract } from "@Module/Licensing/Models/Contract.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class ContractListRow extends Vue {

  @Prop({ type: Object, required: true })
  protected contract: Contract;

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }

}