import { SignIn } from "../Models/SignIn/SignIn.model";
import AxiosAPI from "@Core/API/AxiosAPI";
import { ISignInResponseDto, SignInResponse } from "../Models/SignIn/SignInResponse.model";
import { ISignInChallengeDto, SignInChallenge } from "../Models/SignIn/SignInChallenge.model";
import { SignInApiError } from "../Errors/SignInApiError";
import { SignUp } from "../Models/SignUp/SignUp.model";
import { SignUpApiError } from "../Errors/SignUpApiError";
import { NewPwdChallenge } from "../Models/SignIn/NewPwdChallenge.model";
import { NewPwdApiError } from "../Errors/NewPwdApiError";
import { ForgotPwd } from "../Models/ForgotPwd/ForgotPwd.model";
import { ForgotPwdApiError } from "../Errors/ForgotPwdApiError";
import { SignOutApiError } from "../Errors/SignOutApiError";
import { ChangePwdApiError } from "../Errors/ChangePwdApiError";
import { GetRolesApiError } from "../Errors/GetRolesApiError";
import { GetRolePermissionsApiError } from "../Errors/GetRolePermissionsApiError";
import { IRoleDto, IRolePermissionsDto } from "../Models/Roles/AccessRole.model";
import { Recovery } from "../Models/Recovery.model";

class AuthAPI extends AxiosAPI {
  private static instance: AuthAPI;
 
  public static get Instance(): AuthAPI {
    return this.instance || (this.instance = new this());
  }

  protected url(slug?: string): string {
    const baseUrl = process.env.VUE_APP_SERVICE_URL_AUTH;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  protected rolesUrl(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/roles`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  public async signOut(accessToken: string): Promise<void> {
    try {
      const url = this.url("authentication/servicesignout");
      await this.axios.post(url, undefined, { headers: { "Access-Header": accessToken } });
    } catch (error) {
      const message = `An error occurred while signing out. Please try again later.`;
      throw new SignOutApiError(message, {
        cause: error,
        meta: { accessToken }
      });
    }
  }

  public async signIn(model: SignIn): Promise<SignInResponse | SignInChallenge> {
    try {
      const requestDto = model.toDto();
      const url = this.url("authentication/servicesignin");
      const response = await this.axios.post(url, requestDto, { unauthenticated: true });
      const responseDto: ISignInResponseDto | ISignInChallengeDto = response.data;
      if (this.isSignInChallengeDto(responseDto)) {
        const challenge = SignInChallenge.fromDto(responseDto);
        challenge.keepSession = model.keepSession;
        return challenge;
      } else {
        return SignInResponse.fromDto(responseDto);
      }
    } catch (error) {
      if (error.response.status === 413) {
        const challenge = SignInChallenge.fromDto({ challenge_name: "VALIDATION_REQUIRED", username: model.email, session: null });
        challenge.keepSession = model.keepSession;
        return challenge;
      }
      const message = `An error occurred while signing in. Please check your email and password.`;
      throw new SignInApiError(message, { 
        cause: error, 
        meta: { username: model.email, code: error.response.status }
      });
    }
  }

  public async signUp(model: SignUp): Promise<void> {
    const requestDto = model.toDto();
    const environment = process.env.VUE_APP_ENV;
    const url = this.url("user/register");
    try {
      await this.axios.post(url, requestDto, { unauthenticated: true });
    } catch (error) {
      const message = `An error occurred while signing up.`;
      throw new SignUpApiError(message, { 
        cause: error, 
        meta: requestDto
      });
    }
  }

  public async changePassword(username: string, newPwd: string): Promise<void> {
    try {
      const url = this.url("user/changepassword");
      const response = await this.axios.post(url, {
        username, newpassword: newPwd
      });
      const data = response.data as { success: boolean };
      if (data.success !== true) {
        const msg = `This request ended with a failure flag into the response body. (success: ${data.success})`;
        throw new Error(msg);
      }
    } catch (error) {
      const message = `An error occurred while changing your password. Please try again later.`;
      throw new ChangePwdApiError(message, {
        cause: error,
        meta: { username }
      });
    }
  }

  public async forgotPwd(model: ForgotPwd): Promise<void> {
    const requestDto = model.toDto();
    const url = this.url("authentication/forgotpassword");
    try {
      await this.axios.post(url, requestDto, { unauthenticated: true });
    } catch (error) {
      const message = `An error occurred requesting your password recovery.`;
      throw new ForgotPwdApiError(message, { 
        cause: error, 
        meta: { username: requestDto.username }
      });
    }
  }

  public async confirmForgotPwd(model: ForgotPwd): Promise<void> {
    const requestDto = model.toDto();
    const url = this.url("authentication/confirmforgotpassword");
    try {
      await this.axios.post(url, requestDto, { unauthenticated: true });
    } catch (error) {
      const message = `An error occurred performing your password recovery.`;
      throw new ForgotPwdApiError(message, { 
        cause: error, 
        meta: { username: requestDto.username }
      });
    }
  }

  public async respondToNewPwdChallenge(challenge: NewPwdChallenge): Promise<SignInResponse> {
    try {
      const requestDto = challenge.toDto();
      const url = this.url("authentication/activateuser");
      const response = await this.axios.post(url, requestDto, { unauthenticated: true });
      const responseDto: ISignInResponseDto = response.data;
      return SignInResponse.fromDto(responseDto);
    } catch (error) {
      const message = `An error occurred while responding to mandatory password change. Please try again.`;
      throw new NewPwdApiError(message, { 
        cause: error, 
        meta: { username: challenge.username, challenge }
      });
    }
  }

  public async verifyCode(challenge: Recovery): Promise<string> {
    try {
      const requestDto = challenge.toDto();
      const url = this.url("authentication/activateuser");
      const response = await this.axios.post(url, requestDto, { unauthenticated: true });
      const responseDto: ISignInResponseDto = response.data;
      return response.data;
    } catch (error) {
      const message = `An error occurred while responding to mandatory password change. Please try again.`;
      throw new NewPwdApiError(message, { 
        cause: error, 
        meta: { username: challenge.username }
      });
    }
  }

  private isSignInChallengeDto(dto: any): dto is ISignInChallengeDto {
    if (!dto) return false;
    return !!(dto as ISignInChallengeDto).challenge_name;
  }

  public async getRolePermissions(id: number): Promise<IRolePermissionsDto> {
    try {
      const url = this.rolesUrl(`${id.toString()}/permissions`);
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving user organizations.`;
      throw new GetRolePermissionsApiError(message, { 
        cause: error, 
        meta: { id }
      });
    }
  }

  public async getRoles(): Promise<IRoleDto[]> {
    try {
      const url = this.rolesUrl();
      const response = await this.axios.get(url);
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving role list.`;
      throw new GetRolesApiError(message, { cause: error });
    }
  }

}

export default AuthAPI.Instance;