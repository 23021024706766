import { VueClickOutside } from './Directives/ClickOutside';
import BrowserDetect from "vue-browser-detect-plugin";
import locale from 'element-ui/lib/locale/lang/en'
import VueProgressBar from 'vue-progressbar';
import VueCookies from 'vue-cookies';
import ElementUI from 'element-ui';
import DayJS from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Duration from "dayjs/plugin/duration";
import UTC from "dayjs/plugin/utc";
import AxiosRetry from 'axios-retry';
import MQ from 'vue-match-media';
import VueHotkey from 'v-hotkey';
import "reflect-metadata";

import { Capitalize } from './Filters/Capitalize';
import { Pluralize } from "./Filters/Pluralize";

import Router from './Router';
import Store from './Store';
import App from './App.vue';
import Vue from 'vue';

import './Styles/header.css';
import './Styles/element/index.scss';
import './Styles/index.scss';

import './Components';
import AppConfig from './AppConfig';
import AxiosAPI from './API/AxiosAPI';
import { Env } from './Utils/Env';

AxiosRetry(AxiosAPI.axios, { 
  retries: 3, 
  retryDelay: AxiosRetry.exponentialDelay 
});

Vue.config.ignoredElements = ["ion-icon"];
Vue.config.productionTip = false;
Vue.$router = Router;
Vue.$ENV = Env;

Vue.directive(VueClickOutside.Name, VueClickOutside.Definition);

Vue.filter(Capitalize.name, Capitalize.filter);
Vue.filter(Pluralize.name, Pluralize.filter);

Vue.prototype.$ENV = Env;
Vue.use(ElementUI, { locale, size: "large" });
Vue.prototype.$ELEMENT = AppConfig;
Vue.use(VueCookies);
Vue.use(VueHotkey);
Vue.use(BrowserDetect);
Vue.use(MQ);
Vue.use(VueProgressBar, { position: "absolute" });


DayJS.extend(RelativeTime);
DayJS.extend(LocalizedFormat);
DayJS.extend(Duration);
DayJS.extend(UTC);

new Vue({
  router: Router,
  store: Store,
  render: (h) => h(App),
  mq: {
    xs: "screen and (max-width: 480px)",
    sm: "screen and (max-width: 640px)",
    md: "screen and (max-width: 768px)",
    lg: "screen and (max-width: 1124px)",
    xl: "screen and (max-width: 1536px)",
    xxl: "screen and (min-width: 1700px)"
  }
} as any).$mount('#app');
