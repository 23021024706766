import { AppSettingsDefaults, AppSettingsStoreState, UIContentSizeMode, UIMode, UISize } from "./AppSettings.state";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import { ActionContext } from "vuex";
import Vue from "vue";
import AppConfig from "@Core/AppConfig";

const enum AppSettingsStoreCookie {
  ExpandSubmenus = "settings.app.menu.expand-submenus",
  CollapsedMenu = "settings.app.menu.collapsed",
  HiddenBreadcrumb = "settings.app.ui.hidden-breadcrumb",
  HiddenLoadingIndicator = "settings.app.ui.hidden-loading-indicator",
  UiMode = "settings.app.ui.ui-mode",
  UiSize = "settings.app.ui.ui-size",
  UiContentSizeMode = "settings.app.ui.content-size-mode"
}

const enum AppSettingsStoreCommit {
  SetCollapsedMenu = "_setCollapsedMenu",
  SetExpandSubmenus = "_setExpandSubmenus", 
  SetUiMode = "_setUiMode",
  SetUiSize = "_setUiSize",
  SetUiContentSizeMode = "_setUiContentSizeMode",
  SetHiddenBreadcrumb = "_setHiddenBreadcrumb",
  SetHiddenLoadingIndicator = "_setHiddenLoadingIndicator"
}

const enum AppSettingsStoreAction {
  SetCollapsedMenu = "setCollapsedMenu",
  SetExpandSubmenus = "setExpandSubmenus",
  SetUiMode = "setUiMode",
  SetUiSize = "setUiSize",
  SetUiContentSizeMode = "setUiContentSizeMode",
  SetHiddenBreadcrumb = "setHiddenBreadcrumb",
  SetHiddenLoadingIndicator = "setHiddenLoadingIndicator",
}

@Module({ namespaced: true })
export default class AppSettingsStore extends VuexModule<AppSettingsStoreState> implements AppSettingsStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("AppSettings");

  public _collapsedMenu: boolean = AppSettingsDefaults._collapsedMenu;
  public _hiddenBreadcrumb: boolean = AppSettingsDefaults._hiddenBreadcrumb;
  public _uiSize: UISize = AppSettingsDefaults._uiSize;
  public _uiMode: UIMode = AppSettingsDefaults._uiMode;
  public _uiContentSizeMode: UIContentSizeMode = AppSettingsDefaults._uiContentSizeMode;
  public _hiddenLoadingIndicator: boolean = AppSettingsDefaults._hiddenLoadingIndicator;
  public _expandSubmenus: boolean = AppSettingsDefaults._expandSubmenus;

  @Getter() public collapsedMenu(): boolean { 
    return this._collapsedMenu; 
  }

  @Getter() public expandSubmenus(): boolean { 
    return this._expandSubmenus; 
  }

  @Getter() public uiSize(): UISize { 
    return this._uiSize; 
  }

  @Getter() public uiMode(): UIMode { 
    return this._uiMode; 
  }

  @Getter() public hiddenBreadcrumb(): boolean { 
    return this._hiddenBreadcrumb; 
  }

  @Getter() public hiddenLoadingIndicator(): boolean { 
    return this._hiddenLoadingIndicator; 
  }

  @Getter() public uiContentSizeMode(): UIContentSizeMode { 
    return this._uiContentSizeMode; 
  }

  @Getter()
  public isPlainUi(): boolean { 
    return this._uiMode === "plain"; 
  }

  @Mutation()
  protected _setCollapsedMenu(collapsed: boolean) {
    this._collapsedMenu = collapsed;
    Vue.$cookies.set(AppSettingsStoreCookie.CollapsedMenu, collapsed, Infinity);
  }

  @Mutation()
  protected _setExpandSubmenus(expand: boolean) {
    this._expandSubmenus = expand;
    Vue.$cookies.set(AppSettingsStoreCookie.ExpandSubmenus, expand, Infinity);
  }

  @Mutation()
  protected _setHiddenBreadcrumb(hidden: boolean) {
    this._hiddenBreadcrumb = hidden;
    Vue.$cookies.set(AppSettingsStoreCookie.HiddenBreadcrumb, hidden, Infinity);
  }

  @Mutation()
  protected _setHiddenLoadingIndicator(hidden: boolean) {
    this._hiddenLoadingIndicator = hidden;
    Vue.$cookies.set(AppSettingsStoreCookie.HiddenLoadingIndicator, hidden, Infinity);
  }

  @Mutation()
  protected _setUiMode(mode: UIMode) {
    this._uiMode = mode;
    Vue.$cookies.set(AppSettingsStoreCookie.UiMode, mode, Infinity);
  }

  @Mutation()
  protected _setUiSize(size: UISize) {
    this._uiSize = size;
    AppConfig.size = size;
    Vue.$cookies.set(AppSettingsStoreCookie.UiSize, size, Infinity);
  }

  @Mutation()
  protected _setUiContentSizeMode(mode: UIContentSizeMode) {
    this._uiContentSizeMode = mode;
    Vue.$cookies.set(AppSettingsStoreCookie.UiContentSizeMode, mode, Infinity);
  }

  @Action({ useContext: true, commit: AppSettingsStoreCommit.SetCollapsedMenu })
  protected async toggleCollapsedMenu(ctx: ActionContext<AppSettingsStoreState, any>) {
    return !ctx.state._collapsedMenu;
  }

  @Action({ commit: AppSettingsStoreCommit.SetCollapsedMenu })
  protected async setCollapsedMenu(collapsed: boolean) {
    return collapsed ?? AppSettingsDefaults._collapsedMenu;
  }

  @Action({ commit: AppSettingsStoreCommit.SetExpandSubmenus })
  protected async setExpandSubmenus(expand: boolean) {
    return expand ?? AppSettingsDefaults._expandSubmenus;
  }

  @Action({ commit: AppSettingsStoreCommit.SetHiddenBreadcrumb })
  protected async setHiddenBreadcrumb(hidden: boolean) {
    return hidden ?? AppSettingsDefaults._hiddenBreadcrumb;
  }

  @Action({ commit: AppSettingsStoreCommit.SetHiddenLoadingIndicator })
  protected async setHiddenLoadingIndicator(hidden: boolean) {
    return hidden ?? AppSettingsDefaults._hiddenLoadingIndicator;
  }

  @Action({ commit: AppSettingsStoreCommit.SetUiMode })
  protected async setUiMode(mode: UIMode) {
    return mode || AppSettingsDefaults._uiMode;
  }

  @Action({ commit: AppSettingsStoreCommit.SetUiSize })
  protected async setUiSize(size: UISize) {
    return size || AppSettingsDefaults._uiSize;
  }

  @Action({ commit: AppSettingsStoreCommit.SetUiContentSizeMode })
  protected async setUiContentSizeMode(mode: UIContentSizeMode) {
    return mode || AppSettingsDefaults._uiContentSizeMode;
  }

  @Action({ useContext: true })
  protected async restoreAppSettingDefaults(ctx: ActionContext<AppSettingsStoreState, any>) {
    await ctx.dispatch(AppSettingsStoreAction.SetCollapsedMenu, null);
    await ctx.dispatch(AppSettingsStoreAction.SetExpandSubmenus, null);
    await ctx.dispatch(AppSettingsStoreAction.SetHiddenBreadcrumb, null);
    await ctx.dispatch(AppSettingsStoreAction.SetHiddenLoadingIndicator, null);
    await ctx.dispatch(AppSettingsStoreAction.SetUiMode, null);
    await ctx.dispatch(AppSettingsStoreAction.SetUiContentSizeMode, null);
    await ctx.dispatch(AppSettingsStoreAction.SetUiSize, null);
  }

  @Action({ useContext: true })
  protected async loadSettingsFromCookies(ctx: ActionContext<AppSettingsStoreState, any>) {
    let collapsed = Vue.$cookies.get(AppSettingsStoreCookie.CollapsedMenu);
    collapsed = Utils.isString(collapsed) ? collapsed === "true" : collapsed; 
    await ctx.dispatch(AppSettingsStoreAction.SetCollapsedMenu, collapsed);

    let expandSubmenus = Vue.$cookies.get(AppSettingsStoreCookie.ExpandSubmenus);
    expandSubmenus = Utils.isString(expandSubmenus) ? expandSubmenus === "true" : expandSubmenus; 
    await ctx.dispatch(AppSettingsStoreAction.SetExpandSubmenus, expandSubmenus);

    let hiddenBreadcrumb = Vue.$cookies.get(AppSettingsStoreCookie.HiddenBreadcrumb);
    hiddenBreadcrumb = Utils.isString(hiddenBreadcrumb) ? hiddenBreadcrumb === "true" : hiddenBreadcrumb; 
    await ctx.dispatch(AppSettingsStoreAction.SetHiddenBreadcrumb, hiddenBreadcrumb);

    let hiddenLoadingIndicator = Vue.$cookies.get(AppSettingsStoreCookie.HiddenLoadingIndicator);
    hiddenLoadingIndicator = Utils.isString(hiddenLoadingIndicator) ? hiddenLoadingIndicator === "true" : hiddenLoadingIndicator; 
    await ctx.dispatch(AppSettingsStoreAction.SetHiddenLoadingIndicator, hiddenLoadingIndicator);

    const uiSize = Vue.$cookies.get(AppSettingsStoreCookie.UiSize);
    await ctx.dispatch(AppSettingsStoreAction.SetUiSize, uiSize);

    const uiMode = Vue.$cookies.get(AppSettingsStoreCookie.UiMode);
    await ctx.dispatch(AppSettingsStoreAction.SetUiMode, uiMode);

    const uiContentSizeMode = Vue.$cookies.get(AppSettingsStoreCookie.UiContentSizeMode);
    await ctx.dispatch(AppSettingsStoreAction.SetUiContentSizeMode, uiContentSizeMode);
  }

}