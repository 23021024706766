import { BackupStatus } from "@Service/Emory/Models/Backups/Backup.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class BackupStatusTag extends Vue {

  @Prop({ type: String, required: true })
  protected status: BackupStatus;

  protected get type(): "info" | "success" | "danger" | "warning" | "" {
    switch (this.status) {
      case BackupStatus.Completed: return "success";
      case BackupStatus.Started: return "info";
      case BackupStatus.Failed: return "danger";
    }
  }

}