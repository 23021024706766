<template functional>
  <el-skeleton 
    class="org-suggestion-skeleton"
    :loading="props.loading" 
    animated>
    <div slot="template" class="space-y-6">
      <div v-for="index in props.repeat" :key="index" class="flex space-x-16 p-y-6 items-center">
        <div class="flex-1 flex-y space-y-4">
          <el-skeleton-item variant="h3" style="width: 11rem" />
          <el-skeleton-item variant="text" style="width: 17rem" />
        </div>
        <el-skeleton-item variant="button" style="height: 30px; width: 7.5rem;" />
      </div>
    </div>
    <slot />
  </el-skeleton>
</template>

<script>
export default {
  props: { 
    loading: {
      type: Boolean,
      default: false
    },
    repeat: {
      type: Number,
      default: 1
    }
  }
}
</script>