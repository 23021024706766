import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { AccessRole, Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { Execution } from "@Core/Models/Execution";
import { ServiceID } from "@Service/ServiceID";
import { Notify } from "@Core/Utils/Notify";
import Vue from "vue";

interface UserRoleManagerFilters {
  search: string;
  roles: Role[];
}

@Component
export default class UsersRoleManager extends Vue {

  @Prop({ type: Array, required: true, default: () => [] })
  protected users: User[];

  @MapGetter(AuthStore.Mapping)
  protected roles: Role[];

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRole: OrganizationRole;

  @MapAction(UsersStore.Mapping)
  protected updateOrgUserRole: (user: User) => Promise<void>;

  @MapAction(UsersStore.Mapping)
  protected removeUserFromCurrentOrg: (user: User) => Promise<void>;

  protected remove = Execution.create((user: User) => this.removeUserFromCurrentOrg(user))
  protected update = Execution.create((user: User) => this.updateOrgUserRole(user))

  protected filters: UserRoleManagerFilters = {
    search: null,
    roles: []
  }

  protected get currentUserHasManagedRole(): boolean {
    return this.currentOrgRole.role.isManaged;
  }

  protected get isFiltering(): boolean {
    return !!this.filters.search 
      || (!!this.filters.roles && this.filters.roles.length > 0);
  }

  protected get filteredUsers(): User[] {
    const filteredUsers = this.users.filter(user => user.meetsFilter(this.filters.search));
    return this.filters.roles.length === 0 
      ? filteredUsers 
      : filteredUsers.filter(user => this.filters.roles.some(role => {
        const userRoleInCurrentOrg = user.getRemoteOrgRoleForOrg(this.currentOrgRole.organization).role;
        return role.id === userRoleInCurrentOrg.id;
      }));
  }

  protected clearFilters() {
    this.filters = {
      search: null, roles: []
    }
  }

  protected async onRemoveUserFromCurrentOrg(user: User) {
    try {
      const title = `Remove user from organization`;
      const message = `Are you sure you want to remove the user <span class='text-semibold'>${user.username}</span> from <span class='text-semibold'>${this.currentOrgRole.organization.name}</span> organization?`;
      await this.$prompt(message, title, {
        type: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: `Remove user from ${this.currentOrgRole.organization.name}`,
        confirmButtonClass: 'el-button--danger',
        dangerouslyUseHTMLString: true,
        inputErrorMessage: `Type '${user.username}' to confirm`,
        inputValidator: value => value === user.username,
        inputPlaceholder: `Type '${user.username}' to confirm`
      });
      await this.remove.run(user)
      if (this.remove.error) {
        Notify.Error({
          title: "Remove from organization error",
          message: this.remove.error.message
        });
      } else {
        Notify.Success({
          title: "User removed from organization",
          message: `User ${user.username} successfully removed from ${this.currentOrgRole.organization.name} organization.`
        });
      }
    } catch {}
  }

  protected async onUpdateOrgUserRole(user: User) {
    await this.update.run(user);
    if (!!this.update.error) {
      Notify.Error({
        title: "Error updating user access role",
        message: this.update.error.message
      });
    } else {
      Notify.Success({
        title: "User access role updated",
        message: `The access role for ${user.username} has been susccessfully updated.`
      });
    }
  }

  protected get isLoading(): boolean {
    return this.remove.loading || this.update.loading;
  }

  protected get permissions() {
    return {
      CanListOrgUsers: this.accessRole.can(ServiceID.Console, Permission.ListOrgUsers),
      CanDeleteOrgUsers: this.accessRole.can(ServiceID.Console, Permission.DeleteOrgUsers),
      CanEditOrgUsers: this.accessRole.can(ServiceID.Console, Permission.EditOrgUsers),
      CanCreateOrgUsers: this.accessRole.can(ServiceID.Console, Permission.CreateOrgUsers)
    }
  }

}