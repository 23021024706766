import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class DocsLink extends Vue {

  @Prop({ type: String, required: true })
  protected url: string;

  @Prop({ type: String, default: "Embedded documentation" })
  protected tooltip: string;

  @Prop({ type: String, default: "inherit" })
  protected type: "primary" | "secondary" | "inherit" | "danger" | "success";
  
  @Prop({ type: String, default: "start" })
  protected iconPosition: "start" | "end";

  @Prop({ type: String, default: "inherit" })
  protected labelFontSize: string;

  @Prop({ type: String, default: "inherit" })
  protected iconSize: string;

  @Prop({ type: String, default: "4px" })
  protected iconSpacing: string;

  @MapGetter(NavigationStore.Mapping)
  protected docsUrl: string;

  @MapAction(NavigationStore.Mapping)
  protected openDocsPanel: (url: string) => Promise<string>;

  protected get linkStyle() {
    return {
      "font-size": this.labelFontSize
    }
  }

}