import { FileSize, FileSizeUnit } from "@Core/Utils/FileSize";

export class FileSizeFilter {
  public _value: string = null;
  public unit: FileSizeUnit = FileSizeUnit.B;

  public static create(bytes?: number): FileSizeFilter {
    return new FileSizeFilter(bytes);
  }

  private constructor(bytes: number = 0) {
    const fileSize = FileSize.fromBytes(bytes);
    this._value = fileSize.value ? fileSize.value.toString() : null;
    this.unit = fileSize.unit;
  }

  public set value(newVal: string) {
    this._value = newVal !== null 
      ? isNaN(Number(newVal)) 
        ? this._value 
        : newVal 
      : null;
  }

  public get value(): string {
    return this._value;
  }

  public get bytes(): number {
    return Number(this._value) * FileSizeUnit.toBytes(this.unit);
  }
}