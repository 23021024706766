import Dialog from "@Core/Components/Dialog/Dialog";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { User } from "@Module/Users/Models/User.model";
import UsersStore from "@Module/Users/Store/Users.store";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import { DatePickerOptions } from "element-ui/types/date-picker";
import { Component, MapAction, MapGetter, Watch } from "types-vue";
import { Prop } from "vue-property-decorator";
import DayJS from "dayjs";
import Vue from "vue";
import LicensingStore, { CreateLinkeContractPayload } from "@Module/Licensing/Store/Licensing.store";
import { Dimension, IDimensionUnits } from "@Module/Licensing/Models/Dimension.model";
import { Execution } from "@Core/Models/Execution";
import { Notify } from "@Core/Utils/Notify";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";

interface TrialLicense {
  dimension: Dimension;
  dimensionUnits: number;
}

@Component
export default class AdminCreateContractDialog extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(NavigationStore.Mapping)
  protected currentServiceId: ServiceID;

  @MapGetter(LicensingStore.Mapping)
  protected dimensions: ServiceMap<Dimension[]>;
  
  @MapAction(LicensingStore.Mapping)
  private createLinkeContract: (payload: CreateLinkeContractPayload) => Promise<void>;

  protected servicesWithLicensing = ServiceID.WithLicensing
    .map(serviceId => ({ id: serviceId, label: ServiceID.nameOf(serviceId) }))
    .sort((a, b) => a.label.localeCompare(b.label));
  
  protected service: ServiceID = null;
  protected poc: boolean = false;
  protected expiration: Date = null;
  protected origin: string = "";
  protected extendedContractId: Number = null;
  protected licenses: TrialLicense[] = [];
  protected organization: Organization = null;
  protected originsLinke = ContractOrigin.OnlyLinke;
  protected createContract = Execution.create((
    service: ServiceID,
    origin: string,
    org: Organization,
    expiration: Date,
    dimensions: IDimensionUnits[],
    poc: boolean,
    extendedContractId: Number,
  ) => 
    this.createLinkeContract({ 
      service, 
      data: { org, origin, expiration, dimensions, poc, extendedContractId}
    } as CreateLinkeContractPayload)
  );

  public open(): Promise<boolean> {
    const dialog = this.$refs.dialog as Dialog;
    if (!dialog) { 
      return Promise.resolve(false); 
    }
    this.service = this.serviceId;
    this.organization = this.currentOrg;
    return dialog.open();
  }

  protected mounted() {
    this.service = this.serviceId;
    this.organization = this.currentOrg;
  }

  protected onClosed() {
    this.expiration = null;
    this.organization = null;
    this.extendedContractId = null;
    this.poc = false;
    this.licenses = [];
    this.createContract.reset();
  }


  @Watch("origin")
  protected onOriginChanged() {
    if (this.origin !== ContractOrigin.Extended){
      this.extendedContractId = null;
    }
  }

  protected async create() {
    await this.createContract.run(this.service, this.origin, this.organization, this.expiration, this.dimensionUnits, this.poc, this.extendedContractId);
    if (!this.createContract.error) {
      Notify.Success({
        title: "Contract created",
        message: `Contract successfully created for '${this.organization.name}' organization.`
      });
      const dialog = this.$refs.dialog as Dialog;
      dialog.success();
    }
  }

  @Watch("service")
  protected onServiceIdChange() {
    this.licenses = [];
  }

  protected onDimensionSelected(dimension: Dimension) {
    this.licenses.push({ dimension, dimensionUnits: 1 });
  }

  protected shouldIncludeDimension(dimension: Dimension): boolean {
    return !this.licenses.some(license => license.dimension.name === dimension.name);
  }

  protected get dimensionUnits(): IDimensionUnits[] {
    return this.licenses.reduce((acc, curr) => {
      acc.push({ dimension: curr.dimension.name, units: curr.dimensionUnits });
      return acc;
    }, [] as IDimensionUnits[]);
  }

  protected removeLicense(license: TrialLicense) {
    const index = this.licenses
      .findIndex(l => l.dimension.name === license.dimension.name);
    if (index !== -1) {
      this.licenses.splice(index, 1);
    }
  }

  protected get canCreateContract(): boolean {
    return !!this.organization && !!this.expiration && this.licenses.length > 0;
  }

  protected getServiceName(serviceId: ServiceID): string {
    return ServiceID.nameOf(serviceId);
  }

  protected get datePickerOptions(): DatePickerOptions {
    return {
      firstDayOfWeek: 1,
      disabledDate: (time) => time.getTime() < Date.now(),
      shortcuts: [{
        text: 'In 1 Week',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "weeks").toDate())
      }, {
        text: 'In 15 Days',
        onClick: (picker) => picker.$emit("pick", DayJS().add(15, "days").toDate())
      }, {
        text: 'In 1 Month',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "month").toDate())
      }, {
        text: 'In 1 Year',
        onClick: (picker) => picker.$emit("pick", DayJS().add(1, "year").toDate())
      }]
    }
  }

}