import { IOrganizationDto, Organization } from "@Module/Orgs/Models/Organization.model";
import DayJS, { Dayjs } from "dayjs";
import IsEqual from "lodash.isequal";

export interface INotificationDto<TDetails = unknown> {
  id: number;
  type: string;
  sent_by?: string;
  status: string;
  sender_org_id?: number;
  org?: IOrganizationDto;
  created_at: string;
  expiration_date: string;
  updated_at: string;
  details: TDetails;
}

export enum NotificationStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Cancelled = "Cancelled"
}

export type NotificationResponseStatus = NotificationStatus.Accepted 
                                      | NotificationStatus.Rejected 
                                      | NotificationStatus.Cancelled;

export enum NotificationType {
  LicenseSharing = "license-sharing",
  LicenseReturn = "license-return",
  OrgInvitation = "org-invitation",
  OrgAccessRequest = "org-access-request",
  ContractPurchase = "contract-purchase"
}

export interface NotificationRef {
  id: number;
}

export class Notification {
  public readonly id: number;
  public readonly organization?: Organization;
  public readonly sender: string;
  public readonly creation: Dayjs;
  public readonly expiration: Dayjs;
  public readonly modification: Dayjs;
  public readonly status: NotificationStatus;
  public readonly type: NotificationType;
  public readonly senderOrgId?: number;

  public readonly remote: INotificationDto;

  public static readonly SystemSender = "CxLink";

  protected constructor(dto: INotificationDto) {
    this.id = dto.id;
    this.sender = dto.sent_by || Notification.SystemSender;
    this.organization = !!dto.org ? Organization.fromDto(dto.org) : undefined;
    this.creation = DayJS(dto.created_at);
    this.expiration = DayJS(dto.expiration_date);
    this.modification = DayJS(dto.updated_at);
    this.status = dto.status as NotificationStatus;
    this.type = dto.type as NotificationType;
    this.senderOrgId = dto.sender_org_id;
    this.remote = dto;
  }

  public get isLicenseSharing(): boolean {
    return this.type === NotificationType.LicenseSharing;
  }

  public get isLicenseReturn(): boolean {
    return this.type === NotificationType.LicenseReturn;
  }

  public get isOrgInvitation(): boolean {
    return this.type === NotificationType.OrgInvitation;
  }

  public get isOrgAccessRequest(): boolean {
    return this.type === NotificationType.OrgAccessRequest;
  }

  public get isContractPurchase(): boolean {
    return this.type === NotificationType.ContractPurchase;
  }

  public get hasUncommittedChanges(): boolean {
    const dto = JSON.parse(JSON.stringify(this.toDto()));
    const remote = JSON.parse(JSON.stringify(this.remote));
    return !IsEqual(dto, remote);
  }

  protected meetsFilter(search: string): boolean {
    const text = search?.toLowerCase() || "";
    const textMeetsSender = this.sender.toLowerCase().includes(text);
    return textMeetsSender;
  }

  public get label(): string {
    return Notification.labelForType(this.type);
  }

  public static labelForType(type: NotificationType): string {
    switch (type) {
      case NotificationType.OrgInvitation: return "organization invitation";
      case NotificationType.OrgAccessRequest: return "organization access request";
      case NotificationType.LicenseSharing: return "license sharing request";
      case NotificationType.LicenseReturn: return "license return request";
      case NotificationType.ContractPurchase: return "Contract purchase";
    }
  }

  public toDto(): Omit<INotificationDto, "details"> {
    return {
      id: this.id,
      type: this.type,
      sent_by: this.sender,
      status: this.status,
      org: this.organization.toDto(),
      created_at: this.remote.created_at,
      expiration_date: this.remote.expiration_date,
      updated_at: this.remote.updated_at,
      sender_org_id: this.senderOrgId
    } as Omit<INotificationDto, "details">;
  }

}