import { ILicenseDto, License } from "./License.model";
import { ServiceID } from "@Service/ServiceID";
import { AbapSuiteLicense } from "@Service/AbapSuite/Models/AbapSuiteLicense.model";
import { EmoryLicense } from "@Service/Emory/Models/EmoryLicense.model";


export class LicenseFactory {

  public static fromDto(dto: ILicenseDto, service: ServiceID): License {
    switch (service) {
      case ServiceID.Emory: return EmoryLicense.fromDto(dto);
      case ServiceID.AbapSuite: return AbapSuiteLicense.fromDto(dto);
      default: throw new Error(`[LicenseFactory] Unknown service ${service}`);
    }
  }
}