import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { Contract } from "@Module/Licensing/Models/Contract.model";
import { Component, Prop } from "types-vue";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";

@Component
export default class ContractGeneralSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected contract: Contract;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;

  protected get contractCode(): string {
    if (this.$mq.md) return Utils.smartTrim(this.contract.externalId, 14);
    else return Utils.smartTrim(this.contract.externalId, 16);
  }

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }
  
}