import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import { Component, MapAction, MapGetter, Prop, Watch } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import Vue from "vue";

@Component
export default class RespondInvitationPanel extends Vue {

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected isInDialog: boolean;

  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgInvitationLink: OrgInvitationNotificationLink;

  @MapAction(NotificationsInboxStore.Mapping)
  protected setOrgInvitationLink: (link: OrgInvitationNotificationLink | null) => Promise<OrgInvitationNotificationLink | null>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected acceptOrgInvitationLink: (link: OrgInvitationNotificationLink) => Promise<void>;

  @MapAction(OrgsStore.Mapping)
  protected fetchOrgRoles: () => Promise<void>;

  protected acceptInvitation = Execution.create(async (link: OrgInvitationNotificationLink) => {
    await this.acceptOrgInvitationLink(link);
    if (!link.isSignUp) {
      await this.fetchOrgRoles();
    }
    link.accepted = true;
  });

  protected mounted() {
    if (!this.orgInvitationLink.accepted) {
      this.acceptInvitation.run(this.orgInvitationLink);
    }
  }

  @Watch("acceptInvitation.loading", { immediate: true })
  protected onInvitationLoading(loading: boolean) {
    this.$emit("update:disabled", loading);
  }

  protected get isCountdownDisabled(): boolean {
    return this.isInDialog || this.acceptInvitation.loading || !!this.acceptInvitation.error;
  }

  public async closeGuard(close: VoidFunction) {
    if (!this.acceptInvitation.loading) {
      return close(); 
    }
  }

  protected onInvitationAccepted() {
    this.$emit("success", this.orgInvitationLink);
  }

  protected beforeDestroy() {
    this.setOrgInvitationLink(null);
  }    

}