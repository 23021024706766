import { License } from "@Module/Licensing/Models/License.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import OrgSwitchMixin from "@Module/Orgs/Mixins/OrgSwitch.mixin";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Component, MapGetter, Prop, mixins } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";

type ExchangeNotification = LicenseSharingNotification | LicenseReturnNotification;

@Component
export default class LicenseExchangeStatusCell extends mixins(OrgSwitchMixin) {

  @Prop({ type: Object, required: true })
  protected license: License;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, required: true })
  protected ownerCell: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseSharingOutbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseReturnOutbox: LicenseReturnNotification[];

  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseSharingInbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsInboxStore.Mapping)
  protected licenseReturnInbox: LicenseReturnNotification[];

  protected get outboxNotif(): ExchangeNotification {
    return [...this.orgLicenseSharingOutbox, ...this.orgLicenseReturnOutbox]
      .find(notif => notif.isLicenseIncluded(this.license));
  }

  protected get inboxNotif(): ExchangeNotification {
    return [...this.licenseSharingInbox, ...this.licenseReturnInbox]
      .find(notif => notif.isLicenseIncluded(this.license));
  }

  protected get sharedTagConfig() {
    return {
      visible: this.ownerCell 
        ? this.license.isShared && this.license.isSharedToOrg(this.currentOrg)
        : this.license.isShared && !this.license.isSharedToOrg(this.currentOrg),
      org: this.ownerCell
        ? this.license.owner
        : this.license.tenant
    }
  }

}