import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { Component, MapGetter } from "types-vue";
import Vue from "vue";

@Component
export default class RoleTesting extends Vue {

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;
  
  protected searchText: string = "";

  protected consolePermissions = [
    "console:*",
    "console:AccessLegacyService",
    "console:CreateChildOrg",
    "console:MarkOrgAsTest",
    "console:ListAppUsers",
    "console:CreateAppUsers",
    "console:EditAppUsers",
    "console:DeleteAppUsers",
    "console:ListLicensingSummary",
    "console:ListManagedPermissions",
    "console:ListOrgInvitations",
    "console:CancelOrgInvitation",
    "console:InviteOrgMembers",
    "console:EditOrg",
    "console:ListOrgUsers",
    "console:EditOrgUsers",
    "console:CreateOrgUsers",
    "console:DeleteOrgUsers",
    "console:ListFinancialInfo",
    "console:AddFinancialInfo",
    "console:EditFinancialInfo",
  ];

  protected get filteredConsolePermissions() {
    return this.consolePermissions.filter(p => p.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  protected emoryPermissions = [
    "emory:*",
    "emory:ShareLicenses",
    "emory:EditContract",
    "emory:CreateContract",
    "emory:ListContracts",
    "emory:EditContractNotifications",
    "emory:ListLicenses",
    "emory:ListHosts",
    "emory:RegisterHost",
    "emory:UnregisterHost",
    "emory:DownloadOtherResources",
    "emory:DownloadPackages",
    "emory:RecoverLicenseFile",
    "emory:RequestTrial",
    "emory:ExportLicensingSummary",
    "emory:ListBackups",
    "emory:ExportBackups",
    "emory:ManageIssues",
    "emory:PurchaseContract"
  ];

  protected get filteredEmoryPermissions() {
    return this.emoryPermissions.filter(p => p.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  protected nimbusPermissions = [
    "nimbus:*",
    "nimbus:ShareLicenses",
    "nimbus:EditContract",
    "nimbus:CreateContract",
    "nimbus:ListContracts",
    "nimbus:EditContractNotifications",
    "nimbus:ListLicenses",
    "nimbus:ListHosts",
    "nimbus:RegisterHost",
    "nimbus:UnregisterHost",
    "nimbus:DownloadOtherResources",
    "nimbus:DownloadPackages",
    "nimbus:RecoverLicenseFile",
    "nimbus:ExportLicensingSummary",
    "nimbus:ManageIssues",
    "nimbus:PurchaseContract"
  ]

  protected get filteredNimbusPermissions() {
    return this.nimbusPermissions.filter(p => p.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  protected abapSuitePermissions = [
    "abap-suite:*",
    "abap-suite:ShareLicenses",
    "abap-suite:EditContract",
    "abap-suite:CreateContract",
    "abap-suite:ListContracts",
    "abap-suite:EditContractNotifications",
    "abap-suite:ListLicenses",
    "abap-suite:ListHosts",
    "abap-suite:RegisterHost",
    "abap-suite:UnregisterHost",
    "abap-suite:DownloadOtherResources",
    "abap-suite:DownloadPackages",
    "abap-suite:RecoverLicenseFile",
    "abap-suite:ExportLicensingSummary",
    "abap-suite:ManageIssues",
    "abap-suite:PurchaseContract"
  ]

  protected get filteredAbapSuitePermissions() {
    return this.abapSuitePermissions.filter(p => p.toLowerCase().includes(this.searchText.toLowerCase()));
  }

}