import { ContractPurchaseNotification } from "@Module/Notifications/Models/ContractPurchaseNotification.model";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Component, Prop, Watch } from "types-vue"
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue"

@Component
export default class ContractPurchaseOrgSelectionDialog extends Vue {
  
  @Prop({ type: Object, required: true })
  protected notification: ContractPurchaseNotification;

  @Prop({ type: Object, default: null })
  protected value: Organization;

  protected internalValue: Organization = null;

  public open() {
    const dialog = this.$refs.dialog as Dialog;
    return dialog.open();
  }

  @Watch("internalValue")
  protected onInternalValueChange(newValue: Organization) {
    this.$emit("input", newValue);
    this.$emit("update:value", newValue);
  }

  @Watch("value", { immediate: true })
  protected onValueChange(newValue: Organization) {
    this.internalValue = newValue;
  }

  protected get contractOrigin(): string {
    return ContractOrigin.nameOf(this.notification.contractOrigin);
  }

  protected get contractService(): string {
    return ServiceID.nameOf(this.notification.service)
  }

  public get isOpened(): boolean {
    return (this.$refs.dialog as Dialog)?.isOpened ?? false;
  }

}