import AxiosAPI from "@Core/API/AxiosAPI";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { IDimensionDto, IDimensionUnitsDto } from "@Module/Licensing/Models/Dimension.model";
import { ServiceID } from "@Service/ServiceID";
import { GetContractsApiError } from "../Errors/GetContractsApiError";
import { GetDimensionsApiError } from "../Errors/GetDimensionsApiError";
import { GetLicensesApiError } from "../Errors/GetLicensesApiError";
import { RegisterHostApiError } from "../Errors/RegisterHostApiError";
import { UnregisterHostApiError } from "../Errors/UnregisterHostApiError";
import { CreateContractApiError } from "../Errors/CreateContractApiError";
import { IContractDto, IUpdateContractDto } from "../Models/Contract.model";
import { ILicenseDto, ILicenseRecoveryDto } from "../Models/License.model";
import { UpdateContractApiError } from "../Errors/UpdateContractApiError";
import { ExportFormat } from "@Core/Models/ExportFormat.model";
import { GetLicensingExportApiError } from "../Errors/GetLicensingExportApiError";
import { AxiosRequestConfig } from "axios";
import { ILicensingSummaryDto } from "../Models/LicensingSummary.model";
import { IContractsSummaryFiltersDto } from "../Models/ContractsSummaryFilters.model";
import { GetLicensingSummaryApiError } from "../Errors/GetLicensingSummaryApiError";
import { RecoverLicenseApiError } from "../Errors/RecoverLicenseApiError";
import { CheckContractApiError } from "../Errors/ContractsCheckApiError";
import { ILicensingCheckDto } from "../Models/LicensingCheck.model";

const enum RegisterHostResponseFormat {
  File = "file"
}

class LicensingAPI extends AxiosAPI {
  private static instance: LicensingAPI;

  public static get Instance(): LicensingAPI {
    return this.instance || (this.instance = new this());
  }

  protected contractsUrl(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/contracts`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  protected licensesUrl(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/licenses`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  protected hostsUrl(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/hosts`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  protected dimensionsUrl(slug?: string): string {
    const baseUrl = `${process.env.VUE_APP_SERVICE_URL_BACKEND}/dimensions`;
    return !!slug ? `${baseUrl}/${slug}` : baseUrl;
  }

  public async licensingCheck(service: ServiceID): Promise<ILicensingCheckDto> {
    try {
      const url = this.contractsUrl("check");
      const response = await this.axios.get(url, { 
        params: { product: service }
      });
      return response.data;
    } catch (error) {
      const message = "An error occurred while checking contracts. Please try again later.";
      throw new CheckContractApiError(message, {
        cause: error,
        meta: { service }
      });
    }
  }

  public async getLicensingSummary(params: IContractsSummaryFiltersDto): Promise<ILicensingSummaryDto[]> {
    try {
      const url = this.contractsUrl("summary");
      const response = await this.axios.get(url, { params });
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving licensing summary. Please try again later.";
      throw new GetLicensingSummaryApiError(message, {
        cause: error,
        meta: { filters: params }
      });
    }
  }

  public async exportLicensingSummary(
      service: ServiceID, 
      format: ExportFormat
    ): Promise<string> {
      try {
        const url = this.licensesUrl("export");
        const response = await this.axios.get(url, { 
          params: { product: service }
        });
        return response.data;
      } catch (error) {
        const message = "An error occurred while exporting your licensing summary. Please try again later.";
        throw new GetLicensingExportApiError(message, { 
          cause: error, 
          meta: { service, format }
        });
      }
  }

  public async getContracts(service: ServiceID): Promise<IContractDto[]> {
    try {
      const url = this.contractsUrl();
      const response = await this.axios.get(url, { 
        params: { product: service }
      });
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving your contracts. Please try again.";
      throw new GetContractsApiError(message, { 
        cause: error, 
        meta: { service }
      });
    }
  }

  public async getContractById(service: ServiceID, contractId: number): Promise<IContractDto> {
    try {
      const url = this.contractsUrl(contractId.toString());
      const response = await this.axios.get(url, { 
        params: { product: service }
      });
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving your contract. Please try again.";
      throw new GetContractsApiError(message, { 
        cause: error, 
        meta: { service, contractId }
      });
    }
  }

  public async getLicenses(service: ServiceID): Promise<ILicenseDto[]> {
    try {
      const url = this.licensesUrl();
      const response = await this.axios.get(url, { 
        params: { product: service }
      });
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving your licenses. Please try again.";
      throw new GetLicensesApiError(message, { 
        cause: error, 
        meta: { service }
      });
    }
  }

  public async getLicensesByContractId(service: ServiceID, contractId: number): Promise<ILicenseDto[]> {
    try {
      const url = this.licensesUrl();
      const response = await this.axios.get(url, { 
        params: { product: service, contractid: contractId }
      });
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving your contract licenses. Please try again.";
      throw new GetLicensesApiError(message, {
        cause: error,
        meta: { service, contractId }
      })
    }
  }

  public async getLicenseById(service: ServiceID, licenseId: number): Promise<ILicenseDto> {
    try {
      const url = this.licensesUrl(licenseId.toString());
      const response = await this.axios.get(url, { 
        params: { product: service }
      });
      return response.data;
    } catch (error) {
      const message = "An error occurred while retrieving your license. Please try again.";
      throw new GetLicensesApiError(message, {
        cause: error,
        meta: { service, licenseId }
      })
    }
  }

  public async registerUnencryptedHost(
    service: ServiceID,
    org: Organization, 
    licenseId: number,
    registration: object
  ): Promise<string> {
    try {
      const url = this.hostsUrl();
      const config: AxiosRequestConfig = { params: { format: RegisterHostResponseFormat.File } };
      const body = { ...registration, orguuid: org.uuid, licenseId, product: service };
      const response = await this.axios.post(url, body, config);
      return response.data;
    } catch (error) {
      const message = "An error occurred registering your new host. Please try again.";
      throw new RegisterHostApiError(message, {
        cause: error,
        meta: { registration, org, service, licenseId }
      });
    }
  }

  public async registerEncryptedHost(
    service: ServiceID, 
    org: Organization, 
    licenseId: number,
    key: string
  ): Promise<{ encryptedHost: string, license: ILicenseDto }> {
    try {
      const url = this.hostsUrl("encrypted");
      const response = await this.axios.post(url, { 
        orguuid: org.uuid, 
        license_id: licenseId, 
        product: service,
        encrypted_input: key
      });
      return response.data;
    } catch (error) {
      const message = "An error occurred registering your new host. Please try again.";
      throw new RegisterHostApiError(message, {
        cause: error,
        meta: { key, org, service, licenseId }
      });
    }
  }

  public async unregisterHost(service: ServiceID, hostId: number): Promise<ILicenseDto> {
    try {
      const url = this.hostsUrl(hostId.toString());
      const response = await this.axios.delete(url);
      return response.data;
    } catch (error) {
      const message = "An error occurred unregistering your host. Please try again.";
      throw new UnregisterHostApiError(message, {
        cause: error,
        meta: { hostId, service }
      });
    }
  }

  public async getDimensions(service: ServiceID): Promise<IDimensionDto[]> {
    try {
      const url = this.dimensionsUrl();
      const response = await this.axios.get(url, {
        params: { trial: false, product: service }
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while retrieving the service products. Please try again.`;
      throw new GetDimensionsApiError(message, { 
        cause: error, 
        meta: { service }  
      });
    }
  }

  public async createLinkeContract(
    org: Organization,
    origin: string, 
    service: ServiceID, 
    expiration: Date, 
    dimensions: IDimensionUnitsDto[],
    poc: boolean,
    extended_contract_id: number
  ): Promise<IContractDto> {
    try {
      const url = this.contractsUrl("linke");
      const response = await this.axios.post(url, {
        dimensions, 
        product: service, 
        origin: origin,
        orgname: org.name, 
        orguuid: org.uuid, 
        extended_contract_id: extended_contract_id,
        expireson: expiration.toISOString(),
        is_poc: poc
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while creating a new contract for ${org.name}. Please try again.`;
      throw new CreateContractApiError(message, {
        cause: error,
        meta: { org, service, expiration, dimensions }
      });
    }
  }

  public async updateContract(contractId: number, payload: IUpdateContractDto): Promise<IContractDto> {
    try {
      const url = this.contractsUrl(contractId.toString());
      const response = await this.axios.put(url, payload);
      return response.data;
    } catch (error) {
      const message = `An error occurred while updating contract. Please try again.`;
      throw new UpdateContractApiError(message, {
        cause: error,
        meta: { contractId, payload }
      });
    }
  }

  public async recoverLicenseFile(hostId: number, service: ServiceID): Promise<ILicenseRecoveryDto> {
    try {
      const url = this.hostsUrl(hostId.toString());
      const response = await this.axios.get(url, { 
        params: { product: service } 
      });
      return response.data;
    } catch (error) {
      const message = `An error occurred while recovering your license file. Please try again.`;
      throw new RecoverLicenseApiError(message, {
        cause: error,
        meta: { hostId, service }
      });
    }
  }

}

export default LicensingAPI.Instance;