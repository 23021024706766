import { ExpirationThresholdCookie } from "@Module/Licensing/Models/ExpirationThreshold.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import { ContractFilters } from "@Module/Licensing/Models/ContractFilters.model";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { Contract } from "@Module/Licensing/Models/Contract.model";
import { Component, MapAction, MapGetter, Prop } from "types-vue";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Execution } from "@Core/Models/Execution";
import { Watch } from "vue-property-decorator";
import Vue from "vue";

const enum ContractsCookie {
  ExpThreshold = "console.contracts.exp-threshold"
}

@Component
export default class ContractsView extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;
  
  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(LicensingStore.Mapping)
  protected contracts: ServiceMap<Contract[]>;

  @MapAction(LicensingStore.Mapping)
  protected fetchAllContracts: (service: ServiceID) => Promise<Contract[]>;

  protected expandedRows: number[] = [];

  protected filters: ContractFilters = ContractFilters.create();

  protected expThresholdCookie = ExpirationThresholdCookie.create(ContractsCookie.ExpThreshold, { 
    default: { amount: 15, unit: "days" } 
  });

  protected getContracts = Execution.create(() => this.fetchAllContracts(this.serviceId));

  @Watch("sessionToken", { immediate: true })
  protected async onSessionChange(token: SessionToken) {
    if (!!token && !token.hasExpired) {
      this.getContracts.run();
    } else {
      this.getContracts.reset();
    }
  }

  @Watch("serviceContracts")
  protected onTableDataChange(data: Contract[]) {
    this.expandedRows = this.expandedRows.filter(id => data.some(contract => contract.id === id));
    if (data.length === 1 && this.expandedRows.length === 0) {
      this.expandedRows = [data[0].id];
    }
  }
  
  protected get hasContracts(): boolean {
    return this.serviceContracts.length > 0;
  }

  protected get firstLoad(): boolean {
    return !this.hasContracts && this.getContracts.loading;
  }

  protected get serviceContractsStatus(): LicensingStatus[] {
    return this.filteredContracts.map(c => c.status);
  }

  protected get serviceContracts(): Contract[] {
    return this.contracts[this.serviceId] || [];
  }

  protected get filteredContracts(): Contract[] {
    return this.serviceContracts.filter(contract => contract.meetFilters(this.filters));
  }

  protected clearFilters() {
    this.filters = ContractFilters.create();
  }

  protected toggleExpansion(contract: Contract) {
    const index = this.expandedRows.indexOf(contract.id);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [contract.id];
    }
  }

  protected get expandedRowIds(): number[] {
    return this.expandedRows
      .filter(id => this.filteredContracts.some(c => c.id === id));
  }

  protected onViewContract(contract: Contract) {
    this.$emit("select", contract);
  }

  protected originName(origin: ContractOrigin): string {
    return ContractOrigin.nameOf(origin);
  }

  protected get permissions() {
    return {
      CanExportSummary: this.accessRole.can(this.serviceId, Permission.ExportLicensingSummary),
      CanCreateContract: this.accessRole.can(this.serviceId, Permission.CreateContract),
      CanEditContract: this.accessRole.can(this.serviceId, Permission.EditContract),
      CanEditContractNotifications: this.accessRole.can(this.serviceId, Permission.EditContractNotifications)
    }
  }

  protected get slotScope() {
    return {
      error: this.getContracts.error,
      loading: this.getContracts.loading,
      hasRecords: this.hasContracts,
      data: { 
        original: this.serviceContracts, 
        filtered: this.filteredContracts
      }
    }
  }

}