import DayJS, { Dayjs } from "dayjs";

export interface ILeadGeneratorDto {
  id: string;
  name: string;
}

export class LeadGenerator {
  id?: string;
  name: string;

 private constructor(dto: ILeadGeneratorDto ) {
   this.id = dto.id;
   this.name = dto.name;
 }

 public static create(dto?: Partial<ILeadGeneratorDto >): LeadGenerator {
   return new LeadGenerator({
     id: dto?.id,
     name: dto?.name,
   });
 }

 public toDto(): ILeadGeneratorDto {
    return {
      id: this.id,
      name: this.name,
    };
 }

 public static fromDto(dto: ILeadGeneratorDto ): LeadGenerator {
   return new LeadGenerator(dto);
 }
}