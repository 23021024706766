export interface ISignInDto {
  username: string;
  password: string;
}

export class SignIn {
  public email: string;
  public password: string;
  public keepSession: boolean;

  private constructor() {
    this.email = null;
    this.password = null;
    this.keepSession = true;
  }

  public static create() {
    return new SignIn();
  }

  public toDto(): ISignInDto {
    return {
      username: this.email,
      password: this.password
    }
  }
}