import { IOrganizationDto, Organization } from "../Organization.model";
import { IRoleDto, Role } from "@Module/Auth/Models/Roles/AccessRole.model";

export interface IOrganizationRoleDto  {
  role: IRoleDto;
  org: IOrganizationDto;
  is_main: boolean;
}

interface OrgRoleOptions {
  org?: Organization;
  role?: Role;
  isMain: boolean;
  children?: OrganizationRole[];
}

export class OrganizationRole {
  public role: Role;
  public organization: Organization;
  public isMain: boolean;
  public children: OrganizationRole[];

  private constructor(dto?: IOrganizationRoleDto, options: OrgRoleOptions = { isMain: false }) {
    this.role = !!dto?.role ? Role.fromDto(dto.role) : (options.role || null);
    this.organization = !!dto?.org ? Organization.fromDto(dto.org) : (options.org || null);
    this.isMain = dto?.is_main ?? options.isMain ?? false;
    this.children = options.children || [];
  }

  public static fromDto(dto: IOrganizationRoleDto): OrganizationRole {
    return new OrganizationRole(dto);
  }

  public static create(options?: OrgRoleOptions): OrganizationRole {
    return new OrganizationRole(null, options);
  }

  public static noAccess(org: Organization): OrganizationRole {
    return new OrganizationRole(null, {
      org, children: [], isMain: false, role: Role.NoAccess
    });
  }

  public get isFulfilled(): boolean {
    return !!this.organization && !!this.role;
  }

  public toDto(): IOrganizationRoleDto {
    return {
      org: this.organization?.toDto(),
      role: this.role?.toDto(),
      is_main: this.isMain
    }
  }

}