import { Component, Prop } from "types-vue";
import { Dayjs } from "dayjs";
import Vue from "vue";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";

@Component
export default class DateLabel extends Vue {

  @Prop({ type: Object, required: true })
  protected date: Dayjs;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Boolean, default: true })
  protected showRelativeTime: boolean; 

  @Prop({ type: String, default: "MM/DD/YY HH:mm:ss" })
  protected format: string;

  @Prop({ type: String, default: "3px" })
  protected iconSpacing: string;

  @Prop({ type: String })
  protected iconYOffset: string;

  @Prop({ type: String, default: "inherit" })
  protected iconSize: string;

  @Prop({ type: String, default: "end" })
  protected iconPosition: "end" | "start";

  @Prop({ type: String, default: "primary" })
  protected labelType: "primary" | "secondary" | "inherit" | "danger" | "success";

  protected get isPrimaryType(): boolean {
    return this.labelType === "primary";
  }

}