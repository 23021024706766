import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { Component, MapAction, MapGetter, mixins } from "types-vue";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";
import AuthStore from "@Module/Auth/Store/Auth.store";
import SignOutMixin from "@Module/Auth/Mixins/SignOut.mixin";

@Component
export default class UserMenu extends mixins(SignOutMixin) {

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgRole: OrganizationRole;

  @MapGetter(OrgsStore.Mapping)
  protected canSwitchOrg: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected currentRoute: ConsoleRoute;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapAction(AuthStore.Mapping)
  protected openAuthDialog: (context?: string) => Promise<boolean>;

  protected async signOutAndRedirect() {
    await this.performSignOut();
    this.$router.replace(ConsoleRoute.AuthPath).catch(e => e);

  }

  protected get isInOrganizationSetup(): boolean {
    return this.currentRoute.path === ConsoleRoute.OrgConfigPath;
  }

  protected goToOrganizationSetup() {
    this.$router.replace(ConsoleRoute.OrgConfigPath);
  }

  protected goToAppSettings() {
    this.$router.replace("/settings/application").catch(e => e);
  }

  protected goToAccountSettings() {
    this.$router.replace("/settings/account").catch(e => e);
  }


  protected get restoringSession(): boolean {
    return this.authStatus === AuthStatus.RestoringSession;
  }

  protected get signingOut(): boolean {
    return this.authStatus === AuthStatus.SigningOut;
  }

  protected get signingIn(): boolean {
    return this.authStatus === AuthStatus.SigningIn;
  }

  protected get signedIn(): boolean {
    return this.authStatus === AuthStatus.SignedIn;
  }

}