import { Utils } from "@Core/Utils/Utils";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class Loading extends Vue {

  @Prop({ type: String, default: null })
  protected label: string;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: [String, Number], default: "10rem" })
  protected height: string | number;

  protected get loadingStyle() {
    return {
      "height": Utils.isNumber(this.height) 
        ? `${this.height}px` 
        : this.height
    }
  }

}