import { DirectiveBinding } from "vue/types/options";
import { DirectiveOptions } from "vue/types/umd";
import { VNode } from "vue/types/vnode";

export namespace VueClickOutside {
  export const Name = "click-outside";
  export const Definition: DirectiveOptions = {
    bind: (el: HTMLElement & { clickOutsideEvent: (event: any) => void }, binding: DirectiveBinding, vnode: VNode): void => {
      el.clickOutsideEvent = function(event: any) {
        // here I check that click was outside the el and his childrens
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: (el: HTMLElement & { clickOutsideEvent: (event: any) => void }) => {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    }
  };
}