import { UserStatusPhase, UserStatus } from "@Module/Users/Models/UserStatus.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class UserStatusTag extends Vue {

  @Prop({ type: Object, required: true })
  protected status: UserStatus;

  protected get type(): "info" | "success" | "danger" | "warning" | "" {
    switch (this.status.phase) {
      case UserStatusPhase.Confirmed: return "success";
      case UserStatusPhase.Archived: return "info";
      case UserStatusPhase.Compromised: return "danger";
      case UserStatusPhase.Unknown: return "";
      case UserStatusPhase.Unconfirmed:
      case UserStatusPhase.ResetRequired:
      case UserStatusPhase.ForcePasswordChange: 
        return "warning";
    }
  }

}