import { OrganizationRole } from "@Module/Orgs/Models/Roles/OrganizationRole.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class OrgRoleSummary extends Vue {

  @Prop({ type: Object, required: true })
  protected orgRole: OrganizationRole;

  @Prop({ type: Boolean, default: false })
  protected expand: boolean;

  @Prop({ type: String, default: "" })
  protected apiKey: string;

}