import ServiceViewport from "@Core/Components/ServiceViewport/ServiceViewport.vue";
import { User } from "@Module/Users/Models/User.model";
import { Console } from "vue-router";

import AppSettings from './Views/AppSettings/AppSettings.vue';
import AccountSettings from './Views/AccountSettings/AccountSettings.vue';
import OrganizationSetup from "./Views/OrganizationSetup/OrganizationSetup.vue";

const routes: Console.RouteConfig[] = [
  {
    path: "/settings",
    name: "settings",
    component: ServiceViewport,
    redirect: { name: "application-settings" },
    meta: {
      authRequired: true,
      appInfo: {
        version: "2.5.0",
        summary: "SAP add-on, completely written in ABAP language, enables SAP systems to integrate with AWS in an unprecedented manner."
      },
      menu: {
        label: "CxLink Settings",
        groupId: "internal",
        replaceNavigation: true,
        icon: {
          type: "custom",
          name: "icons/settings.svg"
        }
      }
    },
    children: [
      {
        path: "application",
        name: "application-settings",
        component: AppSettings,
        meta: {
          authRequired: true,
          menu: {
            label: "Application Settings",
            icon: {
              type: "element",
              name: "el-icon-set-up"
            }
          }
        }
      },
      {
        path: "account",
        name: "account-settings",
        component: AccountSettings,
        meta: {
          authRequired: true,
          menu: {
            label: "Account Settings",
            icon: {
              type: "ion-icon",
              name: "person-circle"
            }
          }
        }
      },
      {
        path: "/settings/account/org-setup",
        name: "org-setup",
        component: OrganizationSetup,
        meta: {
          authRequired: true,
          canAccessUser: (user?: User) => user?.allOrgRoles.length === 0 ?? false,
          breadcrumb: {
            prependPaths: ["/settings/account"]
          },
          menu: {
            hidden: true,
            selectionPath: "/settings/account",
            label: "Organization Setup",
            icon: {
              type: "element",
              name: "el-icon-office-building"
            }
          }
        }
      }
    ]
  }
];

export default routes
