import { ILicenseDto, License } from "@Module/Licensing/Models/License.model";
import { IHostDto } from "@Module/Licensing/Models/Host.model";
import { AbapSuiteHost } from "./AbapSuiteHost.model";
import { ServiceID } from "@Service/ServiceID";

export class AbapSuiteLicense extends License<AbapSuiteHost> {

  protected constructor(dto: ILicenseDto) {
    super(dto, ServiceID.AbapSuite);
  }

  protected parseHosts(dtos: IHostDto[]): AbapSuiteHost[] {
    return dtos.map(AbapSuiteHost.fromDto);
  }

  public static fromDto(dto: ILicenseDto): AbapSuiteLicense {
    return new AbapSuiteLicense(dto);
  }

}