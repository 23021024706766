import { render, staticRenderFns } from "./OrgRoleEditor.vue?vue&type=template&id=5f0b48b3&"
import script from "./OrgRoleEditor.ts?vue&type=script&lang=ts&"
export * from "./OrgRoleEditor.ts?vue&type=script&lang=ts&"
import style0 from "./OrgRoleEditor.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports