import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import ResourcesStore from "@Module/Resources/Store/Resources.store";
import TrialStore from "@Module/Trial/Store/Trial.store";
import UsersStore from "@Module/Users/Store/Users.store";
import EmoryBackupsStore from "@Service/Emory/Store/EmoryBackups.store";
import { Component, MapAction } from "types-vue";
import Vue from "vue";

@Component
export default class StateCleanerMixin extends Vue {

  @MapAction(TrialStore.Mapping)
  protected clearTrialStore: () => Promise<void>;

  @MapAction(ResourcesStore.Mapping)
  protected clearResourcesStore: () => Promise<void>;

  @MapAction(LicensingStore.Mapping)
  protected clearLicensingStore: () => Promise<void>;

  @MapAction(EmoryBackupsStore.Mapping)
  protected clearEmoryBackupsStore: () => Promise<void>;
  
  @MapAction(UsersStore.Mapping)
  protected clearUsersStore: () => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected clearNotificationInboxStore: () => Promise<void>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected clearNotificationsStore: () => Promise<void>;

  protected async clearState() {
    await this.clearTrialStore();
    await this.clearResourcesStore();
    await this.clearLicensingStore();
    await this.clearEmoryBackupsStore();
    await this.clearNotificationInboxStore();
    await this.clearNotificationsStore();
    await this.clearUsersStore();
  }

}