import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import NewPwdForm from "../NewPwdForm/NewPwdForm";
import { Component, MapGetter } from "types-vue";
import AuthApi from "@Module/Auth/API/Auth.api";
import { Notify } from "@Core/Utils/Notify";
import Vue from "vue";

@Component
export default class ChangePwdDialog extends Vue {

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  protected newPassword: string = null;
  protected changePwd = Execution.create(AuthApi.changePassword, AuthApi);

  public open(): Promise<boolean> {
    return (this.$refs.dialog as Dialog).open();
  }
  
  protected async changePassword() {
    this.changePwd.reset();
    await this.changePwd.run(this.sessionUser.username, this.newPassword);
    if (!this.changePwd.error) {
      Notify.Success({ 
        title: "Password Changed",
        message: "Your account password has been successfully changed.",
        duration: 3000
      });
      (this.$refs.dialog as Dialog).success();
    }
  }

  protected onPasswordChange(value: string, valid: boolean) {
    this.newPassword = valid ? value : null;
  }

}