import { Execution } from "@Core/Models/Execution";
import { SessionToken } from "@Module/Auth/Models/SessionToken.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import { License } from "@Module/Licensing/Models/License.model";
import { LicensingStatus } from "@Module/Licensing/Models/LicensingStatus.model";
import LicensingStore, { FetchLicensesPayload } from "@Module/Licensing/Store/Licensing.store";
import { ServiceID, ServiceMap } from "@Service/ServiceID";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapAction, MapGetter, mixins, Watch } from "types-vue";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Prop } from "vue-property-decorator";
import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { ExpirationThresholdCookie } from "@Module/Licensing/Models/ExpirationThreshold.model";
import { Host } from "@Module/Licensing/Models/Host.model";
import { Serializer } from "@Core/Components/QueryParam/QueryParam";
import { Dimension } from "@Module/Licensing/Models/Dimension.model";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import { Utils } from "@Core/Utils/Utils";
import OrgSwitchMixin from "@Module/Orgs/Mixins/OrgSwitch.mixin";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import { LicenseSharingNotification } from "@Module/Notifications/Models/LicenseSharingNotification.model";
import { LicenseReturnNotification } from "@Module/Notifications/Models/LicenseReturnNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";

const enum LicensesCookie {
  ExpThreshold = "console.licenses.exp-threshold",
}

@Component
export default class LicensesView extends mixins(OrgSwitchMixin) {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @Prop({ type: Object, default: () => LicenseFilters.create() })
  protected filters: LicenseFilters;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrgHasChildren: boolean;

  @MapGetter(OrgsStore.Mapping)
  protected orgArrayParamSerializer: Serializer<Organization[]>;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(LicensingStore.Mapping)
  protected licenses: ServiceMap<License[]>;

  @MapGetter(LicensingStore.Mapping)
  protected dimensionArrayParamSerializer: Serializer<Dimension[]>;

  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseSharingOutbox: LicenseSharingNotification[];
  
  @MapGetter(NotificationsOutboxStore.Mapping)
  protected orgLicenseReturnOutbox: LicenseReturnNotification[];

  @MapAction(NotificationsOutboxStore.Mapping)
  protected fetchPendingLicenseExchanges: () => Promise<void>;

  @MapAction(LicensingStore.Mapping)
  protected fetchAllLicenses: (payload: FetchLicensesPayload) => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected fetchInbox: () => Promise<void>;

  protected shouldDisplayTenantColumn: boolean = false;
  protected shouldDisplayOwnerColumn: boolean = false;
  protected shouldDisplayHostsColumns: boolean = false;

  protected expandedRows: number[] = [];

  protected expThresholdCookie = ExpirationThresholdCookie.create(LicensesCookie.ExpThreshold, { 
    default: { amount: 15, unit: "days" } 
  });

  protected get serviceLicenses(): License[] {
    return this.licenses[this.serviceId] || [];
  }

  protected get serviceLicenseStatus(): LicensingStatus[] {
    return this.filteredLicenses.map(l => l.status);
  }
  
  protected getLicenses = Execution.create(() => this.fetchAllLicenses({ service: this.serviceId }));
  protected getPendingExchanges = Execution.create(() => {
    return Promise.all([
      this.fetchInbox(),
      this.fetchPendingLicenseExchanges()
    ]);
  });

  protected get isServiceEmory(): boolean {
    return this.serviceId === ServiceID.Emory
  }

  protected created() {
    if (this.permissions.CanShareLicenses) {
      this.getPendingExchanges.run();
    }
    this.updateLicenseSharingColumns();
  }

  protected updateLicenseSharingColumns() {
    this.shouldDisplayTenantColumn = this.currentOrgHasChildren || this.serviceLicenses.some(license => license.isShared && !license.isSharedToOrg(this.currentOrg));
    this.shouldDisplayOwnerColumn = !!this.currentOrg.parentId || this.serviceLicenses.some(license => !license.isOwnedByOrg(this.currentOrg));
  }

  protected get isShowingBothSharingColumns(): boolean {
    return this.shouldDisplayOwnerColumn && this.shouldDisplayTenantColumn;
  }

  @Watch("sessionToken", { immediate: true })
  protected async onSessionChange(token: SessionToken) {
    if (!!token && !token.hasExpired) {
      this.getLicenses.run();
    } else {
      this.getLicenses.reset();
    }
  }

  @Watch("serviceLicenses")
  protected async onTableDataChange(data: License[]) {
    this.expandedRows = this.expandedRows.filter(id => data.some(license => license.id === id));
    if (data.length === 1 && this.expandedRows.length === 0) {
      this.expandedRows = [data[0].id];
    }
    await Utils.sleep(100);
    await this.$nextTick();
    this.updateLicenseSharingColumns();

    this.shouldDisplayHostsColumns = false
    this.serviceLicenses.forEach(license => {
      if (license.hosts.length > 0) {
       this.shouldDisplayHostsColumns = true
      }
     })
  }

  protected get filteredLicenses(): License[] {
    return this.serviceLicenses.filter(license => license.meetFilters(this.filters));
  }

  protected get allFilteredLicenseHosts(): Host[] {
    return this.filteredLicenses.map(license => license.hosts).flat();
  }

  protected get hasLicenses(): boolean {
    return this.serviceLicenses.length > 0;
  }

  protected get firstLoad(): boolean {
    return !this.hasLicenses && this.getLicenses.loading;
  }

  protected clearFilters() {
    this.filters.reset();
  }

  protected shouldShowExchangeLoading(license: License): boolean {
    return this.getPendingExchanges.loading
      && !this.getLicenseExchangePending(license);
  }

  protected hasHosts(): boolean {
    let hasHosts: boolean = false
     this.serviceLicenses.forEach(license => {
      if (license.hosts.length > 0) {
        hasHosts = true
      }
     })
     return hasHosts
  }

  protected licenseHasHosts(license: License): boolean {
    return license.hosts.length > 0
  }
  
  protected getHostName(license: License): string {
    return license.hosts.length > 0 ? license.hosts[0].hostname : "No Host"
  }

  protected getClientVersion(license: License): string {
    return license.hosts.length > 0 ? license.hosts[0].clientVersion : "No version info"
  }



  protected getLicenseExchangePending(license: License) {
    return this.getLicenseSharingPending(license) 
        || this.getLicenseReturnPending(license);
  }

  protected getLicenseSharingPending(license: License) {
    return this.orgLicenseSharingOutbox.find(noti => noti.isLicenseIncluded(license));
  }

  protected getLicenseReturnPending(license: License) {
    return this.orgLicenseReturnOutbox.find(noti => noti.isLicenseIncluded(license));
  }

  protected toggleExpansion(license: License) {
    const index = this.expandedRows.indexOf(license.id);
    if (index >= 0) {
      this.expandedRows.splice(index, 1);
    } else {
      this.expandedRows = [license.id];
    }
  }

  protected get expandedRowIds(): number[] {
    return this.expandedRows
      .filter(id => this.filteredLicenses.some(l => l.id === id));
  }

  protected onViewLicenseClick(license: License) {
    this.$emit("select", license);
  }

  protected onViewContractClick(license: License) {
    this.$emit("select-contract", license);
  }

  protected licenseSlotScope(license: License) {
    return {
      ...this.slotScope,
      license,
      exchangeStatus: {
        loading: this.getPendingExchanges.loading,
        isShared: license.isShared,
        isSharedToCurrentOrg: license.isShared && license.isSharedToOrg(this.currentOrg),
        isPending: !!this.getLicenseExchangePending(license),
        sharing: this.getLicenseSharingPending(license),
        return: this.getLicenseReturnPending(license)
      }
    }
  }

  protected get slotScope() {
    return {
      error: this.getLicenses.error,
      loading: this.getLicenses.loading,
      hasRecords: this.hasLicenses,
      data: { 
        original: this.serviceLicenses, 
        filtered: this.filteredLicenses
      }
    }
  }

  protected get permissions() {
    return {
      CanExportSummary: this.accessRole.can(this.serviceId, Permission.ExportLicensingSummary),
      CanListContracts: this.accessRole.can(this.serviceId, Permission.ListContracts),
      CanListHosts: this.accessRole.can(this.serviceId, Permission.ListHosts),
      CanShareLicenses: this.accessRole.can(this.serviceId, Permission.ShareLicenses)
    }
  }

  protected get chanShareLicenses(): boolean {
    const canShare = this.currentOrgHasChildren && this.serviceLicenses.some(license => license.status.isActiveOrInGrace);
    const canReturnToParent = this.serviceLicenses.some(license => license.isSharedToOrg(this.currentOrg));
    const canReturnFromChild = this.serviceLicenses.some(license => license.isShared && !license.isSharedToOrg(this.currentOrg));
    return this.permissions.CanShareLicenses && (canShare || canReturnToParent || canReturnFromChild);
  }

  protected sortByOwner(a: License, b: License): number {
    return a.owner.name.localeCompare(b.owner.name);
  }

  protected sortByTenant(a: License, b: License): number {
    return a.tenant?.name.localeCompare(b.tenant?.name) ?? -1;
  }

}