import { BackupHistoryPage } from "@Service/Emory/Models/Backups/BackupHistoryPage.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { BackupObject } from "@Service/Emory/Models/Backups/BackupObject.model";
import EmoryBackupsStore from "@Service/Emory/Store/EmoryBackups.store";
import NavigationStore from "@Core/Store/Navigation/Navigation.store";
import { Backup } from "@Service/Emory/Models/Backups/Backup.model";
import EmoryBackupsApi from "@Service/Emory/API/EmoryBackups.api";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { ConsoleError } from "@Core/Errors/ConsoleError";
import { Execution } from "@Core/Models/Execution";
import { Component, MapGetter } from "types-vue";
import { Watch } from "vue-property-decorator";
import { Utils } from "@Core/Utils/Utils";
import Vue from "vue";


const enum Section {
  Failure = "failure",
  System = "system",
  Lifecycle = "lifecycle",
  Cloud = "cloud",
  Objects = "objects"
}

@Component
export default class EmoryBackupDetails extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected previousRoute: ConsoleRoute;

  @MapGetter(EmoryBackupsStore.Mapping)
  protected currentBackupHistoryPage: BackupHistoryPage;

  protected error: ConsoleError = null;
  protected backup: Backup = null;
  protected getBackupById = Execution.create(EmoryBackupsApi.getBackup, EmoryBackupsApi);
  protected allSections: Section[] = [ Section.Failure, Section.System, Section.Lifecycle, Section.Cloud, Section.Objects];
  protected expandedSections: Section[] = this.allSections;
  protected backupObjectsSearchText: string = null;

  @Watch("$route.params.id", { immediate: true })
  protected async onIdParamChange() {
    this.error = null;
    this.getBackupById.reset();
    await this.$nextTick();

    const backupId = Number(this.$route.params.id);
    if (isNaN(backupId)) {
      const errorMsg = `Invalid backup identifier '${this.$route.params.id}'.`;
      this.error = new ConsoleError(errorMsg, { fatal: true, meta: { backupId: this.$route.params.id } });
      return;
    }

    this.backup = this.currentBackupHistoryPage?.records.find(backup => backup.id === backupId) || null;
    if (!this.backup) {
      const backupDto = await this.getBackupById.run(backupId);
      if (!!this.getBackupById.error) {
        const errorMsg = `Backup with identifier ${this.$route.params.id} was not found.`;
        this.error = new ConsoleError(errorMsg, { fatal: true, meta: { backupId: this.$route.params.id } });
        return;
      } else {
        this.backup = Backup.fromDto(backupDto);
      }
    }
  }

  protected get backupLicenseUuid(): string {
    if (this.$mq.md) return Utils.smartTrim(this.backup.licenseUuid, 16);
    else if (this.$mq.lg) return Utils.smartTrim(this.backup.licenseUuid, 18);
    else return Utils.smartTrim(this.backup.licenseUuid, 24);
  }

  protected get backupHostUuid(): string {
    if (this.$mq.md) return Utils.smartTrim(this.backup.host.uuid, 16);
    else if (this.$mq.lg) return Utils.smartTrim(this.backup.host.uuid, 18);
    else return Utils.smartTrim(this.backup.host.uuid, 24);
  }

  protected get backupHostname(): string {
    if (this.$mq.md) return Utils.smartTrim(this.backup.host.hostname, 18);
    else if (this.$mq.lg) return Utils.smartTrim(this.backup.host.hostname, 26);
    else return Utils.smartTrim(this.backup.host.hostname, 34);
  }

  protected get backupFilteredObjects(): BackupObject[] {
    return this.backup.objects
      .filter(object => object.meetsFilter(this.backupObjectsSearchText));
  }

  protected resetFilters() {
    this.backupObjectsSearchText = null;
  }

  protected get tagsContainerStyle() {
    return {
      "min-width": (this.backup.tags.length ?? 0) > 6 ? "100%" : "40%"
    }
  }

  protected viewLicense() {
    this.$router.push({
      name: "backup-license-details", 
      params: { id: this.backup.licenseId.toString() },
      query: { expand: this.backup.host.id.toString() }
    });
  }

  protected get allSectionsCollapsed(): boolean {
    return this.expandedSections.length === 0;
  }

  protected toggleExpandAll() {
    this.expandedSections = this.allSectionsCollapsed ? this.allSections : [];
  }

  protected onBackClick() {
    if (!!this.previousRoute) {
      this.$router.back();
    } else {
      this.$router.replace("/backup/history");
    }
  }

}