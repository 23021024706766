import { Component } from "types-vue";
import Vue from "vue";

export interface FileDownloadOptions {
  base64: boolean;
}

@Component
export default class FileDownloadMixin extends Vue {

  protected async downloadFile(fileName: string, content: string, options?: FileDownloadOptions): Promise<void> {
    const base64 = options?.base64 ?? false;
    const element = document.createElement("a");
    element.setAttribute("href", `data:application/octet-stream;charset=utf-8${base64 ? ';base64' : ''},` + encodeURIComponent(content));
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}