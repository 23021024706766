import { User } from "@Module/Users/Models/User.model";
import UsersStore from "@Module/Users/Store/Users.store";
import { Component, MapGetter, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class SuperadminButton extends Vue {
  
  @Prop({ type: String, default: null })
  protected size: string;

  @Prop({ type: String, default: null })
  protected icon: string;

  @Prop({ type: Boolean, default: false })
  protected loading: boolean;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: Boolean, default: false })
  protected autofocus: boolean;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  protected get iconStyle() {
    return {
      "margin-right": !!this.$slots.default ? "6px" : "0"
    }
  }

}