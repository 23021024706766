import { INotificationDto, Notification, NotificationRef } from "./Notification.model";
import { IRoleDto, Role } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";

interface IOrgInvitationDetailsDto {
  role: IRoleDto;
  user_id: string;
}

type IOrgInvitationNotificationDto = INotificationDto<IOrgInvitationDetailsDto>;

export class OrgInvitationNotification extends Notification {

  public readonly organization: Organization;
  public readonly recipient: string;
  public readonly role: Role;

  public readonly detailsRemote: IOrgInvitationDetailsDto;

  private constructor(dto: IOrgInvitationNotificationDto) {
    super(dto);
    this.role = Role.fromDto(dto.details.role);
    this.recipient = dto.details.user_id;
    this.detailsRemote = dto.details;
  }

  public static fromDto(dto: IOrgInvitationNotificationDto): OrgInvitationNotification {
    return new this(dto);
  }

  public meetFilters(search: string, roles: Role[]): boolean {
    const text = search?.toLowerCase() || "";

    if (!!roles && roles.length > 0) {
      const meetsRole = roles.some(r => r.id === this.detailsRemote.role.id);
      if (!meetsRole) return false;
    }

    const textMeetsRecipient = this.recipient.toLowerCase().includes(text);
    const textMeetsSender = this.sender.toLowerCase().includes(text);
    const textMeetsRole = this.role.name.toLowerCase().includes(text);
    return super.meetsFilter(search) || textMeetsRecipient || textMeetsSender || textMeetsRole;
  }
  
  public toDto(): INotificationDto<IOrgInvitationDetailsDto> {
    return {
      ...super.toDto(),
      details: {
        user_id: this.recipient,
        role: this.role.toDto()
      }
    }
  }
}

export interface IOrgInvitationLinkParams {
  email: string;
  orgname: string;
  invitation: string;
}

export class OrgInvitationNotificationLink {
  public readonly notificationId: number;
  public readonly email: string;
  public readonly orgName: string;
  
  public accepted: boolean = false;
  public isSignUp: boolean = false;

  private constructor(params: IOrgInvitationLinkParams) {
    this.notificationId = parseInt(params.invitation);
    this.orgName = params.orgname;
    this.email = params.email;
  }

  public get notificationRef(): NotificationRef {
    return { id: this.notificationId };
  }

  public static fromQuery(query: any): OrgInvitationNotificationLink | null {
    if (this.hasOrgInvitationLink(query)) {
      return new OrgInvitationNotificationLink(query);
    } else {
      return null;
    }
  }

  public static clearQuery(query: any): any {
    delete query.email;
    delete query.orgname;
    delete query.invitation;
    return query;
  }

  public static hasOrgInvitationLink(query: any): query is IOrgInvitationLinkParams {
    if (!query) return false;
    const invitation = query as IOrgInvitationLinkParams;
    return !!invitation.email && !!invitation.orgname && !!invitation.invitation;
  }

}