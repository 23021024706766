import { AMI } from "@Service/Preinstalled/Models/AMI.model";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { Component, MapGetter } from "types-vue";
import Vue from "vue";

enum AmiCategory {
  S4HANA = "SAP S/4 HANA",
  BW4HANA = "SAP BW/4HANA",
  HANAStandalone = "SAP HANA Standalone",
  Netweaver = "SAP Netweaver",
  ERP = "SAP ERP",
  SolutionManager = "SAP Solution Manager",
  SCM = "SAP SCM",
}

@Component
export default class AmisCatalog extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  protected categories: AmiCategory[] = [];
  protected searchText: string = null;

  protected get filteredAmis(): AMI[] {
    const text = this.searchText?.toLowerCase() || "";
    return this.amis.filter(a => {
      if (this.categories.length > 0 && !this.categories.includes(a.category as AmiCategory)) {
        return false;
      }
      return a.name.toLowerCase().includes(text) 
        || a.description.toLowerCase().includes(text)
        || a.category.toLowerCase().includes(text);
    });
  }

  protected clearFilters() {
    this.searchText = null;
    this.categories = [];
  }

  protected get categoryOptions(): string[] {
    return [...new Set(this.amis.map(ami => ami.category))];
  }

  protected onAmiSelected(ami: AMI) {
    window.open(ami.url);
  }

  protected amis: AMI[] = [
    {
      name: "SAP NW 7.50 Java ASE on Suse",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B084GTTDQ7?qid=1581523344533&sr=0-4&ref_=srh_res_product_title",
      category: AmiCategory.Netweaver
    },
    {
      name: "SAP BW/4HANA 2.0 Suse",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B084GS6VXG?qid=1581523344533&sr=0-5&ref_=srh_res_product_title",
      category: AmiCategory.BW4HANA
    },
    {
      name: "SAP NW 7.52 ABAP ASE on Suse",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B084H39JHV?qid=1581523344533&sr=0-3&ref_=srh_res_product_title",
      category: AmiCategory.Netweaver
    },
    {
      name: "SAP S/4 HANA Release 1909 Suse",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B084GS3HM3?qid=1581523344533&sr=0-6&ref_=srh_res_product_title",
      category: AmiCategory.S4HANA
    },
    {
      name: "SAP S/4 HANA Release 1809 Suse",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B07W4Q34RF?qid=1581523344533&sr=0-1&ref_=srh_res_product_title",
      category: AmiCategory.S4HANA
    },
    {
      name: "SAP S/4 HANA Release 1709 Suse",
      description: "Includes complete installation for both SAP S/4 software and database.",
      url: "https://aws.amazon.com/marketplace/pp/B07CF7P2QZ?qid=1529320455993&sr=0-1&ref_=srh_res_product_title",
      category: AmiCategory.S4HANA
    },
    {
      name: "SAP S/4 HANA Release 1709 RedHat",
      description: "Includes complete installation for both SAP S/4 software and database.",
      url: "https://aws.amazon.com/marketplace/pp/B07BBP5HQ8?qid=1529320365097&sr=0-3&ref_=srh_res_product_title",
      category: AmiCategory.S4HANA
    },
    {
      name: "SAP SCM 7.0 Ehp4 on HANA",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B07C7JDZRN?qid=1529320620249&sr=0-1&ref_=srh_res_product_title",
      category: AmiCategory.SCM
    },
    {
      name: "SAP ERP 6.0 EHP8 Linux/MaxDb",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B077GTDMQM?qid=1522225348955&sr=0-3&ref_=srh_res_product_title",
      category: AmiCategory.ERP
    },
    {
      name: "SAP NW 7.5 on HANA",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B0748Z1WJK?qid=1522233441439&sr=0-1&ref_=srh_res_product_title",
      category: AmiCategory.Netweaver
    },
    {
      name: "SAP Sol. Manager 7.2SR1 ASE Suse",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B081QSL8L2",
      category: AmiCategory.SolutionManager
    },
    {
      name: "SAP HANA 2.0 SP04 Suse",
      description: "Includes complete installation for both SAP and RDBMS.",
      url: "https://aws.amazon.com/marketplace/pp/B0844XZ2KL",
      category: AmiCategory.HANAStandalone
    }
  ];
}